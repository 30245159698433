import {createSelector} from 'reselect';

import {hiddenLocationDataSelector} from '../../router/flux/selectors/LocationSelectors.js';

export function createScrollPositionSelector(componentId) {
	const scrollPositionPath = getScrollPositionPropertyPath(componentId);
	return createSelector(hiddenLocationDataSelector,
		hiddenLocationData => hiddenLocationData.getIn(scrollPositionPath, null)
	);
}

export function getScrollPositionPropertyPath(componentId) {
	return ['scrollPositions', componentId];
}
