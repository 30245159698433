import React, {useCallback, useEffect, useRef} from 'react';
import {Tab as MuiTab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';

import {FULLSCREEN_TRANSITION_TIME} from '../../commons/constants/FullScreenWrapperConstants.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';

export {TabBar, Tab};

function TabBar(props) {
	const {className, activeTabIndex, onTabActivated, onDarkBg, children} = props;
	const actionRef = useRef();
	useEffect(() => {
		const timeOutID = window.setTimeout(() => {
			const {updateIndicator} = actionRef.current;
			callSafe(updateIndicator);
		}, FULLSCREEN_TRANSITION_TIME);
		return () => {
			window.clearTimeout(timeOutID);
		};
	}, []);
	const onChangeHandler = useCallback((event, value) => {
		callSafe(onTabActivated, value);
	}, [onTabActivated]);
	const textColor = onDarkBg ? 'inherit' : undefined;
	const indicatorColor = onDarkBg ? 'secondary' : undefined;
	return (
		<Tabs action={actionRef} className={className} value={activeTabIndex} onChange={onChangeHandler}
		      textColor={textColor} indicatorColor={indicatorColor} variant='fullWidth' centered>
			{children}
		</Tabs>
	);
}

TabBar.propTypes = {
	className: PropTypes.string,
	activeTabIndex: PropTypes.number,
	onTabActivated: PropTypes.func,
	onDarkBg: PropTypes.bool
};

TabBar.defaultProps = {
	activeTabIndex: false
};

function Tab(props) {
	const {label, ...remainingProps} = props;
	return <MuiTab label={label} {...remainingProps} />;
}

Tab.propTypes = {
	label: PropTypes.node
};
