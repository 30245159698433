import React, {useCallback, useRef} from 'react';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import {useChangedValueOrState, useMemoFactory} from '../../../commons/utils/customHooks';
import {createDateMoment} from '../../../commons/utils/DateUtils.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import {wrapInLocalizationContext} from '../../../i18n/components/LocalizationContextWrapper.js';
import Month from './Month.js';
import MonthNavigation from './MonthNavigation.js';
import WeekHeader from './WeekHeader.js';

import '../../../../styles/material-design/components/MaterialDatePicker.scss';

function MaterialDatePicker(props) {
	const {value, disabled, onChange, selectionRange, locale, className, name} = props;
	const propsValue = value ? value.startOf('day') : createDateMoment();
	const [dateState, setDateState] = useChangedValueOrState(propsValue, momentsAreSame);
	const datePicker = useRef(null);
	const pickerContainerClassName = useMemoFactory(buildContainerClassNames, disabled, className);
	const focusDatePicker = useCallback(() => {
		if (datePicker.current) {
			datePicker.current.focus();
		}
	}, [datePicker]);

	return (
		<div ref={datePicker} className={pickerContainerClassName} tabIndex='-1'>
			<MonthNavigation date={dateState} setDate={setDateState} locale={locale} name={name}
								  onFastBlurAfterChange={focusDatePicker} disabled={disabled} />
			<table className='material-date-picker--calendar'>
				<WeekHeader locale={locale} />
				<tbody>
					<Month selectedDay={dateState} selectionRange={selectionRange}
							 disabled={disabled} onSelectDay={onChange} />
				</tbody>
			</table>
		</div>
	);
}

function momentsAreSame(momentA, momentB) {
	return momentA === momentB || Boolean(momentA) && Boolean(momentA.isSame) && momentA.isSame(momentB);
}

MaterialDatePicker.propTypes = {
	disabled: PropTypes.bool,
	selectionRange: PropTypes.object,
	onChange: PropTypes.func,
	locale: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	value: PropTypes.instanceOf(moment)
};
MaterialDatePicker.defaultProps = {
	disabled: false,
	selectionRange: null
};

function buildContainerClassNames(disabled, className) {
	return combineClassNames(classnames({
		'material-date-picker--container': true,
		disabled
	}), className);
}

export default wrapInLocalizationContext(MaterialDatePicker);
