import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import Button from '../../ui/components/Button.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import RevealedPassword from '../../ui/components/RevealedPassword.js';
import {Body1} from '../../ui/components/typography/Typography.js';
import {COLOR_SECONDARY} from '../../ui/constants/SynMUIOptions.js';

const style = {
	width: '100%',
	height: '40vh',
	backgroundColor: 'white',
	padding: 2,
	boxSizing: 'border-box'
};

export default function ConfirmBarcodeScanResult(props) {
	const {scanResult, onProceed, onResume, targetName} = props;
	const confirmButtonText = useTranslation('ConfirmBarcodeScan');
	const rejectButtonText = useTranslation('RejectBarcodeScan');
	return scanResult && (
		<VerticalLayout sx={style} justify='center' align='center'>
			<SynFormattedMessage element={Body1} message='BarcodeScanWarning' py={2} messageParams={{targetName}} />
			<RevealedPassword password={scanResult} p={1} my={5} border={1} />
			<Button onClick={onProceed}>
				{confirmButtonText}
			</Button>
			<Button onClick={onResume} color={COLOR_SECONDARY}>
				{rejectButtonText}
			</Button>
		</VerticalLayout>
	);
}

ConfirmBarcodeScanResult.propTypes = {
	scanResult: PropTypes.string,
	onProceed: PropTypes.func,
	onResume: PropTypes.func,
	targetName: PropTypes.string
};
