import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import AdditionalDataForm from '../../components/AdditionalDataForm.js';
import {updateData} from '../ArchiveDocumentsDialogActions.js';
import {selectData} from '../selectors/ArchiveDocumentsDialogSelectors.js';

const AdditionalDataFormContainer = connect(
	createStructuredSelector({
		data: selectData
	}),
	bindActions({
		updateData
	})
)(AdditionalDataForm);

export default AdditionalDataFormContainer;
