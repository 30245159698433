import React from 'react';

import {wrapInLocalizationContext} from '../../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import GeneralTranslator from '../../../i18n/translators/GeneralTranslator.js';
import IconButton from '../../../ui/components/IconButton.js';
import {Left, Right} from '../../../ui/components/icons/ChevronIcons.js';
import {COLOR_PRIMARY} from '../../../ui/constants/SynMUIOptions.js';
import createSimplePureRenderComponent from '../createSimplePureRenderComponent.js';
import LinkButton from '../webview/LinkButton.js';

export default wrapInLocalizationContext(
	createSimplePureRenderComponent(
		props => ((props.total > 0) ? renderFooter(props) : renderEmpty(props))
	)
);

function renderEmpty(renderProps) {
	const {className} = renderProps;
	return (<div className={className} aria-hidden='true' />);
}

function renderFooter(renderProps) {
	const {className} = renderProps;
	const {PaginationButtonComponent, prevButtonProps, nextButtonProps} = getButtonProps(renderProps);

	return (
		<div className={className}>
			{getRangeDisplay(renderProps)}
			<PaginationButtonComponent {...prevButtonProps} key='prev'>
				<Left className='navigation-button' />
			</PaginationButtonComponent>
			<PaginationButtonComponent {...nextButtonProps} key='next'>
				<Right className='navigation-button' />
			</PaginationButtonComponent>
		</div>
	);
}

function getButtonProps(renderProps) {
	const {previousPageLocation, nextPageLocation, onClickPrev, onClickNext, locale} = renderProps;
	let {PaginationButtonComponent, prevButtonProps, nextButtonProps} = getDefaultButtonProps(locale);
	if (onClickPrev || onClickNext) {
		prevButtonProps = {
			...prevButtonProps,
			onClick: onClickPrev,
			disabled: !onClickPrev
		};
		nextButtonProps = {
			...nextButtonProps,
			onClick: onClickNext,
			disabled: !onClickNext
		};
	} else {
		PaginationButtonComponent = LinkButton;
		prevButtonProps = {
			...prevButtonProps,
			location: previousPageLocation,
			buttonComponent: IconButton
		};
		nextButtonProps = {
			...nextButtonProps,
			location: nextPageLocation,
			buttonComponent: IconButton
		};
	}
	return {PaginationButtonComponent, prevButtonProps, nextButtonProps};
}

function getDefaultButtonProps(locale) {
	const PaginationButtonComponent = IconButton;
	const prevButtonProps = {
		title: GeneralTranslator.getFormattedMessage('PrevResultsPage', locale),
		color: COLOR_PRIMARY
	};
	const nextButtonProps = {
		title: GeneralTranslator.getFormattedMessage('NextResultsPage', locale),
		color: COLOR_PRIMARY
	};

	return {
		PaginationButtonComponent,
		prevButtonProps,
		nextButtonProps
	};
}

function getRangeDisplay(renderProps) {
	const {total: totalItems, maxLimit, from, to} = renderProps;
	const rangeMessage = (totalItems > maxLimit) ? 'OpenResultsRange' : 'ClosedResultsRange';
	const total = Math.min(totalItems, maxLimit);
	const messageParams = {
		from: `${from + 1}`,
		to: `${to}`,
		total
	};
	return <SynFormattedMessage message={rangeMessage} translator={GeneralTranslator} messageParams={messageParams} />;
}
