import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import MUIRadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils.js';

export default React.memo(RadioGroup);

function RadioGroup(props) {
	const {
		id,
		label,
		value = '',
		onChange,
		children
	} = props;

	const handleChange = useMemoFactory(createOnChangeHandler, onChange);

	return (
		<FormControl component='fieldset'>
			<FormLabel component='legend'>
				{label}
			</FormLabel>
			<MUIRadioGroup aria-label={id} name={id} value={value} onChange={handleChange}>
				{renderOptions(children)}
			</MUIRadioGroup>
		</FormControl>
	);
}

const RadioGroupOptionsShape = PropTypes.arrayOf(PropTypes.shape({
	label: PropTypes.node,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	])
}));

RadioGroup.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	children: RadioGroupOptionsShape,
	label: PropTypes.node,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	])
};

function createOnChangeHandler(handler) {
	return function handleChange(event) {
		callSafe(handler, event.target.value);
	};
}

function renderOptions(options) {
	return options.map(option => {
		const {label, value} = option;
		const key = `${value}_Radio`;
		const body2Label = (
			<Typography variant='body2'>
				{label}
			</Typography>
		);
		return <FormControlLabel key={key} value={value} control={<Radio />} label={body2Label} />;
	});
}
