import PropTypes from 'prop-types';

import ResultList from '../commons/api/ResultList.js';
import {immutablePropType} from '../commons/utils/CustomPropTypes.js';

export const loadInProgressPropType = PropTypes.oneOfType([
	immutablePropType,
	PropTypes.instanceOf(null)
]);
export const resultListPropType = PropTypes.oneOfType([
	PropTypes.instanceOf(ResultList),
	PropTypes.instanceOf(null)
]);
