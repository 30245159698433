import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {archiveDocumentsButtonVisible} from '../../../archive-documents/flux/selectors/ArchiveDocumentsSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {showPrintLayout} from '../../../ui/flux/UIActions.js';
import {uiIsMobileDevice, uiIsSmallDevice, uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import {anyPrintableViewerVisible, isViewerShown} from '../../../viewer/flux/selectors/ViewerSelectors.js';
import PatientDetailsAppBar from '../../components/PatientDetailsAppBar.js';

export default connect(
	createStructuredSelector({
		viewerShown: isViewerShown,
		showPrintLayout: uiShowPrintLayout,
		isMobileDevice: uiIsMobileDevice,
		isSmallDevice: uiIsSmallDevice,
		anyPrintableViewerVisible,
		archiveDocumentsButtonVisible
	}),
	bindActions({
		doPrint: showPrintLayout
	})
)(PatientDetailsAppBar);
