import React from 'react';

import UploadRestoreTrashButton from '../../../webupload/components/UploadRestoreTrashButton.js';
import useBrick from '../../bricks/hooks/useBrick';
import UploadSpinner from '../../commons/components/upload/UploadSpinner.js';
import {UploadStepperFooter} from '../../commons/components/upload/UploadStepperFooter.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index';
import WebViewProducerList from '../bricks/WebViewProducerList';
import WebViewUploadContext from '../bricks/WebViewUploadContext';
import WebViewUploadController from '../bricks/WebViewUploadController';

export default createUploadStepperFooterWithProducer(
	WebViewUploadController, WebViewUploadContext, WebViewProducerList
);

function createUploadStepperFooterWithProducer(controllerBrickType, contextBrickType, producerListBrickType) {
	return function UploadStepperFooterWithProducer() {
		const {controllerState, startUpload, setStateSelectProducer} = useBrick(
			controllerBrickType, selectControllerProps);
		const currentProducer = useBrick(contextBrickType, uploadContext => uploadContext.getProducerAbk());
		const onlyOneProducerAvailable = useBrick(producerListBrickType, brick => brick.getSingleProducer());
		const hasProducer = useBrick(producerListBrickType, brick => brick.hasProducers);
		const {buttonLabel, buttonHandler} = useMemoFactory(
			getForwardButtonLabel, controllerState, startUpload,
			setStateSelectProducer, currentProducer, onlyOneProducerAvailable);
		const isPreparing = [
			controllerBrickType.State.PREPARING,
			controllerBrickType.State.READY
		].includes(controllerState);
		return (
			hasProducer()
				? (
					<UploadStepperFooter buttonLabel={buttonLabel} buttonHandler={buttonHandler}>
						{isPreparing && <UploadRestoreTrashButton />}
					</UploadStepperFooter>
				)
				: <UploadSpinner />

		);
	};

	function selectControllerProps(brick) {
		return {
			setStateSelectProducer: brick.setStateSelectProducer,
			startUpload: brick.startUpload,
			controllerState: brick.getState()
		};
	}

	function getForwardButtonLabel(
			uploadControllerState, startUpload, setStateSelectProducer, currentProducer, onlyOneProducerAvailable
	) {
		const getButtonLabelStartUpload = onlyOneProducerAvailable ? 'StartUpload' : 'ForwardPhrase';
		switch (uploadControllerState) {
			case controllerBrickType.State.PREPARING:
				return {
					buttonLabel: getButtonLabelStartUpload
				};
			case controllerBrickType.State.READY:
				return {
					buttonLabel: getButtonLabelStartUpload,
					buttonHandler: setStateSelectProducer
				};
			case controllerBrickType.State.SELECT_PRODUCER:
				return {
					buttonLabel: 'StartUpload',
					buttonHandler: currentProducer ? startUpload : null
				};
			default:
				return {};
		}
	}
}
