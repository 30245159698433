import React from 'react';

import BrickRegistryProvider from '../bricks/components/BrickRegistryProvider.js';
import DocumentPermitClaimPage from '../document-permits/components/DocumentPermitClaimPage.js';
import DocumentPermitContextProvider from '../document-permits/components/DocumentPermitContextProvider.js';
import DocumentPermitClaimTranslator from '../document-permits/i18n/DocumentPermitClaimTranslator.js';
import {TranslationProvider} from '../i18n/components/TranslationContext.js';

/**
 * This Component wires all the dependencies for the DocumentPermitClaimPage:
 * - an instance of DocumentPermitContextProvider
 * - translations.
 *
 * @param props - simply passed on to the next component
 * @returns {JSX.Element} - The page bound to web view specific dependencies.
 */
export default function WebViewDocumentPermitClaimPage(props) {
	return (
		<BrickRegistryProvider>
			<DocumentPermitContextProvider />
			<TranslationProvider translator={DocumentPermitClaimTranslator}>
				<DocumentPermitClaimPage {...props} />
			</TranslationProvider>
		</BrickRegistryProvider>
	);
}
