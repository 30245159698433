import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../../a11y/components/landmarks/Alert.js';
import {Subtitle1} from '../../../ui/components/typography/Typography.js';
import {TEXT_SECONDARY} from '../../../ui/constants/SynMUIOptions.js';
import {combineClassNames} from '../../utils/StyleUtils.js';

import '../../../../styles/commons/components/webview/ErrorMessage.scss';

export default function ErrorMessage(props) {
	const {children, className} = props;
	const finalClassName = combineClassNames('error-message-base', className);
	return (
		<Alert element={Subtitle1} className={finalClassName} color={TEXT_SECONDARY}>
			{children}
		</Alert>
	);
}

ErrorMessage.propTypes = {
	className: PropTypes.string
};
