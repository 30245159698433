import React from 'react';

import {memoWithName} from '../../commons/utils/ReactUtils.js';
import EMailChecker from '../flux/containers/EMailChecker.js';
import NewShareReceiverFormContainer from '../flux/containers/NewShareReceiverFormContainer.js';

function TabCreateReceiver() {
	return (
		<React.Fragment>
			<NewShareReceiverFormContainer />
			<EMailChecker />
		</React.Fragment>
	);
}

export default memoWithName(TabCreateReceiver);
