import React from 'react';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';

function SearchingMessage(props) {
	return <SynFormattedMessage message='Searching' {...props} />;
}
function ClosedSearchResultsMessage(props) {
	return <SynFormattedMessage message='ClosedSearchResultsHeader' {...props} />;
}
function OpenSearchResultsMessage(props) {
	return <SynFormattedMessage message='OpenSearchResultsHeader' {...props} />;
}
function NoSearchResultsMessage(props) {
	return <SynFormattedMessage message='NoSearchResults' {...props} />;
}

function dataTableHeader({nrResults, maxLimit}) {
	let message = false;
	if (typeof nrResults === 'number') {
		if (nrResults > maxLimit) {
			message = <OpenSearchResultsMessage id='searchResultsTitle' messageParams={{nrResults: maxLimit}} />;
		} else if (nrResults === 0) {
			message = <NoSearchResultsMessage id='searchResultsTitle' />;
		} else {
			message = <ClosedSearchResultsMessage id='searchResultsTitle' messageParams={{nrResults}} />;
		}
	} else {
		message = <SearchingMessage id='searchResultsTitle' />;
	}
	return message;
}

export default dataTableHeader;
