import React from 'react';

import useBrick from '../../bricks/hooks/useBrick';
import {UploadInfoForm} from '../../commons/components/upload/UploadInfoForm';
import ProducerList from '../bricks/ProducerList';
import WebViewUploadContext from '../bricks/WebViewUploadContext';

export default function AdditionalUploadInfoForm() {
	const archivePermissions = useBrick(ProducerList, producerList => producerList.getProducers());
	const selectProducer = useBrick(WebViewUploadContext, uploadContext => uploadContext.selectProducer);
	const currentProducer = useBrick(WebViewUploadContext, uploadContext => uploadContext.getProducerAbk());
	return (<UploadInfoForm archivePermissions={archivePermissions}
														 selectProducer={selectProducer}
														 currentProducer={currentProducer} />);
}
