import React from 'react';
import PropTypes from 'prop-types';

import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import Column from '../../material-design/components/Column.js';
import DeterminateLinearProgressBar from '../../ui/components/DeterminateLinearProgressBar.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import UploadSummaryInfo from './UploadSummaryInfo.js';

const HUNDRED_PERCENT = 100;

function UploadInProgressStatus(props) {
	const {imagesData, numUploadedFiles} = props;
	const totalFiles = imagesData.count();
	const progress = totalFiles > 0 ? numUploadedFiles / totalFiles * HUNDRED_PERCENT : HUNDRED_PERCENT;

	return (
		<Column element={VerticalLayout} width={{xs: 4, sm: 4}} noGrow align='center' sx={{height: '48px'}}>
			<DeterminateLinearProgressBar value={progress} fullWidth />
			<UploadSummaryInfo numUploadedFiles={numUploadedFiles} totalFiles={totalFiles} />
		</Column>
	);
}

UploadInProgressStatus.propTypes = {
	imagesData: immutablePropType,
	numUploadedFiles: PropTypes.number
};

const PureUploadInProgressStatus = React.memo(UploadInProgressStatus);

export default PureUploadInProgressStatus;
