import reduceReducers from 'reduce-reducers';
import {handleActions} from 'redux-actions';

import {booleanValue} from '../../../commons/utils/BooleanUtils.js';
import {BEGIN_SESSION} from '../../constants/SessionActionTypes.js';
import {SESSION_CAN_ARCHIVE_DOCUMENTS, SESSION_CAN_CREATE_ORDERS} from '../../constants/WebViewSessionStateFields.js';
import sessionReducer, {INITIAL_STATE} from './sessionReducer.js';

const webViewSessionReducer = handleActions({
	[BEGIN_SESSION]: handleBeginSession
}, INITIAL_STATE);

function handleBeginSession(state, action) {
	const {payload: {canCreateOrders, canArchiveDocuments}} = action;
	return state
		.set(SESSION_CAN_CREATE_ORDERS, booleanValue(canCreateOrders))
		.set(SESSION_CAN_ARCHIVE_DOCUMENTS, booleanValue(canArchiveDocuments));
}

export default reduceReducers(
	sessionReducer,
	webViewSessionReducer
);
