import React from 'react';

import Alert from '../../../a11y/components/landmarks/Alert.js';
import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import Title from '../../../material-design/components/Title.js';
import VerticalLayout from '../../../ui/components/layout/VerticalLayout.js';

export default class NoResults extends React.PureComponent {
	render() {
		const {children} = this.props;

		return (
			<VerticalLayout element={Alert} sx={{p: '24px'}} justify='center' align='center' className='no-results'>
				<Title>
					<SynFormattedMessage message='NoSearchResults' />
				</Title>
				{children}
			</VerticalLayout>
		);
	}
}
