import React from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';

import LoginWorkflowControllerContainer from '../../authentication/flux/containers/LoginWorkflowControllerContainer.js';
import createSessionGuardedComponentSelector from '../../authentication/utils/createSessionGuardedComponentSelector.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import LoadingIndicatorOverlay from '../../commons/components/webview/LoadingIndicatorOverlay.js';
import {MAXIMUM_EXTRA_SMALL_WIDTH} from '../../commons/constants/DeviceInformation.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';
import DocumentPermitClaimForm from './DocumentPermitClaimForm.js';
import {DocumentPermitRegisterUserForm} from './DocumentPermitRegisterUserForm';

const MQ = `@media (min-width: ${MAXIMUM_EXTRA_SMALL_WIDTH}px)`;

const StyledLimitWidth = styled(VerticalLayout)({
	[MQ]: {maxWidth: '300px'}
});

export default function SessionGuardedDocumentPermitClaimForm() {
	const {
		isNewUserRequired, requireNewUser, isBusy
	} = useBrick(DocumentPermitClaimWorkflow, selectDocumentPermitClaimWorkflowProps);
	const GuardedComponent = useSelector(pageSelector);
	return (
		<React.Fragment>
			{isNewUserRequired
				? <DocumentPermitRegisterUserForm />
				: (
					<StyledLimitWidth>
						<GuardedComponent registerNewUserHandler={requireNewUser} />
					</StyledLimitWidth>
				)
			}
			<LoadingIndicatorOverlay loading={isBusy} />
		</React.Fragment>
	);
}

const pageSelector = createSessionGuardedComponentSelector(
	() => DocumentPermitClaimForm,
	LoginWorkflowControllerContainer
);

function selectDocumentPermitClaimWorkflowProps(service) {
	return {
		isNewUserRequired: service.isNewUserRequired(),
		requireNewUser: service.requireNewUser,
		isBusy: service.isBusy()
	};
}
