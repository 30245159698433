import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {column} from '../../commons/components/data/Table.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import DocumentSearchDataTableRow from '../../document-search/components/DocumentSearchDataTableRow.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import createMaterialTable from '../../material-design/components/createMaterialTable.js';
import searchResultPatientInfo from '../../search/components/SearchResultPatientInfo.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';

import '../../../styles/commons/components/data/DataTable.scss';

export default function LargePatientSearchResults(props) {
	const {resultList, rowCount, startIndex, onPatientClick} = props;
	return (
		<LargePatientSearchResultsTable resultList={resultList} bodyProps={{onPatientClick}} startIndex={startIndex}
		                                loadInProgress={resultList === null} numberRows={rowCount} />
	);
}

const LargePatientSearchResultsTable = createMaterialTable([
	resultColumn(
		() => <SynFormattedMessage message='PatientId' />,
		props => props.result.get('patient_id'),
		'20%'
	),
	resultColumn(
		() => <SynFormattedMessage message='CaseId' />,
		props => props.result.get('preadmit_number', ''),
		'15%'
	),
	resultColumn(
		() => <SynFormattedMessage message='Patient' />,
		searchResultPatientInfo(),
		'auto'
	)
], {
	RowComponent: PropsCleaningRow
});

LargePatientSearchResults.measureNumberRowsForHeight = LargePatientSearchResultsTable.measureNumberRowsForHeight;

LargePatientSearchResults.propTypes = {
	resultList: resultListPropType.isRequired,
	rowCount: PropTypes.number.isRequired,
	startIndex: PropTypes.number.isRequired,
	onPatientClick: PropTypes.func.isRequired
};

function resultColumn(HeaderComponent, cellRenderer, columnWidth) {
	return column(
		HeaderComponent,
		resultRow(cellRenderer),
		columnWidth
	);
}

function resultRow(CellRenderer) {
	function ResultRow(props) {
		const {result, onPatientClick} = props;
		const onClick = useCallback(() => onPatientClick(result), [result, onPatientClick]);
		return (
			<div role='button' onClick={onClick} style={{cursor: 'pointer', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
				<CellRenderer result={result} />
			</div>
		);
	}

	ResultRow.propTypes = {
		result: immutableMapPropType.isRequired,
		onPatientClick: PropTypes.func.isRequired
	};

	return ResultRow;
}

function PropsCleaningRow(props) {
	const cleanedProperties = cloneWithoutProperties(props, 'onPatientClick');
	return <DocumentSearchDataTableRow {...cleanedProperties} />;
}
