import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import VariableSizeList from '../../commons/components/data/itemview/VariableSizeList.js';
import ScrollView from '../../commons/components/ScrollView.js';
import ScrollContainer from '../../commons/containers/ScrollContainer.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn.js';
import FlexLayoutBase from '../../ui/components/layout/FlexLayoutBase.js';
import {uiIsMobileDevice} from '../../ui/flux/UISelectors.js';
import withViewerLayoutProps from '../../viewer/flux/containers/withViewerLayoutProps.js';
import {INBOX_SCROLL_COMPONENT_ID} from '../constants/InboxPropertyNames.js';
import PatientGroup from './PatientGroup.js';

function SimpleResultList(props) {
	const {data, markSharesDone, width, height, forwardedRef} = props;
	const isMobile = useSelector(uiIsMobileDevice);
	const boundRenderListItem = useMemoFactory(createBoundRenderListItem, data, markSharesDone);
	const boundCalcItemSize = useMemoFactory(createBoundCalcItemSize, data, isMobile, width);
	const [viewport, setViewport] = useState({left: 0, top: 0, width, height, bottom: height - 1, right: width - 1});
	return (
		<FlexLayoutBase forwardRef={forwardedRef} sx={{boxSizing: 'border-box', width: '100%', overflow: 'hidden'}}>
			<ScrollView element={ScrollContainer} componentId={INBOX_SCROLL_COMPONENT_ID} width={width} height={height}
							onViewportChange={setViewport} className='inbox-scroll--area'>
				<ContentRow className='inbox-result-list--container'>
					<FullWidthColumn className='inbox-result-list--content'>
						<VariableSizeList viewport={viewport} itemDimensions={boundCalcItemSize}
						                  renderRow={boundRenderListItem} overscanRows={5} />
					</FullWidthColumn>
				</ContentRow>
			</ScrollView>
		</FlexLayoutBase>
	);
}

SimpleResultList.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	data: immutableListPropType,
	markSharesDone: PropTypes.func,
	forwardedRef: withForwardRef.PropTypes.Ref
};

SimpleResultList.defaultProps = {
	width: 0,
	height: 0
};

function createBoundCalcItemSize(data, isMobile, width) {
	function itemSize(index) {
		const margin = 8;
		const padding = 8;
		const patientInfoHeight = 56;
		const remainingDaysLineBreakBreakpoint = 400;
		const documentShareHeightLarge = 64;
		const documentShareHeightSmall = 49;
		const documentShareHeight = width <= remainingDaysLineBreakBreakpoint
			? documentShareHeightLarge : documentShareHeightSmall;
		const borderWidth = isMobile ? 0 : 1;
		const paddingScrollbar = 60;
		const numDocumentShares = data.get(index).get('documentShares').size;
		return {
			width: width - (isMobile ? 0 : paddingScrollbar),
			height: 2 * (padding + (isMobile ? 0 : margin)) +
				        patientInfoHeight +
				        numDocumentShares *
				        documentShareHeight -
				        borderWidth
		};
	}
	return [...Array(data.size).keys()].map(itemSize);
}

function createBoundRenderListItem(data, markSharesDone) {
	return function InboxListItemRenderer(index, key, style) {
		const patientGroupData = data.get(index);
		return (
			<div key={key} style={style}>
				<PatientGroup data={patientGroupData} key={patientGroupData.getIn(['patientInfo', 'aimid'])}
				              markSharesDone={markSharesDone} />
			</div>
		);
	};
}

export default withViewerLayoutProps(withForwardRef(SimpleResultList, 'forwardedRef'));
