import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

import LinkButton from '../../../commons/components/webview/LinkButton';
import {fragmentSelector, pathSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors';
import IconButton from '../../../ui/components/IconButton';
import {uiIsMobileDevice} from '../../../ui/flux/UISelectors';
import {VIEWER_QUERY_PROPERTY_SHOW_VIEWER} from '../../../viewer/constants/ViewerConstants';

const EmbeddedPatientDetailsBackLinkContainer = connect(
	createSelector(
		pathSelector, querySelector, fragmentSelector,
		uiIsMobileDevice, 
        
		(path, query, fragment, isMobile) => {
			const showsMobileViewer = isMobile && query.get(VIEWER_QUERY_PROPERTY_SHOW_VIEWER, false);
			if (!showsMobileViewer) {
				return {children: undefined};
			}
			return {
				location: Immutable.Map({
					path,
					query: showsMobileViewer ? query.delete(VIEWER_QUERY_PROPERTY_SHOW_VIEWER) : query.get('originQueryParams', Immutable.Map()),
					fragment
				})
			};
		}
	), {}
)(LinkButton);

EmbeddedPatientDetailsBackLinkContainer.defaultProps = {
	...EmbeddedPatientDetailsBackLinkContainer.defaultProps,
	buttonComponent: IconButton,
	color: 'inherit'
};

export default EmbeddedPatientDetailsBackLinkContainer;
