import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import InboxResultsList from '../../components/InboxResultsList.js';
import {loadInbox, markSharesDone} from '../InboxActions.js';
import {getInboxShares, hasInboxData, isInboxDataUpToDate, selectIsLoading} from '../InboxSelectors.js';

export default connect(
	createStructuredSelector({
		isInboxDataUpToDate,
		hasInboxData,
		data: getInboxShares,
		isLoading: selectIsLoading
	}),
	bindActions({
		load: loadInbox,
		markSharesDone
	})
)(InboxResultsList);
