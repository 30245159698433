import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import PatientSearchAppBar from '../../patient-search/components/PatientSearchAppBar.js';
import CreateOrderService from '../CreateOrderService.js';
import CreateOrderAppBar from './CreateOrderAppBar.js';

export default React.memo(CreateOrderAppBarController);

function CreateOrderAppBarController(props) {
	const {state} = useBrick(CreateOrderService, selectServiceProps);
	const AppBarComponent = useMemoFactory(appBarSelector, state);
	return <AppBarComponent {...props} />;
}

CreateOrderAppBarController.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired
};

function selectServiceProps(service) {
	return {
		state: service.getState()
	};
}

function appBarSelector(state) {
	return state === CreateOrderService.State.SELECT_PATIENT ? PatientSearchAppBar : CreateOrderAppBar;
}
