import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {wrapInTranslationContext} from '../../i18n/components/TranslationContext.js';
import MessageTranslator from '../../i18n/translators/MessageTranslator.js';
import Button from '../../ui/components/Button.js';
import EditIcon from '../../ui/components/icons/EditIcon.js';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout.js';

import '../../../styles/search/components/SearchParametersList.scss';

const SEARCH_PARAMETER_LABEL = <SynFormattedMessage message='SearchParametersListLabel' />;

function renderDateValue(possibleDate, translator, locale) {
	let value = '';
	if (possibleDate.get !== undefined) {
		const from = possibleDate.get('from', null);
		if (from) {
			value = translator.getFormattedMessage(
				'ShortDate', locale, {date: from.utc(true).toDate()}
			);
		}
		const to = possibleDate.get('to', null);
		if (to) {
			value = `${value} - ${translator.getFormattedMessage(
				'ShortDate', locale, {date: to.utc(true).toDate()}
			)}`;
		}
	}
	return value;
}

function Parameter(props) {
	const {label, value, translator, locale} = props;
	return (
		<span className='parameter'>
			<span className='label'>
				{`${label}:`}
			</span>
			<span className='value'>
				{renderDateValue(value, translator, locale) || value}
			</span>
		</span>
	);
}

Parameter.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.instanceOf(moment),
		PropTypes.string
	]).isRequired,
	locale: PropTypes.string.isRequired,
	translator: PropTypes.instanceOf(MessageTranslator).isRequired
};

const LocalizedParameter = wrapInTranslationContext(wrapInLocalizationContext(Parameter));

export default class SearchParametersList extends React.Component {
	render() {
		const {parameters, className, showForm} = this.props;
		return (
			<HorizontalLayout className='search-parameters' noGrow align='center' justify='center'>
				<div className={combineClassNames('search-parameters--list', className)}>
					{SEARCH_PARAMETER_LABEL}
					:&nbsp;
					<span className='search-parameters--list--parameters'>
						{
							parameters.reduceRight((summaries, value, key) => {
								if (value) {
									if (summaries.length === 1) {
										summaries.unshift(<SynFormattedMessage key='and' className='parameter'
									                                       message='And' />);
									}
									const label = this.getParameterLabel(key);
									summaries.unshift(<LocalizedParameter key={label} label={label} value={value} />);
								}
								return summaries;
							}, [])
						}
					</span>
				</div>
				<Button startIcon={<EditIcon />} flat onClick={showForm} className='search-parameters--show-form-button'>
					<SynFormattedMessage message='SearchParametersEditButtonLabel' />
				</Button>
			</HorizontalLayout>
		);
	}

	getParameterLabel(parameterName) {
		const {translateParameter} = this.props;
		return translateParameter(parameterName);
	}
}

SearchParametersList.propTypes = {
	parameters: immutableMapPropType.isRequired,
	className: PropTypes.string,
	showForm: PropTypes.func.isRequired,
	translateParameter: PropTypes.func.isRequired
};
