import React from 'react';
import PropTypes from 'prop-types';

import {useFormattedMessage} from '../../commons/utils/customHooks';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import LinkLikeButton from '../../ui/components/LinkLikeButton.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

function UndoButton(props) {
	const {locale, undoData: {task}, onUndo} = props;
	const undoLabel = useFormattedMessage(ArchiveDocumentsTranslator, 'Undo', locale);

	return task && 
			<LinkLikeButton className='undoArchiveDocumentsAction' onClick={onUndo} flat>
				{undoLabel}
			</LinkLikeButton>;
}

UndoButton.propTypes = {
	locale: PropTypes.string,
	undoData: PropTypes.object,
	onUndo: PropTypes.func
};

export default wrapInLocalizationContext(React.memo(UndoButton));
