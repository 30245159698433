import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext.js';
import SearchAppBar from '../../search/components/SearchAppBar.js';
import SearchBackLinkContainer from '../../search/flux/SearchBackLinkContainer.js';
import IconButton from '../../ui/components/IconButton.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import {MAX_LIMIT} from '../constants/DocumentSearchConstants.js';
import {documentSearchFetchInProgress, documentSearchResults} from '../flux/DocumentSearchSelectors.js';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';

const TRANSLATED_PAGE_TITLE = <SynFormattedMessage message='PageTitle' />;
const BACKLINK_CONTAINER = 	<SearchBackLinkContainer buttonComponent={IconButton} color='inherit' icon={<BackIcon />} />;

export default function DocumentSearchAppBar(props) {
	const {sidebarElevated} = props;
	const AppBarComponent = sidebarElevated ? SearchAppBarContainer : SearchAppBar;
	return (
		<TranslationProvider translator={DocumentSearchPageMessagesTranslator}>
			<AppBarComponent sidebarElevated={sidebarElevated} title={TRANSLATED_PAGE_TITLE}
		                   maxLimit={MAX_LIMIT} {...props} />
		</TranslationProvider>
	);
}

DocumentSearchAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired
};

const SearchAppBarContainer = connect(
	createStructuredSelector({
		resultList: documentSearchResults,
		loadInProgress: documentSearchFetchInProgress
	}),
	null,
	function mergeProps(stateProps, dispatchProps, ownProps) {
		const {loadInProgress, resultList} = stateProps || {};
		return {
			...ownProps,
			loadInProgress,
			nrResults: resultList?.getTotalSize(),
			backButton: BACKLINK_CONTAINER
		};
	}
)(SearchAppBar);
