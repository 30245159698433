import {BARCODE_TYPE_EAN_13_EAN_5_EAN_2} from '../constants/BarcodeScannerTypes.js';
import {ALLOWED_BARCODE_TYPES} from '../constants/SynSettingsConstants.js';

const DEFAULT_REGEX = /.*/;
const REGEX_NAMED_GROUP = 'barcode';
const EAN13_LENGTH = 13;

export function parseBarcodeTypes(barcodeTypes) {
	return barcodeTypes.map(parseBarcodeConfigEntry);
}

function parseBarcodeConfigEntry(entry) {
	const {symbology, regex: regexPart} = entry;
	let regex = DEFAULT_REGEX;
	if (regexPart?.length > 0) {
		try {
			regex = new RegExp(regexPart);
		} catch (err) {
			throw new Error(`Error parsing barcode symbology "${symbology}" regex "${regexPart}". ${err}`);
		}
	}
	return {symbology, regex};
}

export function postProcessBarcode(code, format, barcodeTypes = ALLOWED_BARCODE_TYPES) {
	const targetFormat = isEan13WithSupplements(format, code) ? BARCODE_TYPE_EAN_13_EAN_5_EAN_2 : format;
	const postRegexList = parseBarcodeTypes(barcodeTypes)
		.filter(entry => entry.symbology === targetFormat)
		.map(entry => entry.regex);
	let postProcessResult = null;
	postRegexList.some((regexEntry => {
		const match = code.match(regexEntry);
		if (match !== null) {
			postProcessResult = match?.groups?.[REGEX_NAMED_GROUP] || match[0];
			return true;
		}
		return false;
	}));
	return postProcessResult;
}

function isEan13WithSupplements(format, code) {
	return format === 'ean_13' && code.length > EAN13_LENGTH;
}
