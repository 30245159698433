import React from 'react';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import LoadingIndicator from '../../commons/components/webview/LoadingIndicator.js';

export default React.memo(ServiceBusy);

function ServiceBusy(/*props*/) {
	return (
		<CenterLayout>
			<LoadingIndicator />
		</CenterLayout>
	);
}
