import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Spacer from '../../ui/components/layout/Spacer';
import {CaptionText} from '../../ui/components/typography/Typography.js';
import {COLOR_SECONDARY} from '../../ui/constants/SynMUIOptions.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';

export default function ClaimErrorDisplay(props) {
	const {align} = props;
	const {lastClaimResult, isTanRequired} = useBrick(DocumentPermitClaimWorkflow, selectWorkflowProps);
	const {message, hintMessage} = useMemoFactory(selectErrorMessage, lastClaimResult, isTanRequired);
	if (!message) {
		return null;
	}
	return (
		<React.Fragment>
			<Spacer minHeight='8px' />
			<CaptionText color={COLOR_SECONDARY} fontWeight='600' align={align}>
				<SynFormattedMessage message={message} />
				{' '}
				{hintMessage && <SynFormattedMessage message={hintMessage} />}
			</CaptionText>
		</React.Fragment>
	);
}

function selectWorkflowProps(workflow) {
	return {
		lastClaimResult: workflow.getLastClaimResult(),
		isTanRequired: workflow.isTanRequired()
	};
}

function selectErrorMessage(lastClaimResult, isTanRequired) {
	let message = null;
	let hintMessage = null;
	if (lastClaimResult === DocumentPermitClaimWorkflow.State.CLAIM_FAILED) {
		message = 'ClaimWasFailure';
		if (isTanRequired) {
			hintMessage = 'ClaimErrorHint';
		}
	} else if (lastClaimResult === DocumentPermitClaimWorkflow.State.CLAIM_FAILED_USER_ALREADY_EXISTS) {
		message = 'ClaimErrorUserAlreadyExists';
	}
	return {message, hintMessage};
}

ClaimErrorDisplay.propTypes = {
	align: PropTypes.string
};
