import {declareBrick} from '../../bricks/brickTools.js';
import ProducerList from './ProducerList.js';
import WebViewUploadPatientContext from './WebViewUploadPatientContext.js';

export default class WebViewProducerList extends ProducerList {
	constructor(patientContext, searchArchivPermissions) {
		super();
		this.fetchApiCall = searchArchivPermissions;
		this.subscribeTo(patientContext, this.onPatientContextChanged);
	}

	onPatientContextChanged(patientContext) {
		const oldPatientId = this.getPatientId();
		const newPatientId = patientContext.getPatientAIMId();
		if (oldPatientId !== newPatientId) {
			this.updateBrickState(() => ({
				patientId: newPatientId
			}));
			this.loadResults();
		}
	}

	fetchProducerList() {
		const patientIdToLoad = this.getPatientId();
		if (patientIdToLoad) {
			return this.fetchApiCall(patientIdToLoad);
		}
		return Promise.resolve();
	}
}
declareBrick(WebViewProducerList, [WebViewUploadPatientContext]);
