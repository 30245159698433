import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout.js';

import '../../../styles/search/components/SearchLayout.scss';

export default class SearchLayout extends React.PureComponent {
	render() {
		const {children, showResults, sideBySide, className} = this.props;

		const layoutClasses = classNames({
			'search-layout': true,
			'side-by-side': sideBySide,
			'show-results': showResults,
			'auto-side-by-side': sideBySide === undefined
		});

		const finalClasses = combineClassNames(layoutClasses, className);
		return (
			<HorizontalLayout className={finalClasses}>
				{children}
			</HorizontalLayout>
		);
	}
}

SearchLayout.propTypes = {
	showResults: PropTypes.bool,
	sideBySide: PropTypes.bool,
	className: PropTypes.string
};
