import UploadContext from '../../../webupload/bricks/UploadContext.js';
import {declareBrick} from '../../bricks/brickTools.js';
import ProducerList from './ProducerList.js';

export default class WebViewUploadContext extends UploadContext {
	constructor(producerListContext) {
		super();
		this.loadContext({
			bytes_uploaded: 0,
			upload_quota: Infinity
		});
		this.reset();
		this.producerListContext = producerListContext;
		this.subscribeTo(producerListContext, this.selectDefaultProducer);
	}

	selectDefaultProducer() {
		const producers = this.producerListContext?.getProducers();
		if (producers && producers.size === 1) {
			this.selectProducer(producers.first());
			this.updateBrickState(oldState => ({
				...oldState,
				defaultProducerSelected: true
			}));
		} else {
			this.updateBrickState(oldState => ({
				...oldState,
				defaultProducerSelected: false
			}));
		}
	}

	setUploadState(/*newState*/) {
		super.setUploadState(UploadContext.State.LOADED);
	}

	reset() {
		this.updateBrickState(oldState => ({
			...oldState,
			preadmitNumber: null,
			producerAbk: oldState.defaultProducerSelected ? oldState.producerAbk : null
		}));
		this.selectDefaultProducer();
	}

	selectProducer(producer) {
		this.updateBrickState(oldState => {
			const newProducerAbk = producer ? producer.get('orgunitAbk') : null;
			return {
				...oldState,
				producerAbk: newProducerAbk
			};
		});
	}

	getProducerAbk() {
		return this.getBrickState().producerAbk;
	}
}
declareBrick(WebViewUploadContext, [ProducerList]);
