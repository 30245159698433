import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import DataGuard from '../../../commons/components/data/DataGuard.js';
import {shareReceiver as shareReceiverFluxApi} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {resultListPropType} from '../../../search/searchLayoutUtil.js';
import ShareReceiverList from '../../components/ShareReceiverList.js';

function GuardedShareReceiverList(props) {
	const {shareReceivers} = props;
	return (
		<DataGuard data={shareReceivers}>
			<ShareReceiverList {...props} />
		</DataGuard>
	);
}

GuardedShareReceiverList.propTypes = {
	shareReceivers: resultListPropType
};

const {
	selectors: shareReceiverSelectors
} = shareReceiverFluxApi;

const ShareReceiverListContainer = connect(
	createStructuredSelector({
		shareReceivers: shareReceiverSelectors.getRawResponse
	})
)(GuardedShareReceiverList);

export default ShareReceiverListContainer;
