import React from 'react';

import {useMemoFactory} from '../../commons/utils/customHooks';
import useTranslator from '../../i18n/hooks/useTranslator.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import {MaterialFormSuggestComboBox} from '../../material-design/components/form/MaterialFormComboBox.js';

const SEX_COMBO_ENTRIES = {
	f: 'SexFemale',
	m: 'SexMale',
	o: 'SexOther'
};

export default function SexComboFormField(props) {
	const getFormattedMessage = useTranslator(GeneralMessagesTranslator);
	const options = useMemoFactory(createOptions, getFormattedMessage);
	return (
		<MaterialFormSuggestComboBox {...props} options={options} getSelectedValue={getSelectedValue}
		                             getValue={getValue} getOptionLabel={getOptionLabel} />
	);
}

function getOptionLabel(option) {
	return option.text;
}

function getSelectedValue(newValue) {
	if (newValue !== null) {
		return newValue.id;
	}
	return null;
}

function getValue(value, options) {
	const selectedOption = options.find(option => option.id === value);
	if (typeof selectedOption !== 'undefined') {
		return selectedOption;
	}
	return null;
}

function createOptions(getFormattedMessage) {
	const sexOptions = Object.keys(SEX_COMBO_ENTRIES).map(id => ({
		id,
		text: getFormattedMessage(SEX_COMBO_ENTRIES[id])
	}));
	sexOptions.unshift({
		id: '',
		text: ''
	});
	return sexOptions;
}
