import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import Stepper from '../../../material-design/components/Stepper.js';
import {selectPatient} from '../../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import StepAdditionalData from '../../components/StepAdditionalData.js';
import StepChooseDocuments from '../../components/StepChooseDocuments.js';
import StepPerformUpload from '../../components/StepPerformUpload.js';
import {nextStep, previousStep, updateData} from '../ArchiveDocumentsDialogActions.js';
import {
	selectCurrentStep,
	selectData,
	selectFooterState,
	selectHasFinished,
	selectIsStepComplete,
	selectNrSteps,
	selectWasCancelled
} from '../selectors/ArchiveDocumentsDialogSelectors.js';

const STEP_COMPONENTS = [
	StepChooseDocuments,
	StepAdditionalData,
	StepPerformUpload
];

const ArchiveDocumentsStepperContainer = connect(
	createStructuredSelector({
		nrSteps: selectNrSteps,
		currentStep: selectCurrentStep,
		data: selectData,
		isStepComplete: selectIsStepComplete,
		hasFinished: selectHasFinished,
		wasCancelled: selectWasCancelled,
		footerState: selectFooterState,
		patient: selectPatient
	}),
	bindActions({
		nextStep, previousStep, updateData
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {currentStep} = stateProps || {};
		return {
			...stateProps,
			...dispatchProps,
			...ownProps,
			StepComponent: STEP_COMPONENTS[currentStep]
		};
	}
)(Stepper);

export default ArchiveDocumentsStepperContainer;
