import React from 'react';
import sortDescSVG from 'material-design-iconic-font/svg/2.2/09 - directional/long-arrow-down.svg';
import sortAscSVG from 'material-design-iconic-font/svg/2.2/09 - directional/long-arrow-up.svg';
import PropTypes from 'prop-types';

import createInlineSVGIcon from '../../../commons/components/createInlineSVGIcon.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';

import '../../../../styles/material-design/components/icons/SortIcons.scss';

function SortIconContainer(props) {
	const {className, children} = props;
	const finalClassName = combineClassNames('material-sort-icon', className);
	return (
		<div className={finalClassName}>
			{children}
		</div>
	);
}

SortIconContainer.propTypes = {
	className: PropTypes.string
};

export const SortAscIcon = createInlineSVGIcon(sortAscSVG, SortIconContainer);
export const SortDescIcon = createInlineSVGIcon(sortDescSVG, SortIconContainer);
