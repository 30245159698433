import debounce from 'lodash.debounce';
import {createStructuredSelector} from 'reselect';

import {updateScrollPosition} from '../actions/ScrollActions.js';
import Scrollable from '../components/Scrollable.js';
import connectWithInstanceSelector from '../flux/connectWithInstanceSelector.js';
import {createScrollPositionSelector} from '../selectors/ScrollSelectors.js';
import {bindActions} from '../utils/FluxUtils.js';
import {callSafe} from '../utils/FunctionUtils.js';

const ON_SCROLL_DEBOUNCE_DELAY_MS = 200;

export default connectWithInstanceSelector(props => createStructuredSelector({
	initialScrollTop: createScrollPositionSelector(props.componentId)
}), bindActions({updateScrollPosition}), function mergeProps(stateProps, dispatchProps, ownProps) {
	const {
		onScroll: onScrollFromProps,
		...remainingOwnProps
	} = ownProps;
	return {
		...remainingOwnProps, ...stateProps,
		onScroll: debounce(function onScroll(newScrollPosition) {
			callSafe(onScrollFromProps, newScrollPosition);
			dispatchProps.updateScrollPosition(ownProps.componentId, newScrollPosition);
		}, ON_SCROLL_DEBOUNCE_DELAY_MS, {leading: true, trailing: true})
	};
})(Scrollable);
