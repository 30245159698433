import React from 'react';
import PropTypes from 'prop-types';

import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import GridList from '../../material-design/components/layout/GridList.js';
import UploadPhotoTile from './UploadPhotoTile.js';

import '../../../styles/archive-documents/components/DocumentsBrowserImagesGrid.scss';

function DocumentsBrowserImagesGrid(props) {
	const {selectedImages, createSelectionToggle, imagesData, children} = props;
	return (
		<GridList className='documents-browser-images-grid-list'>
			{!imagesData.isEmpty() &&
				imagesData.map(file => {
					const isSelected = selectedImages && selectedImages.has(file);
					const onToggleSelection = createSelectionToggle ? createSelectionToggle(file) : undefined;
					return (
						<UploadPhotoTile key={file.path + file.name} isSelected={isSelected}
						                 file={file} onToggleSelection={onToggleSelection} />
					);
				})
			}
			{children}
		</GridList>
	);
}

DocumentsBrowserImagesGrid.propTypes = {
	imagesData: immutablePropType.isRequired,
	selectedImages: immutablePropType,
	createSelectionToggle: PropTypes.func
};

export default React.memo(DocumentsBrowserImagesGrid);
