import {useState} from 'react';

import useBrick from '../bricks/hooks/useBrick.js';
import {useEffectEasily} from '../commons/utils/customHooks';
import AbstractPatientSearchService from './AbstractPatientSearchService.js';

export default function useAbstractPatientSearchServiceIsReset() {
	const patientSearchServiceIsCleared = useBrick(AbstractPatientSearchService, service => service.isCleared);
	const [showPatientSearch, setShowPatientSearch] = useState(false);
	useEffectEasily(checkBrickIsCleared, patientSearchServiceIsCleared, showPatientSearch, setShowPatientSearch);
	return showPatientSearch;
}

function checkBrickIsCleared(patientSearchServiceIsCleared, showPatientSearch, setShowPatientSearch) {
	if (!showPatientSearch && patientSearchServiceIsCleared()) {
		setShowPatientSearch(true);
	}
}
