import React from 'react';

import UploadStepperInfo from '../../commons/components/upload/UploadStepperInfo.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index';
import useTranslation from '../../i18n/hooks/useTranslation';
import WebViewUploadController from '../bricks/WebViewUploadController';

const STEP_1 = 1;
const STEP_2 = 2;
const STEP_3 = 3;

export const WebViewUploadStepperInfo = createUploadStepperInfoWithProducer(WebViewUploadController);

export function createUploadStepperInfoWithProducer(controllerBrickType) {
	return function UploadStepperInfoWithProducer() {
		const steps = [
			useTranslation('StepSelectPatient'),
			useTranslation('StepLabelChoseFiles'),
			useTranslation('StepLabelStartUpload'),
			useTranslation('StepLabelConfirm')
		];
		const getActiveStep = useMemoFactory(createGetActiveStep, controllerBrickType);
		return <UploadStepperInfo stepMap={steps} getStepFromState={getActiveStep} />;
	};
}

function createGetActiveStep(controllerBrick) {
	return function getActiveStep(uploadControllerState) {
		switch (uploadControllerState) {
			case controllerBrick.State.PREPARING:
			case controllerBrick.State.READY:
				return STEP_1;
			case controllerBrick.State.SELECT_PRODUCER:
			case controllerBrick.State.UPLOADING:
				return STEP_2;
			case controllerBrick.State.FINISHED:
				return STEP_3;
			default:
				return -1;
		}
	};
}
