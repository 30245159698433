import Immutable from 'immutable';
import moment from 'moment';

import {getValidYearOptions} from './components/MaterialDatePicker/YearComboBox.js';

const MAXIMUM_DAY = 31;
const AUTO_CORRECT_DAYS = [MAXIMUM_DAY, MAXIMUM_DAY - 1, MAXIMUM_DAY - 2];

const TOKEN_SINGLE_MATCHER = /^(\d{2,8})$/;
const TOKEN_RANGE_MATCHER = /^(\d{2,8})\s?-\s?(\d{2,8})$/;
const DAY_MATCHER = /^\d{2}$/;
const MONTH_MATCHER = /^(\d{2})(\d{2})$/;
const YEAR_SHORT_MATCHER = /^(\d{2})(\d{2})(\d{2})$/;
const YEAR_LONG_MATCHER = /^(\d{2})(\d{2})(\d{4})$/;

const HANDLERS = [
	{
		matcher: DAY_MATCHER,
		handler: handleDay
	},
	{
		matcher: MONTH_MATCHER,
		handler: handleMonth
	},
	{
		matcher: YEAR_SHORT_MATCHER,
		handler: handleYearShort
	},
	{
		matcher: YEAR_LONG_MATCHER,
		handler: handleYearLong
	}
];

export default function assistiveDateHandler(value) {
	let detectedValue;
	if (TOKEN_SINGLE_MATCHER.test(value)) {
		detectedValue = parseSingleToken(value);
	} else if (TOKEN_RANGE_MATCHER.test(value)) {
		detectedValue = parseRangeTokens(value);
	}
	return detectedValue;
}

function parseSingleToken(token) {
	let parsedToken;
	for (const assistiveVariant of HANDLERS) {
		const {matcher, handler} = assistiveVariant;
		if (token.match(matcher)) {
			parsedToken = handler(token);
			break;
		}
	}
	return moment.isMoment(parsedToken) && parsedToken.isValid() ? parsedToken : undefined;
}

function parseRangeTokens(token) {
	const [, inputFrom, inputTo] = token.match(TOKEN_RANGE_MATCHER);
	const from = parseSingleToken(inputFrom);
	const to = parseSingleToken(inputTo);
	let dateRangeMap;
	if (from && to) {
		dateRangeMap = Immutable.fromJS({from, to});
	}
	return dateRangeMap;
}

function handleDay(day) {
	return new AssistiveDateBuilder()
		.setDay(day)
		.build();
}

function handleMonth(value) {
	const [, day, month] = value.match(MONTH_MATCHER);
	return new AssistiveDateBuilder()
		.setDay(day)
		.setMonth(month)
		.build();
}

function handleYearShort(value) {
	const [, day, month, year] = value.match(YEAR_SHORT_MATCHER);
	return new AssistiveDateBuilder()
		.setDay(day)
		.setMonth(month)
		.setYear(year)
		.build();
}

function handleYearLong(value) {
	const [, day, month, year] = value.match(YEAR_LONG_MATCHER);
	return new AssistiveDateBuilder()
		.setDay(day)
		.setMonth(month)
		.setYear(year)
		.build();
}

class AssistiveDateBuilder {
	constructor() {
		const currentDate = getCurrentMoment();
		this.day = currentDate.date();
		this.month = currentDate.month();
		this.year = currentDate.year();
	}

	setDay(day) {
		this.day = Number(day);
		return this;
	}

	setMonth(month) {
		this.month = Number(month) - 1;
		return this;
	}

	setYear(year) {
		if (year.length === 2) {
			this.#setShortYear(year);
		} else {
			this.year = Number(year);
		}
		return this;
	}

	build() {
		this.#limitDayOverflow();
		const yearValid = this.#limitYear();
		return yearValid ? moment({
			year: this.year,
			month: this.month,
			day: this.day
		}) : moment.invalid();
	}

	#setShortYear(year) {
		const century = 100;
		const millennium = 2000;
		const currentYear = getCurrentMoment().year();
		const targetYear = Number(year) + millennium;
		this.year = targetYear > currentYear ? targetYear - century : targetYear;
	}

	#limitDayOverflow() {
		if (AUTO_CORRECT_DAYS.includes(this.day)) {
			const daysInMonth = moment({
				year: this.year,
				month: this.month
			}).daysInMonth();
			this.day = Math.min(this.day, daysInMonth);
		}
	}

	#limitYear() {
		const validYearValues = getValidYearOptions(getCurrentMoment());
		const minYear = validYearValues[0];
		const maxYear = validYearValues.at(-1);
		return this.year >= minYear && this.year <= maxYear;
	}
}

function getCurrentMoment() {
	return moment();
}
