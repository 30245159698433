import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';
import {useIsTanRequired} from '../hooks/useIsTanRequired';
import DocumentPermitClaimFormWithoutTan from './DocumentPermitClaimFormWithoutTan.js';
import DocumentPermitClaimFormWithTan from './DocumentPermitClaimFormWithTan.js';

export default function DocumentPermitClaimForm() {
	const isTanRequired = useIsTanRequired();
	const onSubmit = useOnSubmit();
	const isBusy = useIsBusy();
	return isTanRequired
		? <DocumentPermitClaimFormWithTan onSubmit={onSubmit} isBusy={isBusy} />
		: <DocumentPermitClaimFormWithoutTan onSubmit={onSubmit} isBusy={isBusy} />;
}

function useIsBusy() {
	return useBrick(DocumentPermitClaimWorkflow, workflow => workflow.isBusy());
}

function useOnSubmit() {
	return useBrick(
		DocumentPermitClaimWorkflow,
		workflow => (workflow.canClaim() ? workflow.claim : noop)
	);
}
