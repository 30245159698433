import React, {useState} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import IconButton from '../../../ui/components/IconButton.js';
import {Left, Right} from '../../../ui/components/icons/ChevronIcons.js';
import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import {THE_YEAR_1900} from './constants.js';
import MonthComboBox from './MonthComboBox.js';
import YearComboBox from './YearComboBox.js';

export const FOCUS_TIMEOUT_AFTER_COMBO_BLUR = 150;
export const INDEX_OF_DECEMBER = 11;

export default function MonthNavigation(props) {
	const {disabled, date, setDate, locale, onFastBlurAfterChange, name} = props;
	const [lastComboUpdate] = useState({current: 0});
	const onComboBlur = useMemoFactory(createOnFastBlurAfterChange, lastComboUpdate, onFastBlurAfterChange);
	const updateCurrentDate = useMemoFactory(createUpdateCurrentDate, lastComboUpdate, setDate);
	const [onMonthBackward, onMonthForward] = useMemoFactory(createMonthNavigationHandlers, date, setDate);
	const currentMonth = date.month();
	const hasPreviousMonth = currentMonth > 0 ||
			date.get('year') > THE_YEAR_1900;
	const hasNextMonth = currentMonth < INDEX_OF_DECEMBER ||
			date.get('year') < moment().get('year');
	return (
		<HorizontalLayout className='material-date-picker--combo-boxes' justify='between' align='center'>
			<IconButton size='small' onClick={onMonthBackward}
			            disabled={disabled || !hasPreviousMonth} icon={<Left />} />
			<MonthComboBox date={date} locale={locale} onChange={updateCurrentDate} name={`${name}-month`}
			               disabled={disabled} onBlur={onComboBlur} />
			<YearComboBox date={date} locale={locale} onChange={updateCurrentDate} name={`${name}-year`}
			              disabled={disabled} onBlur={onComboBlur} />
			<IconButton size='small' onClick={onMonthForward} disabled={disabled || !hasNextMonth} icon={<Right />} />
		</HorizontalLayout>
	);
}
MonthNavigation.propTypes = {
	disabled: PropTypes.bool,
	date: PropTypes.instanceOf(moment),
	setDate: PropTypes.func,
	locale: PropTypes.string,
	onFastBlurAfterChange: PropTypes.func,
	name: PropTypes.string.isRequired
};

function createUpdateCurrentDate(lastComboUpdate, setDateState) {
	return function updateCurrentDate(newDate) {
		lastComboUpdate.current = Date.now();
		setDateState(newDate);
	};
}

function createOnFastBlurAfterChange(lastComboUpdate, callback) {
	return function onComboBlur() {
		const timeElapsed = (Date.now() - lastComboUpdate.current);
		if (timeElapsed < FOCUS_TIMEOUT_AFTER_COMBO_BLUR) {
			callback();
		}
	};
}

function createMonthNavigationHandlers(currentDate, setDateState) {
	function onMonthBackward() {
		setDateState(moment(currentDate)
			.subtract(1, 'months'));
	}
	function onMonthForward() {
		setDateState(moment(currentDate)
			.add(1, 'months'));
	}
	return [onMonthBackward, onMonthForward];
}

