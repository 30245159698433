import React from 'react';

import {getHocDisplayName} from '../commons/utils/ReactUtils.js';
import useBrick from './hooks/useBrick.js';

export default function connectBrick(brickType, selectFunction) {
	function selectBrickProperties(brick) {
		return selectFunction(brick);
	}
	return Component => brickConnectorFactory(brickType, selectBrickProperties, Component);
}

function brickConnectorFactory(brickType, selectFunction, Component) {
	function WithBrickProps(props) {
		const serviceProps = useBrick(brickType, selectFunction);
		return <Component {...serviceProps} {...props} />;
	}

	WithBrickProps.displayName = getHocDisplayName(WithBrickProps, 'withBrickProps');
	return WithBrickProps;
}

