import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import PrimaryAction from '../../material-design/components/layout/PrimaryAction.js';
import {PrimaryActionAvatarArea} from '../../material-design/components/layout/PrimaryActionSideAreas.js';
import TwoLineListItem from '../../material-design/components/layout/TwoLineListItem.js';
import PrimaryItemText from '../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../material-design/components/text/SecondaryItemText.js';
import Avatar from '../../ui/components/Avatar.js';
import PersonIcon from '../../ui/components/icons/PersonIcon.js';
import {
	DATA_FIELD_EMAIL,
	DATA_FIELD_FIRSTNAME, DATA_FIELD_LASTNAME, DATA_FIELD_TITLE
} from '../constants/ShareReceiverConstants.js';

import '../../../styles/share-document/components/ShareReceiverListItem.scss';

function formatReceiverName(title, firstname, lastname) {
	const receiverName = `${firstname} ${lastname}`;
	return title
		? `${title} ${receiverName}`
		: receiverName;
}

export default function ShareReceiverListItem(props) {
	const {shareReceiver, selected, nonInteractive} = props;
	const email = shareReceiver.get(DATA_FIELD_EMAIL);
	const firstname = shareReceiver.get(DATA_FIELD_FIRSTNAME);
	const lastname = shareReceiver.get(DATA_FIELD_LASTNAME);
	const title = shareReceiver.get(DATA_FIELD_TITLE);

	const receiverName = formatReceiverName(title, firstname, lastname);
	const rowClassName = classNames({
		'share-receiver-list-item': true,
		interactive: !selected && !nonInteractive,
		selected
	});
	return (
		<ContentRow className={rowClassName}>
			<PrimaryAction>
				<PrimaryActionAvatarArea>
					<Avatar>
						<PersonIcon />
					</Avatar>
				</PrimaryActionAvatarArea>
				<TwoLineListItem>
					<PrimaryItemText>
						{receiverName}
					</PrimaryItemText>
					<SecondaryItemText>
						{email}
					</SecondaryItemText>
				</TwoLineListItem>
			</PrimaryAction>
		</ContentRow>
	);
}

ShareReceiverListItem.propTypes = {
	shareReceiver: immutableMapPropType,
	selected: PropTypes.bool,
	nonInteractive: PropTypes.bool
};
