import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import SearchAppBar from '../../search/components/SearchAppBar.js';
import IconButton from '../../ui/components/IconButton.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import {MAX_PATIENT_SEARCH_RESULTS_LIMIT} from '../PatientSearchConstants.js';

const TRANSLATED_PAGE_TITLE = <SynFormattedMessage message='PageTitle' />;
const TRANSLATED_PAGE_SEARCH_PATIENT_TITLE = <SynFormattedMessage message='SearchFormCaption' />;
const TRANSLATED_PAGE_CHOOSE_PATIENT_TITLE = <SynFormattedMessage message='ChoosePatient' />;

export default function PatientSearchAppBar(props) {
	const {sidebarElevated} = props;
	const {showResults, editCriteria, nrResults} = useBrick(AbstractPatientSearchService, selectServiceProps);
	const backButton = (
		<IconButton onClick={editCriteria} color='inherit'>
			<BackIcon />
		</IconButton>
	);
	const subTitle = showResults ? TRANSLATED_PAGE_CHOOSE_PATIENT_TITLE : TRANSLATED_PAGE_SEARCH_PATIENT_TITLE;
	return (
		<SearchAppBar sidebarElevated={sidebarElevated} maxLimit={MAX_PATIENT_SEARCH_RESULTS_LIMIT}
		              nrResults={nrResults} title={TRANSLATED_PAGE_TITLE} subTitle={subTitle} showResults={showResults}
		              backButton={backButton} />
	);
}

PatientSearchAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired
};

function selectServiceProps(service) {
	return {
		showResults: service.getState() === AbstractPatientSearchService.State.SHOW_RESULTS,
		editCriteria: service.editCriteria,
		nrResults: service.hasResults()
			              ? service.getResults().getTotalSize()
			              : undefined
	};
}
