import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import GuardedPatientInfo from '../../components/GuardedPatientInfo.js';
import {
	getLoadError,
	selectCanLoad,
	selectPatient
} from '../selectors/PatientDetailsSelectors.js';

export default connect(
	createStructuredSelector({
		patient: selectPatient,
		canLoad: selectCanLoad,
		hasLoadError: state => Boolean(getLoadError(state))
	})
)(GuardedPatientInfo);
