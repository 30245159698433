import React from 'react';
import PropTypes from 'prop-types';

import DataTableFooter from '../../commons/components/data/DataTableFooter.js';
import NoResults from '../../commons/components/data/NoResults.js';
import RowCountGuardContainer from '../../commons/containers/data/RowCountGuardContainer.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import {MAX_LIMIT} from '../../document-search/constants/DocumentSearchConstants.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';

import '../../../styles/commons/components/data/DataTable.scss';

export default function createDataTable(DataTableHeader, TableComponent, NoResultsComponent = NoResults) {
	const DataTableWithRef = withForwardRef(function DataTable(props) {
		const {forwardRef, ...remainingProps} = props;
		return (
			<DataTableImpl ref={forwardRef}
								tableComponent={TableComponent}
								noResultsComponent={NoResultsComponent}
								tableHeader={DataTableHeader}
								{...remainingProps} />
		);
	}, 'forwardRef');
	DataTableWithRef.measureNumberRowsForHeight = TableComponent.measureNumberRowsForHeight;
	DataTableWithRef.propTypes = cloneWithoutProperties(DataTableImpl.propTypes,
		'tableComponent', 'noResultsComponent', 'tableHeader'
	);
	return DataTableWithRef;
}

class DataTableImpl extends React.Component {
	render() {
		const {resultList, isFetchInProgress} = this.props;
		if (resultList === null) {
			if (isFetchInProgress) {
				return this.renderDataTable();
			}
			return false;
		} else if (resultList.getTotalSize() === 0) {
			return this.renderNoResults();
		}
		return this.renderDataTable();
	}

	renderNoResults() {
		const {noResultsComponent: NoResultsComponent} = this.props;
		return <NoResultsComponent {...this.props} />;
	}

	renderDataTable() {
		const {
			rowsPerPage, nrResults, startIndex, additionalProps, onRowCountChange, resultList, isFetchInProgress,
			tableComponent: TableComponent, tableHeader: DataTableHeader
		} = this.props;
		return (
			<React.Fragment>
				<DataTableHeader className='data-table--header header' nrResults={nrResults}
										  additionalProps={additionalProps} />
				<RowCountGuardContainer className='data-table--content-area' rowsPerPage={rowsPerPage}
												measureNumberRowsForHeight={TableComponent.measureNumberRowsForHeight}
												onRowCountChange={onRowCountChange}>
					<TableComponent numberRows={rowsPerPage} startIndex={startIndex} resultList={resultList}
											 loadInProgress={isFetchInProgress} {...additionalProps} />
				</RowCountGuardContainer>
				{this.renderFooter()}
			</React.Fragment>
		);
	}

	renderFooter() {
		const {
			resultList, startIndex, to, previousPageLocation, nextPageLocation, onClickPrev, onClickNext
		} = this.props;
		const footerClassName = 'data-table--footer footer';
		if (resultList === null) {
			return <div className={footerClassName} />;
		}
		return (
			<DataTableFooter className={footerClassName} from={startIndex} to={to} maxLimit={MAX_LIMIT}
									  total={resultList.getTotalSize()} previousPageLocation={previousPageLocation}
									  nextPageLocation={nextPageLocation} onClickPrev={onClickPrev}
									  onClickNext={onClickNext} />
		);
	}

	checkFetch() {
		const {isFetchNeeded, fetchResults} = this.props;
		if (isFetchNeeded) {
			window.requestAnimationFrame(fetchResults);
		}
	}

	componentDidMount() {
		this.checkFetch();
	}

	componentDidUpdate() {
		this.checkFetch();
	}
}

DataTableImpl.propTypes = {
	tableComponent: PropTypes.elementType,
	noResultsComponent: PropTypes.elementType,
	tableHeader: PropTypes.elementType,
	resultList: resultListPropType,
	isFetchInProgress: PropTypes.bool,
	rowsPerPage: PropTypes.number,
	nrResults: PropTypes.number,
	startIndex: PropTypes.number,
	additionalProps: PropTypes.object,
	onRowCountChange: PropTypes.func,
	to: PropTypes.number,
	previousPageLocation: immutableMapPropType,
	nextPageLocation: immutableMapPropType,
	isFetchNeeded: PropTypes.bool,
	fetchResults: PropTypes.func,
	onClickPrev: PropTypes.func,
	onClickNext: PropTypes.func
};
