import createFormAccessors from '../../commons/data/FormAccessorsFactory.js';
import {formNotEmpty} from '../../commons/data/validators';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';

const SEARCH_FORM_SCHEMA = {
	fields: {
		patient_id: {
			label: 'PatientId',
			defaultValue: null
		},
		last_name: {
			label: 'LastName',
			defaultValue: null
		},
		first_name: {
			label: 'FirstName',
			defaultValue: null
		},
		sex_fk: {
			label: 'Sex',
			defaultValue: ''
		},
		birth_date: {
			label: 'BirthDate',
			defaultValue: null
		},
		description: {
			label: 'Description',
			defaultValue: null
		},
		document_created_when: {
			label: 'ExaminationDate',
			defaultValue: null
		},
		modality_fk: {
			label: 'Modality',
			defaultValue: ''
		}
	},
	validators: [formNotEmpty(DocumentSearchPageMessagesTranslator, 'SearchFormEmptyMessage')]
};

export default createFormAccessors(SEARCH_FORM_SCHEMA, DocumentSearchPageMessagesTranslator);
