import {isError} from 'flux-standard-action';
import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../authentication/constants/SessionActionTypes.js';
import {
	PATIENT_SEARCH_CLEAR_CRITERIA,
	PATIENT_SEARCH_EDIT_CRITERIA,
	PATIENT_SEARCH_FETCH_RESULTS,
	PATIENT_SEARCH_PROCESS_RESULTS,
	PATIENT_SEARCH_RESET,
	PATIENT_SEARCH_SEARCH_PATIENTS
} from './PatientSearchActionTypes.js';
import {PATIENT_SEARCH_FETCH_FAILED} from './PatientSearchErrorTypes.js';
import {
	PATIENT_SEARCH_FIELD_CRITERIA, PATIENT_SEARCH_FIELD_ERROR_TYPE,
	PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER,
	PATIENT_SEARCH_FIELD_RESULTS, PATIENT_SEARCH_FIELD_STATE
} from './PatientSearchStateFields.js';
import {
	PATIENT_SEARCH_STATE_EDIT_CRITERIA,
	PATIENT_SEARCH_STATE_ERROR,
	PATIENT_SEARCH_STATE_SHOW_RESULTS
} from './PatientSearchStates.js';

const INITIAL_STATE = Immutable.Map({
	[PATIENT_SEARCH_FIELD_CRITERIA]: null,
	[PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER]: -1,
	[PATIENT_SEARCH_FIELD_STATE]: PATIENT_SEARCH_STATE_EDIT_CRITERIA,
	[PATIENT_SEARCH_FIELD_RESULTS]: null,
	[PATIENT_SEARCH_FIELD_ERROR_TYPE]: null
});

export default handleActions(
	{
		[PATIENT_SEARCH_SEARCH_PATIENTS]: searchPatients,
		[PATIENT_SEARCH_FETCH_RESULTS]: fetchResults,
		[PATIENT_SEARCH_PROCESS_RESULTS]: processResults,
		[PATIENT_SEARCH_EDIT_CRITERIA]: editCriteria,
		[PATIENT_SEARCH_CLEAR_CRITERIA]: clearCriteria,
		[PATIENT_SEARCH_RESET]: resetReducer,
		[END_SESSION]: resetReducer,
		[BEGIN_SESSION]: resetReducer
	},
	INITIAL_STATE
);

function resetReducer(state) {
	return INITIAL_STATE
		.set(PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER, state.get(PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER) + 1);
}

function searchPatients(state, action) {
	const {criteria} = action.payload;
	const immutableCriteria = Immutable.fromJS(criteria);
	return state
		.set(PATIENT_SEARCH_FIELD_CRITERIA, immutableCriteria)
		.set(PATIENT_SEARCH_FIELD_STATE, PATIENT_SEARCH_STATE_SHOW_RESULTS);
}

function fetchResults(state, action) {
	const {payload: currentLoadSequenceNumber} = action;
	return state
		.set(PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER, currentLoadSequenceNumber);
}

function processResults(state, action) {
	if (isError(action)) {
		return processErroneousLoad(state);
	} else if (isInSequence(state, action)) {
		return processSuccessfulLoad(state, action);
	}
	return state;
}

function editCriteria(state) {
	return state
		.set(PATIENT_SEARCH_FIELD_STATE, PATIENT_SEARCH_STATE_EDIT_CRITERIA)
		.set(PATIENT_SEARCH_FIELD_RESULTS, null);
}

function clearCriteria(state) {
	return editCriteria(state)
		.set(PATIENT_SEARCH_FIELD_CRITERIA, INITIAL_STATE.get(PATIENT_SEARCH_FIELD_CRITERIA));
}

function isInSequence(state, action) {
	const {payload} = action;
	const {loadSequenceNumber} = payload;
	const currentLoadSequenceNumber = state.get(PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER);
	return loadSequenceNumber === currentLoadSequenceNumber;
}

function processErroneousLoad(state) {
	return state
		.set(PATIENT_SEARCH_FIELD_STATE, PATIENT_SEARCH_STATE_ERROR)
		.set(PATIENT_SEARCH_FIELD_ERROR_TYPE, PATIENT_SEARCH_FETCH_FAILED);
}

function processSuccessfulLoad(state, action) {
	const {payload} = action;
	const {results} = payload;
	return state
		.set(PATIENT_SEARCH_FIELD_RESULTS, results);
}
