import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Thumbnail from '../../../webupload/components/UploadFilesGrid/Thumbnail.js';
import HumanReadableDataSize from '../../commons/components/HumanReadableDataSize.js';
import Tappable from '../../commons/components/Tappable.js';
import {FileEntryType} from '../../commons/utils/CustomPropTypes.js';
import CheckCircleIcon, {CheckCircleIconBlank} from '../../material-design/components/icons/CheckCircleIcon.js';
import PrimaryTileText from '../../material-design/components/text/PrimaryTileText.js';
import SecondaryTileText from '../../material-design/components/text/SecondaryTileText.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/archive-documents/components/ArchiveImagePreview.scss';

// TODO: Extracted from patient details preview images styles. A generic material image list component
//  should be created, which gets used by all components in need for image lists.

export default React.memo(ArchiveImagePreview);

function ArchiveImagePreview(props) {
	const {toggleSelection, file: fileEntry, isSelected} = props;
	const {name: fileName, size} = fileEntry;
	const isSelectable = Boolean(toggleSelection);
	const finalClassName = classNames({
		'archive-image': true,
		selectable: isSelectable
	});
	return fileEntry && (
		<Tappable onClick={toggleSelection} className={finalClassName}>
			<Thumbnail fileEntry={fileEntry} />
			<Overlay isSelected={isSelected} isSelectable={isSelectable} fileName={fileName} sizeBytes={size} />
		</Tappable>
	);
}

ArchiveImagePreview.propTypes = {
	toggleSelection: PropTypes.func,
	file: FileEntryType,
	isSelected: PropTypes.bool
};

function Overlay(props) {
	const {isSelectable, fileName, sizeBytes, isSelected} = props;
	const justifyContent = isSelectable ? 'between' : 'end';
	const finalClassNames = classNames({
		'archive-image--overlay': true,
		selected: isSelected
	});
	return (
		  <VerticalLayout justify={justifyContent} className={finalClassNames}>
			  {isSelectable && <SelectionIcon isSelected={isSelected} />}
			  <Caption fileName={fileName} sizeBytes={sizeBytes} />
		  </VerticalLayout>
	);
}

Overlay.propTypes = {
	fileName: PropTypes.string.isRequired,
	isSelectable: PropTypes.bool.isRequired,
	isSelected: PropTypes.bool,
	sizeBytes: PropTypes.number.isRequired
};


function SelectionIcon(props) {
	const {isSelected} = props;
	return (
		<div className='archive-image--overlay-select-icon'>
			{isSelected ? <CheckCircleIcon /> : <CheckCircleIconBlank />}
		</div>
	);
}

SelectionIcon.propTypes = {
	isSelected: PropTypes.bool
};

function Caption(props) {
	const {fileName, sizeBytes} = props;
	return (
		  <VerticalLayout justify={'center'} align={'start'} noGrow noShrink className='archive-image--overlay-caption'>
			  <PrimaryTileText>
				  {fileName}
			  </PrimaryTileText>
			  <SecondaryTileText>
				  <HumanReadableDataSize amountBytes={sizeBytes} />
			  </SecondaryTileText>
		  </VerticalLayout>
	);
}

Caption.propTypes = {
	fileName: PropTypes.string.isRequired,
	sizeBytes: PropTypes.number.isRequired
};
