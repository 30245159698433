import {Record} from 'immutable';

import {booleanValue} from '../../commons/utils/BooleanUtils.js';

export const DocumentClass = new Record({
	isDefault: false,
	id: null,
	displayName: ''
}, 'DocumentClassEntry');

export default function convertToDocumentClass(rawJSON) {
	return new DocumentClass({
		isDefault: booleanValue(rawJSON.is_default),
		id: rawJSON.id,
		displayName: rawJSON.display_name
	});
}
