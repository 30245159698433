import React from 'react';
import Cleave from 'cleave.js/react.js';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../utils/customHooks';
import {callSafe} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {withForwardRef} from '../utils/ReactUtils.js';

const COUNTRY_BLOCK = 3;
const NUMBER_BLOCK = 23;

const CLEAVE_PHONE_FIELD_OPTIONS = {
	prefix: '+',
	blocks: [COUNTRY_BLOCK, NUMBER_BLOCK],
	delimiters: [' '],
	delimiterLazyShow: true
};

export default withForwardRef(React.memo(PhoneField, propsAlwaysEqual), 'inputRef');

function PhoneField(props) {
	const {inputRef, onChange} = props;
	const onCleaveChange = useMemoFactory(createOnCleaveChange, onChange);
	const remainingProps = cloneWithoutProperties(props, 'onChange', 'inputRef');
	return (
		<Cleave options={CLEAVE_PHONE_FIELD_OPTIONS} htmlRef={inputRef} onChange={onCleaveChange} {...remainingProps} />
	);
}

PhoneField.propTypes = {
	onChange: PropTypes.func,
	inputRef: withForwardRef.PropTypes.Ref
};

function createOnCleaveChange(onChange) {
	return function onCleaveChange(event) {
		const {rawValue} = event.target;
		callSafe(onChange, rawValue);
	};
}

function propsAlwaysEqual() {
	//does not require updating, as all updates are done by cleave
	return true;
}
