import React from 'react';
import PropTypes from 'prop-types';

import Status from '../../a11y/components/landmarks/Status.js';
import {DEVICE_SIZE_LARGE, deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import CardHeader from '../../material-design/components/layout/CardHeader.js';
import Title from '../../material-design/components/Title.js';
import SearchStatus from '../../search/components/SearchStatus.js';
import {MAX_PATIENT_SEARCH_RESULTS_LIMIT} from '../PatientSearchConstants.js';
import TranslatedSearchResultsParameters from './PatientSearchResultsParameters.js';

export default function PatientSearchResultsHeader(props) {
	const {nrResults, additionalProps: {deviceSize, editCriteria, searchCriteria}} = props;
	return deviceSize === DEVICE_SIZE_LARGE && (
		<CardHeader className='header'>
			<Title wrapperComponent={Status}>
				<SearchStatus nrResults={nrResults} maxLimit={MAX_PATIENT_SEARCH_RESULTS_LIMIT} />
			</Title>
			<TranslatedSearchResultsParameters nrResults={nrResults} showForm={editCriteria}
			                                   parameters={searchCriteria} />
		</CardHeader>
	);
}

PatientSearchResultsHeader.propTypes = {
	nrResults: PropTypes.number,
	additionalProps: PropTypes.shape({
		deviceSize: deviceSizePropType,
		editCriteria: PropTypes.func,
		searchCriteria: immutableMapPropType
	})
};
