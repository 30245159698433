import {createStructuredSelector} from 'reselect';

import DocumentSearchDataTableRow from '../../../document-search/components/DocumentSearchDataTableRow.js';
import connectWithInstanceSelector from '../../flux/connectWithInstanceSelector.js';
import {createSelectIsItemSelected} from '../../selectors/SelectedItemsSelectors.js';

function createMapStateToProps(props) {
	const selectIsItemSelected = createSelectIsItemSelected(props.result.get('id'));

	return createStructuredSelector({
		active: selectIsItemSelected
	});
}

export default connectWithInstanceSelector(
	createMapStateToProps,
	{}
)(DocumentSearchDataTableRow);
