import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {ClickTrackedIconButton} from '../../metrics-collector/components/TrackedComponents.js';
import Dialog from '../../ui/components/Dialog.js';
import BarcodeScanIcon from '../../ui/components/icons/BarcodeScanIcon.js';
import {ALLOWED_BARCODE_TYPES} from '../constants/SynSettingsConstants.js';
import {deviceHasVideoSource} from '../utils/FeatureDetectionUtils.js';
import {callSafe} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';
import ConfirmBarcodeScanResult from './ConfirmBarcodeScanResult.js';
import JSBasedBarcodeScanner from './JSBasedBarcodeScanner.js';

export default class BarcodeScannerButton extends React.PureComponent {
	constructor(props) {
		super(props);
		const {showInitial} = this.props;
		this.state = {
			showScanDialog: showInitial,
			hasCamera: false,
			scanResult: null
		};
		this.componentIsMounted = false;
		this.hasHTTPS = location.protocol && location.protocol === 'https:';
		this.boundHideScannerDialog = this.hideScannerDialog.bind(this);
		this.boundShowScannerDialog = this.showScannerDialog.bind(this);
		this.boundHandleOnChange = this.handleOnChange.bind(this);
		this.boundOnRejectScanResult = this.handleOnRejectScanResult.bind(this);
		this.boundHandleOnDetected = this.handleOnDetected.bind(this);
		this.scanBarcodeScanTitle = <SynFormattedMessage message='ScanBarcode' />;
		this.scanBarcodeConfirmTitle = <SynFormattedMessage message='BarcodeDetected' />;
	}

	render() {
		const {usageContext, metricsKey, isMobileDevice, small, ...remainingProps} = this.props;
		const {hasCamera, showScanDialog, scanResult} = this.state;
		const buttonProps = cloneWithoutProperties(
			remainingProps, 'onChange', 'registerMetricsAction', 'showInitial', 'targetName', 'scanner'
		);
		return this.hasHTTPS && hasCamera && (
			<React.Fragment>
				<ClickTrackedIconButton onClick={this.boundShowScannerDialog} icon={<BarcodeScanIcon />}
				                        usageContext={usageContext} metricsKey={`${metricsKey}Open`}
				                        size={small ? 'small' : undefined} {...buttonProps} />
				<Dialog fullScreen={isMobileDevice} show={showScanDialog} onClose={this.boundHideScannerDialog}
				        title={scanResult ? this.scanBarcodeConfirmTitle : this.scanBarcodeScanTitle}>
					{showScanDialog && this.renderDialogContent()}
				</Dialog>
			</React.Fragment>
		);
	}

	renderDialogContent() {
		const {scanner: Scanner, targetName} = this.props;
		const {scanResult} = this.state;
		if (scanResult) {
			return (
				<ConfirmBarcodeScanResult scanResult={scanResult} onProceed={this.boundHandleOnChange}
				                          onResume={this.boundOnRejectScanResult} targetName={targetName} />
			);
		}
		return <Scanner onChange={this.boundHandleOnDetected} symbologies={ALLOWED_BARCODE_TYPES} />;
	}

	componentDidMount() {
		this.componentIsMounted = true;
		deviceHasVideoSource().then(cameraAvailable => {
			if (cameraAvailable && this.componentIsMounted) {
				this.setState({
					hasCamera: true
				});
			}
		});
	}

	handleOnRejectScanResult() {
		this.setState({
			scanResult: null
		});
	}

	handleOnDetected(scanResult) {
		this.setState({scanResult});
	}

	handleOnChange() {
		const {scanResult} = this.state;
		const {onChange, registerMetricsAction, usageContext, metricsKey} = this.props;
		if (metricsKey) {
			callSafe(registerMetricsAction, `${metricsKey}DetectionSuccessful`, usageContext);
		}
		callSafe(onChange, scanResult);
		this.hideScannerDialog();
	}

	hideScannerDialog() {
		const {onCancel} = this.props;
		callSafe(onCancel);
		if (this.componentIsMounted) {
			this.setState({
				showScanDialog: false,
				scanResult: null
			});
		}
	}

	showScannerDialog() {
		this.setState({
			showScanDialog: true
		});
	}

	componentWillUnmount() {
		this.componentIsMounted = false;
	}
}

BarcodeScannerButton.propTypes = {
	scanner: PropTypes.elementType,
	usageContext: PropTypes.string,
	metricsKey: PropTypes.string,
	onChange: PropTypes.func,
	registerMetricsAction: PropTypes.func,
	isMobileDevice: PropTypes.bool,
	small: PropTypes.bool,
	showInitial: PropTypes.bool,
	onCancel: PropTypes.func,
	targetName: PropTypes.string
};

BarcodeScannerButton.defaultProps = {
	scanner: JSBasedBarcodeScanner,
	showInitial: false
};
