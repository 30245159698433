import React from 'react';
import PropTypes from 'prop-types';

import {callSafe} from '../../utils/FunctionUtils.js';
import {withForwardRef} from '../../utils/ReactUtils';
import makeMeasured from '../makeMeasured.js';

class RowCountGuard extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			measuredRows: null,
			prevHeight: props.height
		};
	}

	render() {
		const {measuredRows} = this.state;
		const {rowsPerPage} = this.props;
		return (measuredRows === rowsPerPage) ? this.renderChildren() : this.renderEmpty();
	}

	renderChildren() {
		const {className, children, forwardRef} = this.props;
		return (
			<div ref={forwardRef} className={className}>
				{children}
			</div>
		);
	}

	renderEmpty() {
		const {className, forwardRef} = this.props;
		return <div className={className} ref={forwardRef} />;
	}

	static getDerivedStateFromProps(nextProps, state) {
		const {height, measureNumberRowsForHeight} = nextProps;
		if (height !== undefined && height !== state.prevHeight && measureNumberRowsForHeight !== undefined) {
			const measuredRows = measureNumberRowsForHeight(height);
			if (measuredRows !== state.measuredRows) {
				const {onRowCountChange} = nextProps;
				callSafe(onRowCountChange, measuredRows);
				return {
					measuredRows,
					prevHeight: height
				};
			}
		}
		return null;
	}

	componentDidUpdate() {
		const {measuredRows} = this.state;
		const {height, measureNumberRowsForHeight, onRowCountChange} = this.props;
		if (measuredRows === null && height !== undefined) {
			const newMeasuredRows = measureNumberRowsForHeight(height);
			if (newMeasuredRows !== measuredRows) {
				this.setState({measuredRows: newMeasuredRows});
				callSafe(onRowCountChange, newMeasuredRows);
			}
		}
	}
}

RowCountGuard.propTypes = {
	height: PropTypes.number,
	measureNumberRowsForHeight: PropTypes.func,
	onRowCountChange: PropTypes.func,
	className: PropTypes.string,
	rowsPerPage: PropTypes.number,
	forwardRef: withForwardRef.PropTypes.Ref
};

function windowSizeChanged(nextProps, props) {
	return nextProps.windowSize !== props.windowSize;
}
export default makeMeasured(windowSizeChanged, withForwardRef(RowCountGuard, 'forwardRef'));
