import React from 'react';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import MaterialFormOrgunitComboBox from '../../material-design/components/form/MaterialFormOrgunitComboBox';

export default function OrdererField(props) {
	const {ordererList} = props;
	const withEmptyEntry = useMemoFactory(prependUndefinedEntry, ordererList);
	const ordererListArray = useMemoFactory(list => list.toJS(), withEmptyEntry);
	return <MaterialFormOrgunitComboBox id='orderer_select_field' fieldName='orderer' orgUnits={ordererListArray} />;
}

OrdererField.propTypes = {
	ordererList: immutablePropType.isRequired
};

function prependUndefinedEntry(list) {
	return list.unshift(undefined);
}
