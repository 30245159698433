import React from 'react';
import PropTypes from 'prop-types';

import StepperContent from '../../material-design/components/StepperContent.js';
import ShareReceiverLoaderContainer from '../flux/containers/ShareReceiverLoaderContainer.js';
import TabCreateReceiver from './TabCreateReceiver.js';
import TabSearchReceiver from './TabSearchReceiver.js';

export default class StepSelectReceiver extends React.PureComponent {
	render() {
		const {createNewReceiver} = this.props;
		return (
			<StepperContent>
				<ShareReceiverLoaderContainer key='guardedShareReceiverList' />
				{createNewReceiver ? <TabCreateReceiver {...this.props} /> : <TabSearchReceiver {...this.props} />}
			</StepperContent>
		);
	}
}

StepSelectReceiver.propTypes = {
	createNewReceiver: PropTypes.bool
};
