import {StatusCodes} from 'http-status-codes';

import {get} from '../../commons/api/operations/index.js';
import update from '../../commons/api/operations/update.js';
import UnauthorizedError from '../../commons/api/UnauthorizedError.js';
import {extractLongDate} from '../../commons/utils/DateUtils.js';

const DOCUMENT_PERMIT_BASE_URL = '/documentpermit';
const REQUIRED_NEW_USER_PARAMS = ['firstname', 'lastname', 'passwd', 'email', 'sms'];

export const PERMIT_STATUS_REQUIRES_AUTHENTICATION = Symbol('PERMIT_STATUS_REQUIRES_AUTHENTICATION');
export const PERMIT_STATUS_UNKNOWN = Symbol('PERMIT_STATUS_UNKNOWN');
export const CLAIM_SUCCEEDED = Symbol('CLAIM_SUCCEEDED');
export const CLAIM_FAILED = Symbol('CLAIM_FAILED');
export const CLAIM_ERROR_USER_EXISTS = Symbol('CLAIM_ERROR_USER_EXISTS');
export const CLAIM_ERROR_UNKNOWN = Symbol('CLAIM_ERROR_USER_EXISTS');

export async function getPermitStatus(permitId) {
	try {
		const {documents, valid_until: validUntil} = await get(`${DOCUMENT_PERMIT_BASE_URL}/${permitId}`);
		return {valid_until: extractLongDate(validUntil), documents};
	} catch (fetchError) {
		if (fetchError instanceof UnauthorizedError) {
			return PERMIT_STATUS_REQUIRES_AUTHENTICATION;
		}
	}
	return PERMIT_STATUS_UNKNOWN;
}

export async function tryToClaimPermit(permitId, token, tan, newUserInfo = null) {
	const permitClaimUrl = createClaimUrl(permitId);
	const claimParameters = {token, tan};
	if (newUserInfo) {
		claimParameters.newUser = getValidatedUserInfo(newUserInfo);
	}
	let claimResult = CLAIM_FAILED;
	try {
		await update(permitClaimUrl, claimParameters);
		claimResult = CLAIM_SUCCEEDED;
	} catch (claimError) {
		const {responseAsJson, response} = claimError;
		if (responseAsJson && responseAsJson.userExists) {
			claimResult = CLAIM_ERROR_USER_EXISTS;
		} else if (response && response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
			claimResult = CLAIM_ERROR_UNKNOWN;
		}
	}
	return claimResult;
}

export function getValidatedUserInfo(newUserInfo) {
	const allParametersPresent = REQUIRED_NEW_USER_PARAMS.every(param => {
		const paramValue = newUserInfo[param];
		return paramValue !== null && paramValue !== undefined && paramValue.trim() !== '';
	});
	if (!allParametersPresent) {
		throw new Error(`All required parameters need to be specified: ${REQUIRED_NEW_USER_PARAMS.join(', ')}`);
	}
	return newUserInfo;
}

function createClaimUrl(permitId) {
	return `${DOCUMENT_PERMIT_BASE_URL}/${permitId}/claim`;
}

