import React from 'react';
import Proptypes from 'prop-types';

import BarcodeScannerButtonContainer from '../../commons/containers/BarcodeScannerButtonContainer.js';
import {isBarcodeScannerEnabled} from '../../commons/selectors/GeneralConfigSelectors.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import SingleLineTextField from './SingleLineTextField.js';

export default function BarcodeEnabledSingleLineTextField(props) {
	const {usageContext, barcodeMetricsKey, onChange, label} = props;
	const scannerButton = useMemoFactory(createScannerButton, usageContext, barcodeMetricsKey, onChange, label);
	return <SingleLineTextField {...props} textFieldButtons={scannerButton} />;
}

function createScannerButton(usageContext, barcodeMetricsKey, onChange, label) {
	if (isBarcodeScannerEnabled()) {
		return (
			<BarcodeScannerButtonContainer usageContext={usageContext} metricsKey={barcodeMetricsKey}
			                               onChange={onChange} targetName={label} />
		);
	}
	return undefined;
}

BarcodeEnabledSingleLineTextField.propTypes = {
	...SingleLineFormField.propTypes,
	usageContext: Proptypes.string,
	barcodeMetricsKey: Proptypes.string
};
