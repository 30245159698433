import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '../../commons/components/AppBar.js';
import AppBarOverflowButton from '../../commons/components/AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../../commons/components/webview/AppBarSidebarHeader.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Title from '../../material-design/components/Title.js';

export default function DocumentPermitClaimSuccessAppBar(props) {
	const {sidebarElevated} = props;
	const appBarProps = {};
	if (!sidebarElevated) {
		appBarProps.menuButton = false;
	}

	return (
		<AppBar {...props}>
			<AppBarPrimaryContent {...appBarProps}>
				<Title>
					<SynFormattedMessage message='DocumentPermit' />
				</Title>
			</AppBarPrimaryContent>
			<AppBarOverflowButton visible={sidebarElevated} />
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

DocumentPermitClaimSuccessAppBar.propTypes = {
	sidebarElevated: PropTypes.bool
};
