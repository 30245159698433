import React from 'react';

import StepperContent from '../../material-design/components/StepperContent.js';
import DocumentsBrowser from './DocumentsBrowser.js';

function StepChooseDocuments(/*props*/) {
	return (
		<StepperContent>
			<DocumentsBrowser showAddDocumentButton />
		</StepperContent>
	);
}

export default React.memo(StepChooseDocuments);
