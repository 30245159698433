import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import {useEffectOnMount} from '../../commons/utils/customHooks';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import PatientSearchBody from './PatientSearchBody.js';
import PatientSearchErrorDisplay from './PatientSearchErrorDisplay.js';

export default React.memo(PatientSearchController);

function PatientSearchController(props) {
	const {deviceSize, onSelectEmergencyPatient, onPatientClick, initiallyExpandedSearchFields} = props;
	const {state, editCriteria, hasResults, errorType} = useBrick(AbstractPatientSearchService, selectServiceProps);
	useEffectOnMount(() => {
		if (!hasResults) {
			editCriteria();
		}
	});

	switch (state) {
		case AbstractPatientSearchService.State.ERROR:
			return <PatientSearchErrorDisplay errorType={errorType} />;
		default:
			return (
				<PatientSearchBody deviceSize={deviceSize}
					initiallyExpandedSearchFields={initiallyExpandedSearchFields}
					onPatientClick={onPatientClick} onSelectEmergencyPatient={onSelectEmergencyPatient} />
			);
	}
}

PatientSearchController.propTypes = {
	deviceSize: deviceSizePropType.isRequired,
	onPatientClick: PropTypes.func,
	onSelectEmergencyPatient: PropTypes.func,
	initiallyExpandedSearchFields: PropTypes.bool
};

function selectServiceProps(service) {
	return {
		state: service.getState(),
		editCriteria: service.editCriteria,
		errorType: service.getErrorType(),
		hasResults: service.hasResults()
	};
}
