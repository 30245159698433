import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {searchArchiveDocumentPermissions} from '../../commons/api/ArchivePermissionApi.js';
import InsufficientPermissionsError from '../../commons/api/InsufficientPermissionsError.js';
import {searchVisits} from '../../commons/api/PatientVisitApi.js';
import DataGuard from '../../commons/components/data/DataGuard.js';
import ErrorGuard from '../../commons/components/ErrorGuard.js';
import {FULLSCREEN_TRANSITION_TIME} from '../../commons/constants/FullScreenWrapperConstants.js';
import {
	getAllSettledPayLoad,
	isAllSettledFulFilled,
	isAllSettledRejected
} from '../../commons/utils/PromiseUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DialogButtonContainer from '../../material-design/components/DialogButtonContainer.js';
import DialogFooter from '../../material-design/components/DialogFooter.js';
import PatientInfo from '../../patient-details/data/PatientInfo.js';
import {selectPatient} from '../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import Button from '../../ui/components/Button.js';
import {searchDocumentClasses} from '../api/DocumentClassApi.js';
import ArchiveDocumentsStepperContainer from '../flux/containers/ArchiveDocumentsStepperContainer.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

const TRANSITION_TIME_FACTOR = 1.2;

class GuardedArchiveDocumentsContent extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.state = {
			archivePermissions: null,
			documentClasses: null,
			visits: null,
			loadError: false,
			viewPatientVisitPermission: true
		};

		this.boundDoDataFetch = this.doDataFetch.bind(this);
	}

	render() {
		const {archivePermissions, documentClasses, visits, loadError, viewPatientVisitPermission} = this.state;
		return (
			<DataGuard indicatorDelayMS={FULLSCREEN_TRANSITION_TIME * TRANSITION_TIME_FACTOR}
				data={archivePermissions && documentClasses && visits}
				load={this.boundDoDataFetch} isLoadNeeded>
				<ErrorGuard errorNode={this.renderError()} error={loadError !== false}>
					<ArchiveDocumentsStepperContainer archivePermissions={archivePermissions}
						documentClasses={documentClasses} visits={visits}
						enableVisitField={viewPatientVisitPermission} />
				</ErrorGuard>
			</DataGuard>
		);
	}

	renderError() {
		const {handleDialogClose} = this.props;
	    return (
			<React.Fragment>
				<SynFormattedMessage translator={ArchiveDocumentsTranslator} message='AdditionalDataLoadingError' />
				<DialogFooter>
					<DialogButtonContainer>
						<Button flat opaque onClick={handleDialogClose}>
							<SynFormattedMessage message='ClosePhrase' translator={ArchiveDocumentsTranslator} />
						</Button>
					</DialogButtonContainer>
				</DialogFooter>
			</React.Fragment>
		);
	}

	doDataFetch() {
		const {patient} = this.props;
		const patientAimId = patient.get('id');

		Promise.allSettled([
			searchArchiveDocumentPermissions(patientAimId),
			searchDocumentClasses(),
			searchVisits(patientAimId)
		])
			.then(results => {
				const [archivePermissionsResult, documentClassesResult, visitsResult] = results;
				let archivePermissions = Immutable.List();
				let documentClasses = Immutable.List();
				let visits = Immutable.List();
				let archivePermissionsLoadError = false;
				let documentClassesLoadError = false;
				let visitsLoadError = false;
				let viewPatientVisitPermission = true;

				if (isAllSettledFulFilled(archivePermissionsResult)) {
					archivePermissions = getAllSettledPayLoad(archivePermissionsResult).getList();
				} else {
					archivePermissionsLoadError = true;
				}

				if (isAllSettledFulFilled(documentClassesResult)) {
					documentClasses = getAllSettledPayLoad(documentClassesResult).getList();
				} else {
					documentClassesLoadError = true;
				}

				if (isAllSettledFulFilled(visitsResult)) {
					visits = getAllSettledPayLoad(visitsResult).getList();
				} else if (
					isAllSettledRejected(visitsResult) &&
					getAllSettledPayLoad(visitsResult) instanceof InsufficientPermissionsError) {
					viewPatientVisitPermission = false;
				} else {
					visitsLoadError = true;
				}

				this.setState(
					{
						archivePermissions,
						documentClasses,
						visits,
						loadError: archivePermissionsLoadError || documentClassesLoadError || visitsLoadError,
						viewPatientVisitPermission
					}
				);
			});
	}
}

GuardedArchiveDocumentsContent.propTypes = {
	handleDialogClose: PropTypes.func,
	patient: PropTypes.instanceOf(PatientInfo)
};

export default connect(
	createStructuredSelector({
		patient: selectPatient
	})
)(GuardedArchiveDocumentsContent);

