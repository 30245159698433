import React from 'react';

import {HELP_PATH} from '../commons/constants/CommonRoutingConstants.js';
import SidebarLinkContainer from '../commons/containers/SidebarLinkContainer.js';
import SynFormattedMessage from '../i18n/components/SynFormattedMessage.js';
import WebPatientMessagesTranslator from '../i18n/translators/WebPatientMessagesTranslator.js';
import WebViewMessagesTranslator from '../i18n/translators/WebViewMessagesTranslator.js';
import CreateOrderIcon from '../ui/components/icons/CreateOrderIcon.js';
import HelpIcon from '../ui/components/icons/HelpIcon.js';
import MailIcon from '../ui/components/icons/MailIcon.js';
import SearchIcon from '../ui/components/icons/SearchIcon.js';
import {UploadRoundedIcon} from '../ui/components/icons/UploadIcon.js';
import Navigation from '../ui/components/Navigation.js';
import {
	WEBVIEW_CREATE_ORDER_PATH,
	WEBVIEW_DOCUMENT_PATH,
	WEBVIEW_DOCUMENT_SHARE_PATH, WEBVIEW_UPLOAD_PATH
} from '../WebViewRoutingConstants.js';

export default function WebViewMenu() {
	return (
		<Navigation>
			<SidebarLinkContainer path={WEBVIEW_DOCUMENT_SHARE_PATH} icon={<MailIcon />}>
				<SynFormattedMessage message='Inbox' translator={WebViewMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBVIEW_DOCUMENT_PATH} icon={<SearchIcon />}>
				<SynFormattedMessage message='DocumentSearch' translator={WebViewMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBVIEW_CREATE_ORDER_PATH} icon={<CreateOrderIcon />}>
				<SynFormattedMessage message='NewOrder' translator={WebViewMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={WEBVIEW_UPLOAD_PATH} icon={<UploadRoundedIcon />}>
				<SynFormattedMessage message='UploadNavigationMenuTitle' translator={WebPatientMessagesTranslator} />
			</SidebarLinkContainer>
			<SidebarLinkContainer path={HELP_PATH} icon={<HelpIcon />}>
				<SynFormattedMessage message='Help' translator={WebViewMessagesTranslator} />
			</SidebarLinkContainer>
		</Navigation>
	);
}
