import React, {useCallback} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Tappable from '../../../commons/components/Tappable.js';

export default function Day(props) {
	const {date, className, onSelectDay, disabled} = props;
	const boundSelectDay = useCallback(() => onSelectDay(date), [onSelectDay, date]);
	const onTap = disabled ? undefined : boundSelectDay;
	return (
		<Tappable Component='td' className={className} onTap={onTap}>
			{date.date()}
		</Tappable>
	);
}
Day.propTypes = {
	date: PropTypes.instanceOf(moment),
	className: PropTypes.string,
	onSelectDay: PropTypes.func,
	disabled: PropTypes.bool
};
