import React from 'react';

import Search from '../../a11y/components/landmarks/Search.js';
import FormEvaluationErrorContainer from '../../commons/containers/form/FormEvaluationErrorContainer.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {isPatientIDSearchEnabled} from '../../commons/selectors/GeneralConfigSelectors.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DialogButtonContainer from '../../material-design/components/DialogButtonContainer.js';
import BarcodeEnabledFormField from '../../material-design/components/form/BarcodeEnabledFormField.js';
import LinkLikeMaterialFormButton from '../../material-design/components/form/LinkLikeMaterialFormButton.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import MaterialFormDateRangeField from '../../material-design/components/form/MaterialFormDateRangeField.js';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import {DATE_RANGE_MODE_DATE} from '../../material-design/components/MaterialDateRangePicker.js';
import Row from '../../material-design/components/Row.js';
import {createResetThunkAction, setSearchQuery} from '../../search/flux/SearchActions.js';
import {DOCUMENT_SEARCH_METRICS_USAGE_CONTEXT} from '../constants/DocumentSearchConstants.js';
import ModalityComboFormFieldContainer from '../flux/containers/ModalitiesComboFormFieldContainer.js';
import ModalitiesDataGuard from '../flux/containers/ModalitiesDataGuard.js';
import {documentSearchQueryParams} from '../flux/DocumentSearchSelectors.js';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';
import searchFormAccessors from './SearchFormAccessors.js';
import SexComboFormField from './SexComboFormField.js';

import '../../../styles/search/components/SearchForm.scss';

const RESET_FORM_THUNK = createResetThunkAction(searchFormAccessors);

const GROUP_LABEL_MAPPING = [
	'ModalitiesComboBoxRecommendedModalitiesLabel',
	'ModalitiesComboBoxAllModalitiesLabel'
];

export default function DocumentSearchForm() {
	return (
		<ModalitiesDataGuard>
			<FormContainer className='search-form' description={searchFormAccessors} initialValuesSelector={documentSearchQueryParams}
					submitAction={setSearchQuery} resetAction={RESET_FORM_THUNK}>
				<Search className='search-form--fields-container'>
					<Row>
						{isPatientIDSearchEnabled() &&
								<div className='patient-id-field'>
									<BarcodeEnabledFormField fieldName='patient_id'
									                         usageContext={DOCUMENT_SEARCH_METRICS_USAGE_CONTEXT}
									                         barcodeMetricsKey='PatientIDBarcodeScanner' />
								</div>
						}
						<div className='last-name-field'>
							<SingleLineFormField fieldName='last_name' autoFocus />
						</div>
						<div className='first-name-field'>
							<SingleLineFormField fieldName='first_name' />
						</div>
						<div className='birth-date-field'>
							<MaterialFormDateRangeField dateRangeMode={DATE_RANGE_MODE_DATE} fieldName='birth_date' />
						</div>
						<div className='sex-field'>
							<SexComboFormField fieldName='sex_fk' />
						</div>

						<div className='document-created-when-field'>
							<MaterialFormDateRangeField fieldName='document_created_when' />
						</div>
						<div className='description-field'>
							<SingleLineFormField fieldName='description' />
						</div>
						<div className='modality-field'>
							<ModalityComboFormFieldContainer fieldName='modality_fk' getGroupLabel={getTranslatedModalityGroupLabel} />
						</div>
					</Row>
				</Search>
				<DialogButtonContainer className='search-form--buttons-container'>
					<LinkLikeMaterialFormButton type='reset' flat className='reset-link'>
						<SynFormattedMessage message='ResetButtonLabel' translator={DocumentSearchPageMessagesTranslator} />
					</LinkLikeMaterialFormButton>
					<FormEvaluationErrorContainer className='form-evaluation-error' />
					<MaterialFormButton className='search-button' type='submit' flat opaque>
						<SynFormattedMessage message='SearchButtonLabel' translator={DocumentSearchPageMessagesTranslator} />
					</MaterialFormButton>
				</DialogButtonContainer>
			</FormContainer>
		</ModalitiesDataGuard>
	);
}

function getTranslatedModalityGroupLabel(groupIndex, locale) {
	return DocumentSearchPageMessagesTranslator.getFormattedMessage(GROUP_LABEL_MAPPING[groupIndex], locale);
}
