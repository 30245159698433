import React from 'react';

import {PATIENTDETAILS_PAGE} from '../../commons/constants/PageNames.js';
import createWebViewDetailsPage from '../../components/createWebViewDetailsPage.js';
import PatientDetailsAppBarContainer from '../flux/containers/PatientDetailsAppBarContainer.js';
import PatientDetailsPageBodyContainer from '../flux/containers/PatientDetailsPageBodyContainer.js';

export default createWebViewDetailsPage(
	PatientDetailsAppBarContainer,
	PatientDetailsPageBodyContainer,
	PATIENTDETAILS_PAGE
);
