import {connect} from 'react-redux';
import {batchActions} from 'redux-batched-actions';
import {createStructuredSelector} from 'reselect';

import {shareReceiver as shareReceiverFluxApi, shareReceiverBackgroundValidator as shareReceiverBackgroundValidatorApi} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {uiIsSmallDevice} from '../../../ui/flux/UISelectors.js';
import ShareDocumentDialog from '../../components/ShareDocumentDialog.js';
import ShareReceiverFormAccessors from '../../components/ShareReceiverFormAccessors.js';
import {
	deleteStepperState, updateData
} from '../ShareDocumentDialogActions.js';
import {
	selectCurrentStep, selectData,
	selectHasFinished, selectIsCreatingShare, 	selectReceiverFilterOrResourceFilterString,
	selectShareMode,
	selectWasCancelled
} from '../ShareDocumentDialogSelectors.js';

const {
	selectFilterString,
	getRawResponse: selectShareReceiverList
} = shareReceiverFluxApi.selectors;

const {
	isLoadInProgress: isBackgroundValidationInProgress
} = shareReceiverBackgroundValidatorApi.selectors;

const {
	delete: deleteShareReceiverFormData
} = ShareReceiverFormAccessors;

const ShareDocumentDialogContainer = connect(
	createStructuredSelector({
		hasFinished: selectHasFinished,
		wasCancelled: selectWasCancelled,
		isCreatingShare: selectIsCreatingShare,
		receiverFilter: selectReceiverFilterOrResourceFilterString,
		requestedReceiverFilter: selectFilterString,
		receiverList: selectShareReceiverList,
		isSmallDevice: uiIsSmallDevice,
		isBackgroundValidationInProgress,
		currentStep: selectCurrentStep,
		data: selectData,
		shareMode: selectShareMode
	}),
	bindActions({
		updateData,
		cleanup: () => batchActions([
			deleteStepperState(),
			deleteShareReceiverFormData()
		])
	})
)(ShareDocumentDialog);

export default ShareDocumentDialogContainer;
