import React from 'react';

import AutoBrickFactory from '../../bricks/components/AutoBrickFactory.js';
import {searchArchiveDocumentPermissions} from '../../commons/api/ArchivePermissionApi.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext.js';
import CleanPatientSearchServiceProvider from '../../patient-search/components/CleanPatientSearchServiceProvider.js';
import ReduxPatientSearchService from '../../patient-search/flux/ReduxPatientSearchService.js';
import WebViewProducerList from '../bricks/WebViewProducerList';
import WebViewUploadPatientContext from '../bricks/WebViewUploadPatientContext.js';
import UploadTranslator from '../i18n/UploadTranslator.js';

export default React.memo(WebViewUploadPageContext);

function WebViewUploadPageContext(props) {
	const {children} = props;
	return (
		<CleanPatientSearchServiceProvider>
			<AutoBrickFactory brickType={WebViewUploadPatientContext}
			                  additionalDependencies={[ReduxPatientSearchService]} />
			<AutoBrickFactory brickType={WebViewProducerList} brickFactory={createProducerList} />
			<TranslationProvider translator={UploadTranslator}>
				{children}
			</TranslationProvider>
		</CleanPatientSearchServiceProvider>
	);
}

function createProducerList(...bricks) {
	return new WebViewProducerList(...bricks, searchArchiveDocumentPermissions);
}
