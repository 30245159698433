import Immutable from 'immutable';

import BrickBase from '../../bricks/BrickBase.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {debugError} from '../../commons/utils/DebugLog.js';
import {withSelectors} from '../../commons/utils/FunctionUtils.js';
import {onlyMostResentCancellable} from '../../commons/utils/PromiseUtils.js';
import {
	CLAIM_ERROR_USER_EXISTS, CLAIM_FAILED,
	CLAIM_SUCCEEDED,
	getValidatedUserInfo
} from '../api/documentPermitApi.js';
import DocumentPermitContext from './DocumentPermitContext.js';

export default class DocumentPermitClaimWorkflow extends BrickBase {
	#tryToClaim;

	constructor(tryToClaimPermitOperation, documentPermitContext) {
		super({
			isTanRequired: true,
			canBeClaimed: false,
			tan: null,
			token: documentPermitContext.canBeClaimed(),
			permitId: documentPermitContext.getPermitId(),
			pendingClaim: null,
			lastClaimResult: DocumentPermitClaimWorkflow.State.NOT_CLAIMED_YET,
			requireNewUser: false,
			newUser: null
		});
		this.#tryToClaim = DocumentPermitClaimWorkflow.#createTryToClaimOperation(tryToClaimPermitOperation);
		this.subscribeTo(documentPermitContext, withSelectors(
			context => context.canBeClaimed(),
			this.#setCanBeClaimed.bind(this)
		));
		this.subscribeTo(documentPermitContext, withSelectors(
			context => context.getPermitId(),
			this.#setPermitId.bind(this)
		));
		this.subscribeTo(this, withSelectors(
			workflow => workflow.getBrickState().permitId,
			this.#cancelPendingClaim.bind(this)
		));
	}

	shutdown() {
		super.shutdown();
		const {pendingClaim} = this.getBrickState();
		if (pendingClaim) {
			pendingClaim.cancel();
		}
	}

	isBusy() {
		return this.getBrickState().pendingClaim !== null;
	}

	isTanRequired() {
		return this.getBrickState().isTanRequired;
	}

	setIsTanRequired(required) {
		this.updateBrickState({isTanRequired: required});
	}

	setTan(tan) {
		this.updateBrickState({tan});
	}

	hasRequiredTan() {
		const {isTanRequired, tan} = this.getBrickState();
		return !isTanRequired || Boolean(tan);
	}

	clearTan() {
		this.updateBrickState({tan: null});
	}

	setNewUserInfo(newUser) {
		let newUserInfo = null;
		try {
			let newUserAsObject = newUser;
			if (newUserAsObject.toObject === Immutable.Map().toObject) {
				newUserAsObject = newUserAsObject.toObject();
			}
			newUserInfo = getValidatedUserInfo(newUserAsObject);
		} catch (validationError) {
			debugError(`NewUserInfo is not valid: ${validationError}`);
		}
		this.updateBrickState({newUser: newUserInfo});
	}

	hasNewUserInfo() {
		const {requireNewUser, newUser} = this.getBrickState();
		return !requireNewUser || Boolean(newUser);
	}

	clearNewUserInfo() {
		this.updateBrickState({newUser: null});
	}

	setToken(token) {
		this.updateBrickState({token});
	}

	canClaim() {
		const {token, canBeClaimed} = this.getBrickState();
		return canBeClaimed &&
			Boolean(token) &&
			this.hasRequiredTan() &&
			this.hasNewUserInfo();
	}

	getLastClaimResult() {
		return this.getBrickState().lastClaimResult;
	}

	claim() {
		if (this.canClaim()) {
			const {tan, token, permitId, newUser} = this.getBrickState();
			const pendingClaim = this.#tryToClaim(permitId, token, tan, newUser)
				.maybe(claimResult => {
					let lastClaimResult = claimResult === CLAIM_SUCCEEDED
						? DocumentPermitClaimWorkflow.State.CLAIM_SUCCEEDED
						: DocumentPermitClaimWorkflow.State.CLAIM_FAILED;
					if (claimResult === CLAIM_ERROR_USER_EXISTS) {
						lastClaimResult = DocumentPermitClaimWorkflow.State.CLAIM_FAILED_USER_ALREADY_EXISTS;
					} else if (claimResult === CLAIM_FAILED) {
						lastClaimResult = DocumentPermitClaimWorkflow.State.CLAIM_FAILED;
					}
					this.updateBrickState({lastClaimResult, pendingClaim: null});
				})
				.catch(() => {
					this.updateBrickState({
						pendingClaim: null,
						lastClaimResult: DocumentPermitClaimWorkflow.State.CLAIM_FAILED
					});
				})
				.cancelled(() => {
					const {pendingClaim: currentlyPendingClaim} = this.getBrickState();
					if (currentlyPendingClaim === pendingClaim) {
						this.updateBrickState({
							pendingClaim: null,
							lastClaimResult: DocumentPermitClaimWorkflow.State.NOT_CLAIMED_YET
						});
					}
				});
			this.updateBrickState({pendingClaim});
		}
	}

	requireNewUser(requireUser = true) {
		this.updateBrickState(oldState => {
			const {newUser, requireNewUser, lastClaimResult} = oldState;
			const requirementChanged = requireNewUser !== requireUser;
			return {
				...oldState,
				requireNewUser: requireUser,
				newUser: requirementChanged ? null : newUser,
				lastClaimResult: (
					requirementChanged ? DocumentPermitClaimWorkflow.State.NOT_CLAIMED_YET : lastClaimResult
				)
			};
		});
	}

	isNewUserRequired() {
		return this.getBrickState().requireNewUser;
	}

	#setCanBeClaimed(canBeClaimed) {
		this.updateBrickState({canBeClaimed});
	}

	#setPermitId(permitId) {
		this.updateBrickState({permitId});
	}

	#cancelPendingClaim() {
		const {pendingClaim} = this.getBrickState();
		if (pendingClaim) {
			pendingClaim.cancel();
		}
	}

	static #createTryToClaimOperation(claimOperation) {
		return onlyMostResentCancellable(claimOperation);
	}
}
declareBrick(DocumentPermitClaimWorkflow, [DocumentPermitContext]);

DocumentPermitClaimWorkflow.State = {
	NOT_CLAIMED_YET: Symbol('NOT_CLAIMED_YET'),
	CLAIM_SUCCEEDED: Symbol('CLAIM_SUCCEEDED'),
	CLAIM_FAILED: Symbol('CLAIM_FAILED'),
	CLAIM_FAILED_USER_ALREADY_EXISTS: Symbol('CLAIM_FAILED_USER_ALREADY_EXISTS')
};
