import Button, {buttonClasses} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

import GridListConstants from '../../../constants/GridListConstants.json';

const ICON_WIDTH_AMOUNT = 0.3;
const TILE_LENGTH = `${GridListConstants['tile-width'] * ICON_WIDTH_AMOUNT}px`;

const STYLES = ({theme}) => ({
	flexDirection: 'column',
	justifyContent: 'space-evenly',
	lineHeight: 'normal',
	height: '100%',
	width: '100%',
	border: `5px dashed ${theme.palette.primary.main}`,
	backgroundColor: 'rgba( 153, 153, 153, 0.2)',
	'&:hover:not(.disabled)': {
		backgroundColor: 'rgba( 153, 153, 153, 0.4)'
	},
	'&:active:not(.disabled)': {
		backgroundColor: 'rgba( 153, 153, 153, 0.6)'
	},
	[`& .${buttonClasses.startIcon}`]: {
		margin: '0',
		'& svg': {
			width: TILE_LENGTH,
			height: TILE_LENGTH
		}
	}
});

const ArchiveDocumentsFileButton = styled(Button, {name: 'ArchiveDocumentsUploadButton'})(STYLES);

export default ArchiveDocumentsFileButton;
