import React from 'react';
import MUIListItem from '@mui/material/ListItem';

export default React.memo(ListItem);

function ListItem(props) {
	const {children} = props;
	return (
		<MUIListItem>
			{children}
		</MUIListItem>
	);
}
