import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import SearchParametersList from '../../../search/components/SearchParametersList.js';
import {hideSearchResults} from '../../../search/flux/SearchActions.js';
import SearchFormAccessors from '../../components/SearchFormAccessors.js';
import {documentSearchQueryParams} from '../DocumentSearchSelectors.js';

function getParameterTranslator(state) {
	return label => SearchFormAccessors.getFieldLabel(state, label);
}

export default connect(
	createStructuredSelector({
		parameters: documentSearchQueryParams,
		locale: currentLocale,
		translateParameter: getParameterTranslator
	}),
	bindActions({
		showForm: hideSearchResults
	})
)(SearchParametersList);
