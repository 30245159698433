import Immutable from 'immutable';
import moment from 'moment';
import {createSelector} from 'reselect';

import {DESC} from '../../commons/constants/SortOrders.js';
import {FORMAT_DATE} from '../../commons/utils/DateUtils.js';
import {querySelector} from '../../router/flux/selectors/LocationSelectors.js';
import {
	SEARCH_QUERY_PARAMS,
	SEARCH_RESULTS,
	SEARCH_SEARCH_IN_PROGRESS,
	SEARCH_SORT_PARAMS
} from '../../search/constants/SearchPropertyNames.js';

const DEFAULT_SORT_OPTS = Immutable.fromJS([['document_created_when', DESC], ['id', DESC]]);

const getReducerState = state => state.documentSearch;

const documentSearchQueryParams = createSelector(querySelector,
	query => {
		let queryParams = query.get(SEARCH_QUERY_PARAMS, Immutable.Map());

		if (queryParams !== null) {
			queryParams = ['birth_date', 'document_created_when']
				.filter(paramName => queryParams.has(paramName))
				.reduce(
					(parsedQueryParams, dateParamName) => parsedQueryParams.update(
						dateParamName, convertStringRepresenationToDateParam
					),
					queryParams
				);
		}

		return queryParams;
	}
);

function convertStringRepresenationToDateParam(stringRepresentation) {
	const isoDates = stringRepresentation.split(';');
	let date = null;
	if (isoDates.length === 1 && isoDates[0] !== '') {
		date = extractDateSearchParam(isoDates[0]);
	} else if (isoDates.length === 2) {
		date = Immutable.Map({
			from: isoDates[0] === '' ? null : extractDateSearchParam(isoDates[0]),
			to: isoDates[1] === '' ? null : extractDateSearchParam(isoDates[1])
		});
	}
	return date;
}

const documentSearchSortParams = createSelector(
	querySelector,
	query => query.get(SEARCH_SORT_PARAMS, DEFAULT_SORT_OPTS)
);

const documentSearchFetchInProgress = createSelector(getReducerState,
	state => state.get(SEARCH_SEARCH_IN_PROGRESS, null)
);
const documentSearchQueryParamsUsedToRetrieveResultList = createSelector(getReducerState,
	state => state.get(SEARCH_QUERY_PARAMS, Immutable.Map())
);
const documentSearchResults = createSelector(getReducerState, state => state.get(SEARCH_RESULTS, null));

export {
	documentSearchQueryParams,
	documentSearchSortParams,
	documentSearchFetchInProgress,
	documentSearchQueryParamsUsedToRetrieveResultList,
	documentSearchResults
};

function extractDateSearchParam(formattedValue) {
	const isLongDate = formattedValue.length > FORMAT_DATE.length;
	return isLongDate ? moment(formattedValue) : moment.utc(formattedValue);
}
