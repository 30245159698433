import React from 'react';

import createFormFieldContainer from '../../../commons/containers/createFormFieldContainer.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import MaterialDateRangeField from '../MaterialDateRangeField.js';

export default createFormFieldContainer(
	MaterialDateRangeField,
	(stateProps, dispatchProps, ownProps) => {
		const {label} = ownProps;
		const {value, validationResult, isDefault, label: stateLabel} = stateProps;
		const {updateFieldValue} = dispatchProps;

		const displayValidationError = stateProps.validationResult !== true && stateProps.isFormValidationForced;
		const remainingProps = cloneWithoutProperties(ownProps,
			'fromDescription',
			'label'
		);
		return {
			...remainingProps,
			value,
			isDefault,
			label: label === undefined ? stateLabel : label,
			hint: displayValidationError ? validationResult : '',
			invalid: displayValidationError,
			onValidationError: (newError, newValue) => dispatchProps.updateFieldValue(newValue, newError),
			onChange: updateFieldValue
		};
	}
);
