import Immutable from 'immutable';
import moment from 'moment';

import {createAction} from '../../commons/utils/ActionUtils.js';
import {FORMAT_DATE} from '../../commons/utils/DateUtils.js';
import {pushLocation, replaceLocation} from '../../router/flux/LocationActions.js';
import {locationSelector, querySelector} from '../../router/flux/selectors/LocationSelectors.js';
import {uiIsExtraLargeDevice} from '../../ui/flux/UISelectors.js';
import {CANCEL_SEARCH} from '../constants/SearchActionTypes.js';
import {
	SEARCH_QUERY_PARAMS,
	SEARCH_SEEK_POSITION,
	SEARCH_SHOW_RESULTS,
	SEARCH_SORT_PARAMS
} from '../constants/SearchPropertyNames.js';

const DEFAULT_SEARCH_QUERY_FORMATTERS = {
	birth_date: convertDateParamToString,
	document_created_when: convertDateParamToString
};
const defaultSetSearchQuery = createSetSearchQueryAction(DEFAULT_SEARCH_QUERY_FORMATTERS);
const cancelSearchAction = createAction(CANCEL_SEARCH);

// Searching
export function setSearchQuery(searchParams) {
	return defaultSetSearchQuery(searchParams);
}

export function hideSearchResults() {
	return (dispatch, getState) => {
		const state = getState();
		const location = locationSelector(state).update('query',
			query => query.delete(SEARCH_SHOW_RESULTS)
		);
		dispatch(uiIsExtraLargeDevice(state) ? replaceLocation(location) : pushLocation(location));
	};
}

export function showSearchResults() {
	return (dispatch, getState) => {
		const state = getState();
		const location = locationSelector(state).update('query',
			query => query.set(SEARCH_SHOW_RESULTS, true)
		);
		dispatch(uiIsExtraLargeDevice(state) ? replaceLocation(location) : pushLocation(location));
	};
}

export function createSetSearchQueryAction(queryFormatters) {
	return function setSearchQueryWithFormatter(searchParams) {
		const filteredValues = filterEmptyEntries(searchParams);
		const valueNames = Object.keys(filteredValues);
		const formattedParams = valueNames.reduce((finalParams, name) => {
			let finalValue = filteredValues[name];
			if (name in queryFormatters) {
				finalValue = queryFormatters[name](finalValue);
			}
			finalParams[name] = finalValue;
			return finalParams;
		}, {});
		return setSearchQueryAction(formattedParams);
	};
}

// Clearing
export function clearSearchQuery() {
	return (dispatch, getState) => {
		let newLocation = locationSelector(getState());
		if (newLocation.has('query') && newLocation.get('query').size > 0) {
			newLocation = newLocation.set('query', Immutable.Map());
			dispatch(cancelSearchAction());
			dispatch(pushLocation(newLocation));
		}
	};
}

// Sorting
export function setSortParams(sortParams = null) {
	return (dispatch, getState) => {
		let newLocation = locationSelector(getState());
		if (Boolean(sortParams) && sortParams.size > 0) {
			newLocation = newLocation.update('query', query => query
				.set(SEARCH_SORT_PARAMS, sortParams));
		} else {
			newLocation = newLocation.update('query', query => query.delete(SEARCH_SORT_PARAMS));
		}
		dispatch(cancelSearchAction());
		dispatch(replaceLocation(newLocation));
	};
}

export function createResetThunkAction(formAccessor) {
	return () => dispatch => {
		dispatch(formAccessor.reset());
		dispatch(formAccessor.setFormValidationForced(false));
		dispatch(clearSearchQuery());
	};
}

function convertDateParamToString(dateParam) {
	let stringRepresentation = '';
	if (moment.isMoment(dateParam)) {
		stringRepresentation = dateParam.format(FORMAT_DATE);
	} else if (Immutable.Map.isMap(dateParam)) {
		const from = dateParam.get('from');
		const to = dateParam.get('to');
		const fromPart = (from && from.format(FORMAT_DATE)) || '';
		const toPart = (to && to.format(FORMAT_DATE)) || '';
		stringRepresentation = `${fromPart};${toPart}`;
	}
	return stringRepresentation;
}

function filterEmptyEntries(params) {
	return Object.keys(params).reduce((finalParams, key) => {
		const value = params[key];
		if (value !== null && value !== '') {
			finalParams[key] = value;
		}
		return finalParams;
	}, {});
}

function setSearchQueryAction(queryParams) {
	return (dispatch, getState) => {
		const state = getState();
		const locationQuery = Immutable.fromJS(queryParams);
		const oldQuery = querySelector(state).get(SEARCH_QUERY_PARAMS, Immutable.Map());
		const newLocation = locationSelector(state).update('query', Immutable.Map(), query => {
			let newQuery = query;
			if (locationQuery.size === 0) {
				newQuery = newQuery.delete(SEARCH_QUERY_PARAMS);
			} else {
				newQuery = newQuery.set(SEARCH_QUERY_PARAMS, locationQuery);
			}
			return newQuery.delete(SEARCH_SEEK_POSITION);
		});

		dispatch(cancelSearchAction());
		if (locationQuery.equals(oldQuery)) {
			dispatch(replaceLocation(newLocation));
		} else {
			dispatch(pushLocation(newLocation));
		}
		dispatch(showSearchResults());
	};
}

