import {Record} from 'immutable';

import {booleanValue} from '../utils/BooleanUtils.js';
import {extractLongDate} from '../utils/DateUtils.js';

export const PatientVisit = new Record({
	id: null,
	isDefault: false,
	admitDateTime: null,
	patientClassFK: '',
	preadmitNumber: ''
}, 'PatientVisitEntry');

export default function convertToPatientVisit(rawJSON) {
	return new PatientVisit({
		id: rawJSON.id,
		isDefault: booleanValue(rawJSON.is_default),
		admitDateTime: extractLongDate(rawJSON.admit_date_time),
		patientClassFK: rawJSON.patient_class_fk,
		preadmitNumber: rawJSON.preadmit_number
	});
}
