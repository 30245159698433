import React from 'react';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import StepperContent from '../../material-design/components/StepperContent.js';
import MultiLineTextField from '../../ui/components/MultiLineTextField.js';
import ShareDocumentMessagesTranslator from '../i18n/ShareDocumentTranslator.js';
import ShareReceiverListItem from './ShareReceiverListItem.js';

import '../../../styles/share-document/components/StepWriteMessage.scss';

class StepWriteMessage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnMessageChanged = this.onMessageChanged.bind(this);

		this.getMessageLabel = memoizeByFirstArg(locale => ShareDocumentMessagesTranslator.getTranslation('MessageToReceiver', locale));
	}

	render() {
		const {data, currentReceiver, locale} = this.props;
		const message = data.get('message');
		const label = this.getMessageLabel(locale);
		return (
			<StepperContent className='step-write-message'>
				<ShareReceiverListItem shareReceiver={currentReceiver} nonInteractive />
				<MultiLineTextField className='input-field' name='share_text_field' label={label} onChange={this.boundOnMessageChanged} value={message} />
			</StepperContent>
		);
	}

	onMessageChanged(newMessage) {
		const {data, updateData} = this.props;
		updateData(data.set('message', newMessage));
	}
}

StepWriteMessage.propTypes = {
	data: immutableMapPropType,
	updateData: PropTypes.func,
	currentReceiver: immutableMapPropType,
	locale: PropTypes.string
};

export default wrapInLocalizationContext(StepWriteMessage);
