import React from 'react';

import PatientContext from '../../../webupload/bricks/PatientContext.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import {PatientInfoWithGenderAvatar} from '../../commons/components/webview/PatientInfo.js';
import ResponsiveCard from '../../ui/components/ResponsiveCard.js';
import AdditionalUploadInfoForm from './AdditionalUploadInfoForm.js';

const CARD_STYLE = {
	boxShadow: 'none', 
	flexDirection: 'column',
	padding: '10px', 
	overflowX: 'hidden', 
	overflowY: 'auto'
};


export default function WebViewStepPrepareUploadProducer(props) {
	const patient = useBrick(PatientContext, brick => brick.getPatient());
	return (
		<React.Fragment>
			<PatientInfoWithGenderAvatar patient={patient} disableGenderAvatar />
			<ResponsiveCard style={CARD_STYLE} growVertically
				maxCardWidth='800px' minCardWidth='700px'>
				<AdditionalUploadInfoForm {...props} />
			</ResponsiveCard>
		</React.Fragment>
	);
}
