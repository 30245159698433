import React from 'react';
import {useSelector} from 'react-redux';

import {NO_BREAK_SPACE} from '../../commons/constants/UnicodeCharacterConstants.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Ellipsis from '../../ui/components/typography/Ellipsis.js';
import {uiIsSmallDevice} from '../../ui/flux/UISelectors.js';

export default function PatientIdInfo(props) {
	const {patientInfo} = props;
	const patientId = patientInfo.get('patient_id');
	const isSmallDevice = useSelector(uiIsSmallDevice);
	const patientIdMessage = isSmallDevice ? 'ShortPatientId' : 'PatientId';

	const preadmitNumber = patientInfo.get('preadmit_number');
	const caseIdMessage = isSmallDevice ? 'ShortCaseId' : 'CaseId';
	const visitIdAvailable = preadmitNumber !== null && preadmitNumber !== undefined;
	const visitInfo = visitIdAvailable && (
		<React.Fragment>
			{`${NO_BREAK_SPACE}|${NO_BREAK_SPACE}`}
			<SynFormattedMessage message={caseIdMessage} />
			{`:${NO_BREAK_SPACE}${preadmitNumber}`}
		</React.Fragment>
	);

	return (
		<Ellipsis>
			<SynFormattedMessage message={patientIdMessage} />
			{`:${NO_BREAK_SPACE}${patientId}`}
			{visitInfo}
		</Ellipsis>
	);
}

PatientIdInfo.propTypes = {
	patientInfo: immutableMapPropType.isRequired
};
