import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import {ListItem, ListItemText} from '../../../ui/components/List';


export default class ErrorCardListItem extends React.PureComponent {
	render() {
		const {error} = this.props;
		const errorText = error.message ? <SynFormattedMessage message={error.message} /> : error.text;
		const errorHint = error.hintMessage ? <SynFormattedMessage message={error.hintMessage} /> : error.hintText;

		return (
			<ListItem>
				<ListItemText secondary={errorHint}>
					{errorText}
				</ListItemText>
			</ListItem>
		);
	}
}

ErrorCardListItem.propTypes = {
	error: PropTypes.exact({
		text: PropTypes.string,
		message: PropTypes.string,
		hintText: PropTypes.string,
		hintMessage: PropTypes.string
	}).isRequired
};
