import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import {deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import {useEffectOnMount, useEffectOnUnmount} from '../../commons/utils/customHooks';
import AbstractPatientSearchService from '../../patient-search/AbstractPatientSearchService.js';
import PatientSearchController from '../../patient-search/components/PatientSearchController.js';
import CreateOrderService from '../CreateOrderService.js';
import ErrorDisplay from './CreateOrderErrorDisplay.js';
import NewOrderReport from './NewOrderReport.js';
import OrderDetailsForm from './OrderDetailsForm.js';
import ServiceBusy from './ServiceBusy.js';

export default React.memo(CreateOrderPageController);

function CreateOrderPageController(props) {
	const {deviceSize} = props;
	const {
		state, startWorkflow, resetCreateOrder, selectPatient, selectEmergencyPatient
	} = useBrick(CreateOrderService, selectCreateOrderServiceProps);
	const {resetPatientSearch} = useBrick(AbstractPatientSearchService, selectPatientSearchServiceProps);
	useEffectOnMount(startWorkflow);
	useEffectOnUnmount(resetCreateOrder);
	useEffectOnUnmount(resetPatientSearch);

	switch (state) {
		case CreateOrderService.State.BUSY:
			return <ServiceBusy />;
		case CreateOrderService.State.SELECT_PATIENT:
			return (
				<PatientSearchController deviceSize={deviceSize}
								  onPatientClick={selectPatient} onSelectEmergencyPatient={selectEmergencyPatient} />
			);
		case CreateOrderService.State.FILL_DETAILS:
			return <OrderDetailsForm />;
		case CreateOrderService.State.FINISHED:
			return <NewOrderReport />;
		default:
			return <ErrorDisplay />;
	}
}

CreateOrderPageController.propTypes = {
	deviceSize: deviceSizePropType.isRequired
};

function selectCreateOrderServiceProps(service) {
	return {
		state: service.getState(),
		startWorkflow: service.start,
		resetCreateOrder: service.reset,
		selectPatient: service.selectPatient,
		selectEmergencyPatient: service.selectEmergencyPatient
	};
}

function selectPatientSearchServiceProps(service) {
	return {
		resetPatientSearch: service.reset
	};
}
