import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import FormEvaluationErrorContainer from '../../commons/containers/form/FormEvaluationErrorContainer.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DialogButtonContainer from '../../material-design/components/DialogButtonContainer.js';
import LinkLikeMaterialFormButton from '../../material-design/components/form/LinkLikeMaterialFormButton.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import {ClickTrackedButton} from '../../metrics-collector/components/TrackedComponents.js';
import {createResetThunkAction} from '../../search/flux/SearchActions.js';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout.js';
import {COLOR_SECONDARY} from '../../ui/constants/SynMUIOptions.js';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import {PATIENT_SEARCH_METRICS_USAGE_CONTEXT} from '../PatientSearchConstants.js';
import patientSearchFormAccessors from '../PatientSearchFormAccessors.js';
import PatientSearchFormFields from './PatientSearchFormFields.js';

import '../../../styles/search/components/SearchForm.scss';
import '../../../styles/patient-search/components/PatientSearchForm.scss';

const resetFormThunk = createResetThunkAction(patientSearchFormAccessors);
const DEFAULT_FIELDS_GRID_DEFINITION = {xs: 4, sm: 4, xxl: 12};

export default function PatientSearchForm(props) {
	const {onSelectEmergencyPatient, initiallyExpandedSearchFields} = props;
	const {
		initialSearchCriteria, searchPatients, clearCriteria
	} = useBrick(AbstractPatientSearchService, selectServiceProps);
	const resetAction = useMemoFactory(createResetAction, clearCriteria);
	return (
		<FormContainer className='search-form' description={patientSearchFormAccessors}
		               initialValues={initialSearchCriteria}
		               onSubmit={searchPatients} resetAction={resetAction}>
			<PatientSearchFormFields initiallyExpandedSearchFields={initiallyExpandedSearchFields}
					fieldWidths={DEFAULT_FIELDS_GRID_DEFINITION}
					initialCriteria={initialSearchCriteria} />
			<DialogButtonContainer className='search-form--buttons-container'>
				<LinkLikeMaterialFormButton type='reset' flat className='reset-link'>
					<SynFormattedMessage message='ResetButtonLabel' />
				</LinkLikeMaterialFormButton>
				<FormEvaluationErrorContainer className='form-evaluation-error' />
				<MaterialFormButton className='search-button' type='submit' flat opaque>
					<SynFormattedMessage message='SearchButtonLabel' />
				</MaterialFormButton>
			</DialogButtonContainer>
			{onSelectEmergencyPatient &&
				<HorizontalLayout className='emergency-patient-link' justify='end'>
					<ClickTrackedButton color={COLOR_SECONDARY} flat opaque onClick={onSelectEmergencyPatient}
											  usageContext={PATIENT_SEARCH_METRICS_USAGE_CONTEXT} metricsKey='EmergencyPatient'>
						<SynFormattedMessage message='EmergencyPatient' />
					</ClickTrackedButton>
				</HorizontalLayout>
			}
		</FormContainer>
	);
}
PatientSearchForm.propTypes = {
	onSelectEmergencyPatient: PropTypes.func,
	initiallyExpandedSearchFields: PropTypes.bool
};

function createResetAction(onReset) {
	return function onFormReset() {
		callSafe(onReset);
		return resetFormThunk();
	};
}

function selectServiceProps(service) {
	return {
		initialSearchCriteria: service.getCriteria(),
		searchPatients: service.searchPatients,
		clearCriteria: service.clearCriteria
	};
}
