import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import SuggestComboBox from '../../../ui/components/SuggestComboBox.js';

export const MONTHS_PER_YEAR = 12;

export default function MonthComboBox(props) {
	const {date, locale, disabled, onBlur, onChange, name} = props;
	const monthOptions = useMemoFactory(getMonthOptions, locale);
	const onMonthChange = useMemoFactory(createHandleOnMonthChange, onChange, date);
	const value = monthOptions.find(monthEntry => monthEntry.monthIndex === date.month());
	return (
		<SuggestComboBox options={monthOptions} small value={value} onChange={onMonthChange} disabled={disabled}
		                 getOptionLabel={getOptionLabel} onBlur={onBlur} disableClearable noFullWidth noMargin
		                 className='material-date-picker--month-box' name={name} />
	);
}
MonthComboBox.propTypes = {
	date: PropTypes.instanceOf(moment),
	locale: PropTypes.string,
	disabled: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired
};

function createHandleOnMonthChange(onChange, date) {
	return function handleOnMonthChange(monthSelection) {
		const {monthIndex} = monthSelection;
		onChange(moment(date).month(monthIndex));
	};
}

function getOptionLabel(option) {
	return option.monthText;
}

function getMonthOptions(locale) {
	return Array.from(Array(MONTHS_PER_YEAR).keys()).map(monthIndex => ({
		monthIndex,
		monthText: monthIndexToShortMonth(locale, monthIndex)
	}));
}

function monthIndexToShortMonth(locale, monthIndex) {
	return moment()
		.month(monthIndex)
		.locale(locale)
		.format('MMM');
}

