/**
 * @class SearchFilter implements an input field similar to the Material SearchFilter.
 * @author p.spitzlinger@synedra.com
 */
import React, {useRef, useState} from 'react';
import classNames from 'classnames';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import {useChangedValueOrState, useEffectEasily, useMemoFactory} from '../../commons/utils/customHooks';
import {preventEventDefault} from '../../commons/utils/DOMEventUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import SearchIcon from '../../ui/components/icons/SearchIcon.js';
import ClearIcon from './icons/ClearIcon.js';

import '../../../styles/material-design/components/SearchFilter.scss';

/**
 * @class SearchFilter implements an input field similar to the Material SearchFilter.
 * @author p.spitzlinger@synedra.com
 */

export default function SearchFilter(props) {
	const {
		onChangeMaxInterval, active, onChange, onActivate, onDeactivate, className, value, name, placeholder
	} = props;
	const [currentInputValue, setCurrentInputValue] = useChangedValueOrState(value);
	const [inputFocus, setInputFocus] = useState(false);
	const input = useRef(null);
	const throttledOnChange = useMemoFactory(createThrottledOnChange, onChangeMaxInterval, active, onChange);
	const handleNewValue = useMemoFactory(
		createHandleNewValue, currentInputValue, setCurrentInputValue, throttledOnChange
	);
	const blurInput = useMemoFactory(createBlurInput, input, inputFocus);
	const focusInput = useMemoFactory(createFocusInput, input, inputFocus);
	const clearInput = useMemoFactory(createClearInput, handleNewValue, focusInput);
	const onInputChange = useMemoFactory(createOnInputChange, handleNewValue);
	const onInputFocus = useMemoFactory(createOnInputFocus, inputFocus, setInputFocus, active, onActivate);
	const onInputBlur = useMemoFactory(
		createOnInputBlur, inputFocus, setInputFocus, currentInputValue, active, onDeactivate
	);
	const preventDefaultOnEmptyInput = useMemoFactory(createPreventDefaultOnEmptyInput, currentInputValue);
	useEffectEasily(processInputFocus, active, focusInput, blurInput);
	const finalClassName = combineClassNames(
		className,
		classNames({
			'search-filter--container': true,
			active: active || value !== ''
		})
	);
	const clearIconClassName = classNames({
		'search-filter--icon-base': true,
		'search-filter--icon-clear': true,
		hidden: currentInputValue === ''
	});
	return (
		<div className={finalClassName} onClick={focusInput} onMouseDown={preventDefaultOnEmptyInput}>
			<SearchIcon className='search-filter--icon-base' />
			<input ref={input} className='search-filter--input' onFocus={onInputFocus} onBlur={onInputBlur} size='1'
			       onChange={onInputChange} value={currentInputValue} name={name} placeholder={placeholder} />
			<ClearIcon className={clearIconClassName} onClick={clearInput} />
		</div>
	);
}

SearchFilter.propTypes = {
	value: PropTypes.string,
	active: PropTypes.bool,
	onActivate: PropTypes.func,
	onDeactivate: PropTypes.func,
	onChange: PropTypes.func,
	onChangeMaxInterval: PropTypes.number,
	className: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string
};

SearchFilter.defaultProps = {
	value: '',
	active: false,
	onActivate: null,
	onDeactivate: null,
	onChange: null,
	onChangeMaxInterval: 500
};

function createHandleNewValue(currentInputValue, setCurrentInputValue, throttledOnChange) {
	return newValue => {
		if (currentInputValue !== newValue) {
			setCurrentInputValue(newValue);
			throttledOnChange(newValue);
		}
	};
}

function createThrottledOnChange(onChangeMaxInterval, active, onChange) {
	return _debounce(newValue => {
		if (active && onChange) {
			onChange(newValue);
		}
	}, Math.max(0, onChangeMaxInterval));
}

function createBlurInput(input, inputFocus) {
	return () => {
		if (input.current && inputFocus) {
			input.current.blur();
		}
	};
}

function createFocusInput(input, inputFocus) {
	return () => {
		if (input.current && !inputFocus) {
			input.current.focus();
		}
	};
}

function createOnInputChange(handleNewValue) {
	return e => {
		handleNewValue(e.target.value);
	};
}

function createClearInput(handleNewValue, focusInput) {
	return () => {
		handleNewValue('');
		focusInput();
	};
}

function createOnInputFocus(inputFocus, setInputFocus, active, onActivate) {
	return () => {
		if (!inputFocus) {
			setInputFocus(true);
			if (!active && onActivate) {
				onActivate();
			}
		}
	};
}

function createOnInputBlur(inputFocus, setInputFocus, currentInputValue, active, onDeactivate) {
	return () => {
		if (inputFocus) {
			setInputFocus(false);
			if (currentInputValue === '' && active && onDeactivate) {
				onDeactivate();
			}
		}
	};
}

function createPreventDefaultOnEmptyInput(currentInputValue) {
	return e => {
		if (currentInputValue === '') {
			preventEventDefault(e);
		}
	};
}

function processInputFocus(active, focusInput, blurInput) {
	if (active) {
		focusInput();
	} else {
		blurInput();
	}
}
