import {createSelector} from 'reselect';

import {SESSION_CAN_ARCHIVE_DOCUMENTS, SESSION_CAN_CREATE_ORDERS} from '../../constants/WebViewSessionStateFields.js';
import {selectSessionData} from './SessionSelectors.js';

export const selectCanCreateOrders = createSelector(
	selectSessionData,
	session => session.get(SESSION_CAN_CREATE_ORDERS, false) === true
);

export const selectCanArchiveDocuments = createSelector(
	selectSessionData,
	session => session.get(SESSION_CAN_ARCHIVE_DOCUMENTS, false) === true
);
