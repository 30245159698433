import React, {useEffect, useReducer} from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import Divider from '../../ui/components/Divider.js';
import ImageCollection from '../bricks/ImageCollection.js';
import statusMessageRecord from '../data/StatusMessageData.js';
import undoDataRecord from '../data/UndoData.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';
import DocumentsBrowserImagesGrid from './DocumentsBrowserImagesGrid.js';
import DocumentsBrowserToolBar from './DocumentsBrowserToolBar.js';
import OpenCameraTile from './OpenCameraTile.js';

const SELECTED_IMAGES = 'selectedImages';
const UNDO_DATA = 'undoData';
const STATUS_MESSAGE = 'statusMessage';

const TOGGLE_IMAGE_SELECTION_ACTION = 'toggleImageSelection';
const CLEAR_IMAGE_SELECTION_ACTION = 'clearImageSelection';
const REMOVE_IMAGES_ACTION = 'removeImages';
const CLEAR_UNDO_DATA_ACTION = 'clearUndoData';
const SET_STATUS_MESSAGE_ACTION = 'setStatusMessage';
const CLEAR_STATUS_MESSAGE_ACTION = 'clearStatusMessage';

const UNDO_REMOVE_IMAGES_TASK = 'UndoRemoveImages';

const INITIAL_STATE = Immutable.Map({
	[SELECTED_IMAGES]: Immutable.Set(),
	[UNDO_DATA]: undoDataRecord(),
	[STATUS_MESSAGE]: statusMessageRecord()
});

export default React.memo(DocumentsBrowser);

function DocumentsBrowser(props) {
	const {deleteMinimumItemsLeft, showAddDocumentButton} = props;
	const {addImages, imagesData, removeImages} = useBrick(ImageCollection, selectFileCollectionProps);
	const [documentsBrowserState, dispatch] = useReducer(documentBrowserReducer, INITIAL_STATE);
	const selectedImages = documentsBrowserState.get(SELECTED_IMAGES);
	const undoData = documentsBrowserState.get(UNDO_DATA);
	const statusMessage = documentsBrowserState.get(STATUS_MESSAGE);

	useEffect(() => {
		if (statusMessage.message === '') {
			dispatch({
				type: SET_STATUS_MESSAGE_ACTION,
				payload: {message: 'NumberAcquiredImages', numberItems: imagesData.count()}
			});
		}
	}, [statusMessage, imagesData]);

	const createSelectionToggle = useMemoFactory(createCreateSelectionToggle, dispatch);
	const handleFileList = useMemoFactory(createHandleFileList, dispatch, addImages);
	const handleRemoveImages = useMemoFactory(createHandleRemoveImages, dispatch, removeImages, undoData);
	const handleUndo = useMemoFactory(createHandleUndo, dispatch, addImages, undoData);
	const disableDelete = imagesData.count() - selectedImages.count() < deleteMinimumItemsLeft;
	const openCameraTileLabel = useTranslation('OpenCamera', undefined, ArchiveDocumentsTranslator);
	return (
		<React.Fragment>
			<DocumentsBrowserToolBar statusMessage={statusMessage} selectedImages={selectedImages} onUndo={handleUndo}
			                         disableDelete={disableDelete} onRemoveImages={handleRemoveImages}
			                         undoData={undoData} />
			<Divider />
			<DocumentsBrowserImagesGrid imagesData={imagesData} selectedImages={selectedImages}
			                            createSelectionToggle={createSelectionToggle}>
				{showAddDocumentButton &&
					<OpenCameraTile onFileChange={handleFileList} label={openCameraTileLabel} />
				}
			</DocumentsBrowserImagesGrid>
		</React.Fragment>
	);
}

DocumentsBrowser.propTypes = {
	showAddDocumentButton: PropTypes.bool,
	deleteMinimumItemsLeft: PropTypes.number
};

DocumentsBrowser.defaultProps = {
	showAddDocumentButton: false,
	deleteMinimumItemsLeft: 0
};

function createCreateSelectionToggle(dispatch) {
	return id => () => {
		dispatch({
			type: TOGGLE_IMAGE_SELECTION_ACTION,
			payload: {id}
		});
	};
}

function createHandleFileList(dispatch, addImages) {
	return fileList => {
		const addedImages = callSafe(addImages, fileList);
		dispatch({type: CLEAR_IMAGE_SELECTION_ACTION});
		dispatch({type: CLEAR_UNDO_DATA_ACTION});
		dispatch({
			type: SET_STATUS_MESSAGE_ACTION,
			payload: {message: 'NumberAddedImages', numberItems: addedImages}
		});
	};
}

function createHandleRemoveImages(dispatch, removeImages, undoData) {
	return imgList => {
		const removedImages = callSafe(removeImages, imgList.toArray());
		dispatch({type: REMOVE_IMAGES_ACTION, payload: {imgList}});
		dispatch({type: CLEAR_IMAGE_SELECTION_ACTION});
		dispatch({
			type: SET_STATUS_MESSAGE_ACTION,
			payload: {message: `${undoData.task}UndoRemoveImages`, numberItems: removedImages}
		});
	};
}

function createHandleUndo(dispatch, addImages, undoData) {
	return () => {
		const {data, task, numFilesAffected} = undoData;
		addImages(data);
		dispatch({
			type: SET_STATUS_MESSAGE_ACTION,
			payload: {message: `${task}Confirm`, numberItems: numFilesAffected}
		});
		dispatch({type: CLEAR_UNDO_DATA_ACTION});
	};
}

function documentBrowserReducer(state, action) {
	let newState;
	const {payload} = action;
	switch (action.type) {
		case TOGGLE_IMAGE_SELECTION_ACTION: {
			newState = handleToggleImageSelectionAction(state, payload);
			break;
		}
		case CLEAR_IMAGE_SELECTION_ACTION: {
			newState = state
				.set(SELECTED_IMAGES, Immutable.Set());
			break;
		}
		case REMOVE_IMAGES_ACTION: {
			newState = state
				.set(UNDO_DATA, undoDataRecord({
					task: UNDO_REMOVE_IMAGES_TASK,
					numFilesAffected: payload.imgList.count(),
					data: payload.imgList.map(imageEntry => imageEntry.file).toArray()
				}));
			break;
		}
		case CLEAR_UNDO_DATA_ACTION: {
			newState = state
				.set(UNDO_DATA, undoDataRecord());
			break;
		}
		case SET_STATUS_MESSAGE_ACTION: {
			newState = state
				.set(STATUS_MESSAGE, statusMessageRecord({message: payload.message, numberItems: payload.numberItems}));
			break;
		}
		case CLEAR_STATUS_MESSAGE_ACTION: {
			newState = state
				.set(STATUS_MESSAGE, statusMessageRecord());
			break;
		}
		default: {
			newState = state;
		}
	}
	return newState;
}

function handleToggleImageSelectionAction(state, payload) {
	const selectedImages = state.get(SELECTED_IMAGES);
	const newState = state
		.set(SELECTED_IMAGES, selectedImages.has(payload.id)
			? selectedImages.delete(payload.id)
			: selectedImages.add(payload.id))
		.set(UNDO_DATA, undoDataRecord());
	const newNumberSelectedImages = newState.get(SELECTED_IMAGES).count();
	if (newNumberSelectedImages > 0) {
		return newState.set(STATUS_MESSAGE, statusMessageRecord({
			message: 'NumberImagesSelected',
			numberItems: newNumberSelectedImages
		}));
	}
	return newState.set(STATUS_MESSAGE, statusMessageRecord());
}

function selectFileCollectionProps(service) {
	return {
		addImages: service.addImages,
		removeImages: service.removeImages,
		imagesData: service.getImages()
	};
}
