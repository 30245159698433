import React from 'react';
import PropTypes from 'prop-types';

import {callSafe, synchronizedWithAnimationFrame} from '../utils/FunctionUtils.js';
import {withForwardRef} from '../utils/ReactUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/Scrollable.scss';

class Scrollable extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {forwardRef} = this.props;
		this.scrollContainer = forwardRef;
		this.boundOnScrollHandler = this.onScrollHandler.bind(this);
		this.forceScrollTop = synchronizedWithAnimationFrame(this.forceScrollTop.bind(this), args => args[0]);
	}

	render() {
		const {className, children} = this.props;
		return (
			<div className={combineClassNames('syn-scrollable', className)} onScroll={this.boundOnScrollHandler}
			     ref={this.scrollContainer}>
				{children}
			</div>
		);
	}

	componentDidMount() {
		const {initialScrollTop} = this.props;
		if (Scrollable.isValidScrollTop(initialScrollTop)) {
			this.forceScrollTop(initialScrollTop);
		}
	}

	componentDidUpdate(prevProps) {
		const {scrollTop} = this.props;
		const {scrollTop: prevScrollTop} = prevProps;
		const scrollTopIsNumber = Scrollable.isValidScrollTop(scrollTop);
		if (scrollTopIsNumber && scrollTop !== prevScrollTop) {
			this.forceScrollTop(scrollTop);
		}
	}

	componentWillUnmount() {
		this.forceScrollTop.stop();
	}

	onScrollHandler() {
		const {onScroll} = this.props;
		const scrollPosition = this.getCurrentScrollPosition();
		callSafe(onScroll, scrollPosition);
	}

	forceScrollTop(scrollTop) {
		const scrollTopIsNumber = Scrollable.isValidScrollTop(scrollTop);
		const shouldSetScrollTop = this.scrollContainer.current && this.scrollContainer.current.scrollTop !== scrollTop;
		if (scrollTopIsNumber && shouldSetScrollTop) {
			this.scrollContainer.current.scrollTop = scrollTop;
			this.forceScrollTop(scrollTop);
		}
	}

	getCurrentScrollPosition() {
		return this.scrollContainer.current.scrollTop;
	}

	static isValidScrollTop(value) {
		return typeof (value) === 'number';
	}
}

Scrollable.propTypes = {
	className: PropTypes.string,
	scrollTop: PropTypes.number,
	initialScrollTop: PropTypes.number,
	onScroll: PropTypes.func,
	forwardRef: withForwardRef.PropTypes.Ref
};

Scrollable.defaultProps = {
	scrollTop: null,
	initialScrollTop: null,
	onScroll: undefined,
	forwardRef: React.createRef()
};

export default withForwardRef(Scrollable, 'forwardRef');
