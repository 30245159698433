import React from 'react';
import PropTypes from 'prop-types';

import connectBrick from '../../bricks/connectBrick.js';
import HumanReadableDataSize from '../../commons/components/HumanReadableDataSize.js';
import {PatientInfoWithGenderAvatar} from '../../commons/components/webview/PatientInfo.js';
import {immutableListPropType, immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import StepperContent from '../../material-design/components/StepperContent.js';
import Divider from '../../ui/components/Divider.js';
import Spacer from '../../ui/components/layout/Spacer.js';
import {Body2} from '../../ui/components/typography/Typography.js';
import ImageCollection from '../bricks/ImageCollection.js';
import AdditionalDataFormContainer from '../flux/containers/AdditionalDataFormContainer.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';
import DocumentsBrowserImagesGrid from './DocumentsBrowserImagesGrid.js';

import '../../../styles/archive-documents/components/StepAdditionalData.scss';

class TotalDataSize extends React.PureComponent {
	render() {
		const {amountBytes} = this.props;
		return (
			<Body2 className='step-additional-data--total-upload-data-size'>
				<SynFormattedMessage message={'TotalUploadDataSize'} translator={ArchiveDocumentsTranslator} />
				{': '}
				<HumanReadableDataSize amountBytes={amountBytes} />
			</Body2>
		);
	}
}

TotalDataSize.propTypes = {
	amountBytes: PropTypes.number
};

class StepAdditionalData extends React.PureComponent {
	render() {
		const {
			patient,
			archivePermissions,
			visits,
			documentClasses,
			enableVisitField,
			imagesData,
			totalImagesSize
		} = this.props;
		const formMetaData = {
			archivePermissions,
			documentClasses,
			visits,
			enableVisitField
		};
		return (
			<StepperContent className='step-additional-data'>
				<PatientInfoWithGenderAvatar patient={patient} />
				<div className='step-additional-data--form'>
					<AdditionalDataFormContainer {...formMetaData} />
				</div>
				<Divider />
				<DocumentsBrowserImagesGrid imagesData={imagesData} />
				<Spacer />
				<TotalDataSize amountBytes={totalImagesSize} />
			</StepperContent>
		);
	}
}

StepAdditionalData.propTypes = {
	patient: immutablePropType.isRequired,
	archivePermissions: immutablePropType,
	visits: immutablePropType,
	documentClasses: immutableListPropType,
	enableVisitField: PropTypes.bool,
	imagesData: immutablePropType,
	totalImagesSize: PropTypes.number
};

function selectFileCollectionProps(service) {
	return {
		totalImagesSize: service.getTotalSize(),
		imagesData: service.getImages()
	};
}

export default connectBrick(ImageCollection, selectFileCollectionProps)(StepAdditionalData);
