import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import useTranslator from '../../i18n/hooks/useTranslator.js';
import AdditionalDataComboBox from './AdditionalDataComboBox.js';

const VISIT_PLACEHOLDER = '-';
const VISIT_SEPARATOR = ', ';

function VisitField(props) {
	const {visitChoice, onChange, visits} = props;
	const getFormattedMessage = useTranslator();
	const comboboxLabel = useTranslation('Visit');
	const comboboxHintMessage = useTranslation('VisitHintMessage');
	const noOpenCase = useTranslation('NoOpenCase');
	const comboBoxNone = useTranslation('ComboBoxNone');
	const options = useMemoFactory(getOptions, visits, getFormattedMessage, comboBoxNone, noOpenCase);
	const handleOnChange = useMemoFactory(getHandleOnChange, onChange, options);
	const selectedOptionsIndex = options.length > 1 && typeof visitChoice === 'number' ? visitChoice + 1 : 0;
	return (
		<AdditionalDataComboBox id='archive-visit-auto-select' onChange={handleOnChange} label={comboboxLabel}
		                        selectedOptionsIndex={selectedOptionsIndex} hintMessage={comboboxHintMessage}
		                        options={options} />
	);
}

VisitField.propTypes = {
	visitChoice: PropTypes.number,
	onChange: PropTypes.func,
	visits: immutableListPropType.isRequired
};

function getOptions(visits, getFormattedMessage, comboBoxNone, noOpenCase) {
	let options = [noOpenCase];
	if (visits.count() > 0) {
		options = visits.toArray().map(({patientClassFK, preadmitNumber, admitDateTime}) => [
			preadmitNumber || VISIT_PLACEHOLDER,
			patientClassFK || VISIT_PLACEHOLDER,
			admitDateTime === null
				? VISIT_PLACEHOLDER
				: getFormattedMessage('ShortDate', {date: admitDateTime, time: admitDateTime})
		].join(VISIT_SEPARATOR));
		options.unshift(comboBoxNone);
	}
	return options;
}

function getHandleOnChange(onChange, options) {
	return function onchangeImpl(newValue) {
		if (options.length > 1) {
			callSafe(onChange, newValue === 0 || newValue === null ? null : newValue - 1);
		}
	};
}

export default wrapInLocalizationContext(VisitField);
