import React from 'react';
import PropTypes from 'prop-types';

import Search from '../../a11y/components/landmarks/Search.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import BarcodeEnabledFormField from '../../material-design/components/form/BarcodeEnabledFormField.js';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn.js';
import Row from '../../material-design/components/Row.js';
import {PATIENT_SEARCH_METRICS_USAGE_CONTEXT} from '../PatientSearchConstants.js';
import ExtendedPatientSearchFields from './ExtendedPatientSearchFields.js';

export default function PatientSearchFormFields(props) {
	const {initialCriteria, fieldWidths, initiallyExpandedSearchFields} = props;
	return (
		<Search className='search-form--fields-container'>
			<Row>
				<FullWidthColumn>
					<BarcodeEnabledFormField fieldName='search_id' usageContext={PATIENT_SEARCH_METRICS_USAGE_CONTEXT}
					                         barcodeMetricsKey='PatientIDVisitIDBarcodeScanner' />
				</FullWidthColumn>
			</Row>
			<ExtendedPatientSearchFields initiallyExpanded={initiallyExpandedSearchFields} 
				fieldWidths={fieldWidths} initialCriteria={initialCriteria} />
		</Search>
	);
}

PatientSearchFormFields.propTypes = {
	initialCriteria: immutableMapPropType,
	fieldWidths: PropTypes.object,
	initiallyExpandedSearchFields: PropTypes.bool
};
