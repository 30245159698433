import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {useFormattedMessage} from '../../commons/utils/customHooks';
import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn.js';
import Button from '../../ui/components/Button.js';
import DeleteIcon from '../../ui/components/icons/DeleteIcon.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';
import UndoButton from './UndoButton.js';

import '../../../styles/archive-documents/components/DocumentsBrowserToolbar.scss';

export default wrapInLocalizationContext(React.memo(DocumentsBrowserToolBar));

function DocumentsBrowserToolBar(props) {
	const {
		onRemoveImages, selectedImages, undoData, onUndo, statusMessage: {message, numberItems}, locale, disableDelete
	} = props;
	const deleteLabel = useFormattedMessage(ArchiveDocumentsTranslator, 'Delete', locale);
	const handleRemoveImages = useCallback(() => {
		onRemoveImages(selectedImages);
	}, [onRemoveImages, selectedImages]);
	return (
		<FullWidthColumn className='archive-documents-browser-toolbar'>
			{message && (
				<SynFormattedMessage translator={ArchiveDocumentsTranslator} message={message}
				                     messageParams={{numberItems}} />
			)}
			{!selectedImages.isEmpty() && (
				<Button className='archiveDocumentsDelete' startIcon={<DeleteIcon />} onClick={handleRemoveImages} disabled={disableDelete} flat>
					{deleteLabel}
				</Button>
			)}
			<UndoButton undoData={undoData} onUndo={onUndo} />
		</FullWidthColumn>
	);
}

DocumentsBrowserToolBar.propTypes = {
	onRemoveImages: PropTypes.func,
	onUndo: PropTypes.func,
	statusMessage: PropTypes.shape({
		message: PropTypes.string,
		numberItems: PropTypes.number
	}),
	locale: PropTypes.string,
	disableDelete: PropTypes.bool,
	selectedImages: immutablePropType,
	undoData: PropTypes.object
};
