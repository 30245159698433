import React from 'react';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import MobileAwareAutoCompleteTextField from './AutoCompleteTextField/MobileAwareAutoCompleteTextField.js';

/**
 * Renders an input field with suggestions that also allows freetext to be entered.
 * The suggestions can be filtered by entering text into the input field.
 * The value provided by the onChange event will be the entered text.
 *
 * @author p.spitzlinger@synedra.com
 */
export default withForwardRef(SuggestTextField, 'forwardedRef');


function SuggestTextField(props) {
	const {id, suggestions, onChange, value, disabled, label, error, hint, forwardedRef} = props;
	return (
		<MobileAwareAutoCompleteTextField lockToOptions={false} ref={forwardedRef} label={label} error={error}
		                                  hint={hint} disabled={disabled} value={value} options={suggestions} id={id}
		                                  onChange={onChange} />
	);
}

SuggestTextField.propTypes = {
	forwardedRef: withForwardRef.PropTypes.Ref,
	suggestions: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
	error: PropTypes.bool,
	hint: PropTypes.string,
	id: PropTypes.string
};
