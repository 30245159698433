const IMPL_PROPERTY = Symbol('ImageDecoderImpl');

export default class ImageDecoder {
	constructor(decodeFunction, releaseFunction, imageConsumer) {
		this[IMPL_PROPERTY] = new ImageDecoderImpl(decodeFunction, releaseFunction, imageConsumer);
	}

	scheduleDecoding(rawImage) {
		this[IMPL_PROPERTY].scheduleDecoding(rawImage);
	}

	stop() {
		this[IMPL_PROPERTY].stop();
	}
}

class ImageDecoderImpl {
	constructor(decodeFunction, releaseFunction, imageConsumer) {
		this.decodeFunction = decodeFunction;
		this.releaseFunction = releaseFunction;
		this.imageConsumer = imageConsumer;
		this.scheduledImage = null;
		this.decodedImage = null;
		this.isDecoding = false;
		this.keepDecoding = true;
	}

	setDecodedImage(decodedImage) {
		const prevDecodedImage = this.decodedImage;
		this.decodedImage = null;
		if (this.keepDecoding) {
			this.decodedImage = decodedImage;
			this.imageConsumer(decodedImage);
			if (this.scheduledImage) {
				this.doDecodeSafe(this.scheduledImage);
			}
		} else {
			this.releaseImage(decodedImage);
		}
		if (prevDecodedImage !== decodedImage) {
			this.releaseImage(prevDecodedImage);
		}
		this.setScheduledImage(null);
	}

	setScheduledImage(rawImage) {
		if (this.keepDecoding || !rawImage) {
			this.scheduledImage = rawImage;
		}
	}

	scheduleDecoding(rawImage) {
		if (this.keepDecoding) {
			if (this.isDecoding) {
				this.setScheduledImage(rawImage);
			} else {
				this.doDecodeSafe(rawImage);
			}
		}
	}

	stop() {
		this.keepDecoding = false;
		if (!this.isDecoding) {
			this.releaseDecodedImage();
		}
	}

	releaseDecodedImage() {
		this.releaseImage(this.decodedImage);
		this.decodedImage = null;
	}

	releaseImage(image) {
		if (image) {
			this.releaseFunction(image);
		}
	}

	doDecodeSafe(rawImage) {
		if (rawImage) {
			this.doDecode(rawImage);
		} else {
			this.imageConsumer(null);
		}
	}

	async doDecode(rawImage) {
		try {
			this.isDecoding = true;
			const decodedImage = await this.decodeFunction(rawImage);
			this.isDecoding = false;
			this.setDecodedImage(decodedImage);
		} catch (e) {
			// this should definitely never be the case, however if it happens, we
			// ensure that the exception will trigger an error event (instead of unhandled promise rejection)
			window.setTimeout(() => {
				throw new Error(`Error decoding image: ${e}`);
			});
		} finally {
			this.isDecoding = false;
		}
	}
}
