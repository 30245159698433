import React from 'react';
import PropTypes from 'prop-types';

import createLayoutComponent from '../../commons/components/layout/createLayoutComponent.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/material-design/components/StepperContent.scss';

const VerticalStepperContentLayout = createLayoutComponent('material-stepper--content', VerticalLayout);
export default function StepperContent(props) {
	const {justify = 'start', ...remainingProps} = props;
	return <VerticalStepperContentLayout justify={justify} {...remainingProps} />;
}

StepperContent.propTypes = {
	justify: PropTypes.string
};
