import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '../../commons/components/AppBar.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../../commons/components/webview/AppBarSidebarHeader.js';
import {loadInProgressPropType} from '../searchLayoutUtil.js';
import SmallSearchAppBar from './SmallSearchAppBar.js';

export default function SearchAppBar(props) {
	const {sidebarElevated, title, nrResults, loadInProgress, showResults, maxLimit, backButton, subTitle} = props;
	return sidebarElevated
		? <SmallSearchAppBar title={title} subTitle={subTitle} nrResults={nrResults} loadInProgress={loadInProgress}
		                     showResults={showResults} maxLimit={maxLimit} backButton={backButton} />
		: <LargeMediumAppBar title={title} subTitle={subTitle} sidebarElevated={sidebarElevated} />;
}

SearchAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired,
	title: PropTypes.node.isRequired,
	subTitle: PropTypes.node,
	showResults: PropTypes.bool,
	maxLimit: PropTypes.number.isRequired,
	loadInProgress: loadInProgressPropType,
	nrResults: PropTypes.number,
	backButton: PropTypes.node
};

function LargeMediumAppBar(props) {
	const {sidebarElevated, title, subTitle} = props;
	return (
		<AppBar>
			<AppBarPrimaryContent menuButton={false} title={title} subTitle={subTitle} {...props} />
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

LargeMediumAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired,
	title: PropTypes.node.isRequired,
	subTitle: PropTypes.node
};
