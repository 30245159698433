import React from 'react';
import {PropTypes} from 'prop-types';

import DocumentSummary from '../../commons/components/DocumentSummary';
import DocumentDownloadButton from '../../commons/components/webview/DocumentDownloadButton.js';
import LinkContainer from '../../commons/containers/LinkContainer.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {getRemainingDays} from '../../commons/utils/DateUtils.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import NumericDate from '../../i18n/components/NumericDate.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ShareDocumentButton from '../../share-document/components/ShareDocumentButton.js';
import InboxMessageTranslator from '../i18n/InboxMessagesTranslator.js';
import DoneCheckbox from './DoneCheckbox.js';

import '../../../styles/inbox/components/DocumentShare.scss';

const DoneCheckboxStyles = {
	container: 'document-share--mark-done-container'
};

class DocumentShare extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundMarkDone = this.markDone.bind(this);
	}

	render() {
		const {data, documentLink, locale, className} = this.props;
		const isShare = data.get('share_id') !== null;
		const isDone = data.get('done') && isShare;
		const documentIsShareable = data.get('document_shareable') === 1;
		const documentId = data.get('document_fk');
		const documentFragment = `document=${documentId}`;
		const documentCreationDate = data.get('document_created_when');
		const documentDescription = data.get('document_description');
		const shareCreationDate = data.get('inserted_when');
		const entityTypes = formatModalitiesOrExtensions(data);
		const dateKeyMessage = isShare ? 'ShareDateKey' : 'PermitDateKey';
		return (
			<div className={combineClassNames('document-share', className)}>
				<LinkContainer className='document-link document-share--document-information' path={documentLink} fragment={documentFragment}>
					<DocumentSummary description={documentDescription}
										  createdWhen={documentCreationDate} containedTypes={entityTypes} />
				</LinkContainer>
				<div className='document-share--share-information'>
					<div className='document-share--valid-until'>
						<span className='document-share--share-date-information'>
							<SynFormattedMessage message={dateKeyMessage}
								className='document-share--share-date-key'
								translator={InboxMessageTranslator} />
							<NumericDate date={shareCreationDate} className='document-share--share-date' />
						</span>
						{' '}
						<SynFormattedMessage message='RemainingDays'
							messageParams={{remainingDays: getRemainingDays(data.get('valid_until'))}}
							className='document-share--remaining-days'
							translator={InboxMessageTranslator} />
					</div>
					<div className='document-share--buttons'>
						{documentIsShareable && <ShareDocumentButton documentIds={[documentId]} />}
						<DocumentDownloadButton documentId={documentId} />
						<DoneCheckbox toolTip={InboxMessageTranslator.getFormattedMessage(isDone ? 'NotDoneCheckBoxToolTip' : 'DoneCheckBoxToolTip', locale)}
							disabled={!isShare} checked={isDone}
							onToggle={this.boundMarkDone}
							styles={DoneCheckboxStyles} />
					</div>
				</div>
			</div>
		);
	}

	markDone(done) {
		const {markShareDone, data} = this.props;
		callSafe(markShareDone, data.get('share_id'), done);
	}
}

DocumentShare.propTypes = {
	data: immutableMapPropType,
	documentLink: PropTypes.string,
	locale: PropTypes.string,
	markShareDone: PropTypes.func,
	className: PropTypes.string
};

export default wrapInLocalizationContext(DocumentShare);

function formatModalitiesOrExtensions(data) {
	const entities = data.get('dicom_all_modalities') || data.get('generic_all_extensions') || '';
	return entities.replace(/\//g, ', ').toUpperCase();
}
