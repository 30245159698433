import {createFormValidatorFactory} from '../ValidatorFactories.js';

function formNotEmptyValidationFunction(locale, translatedFieldLabels, translator, message, fieldValues) {
	return !fieldValues.every(fieldValue => !fieldValue) || translator.getFormattedMessage(message, locale);
}

export default function formNotEmptyValidator(translator, message) {
	return createFormValidatorFactory(
		(locale, translatedFieldLabels, ...fieldValues) => formNotEmptyValidationFunction(
			locale, translatedFieldLabels, translator, message, fieldValues
		)
	);
}
