import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import wrapInFormContext from '../../components/form/wrapInFormContext.js';
import ErrorMessage from '../../components/webview/ErrorMessage.js';
import {cloneWithoutProperties} from '../../utils/ObjectUtils';

function FormErrorMessage(props) {
	const {errorMessage} = props;
	const remainingProps = cloneWithoutProperties(props, 'errorMessage');
	return (
		<ErrorMessage {...remainingProps}>
			{errorMessage}
		</ErrorMessage>
	);
}

FormErrorMessage.propTypes = {
	errorMessage: PropTypes.node
};

export default wrapInFormContext(
	connect(
		(state, props) => ({
			errorMessage: props.formDescription.isFormValidationForced(state) && props.formDescription.getFormValidationError(state) || ''
		})
	)(FormErrorMessage)
);
