import {all} from 'redux-saga/effects';

import createLocationTask from '../authentication/sagas/createLocationTask.js';
import createSessionTask from '../authentication/sagas/createSessionTask.js';
import webAppSaga from '../commons/sagas/webAppSaga.js';
import createOrderPatientSearchSaga from '../create-order/flux/createOrderPatientSearchSaga.js';
import uploadPatientSearchSaga from '../upload/uploadPatientSearchSaga.js';
import defaultViewerSyncSaga from '../viewer/synchronization/flux/defaultViewerSyncSaga.js';
import {WEBVIEW_CREATE_ORDER_PATH, WEBVIEW_UPLOAD_PATH} from '../WebViewRoutingConstants.js';

const sessionBoundPatientSearchSagas = createSessionTask(createLocationTask({
	[WEBVIEW_UPLOAD_PATH]: uploadPatientSearchSaga,
	[WEBVIEW_CREATE_ORDER_PATH]: createOrderPatientSearchSaga
}));

export default function* webViewSaga() {
	yield all([
		webAppSaga(),
		defaultViewerSyncSaga(),
		sessionBoundPatientSearchSagas()
	]);
}
