import React from 'react';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

import GenderAvatar from '../../commons/components/symbols/GenderAvatar.js';
import PatientInfo from '../../commons/components/webview/PatientInfo.js';
import LinkContainer from '../../commons/containers/LinkContainer.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {webAppURL} from '../../commons/WebViewHelpers.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import Card from '../../material-design/components/Card.js';
import {PrimaryActionAvatarArea} from '../../material-design/components/layout/PrimaryActionSideAreas.js';
import AllDoneButton from '../../ui/components/AllDoneButton.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import InboxMessageTranslator from '../i18n/InboxMessagesTranslator.js';
import DocumentShare from './DocumentShare.js';

import '../../../styles/inbox/components/PatientGroup.scss';

class PatientGroup extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundMarkShareDone = this.markShareDone.bind(this);
		this.boundMarkAllSharesDone = this.markAllSharesDone.bind(this);
		this.getShares = createSelector(
			data => data.get('documentShares'),
			shares => shares.filter(share => share.get('share_id') !== null)
		);
		this.allDone = createSelector(
			this.getShares,
			shares => shares.every(share => share.get('done'))
		);
	}

	render() {
		const {locale, data} = this.props;
		const documentShares = data.get('documentShares');
		const patientInfo = data.get('patientInfo');
		const linkEnd = webAppURL(`patient/${patientInfo.get('aimid')}/documentshare`);
		const allDoneChecked = this.allDone(data);
		const toolTip = InboxMessageTranslator.getFormattedMessage(
			allDoneChecked ? 'AllNotDoneCheckBoxToolTip' : 'AllDoneCheckBoxToolTip', locale
		);
		const hasShares = !this.getShares(data).isEmpty();
		return (
			<VerticalLayout element={Card} justify='center' className='patient-group--card'>
				<div className='patient-group--line patient-group--header'>
					<LinkContainer path={linkEnd} className='patient-group--patient-information'>
						<PrimaryActionAvatarArea>
							<GenderAvatar gender={patientInfo.get('sex')} className='patient-group--gender-avatar' />
						</PrimaryActionAvatarArea>
						<PatientInfo className='patient-group--patient-info'
							 firstName={patientInfo.get('first_name')}
							 lastName={patientInfo.get('last_name')}
							 birthDate={patientInfo.get('birth_date')} />
					</LinkContainer>
					<AllDoneButton disabled={!hasShares} toolTip={toolTip}
										checked={allDoneChecked} onToggle={this.boundMarkAllSharesDone} />
				</div>
				{
					documentShares.map(documentShare => {
						let documentShareKey;
						const shareId = documentShare.get('share_id');
						if (shareId === null) {
							const documentId = documentShare.get('document_fk');
							const permitId = documentShare.get('permit_id');
							documentShareKey = `permit_${permitId}_${documentId}`;
						} else {
							documentShareKey = `share_${shareId}`;
						}
						return (
							<DocumentShare className='patient-group--line patient-group--document-share'
												key={documentShareKey} data={documentShare} documentLink={linkEnd}
												markShareDone={this.boundMarkShareDone} />
						);
					}).toArray()
				}
			</VerticalLayout>
		);
	}

	markShareDone(shareId, done) {
		const {markSharesDone, data} = this.props;
		if (Boolean(markSharesDone) && Boolean(data)) {
			const patientInfo = data.get('patientInfo');
			const patId = patientInfo && patientInfo.get('aimid');
			if (patId) {
				markSharesDone([{patId, shareId, done}]);
			}
		}
	}

	markAllSharesDone(done) {
		const {markSharesDone, data} = this.props;
		if (Boolean(markSharesDone) && Boolean(data)) {
			const patientInfo = data.get('patientInfo');
			const patId = patientInfo && patientInfo.get('aimid');
			if (patId) {
				markSharesDone(
					this.getShares(data)
						.filter(share => share.get('done') !== done)
						.map(share => ({patId, shareId: share.get('share_id'), done}))
				);
			}
		}
	}
}

PatientGroup.propTypes = {
	locale: PropTypes.string,
	data: immutableMapPropType,
	markSharesDone: PropTypes.func
};

export default wrapInLocalizationContext(PatientGroup);
