import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import DataGuard from '../../../commons/components/data/DataGuard.js';
import {modality as modalityFluxApi} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';

const {
	selectors: {getModalities, isModalityLoadInProgress},
	actions: {load}
} = modalityFluxApi;

const isLoadNeeded = createSelector(
	getModalities,
	isModalityLoadInProgress,
	(modalities, isLoadInProgress) => modalities === null && !isLoadInProgress
);

export default connect(createStructuredSelector({
	isLoadNeeded,
	data: getModalities
}), bindActions({
	load
}))(DataGuard);
