import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';

import '../../../styles/material-design/components/LoadingBar.scss';

export default function LoadingBar(props) {
	const {className = '', isLoading = true} = props;
	const combinedClassNames = combineClassNames('material-loading-bar', className);
	const finalClassNames = classNames({
		[combinedClassNames]: true,
		loading: isLoading
	});
	return (
		<svg xmlns='http://www.w3.org/2000/svg' className={finalClassNames} viewBox='0 0 100 1' preserveAspectRatio='none'>
			<rect width='100' height='1' x='0' y='0' />
			<line x1='0' y1='0.5' x2='100' y2='0.5' />
		</svg>
	);
}

LoadingBar.propTypes = {
	className: PropTypes.string,
	isLoading: PropTypes.bool
};
