import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import LandingPage from '../../commons/components/webview/LandingPage.js';
import LoadingIndicator from '../../commons/components/webview/LoadingIndicator.js';
import {DOCUMENT_PERMIT_CLAIM_SUCCESS_PAGE} from '../../commons/constants/PageNames.js';
import createWebViewPage from '../../components/createWebViewPage.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import DocumentPermitContext from '../bricks/DocumentPermitContext.js';
import DocumentPermitClaimController from './DocumentPermitClaimController.js';
import DocumentPermitClaimSuccess from './DocumentPermitClaimSuccess.js';
import DocumentPermitClaimSuccessAppBar from './DocumentPermitClaimSuccessAppBar.js';

const WebViewDocumentPermitClaimSuccessPageImpl =
	createWebViewPage(DocumentPermitClaimSuccessAppBar, DocumentPermitClaimSuccess, DOCUMENT_PERMIT_CLAIM_SUCCESS_PAGE);

export default function DocumentPermitClaimPage(props) {
	const {wasClaimedByCurrentUser, isBusy} = useBrick(DocumentPermitContext, selectDocumentPermitContextProps);
	const title = useTranslation('ClaimPageTitle');
	if (isBusy) {
		return (
			<VerticalLayout height='100%'>
				<LoadingIndicator />
			</VerticalLayout>
		);
	}
	if (wasClaimedByCurrentUser) {
		return <WebViewDocumentPermitClaimSuccessPageImpl {...props} />;
	}
	return (
		<LandingPage headerTemplate='loginPage_header' footerTemplate='loginPage_footer' title={title} maxCardWidth='700px'>
			<DocumentPermitClaimController />
		</LandingPage>
	);
}

function selectDocumentPermitContextProps(service) {
	return {
		wasClaimedByCurrentUser: service.wasClaimedByCurrentUser(),
		isBusy: service.isBusy()
	};
}
