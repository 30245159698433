import React from 'react';
import classnames from 'classnames';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import Tappable from '../../commons/components/Tappable.js';
import {createDateMoment} from '../../commons/utils/DateUtils.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DateRangePickerMessagesTranslator from '../../i18n/translators/DateRangePickerMessagesTranslator.js';
import GeneralTranslator from '../../i18n/translators/GeneralTranslator.js';
import Spacer from '../../ui/components/layout/Spacer.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import RadioGroup from '../../ui/components/RadioGroup.js';
import MaterialDatePicker from './MaterialDatePicker';

import '../../../styles/material-design/components/MaterialDateRangePicker.scss';

export const DATE_RANGE_MODE_RANGE = 'RANGE';
export const DATE_RANGE_MODE_DATE = 'DATE';

const THREE_MONTH = 3;

export default class MaterialDateRangePicker extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			currentCalendarValues: MaterialDateRangePicker.getCurrentCalendarValuesFromProps(this.props, this.state),
			dateRangeMode: MaterialDateRangePicker.inferRangeMode(this.props),
			lastValue: props.value,
			lastDateRangeMode: props.dateRangeMode
		};

		this.boundSetDateRangeMode = this.setDateRangeMode.bind(this);
		this.boundOnFromPickerChanged = this.onPickerChanged.bind(this, 'from');
		this.boundOnToPickerChanged = this.onPickerChanged.bind(this, 'to');
		this.boundSetToday = this.setToday.bind(this);
		this.boundSetLastThreeMonth = this.setLasThreeMonth.bind(this);
		this.boundSetLastMonth = this.setLastMonth.bind(this);
		this.boundSetLastWeek = this.setLastWeek.bind(this);
		this.boundSetLastYear = this.setLastYear.bind(this);
		this.boundSetYesterdayh = this.setYesterday.bind(this);
	}

	render() {
		const {dateRangeMode} = this.state;
		const inDateMode = dateRangeMode === DATE_RANGE_MODE_DATE;
		const pickerClassName = classnames({
			'material-date-range-picker': true,
			'mode-date': inDateMode,
			'mode-range': !inDateMode
		});
		return (
			<div className={pickerClassName}>
				{this.renderDatePickers()}
				{this.renderPredefinedRanges()}
			</div>
		);
	}

	renderDatePickers() {
		const {value} = this.props;
		const {dateRangeMode, currentCalendarValues: calendarValues} = this.state;
		const inDateMode = dateRangeMode === DATE_RANGE_MODE_DATE;
		const range = Immutable.Map.isMap(value) ? value : undefined;
		const selectionRange = inDateMode ? undefined : range;
		return (
			<div className='material-date-range-picker--pickers'>
				<MaterialDatePicker selectionRange={selectionRange} disabled={inDateMode} className='from-picker'
				                    name='fromPicker' value={calendarValues.get('from')}
				                    onChange={this.boundOnFromPickerChanged} />
				<MaterialDatePicker selectionRange={selectionRange} className='to-picker' name='toPicker'
				                    value={calendarValues.get('to')} onChange={this.boundOnToPickerChanged} />
			</div>
		);
	}

	renderPredefinedRanges() {
		const {dateRangeMode} = this.state;
		return (
			<VerticalLayout noGrow justify='start' align='start' className='material-date-range-picker--side-bar'>
				<TranslatedPredefinedRange message='Today' onTap={this.boundSetToday} />
				<TranslatedPredefinedRange message='Yesterday' onTap={this.boundSetYesterdayh} />
				<TranslatedPredefinedRange message='LastWeek' onTap={this.boundSetLastWeek} />
				<TranslatedPredefinedRange message='LastMonth' onTap={this.boundSetLastMonth} />
				<TranslatedPredefinedRange message='Last3Month' onTap={this.boundSetLastThreeMonth} />
				<TranslatedPredefinedRange message='LastYear' onTap={this.boundSetLastYear} />
				<Spacer minHeight='8px' />
				<RadioGroup value={dateRangeMode} onChange={this.boundSetDateRangeMode}
					            label={<SynFormattedMessage translator={DateRangePickerMessagesTranslator}
					                                        message='DateRangeSelectionType' />}>
					{[
						{
							label: <SynFormattedMessage translator={DateRangePickerMessagesTranslator}
							                               message='SelectionTypeDateRange' />,
							value: DATE_RANGE_MODE_RANGE
						},
						{
							label: <SynFormattedMessage translator={DateRangePickerMessagesTranslator}
							                               message='SelectionTypeSingleDate' />,
							value: DATE_RANGE_MODE_DATE
						}
					]}
				</RadioGroup>
			</VerticalLayout>
		);
	}

	static getCurrentCalendarValuesFromProps({value}, state) {
		let calendarsValue = value;
		if (!Immutable.Map.isMap(value)) {
			const {currentCalendarsValue} = state || {};
			calendarsValue = Immutable.Map({
				from: (currentCalendarsValue && currentCalendarsValue.get('from')) || null,
				to: moment.isMoment(value) ? value : null
			});
		}
		return calendarsValue;
	}

	onDateRangeModeChange(newDateRangeMode) {
		const {dateRangeMode} = this.state;
		if (dateRangeMode !== newDateRangeMode) {
			this.setState({
				dateRangeMode,
				currentCalendarValues: Immutable.Map({from: null, to: null})
			});
		}
	}

	static inferRangeMode(props) {
		const dateRange = props.value;
		let {dateRangeMode} = props;

		if (Immutable.Map.isMap(dateRange)) {
			dateRangeMode = DATE_RANGE_MODE_RANGE;
		} else if (moment.isMoment(dateRange)) {
			dateRangeMode = DATE_RANGE_MODE_DATE;
		}

		return dateRangeMode;
	}

	onPickerChanged(fieldName, aMoment) {
		const {currentCalendarValues, dateRangeMode} = this.state;
		const {onChange} = this.props;
		const newCalendarValues = currentCalendarValues.set(fieldName, aMoment);
		this.setState({
			currentCalendarValues: newCalendarValues
		});
		if (dateRangeMode === DATE_RANGE_MODE_RANGE && (newCalendarValues.get('from') || newCalendarValues.get('to'))) {
			onChange(newCalendarValues);
		} else if (dateRangeMode === DATE_RANGE_MODE_DATE && (newCalendarValues.get('to'))) {
			onChange(newCalendarValues.get('to'));
		}
	}

	setDate(date) {
		this.setState({
			currentRange: date
		});
		const {onChange} = this.props;
		onChange(date);
	}

	setRange(from, to) {
		const newRange = Immutable.Map({from, to});
		this.setState({
			currentRange: newRange
		});
		const {onChange} = this.props;
		onChange(newRange);
	}

	setToday() {
		this.setDate(createDateMoment());
	}

	setYesterday() {
		this.setDate(createDateMoment().subtract(1, 'days'));
	}

	setLastWeek() {
		this.setRange(createDateMoment().subtract(1, 'weeks'), createDateMoment());
	}

	setLastMonth() {
		this.setRange(createDateMoment().subtract(1, 'months'), createDateMoment());
	}

	setLasThreeMonth() {
		this.setRange(createDateMoment().subtract(THREE_MONTH, 'months'), createDateMoment());
	}

	setLastYear() {
		this.setRange(createDateMoment().subtract(1, 'years'), createDateMoment());
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const needsUpdate =
				(nextProps.value && nextProps.value !== prevState.lastValue) ||
				nextProps.dateRangeMode !== prevState.lastDateRangeMode;
		if (needsUpdate) {
			return {
				currentCalendarValues: MaterialDateRangePicker.getCurrentCalendarValuesFromProps(nextProps, prevState),
				dateRangeMode: MaterialDateRangePicker.inferRangeMode(nextProps),
				lastValue: nextProps.value,
				lastDateRangeMode: nextProps.dateRangeMode
			};
		}
		return null;
	}

	setDateRangeMode(newMode) {
		this.setState(state => ({...state, dateRangeMode: newMode}));
	}
}

MaterialDateRangePicker.propTypes = {
	dateRangeMode: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string
	])
};

MaterialDateRangePicker.defaultProps = {
	dateRangeMode: DATE_RANGE_MODE_RANGE,
	onChange: noop
};

function TranslatedPredefinedRange(props) {
	const {onTap, message} = props;
	return (
		<SynFormattedMessage className='material-date-range-picker--predefined-range' element={Tappable}
			                     translator={GeneralTranslator} onTap={onTap} message={message} />
	);
}
TranslatedPredefinedRange.propTypes = {
	onTap: PropTypes.func,
	message: PropTypes.string
};
