import search from '../../commons/api/operations/search.js';

const ORDERER_BASE_URL = '/order/orderer';
const DEFAULT_SORT = [
	['abk', 'ASC'],
	['description', 'ASC']
];

/**
 * Queries all aets available for order creation.
 * @returns {Promise<ResultList>} The results as a ResultList object.
 */
export function getOrderers() {
	return search(ORDERER_BASE_URL, {nocount: true, sortBy: DEFAULT_SORT});
}
