import React from 'react';

import createFormFieldContainer from '../../../commons/containers/createFormFieldContainer.js';
import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import SuggestComboBox from '../../../ui/components/SuggestComboBox.js';

function propsMergingFunction(stateProps, dispatchProps, ownProps) {
	const {label: stateLabel, validationResult, isFormValidationForced, value} = stateProps;
	const {updateFieldValue} = dispatchProps;
	const {label, onChange} = ownProps;

	const isErroneous = validationResult !== true && isFormValidationForced;
	const remainingProps = cloneWithoutProperties(ownProps,
		'formDescription'
	);
	return {
		...remainingProps,
		value,
		label: typeof label === 'undefined' ? stateLabel : label,
		hint: isErroneous ? validationResult : '',
		error: isErroneous,
		onChange: createOnChangeHandler(updateFieldValue, onChange)
	};
}

function createOnChangeHandler(updateFieldValue, onChange) {
	return function onChangeHandler(newValue) {
		callSafe(onChange, newValue);
		updateFieldValue(newValue);
	};
}

export function connectSuggestComboBox(Component) {
	return createFormFieldContainer(Component, propsMergingFunction);
}

export const MaterialFormSuggestComboBox = connectSuggestComboBox(SuggestComboBox);
