import {createSelector, createStructuredSelector} from 'reselect';

import {declareBrick} from '../../bricks/brickTools.js';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import {
	clearCriteria,
	editCriteria,
	loadNextResults,
	loadPreviousResults,
	reloadResults,
	reset,
	searchPatients
} from './PatientSearchActions.js';
import {PATIENT_SEARCH_FETCH_FAILED} from './PatientSearchErrorTypes.js';
import {
	selectPatientSearchCriteria,
	selectPatientSearchErrorType,
	selectPatientSearchResults,
	selectPatientSearchState
} from './PatientSearchSelectors.js';
import {
	PATIENT_SEARCH_STATE_EDIT_CRITERIA,
	PATIENT_SEARCH_STATE_ERROR,
	PATIENT_SEARCH_STATE_SHOW_RESULTS
} from './PatientSearchStates.js';

const DISPATCH = Symbol('dispatch');

const MAP_REDUX_STATE = {
	[PATIENT_SEARCH_STATE_EDIT_CRITERIA]: AbstractPatientSearchService.State.EDIT_CRITERIA,
	[PATIENT_SEARCH_STATE_SHOW_RESULTS]: AbstractPatientSearchService.State.SHOW_RESULTS,
	[PATIENT_SEARCH_STATE_ERROR]: AbstractPatientSearchService.State.ERROR
};

const MAP_REDUX_ERROR = {
	[PATIENT_SEARCH_FETCH_FAILED]: AbstractPatientSearchService.Error.FETCH_FAILED
};

export default class ReduxPatientSearchService extends AbstractPatientSearchService {
	constructor(reduxStore, selectPatientSearch) {
		super();

		this[DISPATCH] = reduxStore.dispatch.bind(reduxStore);
		const onStoreChange = createOnStoreChanged(
			reduxStore,
			createServiceSelector(selectPatientSearch),
			newState => this.updateBrickState(() => newState)
		);
		this.subscribeTo(reduxStore, onStoreChange);
	}

	reset() {
		this[DISPATCH](reset());
	}

	clearCriteria() {
		this[DISPATCH](clearCriteria());
	}

	searchPatients(criteria, rowsPerPage) {
		this[DISPATCH](searchPatients(criteria, rowsPerPage));
	}

	reloadResults(rowsPerPage) {
		this.assertState(AbstractPatientSearchService.State.SHOW_RESULTS);
		this[DISPATCH](reloadResults(rowsPerPage));
	}

	loadPreviousResults(rowsPerPage) {
		this.assertState(AbstractPatientSearchService.State.SHOW_RESULTS);
		this[DISPATCH](loadPreviousResults(rowsPerPage));
	}

	loadNextResults(rowsPerPage) {
		this.assertState(AbstractPatientSearchService.State.SHOW_RESULTS);
		this[DISPATCH](loadNextResults(rowsPerPage));
	}

	editCriteria() {
		this[DISPATCH](editCriteria());
	}

	getCriteria() {
		return this.getBrickState().criteria;
	}

	getResults() {
		return this.getBrickState().results;
	}

	hasResults() {
		return this.getResults() !== null;
	}

	getState() {
		return MAP_REDUX_STATE[this.getBrickState().state];
	}

	getErrorType() {
		return MAP_REDUX_ERROR[this.getBrickState().errorType];
	}
}
declareBrick(ReduxPatientSearchService);

function createServiceSelector(selectPatientSearch) {
	return createStructuredSelector({
		criteria: createSelector(selectPatientSearch, selectPatientSearchCriteria),
		state: createSelector(selectPatientSearch, selectPatientSearchState),
		results: createSelector(selectPatientSearch, selectPatientSearchResults),
		errorType: createSelector(selectPatientSearch, selectPatientSearchErrorType)
	});
}

function createOnStoreChanged(reduxStore, selector, callback) {
	const onStoreChanged = createSelector(selector, callback);
	return () => onStoreChanged(reduxStore.getState());
}
