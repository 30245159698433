import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import AdditionalDataComboBox from './AdditionalDataComboBox.js';

function DocumentClassField(props) {
	const {documentClasses, documentClassChoice, onChange} = props;
	const documentClassFieldLabel = useTranslation('DocumentClass');
	const options = useMemoFactory(getOptions, documentClasses);
	const noDocumentClassErrorMessage = useTranslation('NoDocumentClassErrorMessage');
	return !documentClasses.isEmpty() && (
		<AdditionalDataComboBox id='archive-document-class-auto-select' onChange={onChange} label={documentClassFieldLabel}
		                        selectedOptionsIndex={documentClassChoice} errorMessage={noDocumentClassErrorMessage}
		                        options={options} />
	);
}

DocumentClassField.propTypes = {
	documentClasses: immutableListPropType.isRequired,
	documentClassChoice: PropTypes.number,
	onChange: PropTypes.func
};

function getOptions(documentClasses) {
	return documentClasses.toArray().map(documentClass => documentClass.displayName);
}

export default DocumentClassField;
