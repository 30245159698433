import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {DAYS_PER_WEEK, WEEK_DAY_OFFSET} from './constants.js';
import Week from './Week.js';

export default function Month(props) {
	const {selectedDay, selectionRange, disabled, onSelectDay} = props;
	const [firstDate, lastDate] = useMemoFactory(getFirstAndLastDateFor, selectedDay);
	const numberOfDays = lastDate.diff(firstDate, 'days');

	return Immutable.Range(0, Math.ceil(numberOfDays / DAYS_PER_WEEK))
		 .map(weekIndex => {
			 const weekOffset = moment(firstDate)
					 .add(weekIndex * DAYS_PER_WEEK, 'days');
			 return (
					 <Week key={weekIndex} weekStart={weekOffset} selectionRange={selectionRange}
							 disabled={disabled} onSelectDay={onSelectDay} selectedDay={selectedDay} />
			 );
		 })
		.toArray();
}
Month.propTypes = {
	date: PropTypes.instanceOf(moment),
	selectionRange: PropTypes.instanceOf(Immutable.Map),
	disabled: PropTypes.bool,
	onSelectDay: PropTypes.func
};

function getFirstAndLastDateFor(date) {
	const firstDate = moment(date)
		.date(1)
		.day(WEEK_DAY_OFFSET);
	if (firstDate.get('month') === date.get('month') && firstDate.date() > 1) {
		firstDate.subtract(1, 'weeks');
	}
	const lastDate = moment(date)
		.date(1)
		.add(1, 'month')
		.subtract(1, 'day')
		.day(DAYS_PER_WEEK);
	return [firstDate, lastDate];
}

