import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import PasswordFormField from '../../authentication/components/PasswordFormField.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {useUniqueId} from '../../commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MaterialFormLoadingButton from '../../material-design/components/form/MaterialFormLoadingButton.js';
import LockOpenIcon from '../../ui/components/icons/LockOpenIcon.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';
import DocumentPermitClaimFormAccessors from './accessors/DocumentPermitClaimFormAccessors.js';
import ClaimErrorDisplay from './ClaimErrorDisplay.js';

export default function DocumentPermitClaimFormWithTan(props) {
	const {isBusy, onSubmit} = props;
	const formId = useUniqueId('form');
	const disableSubmitButton = useDisabledState();
	return (
		<React.Fragment>
			<TanFormContent key='tan-form' onSubmit={onSubmit} formId={formId} />
			<MaterialFormLoadingButton startIcon={<LockOpenIcon />} formDescription={DocumentPermitClaimFormAccessors}
			                           disabled={disableSubmitButton} loading={isBusy} form={formId} type='submit'
			                           loadingPosition='start' flat opaque>
				<SynFormattedMessage message='PerformClaim' />
			</MaterialFormLoadingButton>
			<ClaimErrorDisplay />
		</React.Fragment>
	);
}

DocumentPermitClaimFormWithTan.propTypes = {
	isBusy: PropTypes.bool,
	onSubmit: PropTypes.func
};

function useDisabledState() {
	const canClaim = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.canClaim());
	const formIsValid = useSelector(DocumentPermitClaimFormAccessors.isValid);
	return formIsValid && !canClaim;
}

export function TanFormContent(props) {
	const {onSubmit, formId, autoFocusTan, submitIfInvalid} = props;
	return (
		<VerticalLayout id={formId} element={FormContainer} className='document-permit-claim-form'
						description={DocumentPermitClaimFormAccessors} onSubmit={onSubmit}
						submitIfInvalid={submitIfInvalid} deleteFormDataOnUnmount>
			<SynFormattedMessage message='ClaimWithTanMessage' />
			<PasswordFormField fieldName='tan' autoComplete='off' autoFocus={autoFocusTan} />
		</VerticalLayout>
	);
}

TanFormContent.propTypes = {
	onSubmit: PropTypes.func,
	formId: PropTypes.string,
	autoFocusTan: PropTypes.bool,
	submitIfInvalid: PropTypes.bool
};

TanFormContent.defaultProps = {
	autoFocusTan: true
};
