import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

import LinkButton from '../../commons/components/webview/LinkButton.js';
import {fragmentSelector, pathSelector, querySelector} from '../../router/flux/selectors/LocationSelectors.js';
import {SEARCH_SHOW_RESULTS} from '../constants/SearchPropertyNames.js';

export default connect(
	createSelector(pathSelector, querySelector, fragmentSelector, (path, query, fragment) => ({
		location: Immutable.Map({
			path,
			query: query.delete(SEARCH_SHOW_RESULTS),
			fragment
		})
	})),
	{}
)(LinkButton);
