import React from 'react';

import BarcodeEnabledSingleLineFormField from './BarcodeEnabledSingleLineFormField.js';

export default function BarcodeEnabledFormField(props) {
	return <BarcodeEnabledSingleLineFormField {...props} />;
}

BarcodeEnabledFormField.propTypes = {
	...BarcodeEnabledSingleLineFormField.propTypes
};
