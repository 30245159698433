import React from 'react';
import PropTypes from 'prop-types';

import connectBrick from '../../bricks/connectBrick.js';
import {immutableListPropType, immutableMapPropType, immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import StepperContent from '../../material-design/components/StepperContent.js';
import {createMetricsEntry, pushEntry} from '../../metrics-collector/MetricsStore.js';
import {uploadFile} from '../api/ArchiveDocumentApi.js';
import {UploadContextBuilder} from '../api/UploadContextBuilder.js';
import ImageCollection from '../bricks/ImageCollection.js';
import {
	ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT,
	DATA_FIELD_DESCRIPTION,
	DATA_FIELD_DOCUMENT_CLASS,
	DATA_FIELD_PRODUCER,
	DATA_FIELD_UPLOAD_STATE,
	DATA_FIELD_VISIT,
	UPLOAD_STATE_ERROR,
	UPLOAD_STATE_SUCESS,
	UPLOAD_STATE_UPLOAD_IN_PROGRESS
} from '../constants/ArchiveDocumentsConstants.js';
import UploadErrorStatus from './UploadErrorStatus.js';
import UploadInProgressStatus from './UploadInProgressStatus.js';
import UploadSuccessStatus from './UploadSuccessStatus.js';

import '../../../styles/archive-documents/components/StepPerformUpload.scss';

const DELAY_PROGRESSBAR_UNMOUNT = 1500;
const INITIAL_STATE = {
	numUploadedFiles: 0
};

class StepPerformUpload extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.state = INITIAL_STATE;
		this.boundReInitiateUpload = this.reInitiateUpload.bind(this);
	}

	render() {
		const {data} = this.props;
		const {numUploadedFiles} = this.state;
		let StatusComponent;
		switch (data.get(DATA_FIELD_UPLOAD_STATE)) {
			case UPLOAD_STATE_UPLOAD_IN_PROGRESS: {
				StatusComponent = UploadInProgressStatus;
				break;
			}
			case UPLOAD_STATE_SUCESS: {
				StatusComponent = UploadSuccessStatus;
				break;
			}
			case UPLOAD_STATE_ERROR: {
				StatusComponent = UploadErrorStatus;
				break;
			}
			default: {
				StatusComponent = false;
			}
		}
		return (
			<StepperContent justify='center' align='center' className='step-perform-upload'>
				{StatusComponent && <StatusComponent {...this.props} initiateUpload={this.boundReInitiateUpload}
																 numUploadedFiles={numUploadedFiles} />}
			</StepperContent>
		);
	}

	reInitiateUpload() {
		this.setState(INITIAL_STATE, () => {
			this.initiateUpload();
		});
	}

	createContext() {
		const {data, patient, archivePermissions, documentClasses, visits} = this.props;
		const documentClassChoice = data.get(DATA_FIELD_DOCUMENT_CLASS);
		const visitChoice = data.get(DATA_FIELD_VISIT);
		let contextBuilder = new UploadContextBuilder()
			.setPatientAimId(patient.get('id'))
			.setProducerAbk(archivePermissions.get(data.get(DATA_FIELD_PRODUCER)).orgunitAbk)
			.setDocumentDescription(data.get(DATA_FIELD_DESCRIPTION))
			.setGenericDocumentCreatedWhenToNow();
		if (documentClasses.has(documentClassChoice)) {
			contextBuilder = contextBuilder
				.setDocumentClassId(documentClasses.get(documentClassChoice).id);
		}
		if (visits.has(visitChoice)) {
			contextBuilder = contextBuilder
				.setVisitId(visits.get(visitChoice).id);
		}
		return contextBuilder.build();
	}

	initiateUpload() {
		const {data, imagesData, updateData} = this.props;
		const context = this.createContext();
		if (data.get(DATA_FIELD_UPLOAD_STATE) !== UPLOAD_STATE_UPLOAD_IN_PROGRESS) {
			updateData(data.set(DATA_FIELD_UPLOAD_STATE, UPLOAD_STATE_UPLOAD_IN_PROGRESS));

			const requests =
				imagesData.map((imageFileEntry, index) => uploadFile(
					imageFileEntry.file, context, index, imageFileEntry.name
				).then(result => {
					this.setState(state => ({
						numUploadedFiles: state.numUploadedFiles + 1
					}));
					return {status: 'success', result};
				})
					.catch(error => ({status: 'rejected', error}))
				).toJS();

			Promise.all(requests).then(results => {
				pushEntry(createMetricsEntry('UploadInitiated', ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT));
				const hasError = results.some(task => task.status === 'rejected');
				const updateState = hasError ? UPLOAD_STATE_ERROR : UPLOAD_STATE_SUCESS;
				window.setTimeout(() => {
					updateData(data.set(DATA_FIELD_UPLOAD_STATE, updateState));
				}, DELAY_PROGRESSBAR_UNMOUNT);
			});
		}
	}

	componentDidMount() {
		this.initiateUpload();
	}
}

StepPerformUpload.propTypes = {
	patient: immutablePropType.isRequired,
	updateData: PropTypes.func,
	data: immutableMapPropType,
	archivePermissions: immutablePropType,
	visits: immutablePropType,
	documentClasses: immutableListPropType,
	imagesData: immutablePropType
};

function selectFileCollectionProps(service) {
	return {
		imagesData: service.getImages()
	};
}

export default connectBrick(ImageCollection, selectFileCollectionProps)(StepPerformUpload);
