import convertToArchivePermission from '../data/ArchivePermission.js';
import search from './operations/search.js';

export const ARCHIVE_PERMISSION_URL = '/archivepermission';

function processRawResults(rawResults) {
	const {results} = rawResults;
	rawResults.results = results.map(convertToArchivePermission);
	return rawResults;
}

export function searchArchiveDocumentPermissions(patientAimId) {
	const params = {patient_aim_id: patientAimId};
	return search(ARCHIVE_PERMISSION_URL, {params}, processRawResults);
}
