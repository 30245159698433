import React from 'react';

import {PATIENTDETAILS_PAGE} from '../../commons/constants/PageNames.js';
import PatientDetailsPageBodyContainer from '../../patient-details/flux/containers/PatientDetailsPageBodyContainer.js';
import EmbeddedPatientDetailsAppBarContainer from '../flux/containers/EmbeddedPatientDetailsAppBarContainer.js';
import createEmbeddedWebViewPage from './createEmbeddedWebViewPage.js';

export default createEmbeddedWebViewPage(
	EmbeddedPatientDetailsAppBarContainer,
	PatientDetailsPageBodyContainer,
	PATIENTDETAILS_PAGE
);
