import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {
	shareReceiverBackgroundValidator as shareReceiverBackgroundValidationFluxApi
} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {useAction} from '../../../commons/flux/ReactHooks.js';
import {useMemoFactory} from '../../../commons/utils/customHooks/index.js';
import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import ShareReceiverFormAccessors from '../../components/ShareReceiverFormAccessors.js';
import {selectCurrentBackgroundValidationFilterString} from '../ShareDocumentDialogSelectors.js';

const EMAIL_VALIDATION_ERROR_SELECTOR = ShareReceiverFormAccessors.getFieldValidationErrorSelector('email');
const EMAIL_VALUE_SELECTOR = state => ShareReceiverFormAccessors.getFormFieldValue(state, 'email');
const VALIDATE_EMAIL_ACTION = shareReceiverBackgroundValidationFluxApi.actions.loadFiltered;

export default function EMailChecker() {
	const emailValidationError = useSelector(EMAIL_VALIDATION_ERROR_SELECTOR);
	const emailValue = useSelector(EMAIL_VALUE_SELECTOR);
	const currentlyUsedValidationFilterString = useSelector(selectCurrentBackgroundValidationFilterString);
	const validateEmail = useAction(VALIDATE_EMAIL_ACTION);
	const shouldValidateEmail = useMemoFactory(
		getShouldValidateEMail, emailValidationError, emailValue, currentlyUsedValidationFilterString
	);
	useEffect(() => {
		if (shouldValidateEmail) {
			callSafe(validateEmail, emailValue);
		}
	});
	return null;
}

function getShouldValidateEMail(emailValidationError, emailValue, currentlyUsedValidationFilterString) {
	return emailValidationError === true && emailValue.trim() !== currentlyUsedValidationFilterString.trim();
}
