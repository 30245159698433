import generateUniqueKey from '../generateUniqueKey.js';
import {useMemoFactory} from './index.js';

export default function useUniqueId(prefix = '') {
	return useMemoFactory(generateNewId, prefix);
}

function generateNewId(prefix) {
	return `${prefix}${generateUniqueKey()}`;
}
