import React from 'react';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import {canCreateShareReceivers} from '../../commons/selectors/GeneralConfigSelectors.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../commons/utils/ObjectUtils';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DialogBody from '../../material-design/components/DialogBody.js';
import DialogHeader from '../../material-design/components/DialogHeader.js';
import StepperDialog from '../../material-design/components/StepperDialog.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';
import Spinner from '../../ui/components/Spinner.js';
import {Tab, TabBar} from '../../ui/components/Tabs.js';
import {COLOR_INHERIT, COLOR_PRIMARY} from '../../ui/constants/SynMUIOptions.js';
import {DATA_FIELD_SHARE_MODE, MODE_EXISTING_RECEIVER, MODE_NEW_RECEIVER} from '../constants/ShareDocumentConstants.js';
import ShareDocumentStepperContainer from '../flux/containers/ShareDocumentStepperContainer.js';
import ShareDocumentMessagesTranslator from '../i18n/ShareDocumentTranslator.js';
import ShareDocumentDialogProxy from './ShareDocumentDialogProxy.js';

import '../../../styles/share-document/components/ShareDocumentDialog.scss';

const TAB_TITLE_SEARCH = <SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='TabTitleFindReceiver' />;
const TAB_TITLE_ADD = <SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='TabTitleCreateReceiver' />;
const DEBOUNCE_LOADING_BAR = 500;
const LOADING_SPINNER_STYLES = {px: '16px', py: '8px', opacity: 0.72};

class ShareDocumentDialog extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {locale} = this.props;
		this.boundInvokeOnClose = this.invokeOnClose.bind(this);
		this.boundOnTabActivated = this.onTabActivated.bind(this);
		this.delayedSetShowLoadingBar = _debounce(this.setShowLoadingBar.bind(this), DEBOUNCE_LOADING_BAR);

		this.state = {
			showLoadingBar: this.shouldShowLoadingBar()
		};

		this.spinnerDescription = ShareDocumentMessagesTranslator.getFormattedMessage('Busy', locale);
	}

	render() {
		const {currentStep, shareMode, documentIds, isSmallDevice} = this.props;
		const {showLoadingBar} = this.state;
		const activeTab = shareMode === MODE_EXISTING_RECEIVER ? 0 : 1;
		const isFirstStep = currentStep <= 0;
		const headerClass = isFirstStep && canCreateShareReceivers() ? 'with-tab-bar' : undefined;
		const nDocuments = documentIds.length;
		const dialogTitleElement = (
			<SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='DialogTitle'
			                     messageParams={{nDocuments}} />
		);
		return (
			<StepperDialog className='share-document-dialog' onClose={this.boundInvokeOnClose}>
				<DialogHeader className={headerClass} onClose={this.boundInvokeOnClose} title={dialogTitleElement}
				              subTitle={isFirstStep && !canCreateShareReceivers() ? TAB_TITLE_SEARCH : null}>
					<Spinner isSpinning={showLoadingBar} description={this.spinnerDescription} radius={12}
					         sx={LOADING_SPINNER_STYLES} color={isSmallDevice ? COLOR_INHERIT : COLOR_PRIMARY} />
					{isFirstStep && canCreateShareReceivers() && this.renderTabBar(activeTab)}
				</DialogHeader>
				<DialogBody>
					<ShareDocumentStepperContainer createNewReceiver={activeTab === 1} documentIds={documentIds}
					                               className='share-document-dialog--stepper' />
				</DialogBody>
			</StepperDialog>
		);
	}

	renderTabBar(activeTab) {
		const {isSmallDevice} = this.props;
		return (
			<TabBar className='share-document-dialog--tabbar' activeTabIndex={activeTab}
			        onTabActivated={this.boundOnTabActivated} onDarkBg={isSmallDevice} noMaxWidth>
				<Tab label={TAB_TITLE_SEARCH} />
				<Tab label={TAB_TITLE_ADD} />
			</TabBar>
		);
	}

	shouldShowLoadingBar() {
		const {receiverList, receiverFilter, requestedReceiverFilter, isBackgroundValidationInProgress} = this.props;
		return (Boolean(receiverList) && receiverFilter !== requestedReceiverFilter) ||
			isBackgroundValidationInProgress;
	}

	invokeOnClose() {
		const {onClose, isCreatingShare} = this.props;
		if (!isCreatingShare && Boolean(onClose)) {
			onClose();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
	}

	updateShowLoadingBar(doShow) {
		const {showLoadingBar} = this.state;
		if (doShow !== showLoadingBar) {
			if (doShow) {
				this.setShowLoadingBar(doShow);
			} else {
				this.delayedSetShowLoadingBar(doShow);
			}
		}
	}

	setShowLoadingBar(doShow) {
		this.setState({
			showLoadingBar: doShow
		});
	}

	onTabActivated(newTabIndex) {
		const {updateData, data} = this.props;
		updateData(data.set(DATA_FIELD_SHARE_MODE, newTabIndex === 0 ? MODE_EXISTING_RECEIVER : MODE_NEW_RECEIVER));
	}

	componentDidUpdate(prevProps) {
		const {wasCancelled, hasFinished} = this.props;
		if ((!prevProps.wasCancelled && wasCancelled) ||
			(!prevProps.hasFinished && hasFinished)) {
			this.invokeOnClose();
		}
		this.updateShowLoadingBar(this.shouldShowLoadingBar());
	}

	componentWillUnmount() {
		const {cleanup} = this.props;
		this.delayedSetShowLoadingBar.cancel();
		callSafe(cleanup);
	}
}

ShareDocumentDialog.propTypes = {
	currentStep: PropTypes.number,
	shareMode: PropTypes.oneOf([MODE_EXISTING_RECEIVER, MODE_NEW_RECEIVER]),
	isSmallDevice: PropTypes.bool,
	documentIds: ShareDocumentDialogProxy.propTypes.documentIds,
	onClose: PropTypes.func,
	isCreatingShare: PropTypes.bool,
	updateData: PropTypes.func,
	data: immutableMapPropType,
	wasCancelled: PropTypes.bool,
	hasFinished: PropTypes.bool,
	cleanup: PropTypes.func,
	receiverList: resultListPropType,
	receiverFilter: PropTypes.string,
	requestedReceiverFilter: PropTypes.string,
	isBackgroundValidationInProgress: PropTypes.bool,
	locale: PropTypes.string
};

ShareDocumentDialog.defaultProps = {
	isBackgroundValidationInProgress: false
};

export default wrapInLocalizationContext(ShareDocumentDialog);
