import React from 'react';

import IconButton from '../../../ui/components/IconButton.js';
import DownloadIcon from '../../../ui/components/icons/DownloadIcon.js';
import DocumentDownloadLink from './DocumentDownloadLink.js';

export default function DocumentDownloadButton(props) {
	return (
		<DocumentDownloadLink {...props}>
			<IconButton aria-hidden icon={<DownloadIcon />} />
		</DocumentDownloadLink>
	);
}
