import React, {useRef, useState} from 'react';
import EventIcon from '@mui/icons-material/Event';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

import {KEY_VALUE_RETURN, KEY_VALUE_TAB} from '../../commons/constants/KeyValues.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import Dialog from './Dialog.js';
import IconButton from './IconButton.js';
import SingleLineTextField from './SingleLineTextField.js';

function PickerField(props) {
	const {
		onChange, name, placeholder, label, value, hint, invalid, shouldCloseOnValueChange, isSmall, children,
		id, onBlur, onFocus, onKeyPress
	} = props;

	const [showPicker, setShowPicker] = useState(false);
	const [prevValue, setPrevValue] = useState();
	const buttonRef = useRef(null);
	const handleClick = useMemoFactory(createHandleClick, setShowPicker);
	const handleDatePickerClose = useMemoFactory(createHandlePickerClose, setShowPicker);
	const handleBlur = useMemoFactory(createHandleBlur, onBlur);
	const handleKeyDown = useMemoFactory(createHandleKeyDown, showPicker, isSmall, setShowPicker);
	const handleOnFocus = useMemoFactory(createHandleOnFocus, setShowPicker, onFocus);
	const textFieldButtons = useMemoFactory(createEventIconButton, buttonRef, handleClick);
	const textFieldProps = useMemoFactory(
		createTextFieldProps, id, handleOnFocus, handleBlur, handleKeyDown, onChange, name, placeholder, label, value,
		hint, invalid, textFieldButtons, onKeyPress
	);
	if (showPicker && shouldCloseOnValueChange(prevValue, value)) {
		setShowPicker(false);
		setPrevValue(value);
	}
	return (
		<React.Fragment>
			<SingleLineTextField {...textFieldProps} />
			{isSmall ? (
				<Dialog fullScreen show={showPicker} onClose={handleDatePickerClose} disableRestoreFocus>
					{children}
				</Dialog>
			) : (
				<Popover open={showPicker} anchorEl={buttonRef.current} onClose={handleDatePickerClose}
					anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
					transformOrigin={{vertical: 'top', horizontal: 'right'}} disableAutoFocus disableRestoreFocus disableEnforceFocus>
					{children}
				</Popover>
			)}
		</React.Fragment>
	);
}

PickerField.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	hint: PropTypes.string.isRequired,
	invalid: PropTypes.bool.isRequired,
	shouldCloseOnValueChange: PropTypes.func.isRequired,
	isSmall: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyPress: PropTypes.func
};

PickerField.defaultProps = {
	isSmall: false
};

function createEventIconButton(buttonRef, handleClick) {
	return (
		<IconButton className='date-picker-field-button' ref={buttonRef} onClick={handleClick} icon={<EventIcon />} />
	);
}

function createHandleClick(setShowPicker) {
	return () => setShowPicker(true);
}

function createHandlePickerClose(setShowPicker) {
	return () => setShowPicker(false);
}

function createHandleBlur(onBlur) {
	return () => callSafe(onBlur);
}

function createHandleKeyDown(showPicker, isSmall, setShowPicker) {
	return e => {
		const pressedKey = e.key;
		if (showPicker && !isSmall &&
			(pressedKey === KEY_VALUE_TAB || pressedKey === KEY_VALUE_RETURN)) {
			setShowPicker(false);
		}
	};
}

function createHandleOnFocus(setShowPicker, onFocus) {
	return () => {
		setShowPicker(true);
		callSafe(onFocus);
	};
}

function createTextFieldProps(
		id, handleOnFocus, handleBlur, handleKeyDown, onChange, name, placeholder, label, value, hint, invalid,
		textFieldButtons, onKeyPress
) {
	return {
		id: id ? id : `id-${name}`,
		autoComplete: 'off',
		onFocus: handleOnFocus,
		onBlur: handleBlur,
		onKeyDown: handleKeyDown,
		onKeyPress,
		onChange,
		name,
		placeholder,
		label,
		value,
		hint,
		invalid,
		textFieldButtons
	};
}

export default React.memo(PickerField);
