import React from 'react';
import PropTypes from 'prop-types';

import NoResults from '../../commons/components/data/NoResults.js';
import {DEVICE_SIZE_SMALL, deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import TranslatedSearchResultsParameters from './PatientSearchResultsParameters.js';

export default function PatientSearchResultsNoResults(props) {
	const {nrResults, additionalProps: {deviceSize, editCriteria, searchCriteria}} = props;
	const showParameters = deviceSize !== DEVICE_SIZE_SMALL;
	return (
		<NoResults>
			{
				showParameters && <TranslatedSearchResultsParameters nrResults={nrResults} showForm={editCriteria}
				                                                     parameters={searchCriteria} />
			}
		</NoResults>
	);
}

PatientSearchResultsNoResults.propTypes = {
	nrResults: PropTypes.number,
	additionalProps: PropTypes.shape({
		deviceSize: deviceSizePropType,
		editCriteria: PropTypes.func,
		searchCriteria: immutableMapPropType
	})
};
