import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import SearchBody from '../../search/components/SearchBody.js';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import PatientSearchForm from './PatientSearchForm.js';
import PatientSearchResults from './PatientSearchResults.js';

const PATIENT_SEARCH_FORM_TITLE = (<SynFormattedMessage message='SearchFormCaption' />);

export default function PatientSearchBody(props) {
	const {deviceSize, onSelectEmergencyPatient, onPatientClick, initiallyExpandedSearchFields} = props;
	const showResults = useBrick(AbstractPatientSearchService, selectShowResults);
	return (
		<SearchBody title={PATIENT_SEARCH_FORM_TITLE} className='patient-search-body'
			            showResults={showResults} {...props}>
			<PatientSearchForm initiallyExpandedSearchFields={initiallyExpandedSearchFields}
				onSelectEmergencyPatient={onSelectEmergencyPatient} />
			{showResults && <PatientSearchResults onPatientClick={onPatientClick} deviceSize={deviceSize} />}
		</SearchBody>
	);
}
PatientSearchBody.propTypes = {
	onPatientClick: PropTypes.func,
	onSelectEmergencyPatient: PropTypes.func,
	deviceSize: deviceSizePropType,
	initiallyExpandedSearchFields: PropTypes.bool
};

function selectShowResults(patientSearchService) {
	return patientSearchService.getState() === AbstractPatientSearchService.State.SHOW_RESULTS;
}
