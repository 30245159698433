import Immutable from 'immutable';
import {createAction} from 'redux-actions';

import {
	PATIENT_SEARCH_CLEAR_CRITERIA,
	PATIENT_SEARCH_EDIT_CRITERIA, PATIENT_SEARCH_LOAD_NEXT_RESULTS, PATIENT_SEARCH_LOAD_PREVIOUS_RESULTS,
	PATIENT_SEARCH_RELOAD_RESULTS,
	PATIENT_SEARCH_RESET,
	PATIENT_SEARCH_SEARCH_PATIENTS
} from './PatientSearchActionTypes.js';

const DEFAULT_ROWS_PER_PAGE = 100;

export const searchPatients = createAction(PATIENT_SEARCH_SEARCH_PATIENTS,
	(criteria, rowsPerPage = DEFAULT_ROWS_PER_PAGE) => ({criteria: Immutable.fromJS(criteria), rowsPerPage})
);
export const editCriteria = createAction(PATIENT_SEARCH_EDIT_CRITERIA);
export const clearCriteria = createAction(PATIENT_SEARCH_CLEAR_CRITERIA);
export const reset = createAction(PATIENT_SEARCH_RESET);
export const reloadResults = createAction(PATIENT_SEARCH_RELOAD_RESULTS,
	rowsPerPage => rowsPerPage
);
export const loadNextResults = createAction(PATIENT_SEARCH_LOAD_NEXT_RESULTS,
	rowsPerPage => rowsPerPage
);
export const loadPreviousResults = createAction(PATIENT_SEARCH_LOAD_PREVIOUS_RESULTS,
	rowsPerPage => rowsPerPage
);
