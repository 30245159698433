import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import DocumentPermitContext from '../bricks/DocumentPermitContext.js';
import DocumentPermitClaimWorkflowProvider from './DocumentPermitClaimWorkflowProvider.js';
import SessionGuardedCanNotBeClaimedHandler from './SessionGuardedCanNotBeClaimedHandler.js';
import SessionGuardedDocumentPermitClaimForm from './SessionGuardedDocumentPermitClaimForm.js';

export default function DocumentPermitClaimController() {
	const canBeClaimed = useBrick(DocumentPermitContext, service => service.canBeClaimed());
	if (canBeClaimed) {
		return (
			<DocumentPermitClaimWorkflowProvider>
				<SessionGuardedDocumentPermitClaimForm />
			</DocumentPermitClaimWorkflowProvider>
		);
	}
	return <SessionGuardedCanNotBeClaimedHandler />;
}
