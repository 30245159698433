import React from 'react';
import PropTypes from 'prop-types';

import UploadController from '../../../webupload/bricks/UploadController.js';
import UploadFilesCollection from '../../../webupload/bricks/UploadFilesCollection.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import AppBar from '../../commons/components/AppBar.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Title from '../../material-design/components/Title.js';

export default function WebViewUploadAppBarBody(props) {
	const {button, children} = props;
	const controllerState = useBrick(UploadController, brick => brick.getState());
	const uploadHasFailed = useBrick(UploadFilesCollection, collection => collection.getFailedFilesCount() > 0);
	const subTitle = useMemoFactory(getSubTitle, UploadController, controllerState, uploadHasFailed);

	return (
		<AppBar>
			<AppBarPrimaryContent menuButton={button}>
				<Title subTitle={subTitle}>
					<SynFormattedMessage message='PageTitle' />
				</Title>
			</AppBarPrimaryContent>
			{children}
		</AppBar>
	);
}

function getSubTitle(controllerBrickType, controllerState, uploadHasFailed) {
	if (uploadHasFailed) {
		return <SynFormattedMessage message='Error' />;
	}
	switch (controllerState) {
		case controllerBrickType.State.SELECT_PRODUCER:
			return <SynFormattedMessage message='SelectProducer' />;
		case controllerBrickType.State.UPLOADING:
			return <SynFormattedMessage message='Uploading' />;
		case controllerBrickType.State.FINISHED:
			return <SynFormattedMessage message='UploadFinished' />;
		default:
			return <SynFormattedMessage message='PrepareUpload' />;
	}
}

WebViewUploadAppBarBody.propTypes = {
	button: PropTypes.node
};
