import React from 'react';
import PropTypes from 'prop-types';

import {DEVICE_SIZE_LARGE, DEVICE_SIZE_MEDIUM, DEVICE_SIZE_SMALL} from '../../commons/constants/DeviceInformation.js';
import AutoClosePersistentPortal from '../../commons/containers/AutoClosePersistentPortal.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext.js';
import SearchBody from '../../search/components/SearchBody.js';
import {SHARE_DOCUMENTS_DIALOG_ID_PATTERN} from '../../share-document/constants/ShareDocumentConstants.js';
import {
	LargeDocumentSearchDataTableContainer,
	MediumDocumentSearchDataTableContainer,
	SmallDocumentSearchDataTableContainer
} from '../flux/containers/DocumentSearchDataTableContainers.js';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';
import DocumentSearchForm from './DocumentSearchForm.js';

import '../../../styles/document-search/components/DocumentSearchBody.scss';
import '../../../styles/material-design/components/DataTable.scss';

const DOCUMENT_SEARCH_FORM_TITLE = <SynFormattedMessage message='SearchFormCaption' />;
const DATA_TABLE_COMPONENTS = {
	[DEVICE_SIZE_SMALL]: SmallDocumentSearchDataTableContainer,
	[DEVICE_SIZE_MEDIUM]: MediumDocumentSearchDataTableContainer,
	[DEVICE_SIZE_LARGE]: LargeDocumentSearchDataTableContainer
};

export default function DocumentSearchBody(props) {
	const {showResults, deviceSize} = props;
	const ResultsComponent = DATA_TABLE_COMPONENTS[deviceSize];
	return (
		<TranslationProvider translator={DocumentSearchPageMessagesTranslator}>
			<SearchBody title={DOCUMENT_SEARCH_FORM_TITLE}
			            resultsCardClassName='document-search-body--data-table'
			            {...props} className='document-search-body'>
				<DocumentSearchForm />
				<ResultsComponent showResults={showResults} deviceSize={deviceSize} />
			</SearchBody>
			{showResults &&
				<AutoClosePersistentPortal matching={SHARE_DOCUMENTS_DIALOG_ID_PATTERN} />
			}
		</TranslationProvider>
	);
}

DocumentSearchBody.propTypes = {
	showResults: PropTypes.bool,
	deviceSize: PropTypes.string
};
