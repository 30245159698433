import React from 'react';
import classnames from 'classnames';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import {DAYS_PER_WEEK} from './constants.js';
import Day from './Day.js';

export default function Week(props) {
	const {weekStart, selectionRange, disabled, onSelectDay, selectedDay} = props;
	const currentMonth = selectedDay.month();
	return (
		<tr>
			{
				Immutable.Range(0, DAYS_PER_WEEK)
					.map(dayInWeekIndex => {
						const currentDay = moment(weekStart)
							.add(dayInWeekIndex, 'days');
						const isCurrentMonth = currentDay.month() === currentMonth;
						const isOtherMonth = !isCurrentMonth;
						// NOTE:	The class current-month has no impact on CSS-Styling
						//					but is used in tests to click and select a specific day.
						//					DON'T REMOVE IT or fix the tests.
						const monthClassName = classnames({
							'current-month': isCurrentMonth,
							'other-month': isOtherMonth,
							selected: currentDay.isSame(selectedDay),
							'in-range': isInRange(currentDay, selectionRange)
						});
						return (
							<Day date={currentDay} key={DAYS_PER_WEEK + dayInWeekIndex}
								  disabled={disabled} onSelectDay={onSelectDay} className={monthClassName} />
						);
					})
					.toArray()
			}
		</tr>
	);
}
Week.propTypes = {
	weekStart: PropTypes.instanceOf(moment),
	selectionRange: PropTypes.instanceOf(Immutable.Map),
	disabled: PropTypes.bool,
	onSelectDay: PropTypes.func,
	selectedDay: PropTypes.instanceOf(moment)
};

function isInRange(particularMoment, selectionRange) {
	let inRange = selectionRange !== null;
	if (inRange) {
		const from = selectionRange.get('from');
		const to = selectionRange.get('to');
		inRange =
			(from === null || particularMoment.isSameOrAfter(from)) &&
			(to === null || particularMoment.isSameOrBefore(to));
	}
	return inRange;
}

