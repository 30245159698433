import React from 'react';

import BrickRegistryProvider from '../bricks/components/BrickRegistryProvider.js';
import {UPLOAD_PAGE} from '../commons/constants/PageNames.js';
import WebViewUploadAppBarController from '../upload/components/WebViewUploadAppBarController.js';
import WebViewUploadPageBody from '../upload/components/WebViewUploadPageBody.js';
import WebViewUploadPageContext from '../upload/components/WebViewUploadPageContext.js';
import createWebViewPage from './createWebViewPage.js';

export default React.memo(WebViewUploadPage);

const WebViewUploadPageImpl = createWebViewPage(WebViewUploadAppBarController, WebViewUploadPageBody, UPLOAD_PAGE);

function WebViewUploadPage(props) {
	return (
		<BrickRegistryProvider>
			<WebViewUploadPageContext>
				<WebViewUploadPageImpl {...props} />
			</WebViewUploadPageContext>
		</BrickRegistryProvider>
	);
}
