import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import PropertyItem from '../../commons/components/PropertyItem.js';
import {PatientInfoWithGenderAvatar} from '../../commons/components/webview/PatientInfo.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Button from '../../ui/components/Button.js';
import CardContent from '../../ui/components/Card/CardContent.js';
import Spacer from '../../ui/components/layout/Spacer.js';
import ResponsiveCard from '../../ui/components/ResponsiveCard.js';
import CreateOrderService from '../CreateOrderService.js';

const CARD_STYLE = {
	display: 'flex',
	flexDirection: 'column',
	overflowX: 'hidden',
	overflowY: 'auto'
};

export default function NewOrderReport(/*props*/) {
	const {createdOrder, reset, resetAll} = useBrick(CreateOrderService, selectCreateOrderServiceProps);
	return (
		<ResponsiveCard style={CARD_STYLE} centered maxCardWidth='650px' minCardWidth='550px' className='create-order-report'>
			<CardContent>
				<StatusMessage>
					<SynFormattedMessage message='CreateOrderSuccessMessageShort' />
				</StatusMessage>
				<Spacer />
				<CreatedOrderDetails createdOrder={createdOrder} />
				<Spacer minHeight='8px' />
				<Button flat opaque fullWidth onClick={resetAll}>
					<SynFormattedMessage message='FinishPhrase' />
				</Button>
				<Button flat fullWidth onClick={reset}>
					<SynFormattedMessage message='ConfirmStartOver' />
				</Button>
			</CardContent>
		</ResponsiveCard>
	);
}

function CreatedOrderDetails(props) {
	const {createdOrder} = props;
	const patient = createdOrder.get('patient');
	const serviceDescription = createdOrder.get('serviceDescription');
	const aet = createdOrder.get('aet');
	const aetInfo = `${aet.get('name')} - ${aet.get('description')}`;
	const orderer = createdOrder.get('orderer');
	const orderStartDate = createdOrder.get('orderStartDate');
	return (
		<React.Fragment>
			<PatientInfoWithGenderAvatar patient={patient} disableGenderAvatar />
			<PropertyItem name='OrderStartDate'>
				<SynFormattedMessage message={'ShortDateTime'} messageParams={{date: orderStartDate, time: orderStartDate}} />
			</PropertyItem>
			<PropertyItem name='AETModality'>
				{aetInfo}
			</PropertyItem>
			<PropertyItem name='AETService'>
				{serviceDescription}
			</PropertyItem>
			{orderer && (
				<PropertyItem name='Orderer'>
					{`${orderer.get('abk')} - ${orderer.get('name')}`}
				</PropertyItem>
			)}
		</React.Fragment>
	);
}

CreatedOrderDetails.propTypes = {
	createdOrder: immutableMapPropType.isRequired
};

function selectCreateOrderServiceProps(service) {
	return {
		createdOrder: service.getCreatedOrder(),
		reset: service.reset,
		resetAll: service.resetAll
	};
}
