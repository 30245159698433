import React from 'react';
import {connect} from 'react-redux';
import {batchActions} from 'redux-batched-actions';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import Stepper from '../../../material-design/components/Stepper.js';
import StepCreateDocumentShare from '../../components/StepCreateDocumentShare.js';
import StepSelectReceiver from '../../components/StepSelectReceiver.js';
import StepWriteMessage from '../../components/StepWriteMessage.js';
import {
	DATA_FIELD_SHARE_CREATED_SUCCESSFULLY,
	DATA_FIELD_SHARE_CREATION_ERROR
} from '../../constants/ShareDocumentConstants.js';
import ShareDocumentMessagesTranslator from '../../i18n/ShareDocumentTranslator.js';
import {createPrivateShare, nextStep, previousStep as previousStepAction, updateData as updateDataAction} from '../ShareDocumentDialogActions.js';
import {
	selectCurrentShareReceiver,
	selectCurrentStep,
	selectData,
	selectFooterState,
	selectHasFinished,
	selectIsStepComplete,
	selectNrSteps,
	selectReceiverFilterOrResourceFilterString,
	selectWasCancelled
} from '../ShareDocumentDialogSelectors.js';

const STEP_COMPONENTS = [
	StepSelectReceiver,
	StepWriteMessage,
	StepCreateDocumentShare
];

const STEP_TITLES = {
	0: <SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='StepSelectReceiver' />,
	1: <SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='StepEnterMessage' />,
	2: <SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='StepCreateShare' />
};

function createPreviousStepForShareError(previousStep, data, updateData) {
	return currentStep => batchActions([
		previousStep(currentStep),
		updateData(data
			.delete(DATA_FIELD_SHARE_CREATION_ERROR)
			.delete(DATA_FIELD_SHARE_CREATED_SUCCESSFULLY)
		)
	]);
}

function createPreviousStep(stateProps, dispatchProps) {
	const {currentStep, nrSteps, data} = stateProps;
	const {previousStep, updateData} = dispatchProps;
	let finalPreviousStep = previousStep;
	if (currentStep === (nrSteps - 1) && data.get(DATA_FIELD_SHARE_CREATION_ERROR) !== null) {
		finalPreviousStep = createPreviousStepForShareError(previousStep, data, updateData);
	}
	return finalPreviousStep;
}

const ShareDocumentStepperContainer = connect(
	createStructuredSelector({
		nrSteps: selectNrSteps,
		currentStep: selectCurrentStep,
		data: selectData,
		currentReceiver: selectCurrentShareReceiver,
		receiverFilter: selectReceiverFilterOrResourceFilterString,
		isStepComplete: selectIsStepComplete,
		hasFinished: selectHasFinished,
		wasCancelled: selectWasCancelled,
		footerState: selectFooterState
	}),
	bindActions({
		nextStep,
		previousStep: previousStepAction,
		updateData: updateDataAction,
		createPrivateShare
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {currentStep} = stateProps || {};
		return {
			...stateProps,
			...dispatchProps,
			...ownProps,
			title: STEP_TITLES[currentStep],
			StepComponent: STEP_COMPONENTS[currentStep],
			previousStep: createPreviousStep(stateProps, dispatchProps)
		};
	}
)(Stepper);

export default ShareDocumentStepperContainer;
