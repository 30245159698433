import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import AppBar from '../../commons/components/AppBar.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../../commons/components/webview/AppBarSidebarHeader.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Title from '../../material-design/components/Title.js';
import IconButton from '../../ui/components/IconButton.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import CreateOrderService from '../CreateOrderService.js';

const TRANSLATED_PAGE_TITLE = <SynFormattedMessage message='PageTitle' />;

export default function CreateOrderAppBar(props) {
	const {state, clearSelectedPatient} = useBrick(CreateOrderService, selectServiceProps);
	const {sidebarElevated} = props;
	const subTitle = useMemoFactory(subTitleSelector, state);
	let backButton = false;

	if (state === CreateOrderService.State.FILL_DETAILS) {
		backButton = (
			<IconButton onClick={clearSelectedPatient} color='inherit'>
				<BackIcon />
			</IconButton>
		);
	}

	return (
		<AppBar>
			<AppBarPrimaryContent menuButton={backButton}>
				<Title subTitle={subTitle}>
					{TRANSLATED_PAGE_TITLE}
				</Title>
			</AppBarPrimaryContent>
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

CreateOrderAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired
};

function selectServiceProps(service) {
	return {
		state: service.getState(),
		clearSelectedPatient: service.clearSelectedPatient
	};
}

const SUB_TITLES = {
	[CreateOrderService.State.BUSY]: <SynFormattedMessage message='Busy' />,
	[CreateOrderService.State.FINISHED]: <SynFormattedMessage message='CreateOrderFinished' />,
	[CreateOrderService.State.FILL_DETAILS]: <SynFormattedMessage message='CreateOrderDetails' />,
	[CreateOrderService.State.ERROR]: <SynFormattedMessage message='CreateOrderError' />
};

function subTitleSelector(state) {
	return SUB_TITLES[state] || SUB_TITLES[CreateOrderService.State.ERROR];
}
