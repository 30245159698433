import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

import {KEY_VALUE_RETURN} from '../../commons/constants/KeyValues.js';
import MaterialPickerFieldContainer from '../../commons/containers/MaterialPickerFieldContainer.js';
import ValidatorsMessagesTranslator from '../../commons/data/validators/i18n/ValidatorsMessagesTranslator.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {identity, noop} from '../../commons/utils/FunctionUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import {getDateFormatDisplayString, getDateFormatString} from '../../i18n/i18nUtils.js';
import assistiveDateHandler from '../assistiveDateHandler.js';
import MaterialDateRangePicker from './MaterialDateRangePicker.js';

class MaterialDateRangeField extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {value, locale} = this.props;

		this.boundOnPickerChange = this.onPickerChange.bind(this);
		this.boundOnChange = this.onChange.bind(this);
		this.boundOnKeyPress = this.onKeyPress.bind(this);
		this.boundSetFocused = this.setFocused.bind(this);
		this.boundSetBlurred = this.setBlurred.bind(this);
		this.boundShouldCloseOnNewDateRange = this.shouldCloseOnNewDateRange.bind(this);

		this.state = {
			isFocused: false,
			dateRangeFieldValue: getFieldValueFromDateRange(value, locale),
			selectValueFromInputText: createLocaleAwareSelector(locale),
			prevValue: value
		};
	}

	render() {
		const {value, dateRangeMode, name, label, hint, invalid, locale} = this.props;
		const {dateRangeFieldValue} = this.state;
		const containerProps = {
			name,
			label,
			hint,
			invalid,
			value: dateRangeFieldValue,
			placeholder: `${getDateFormatDisplayString(locale)} - ${getDateFormatDisplayString(locale)}`,
			onFocus: this.boundSetFocused,
			onBlur: this.boundSetBlurred,
			onChange: this.boundOnChange,
			onKeyPress: this.boundOnKeyPress,
			shouldCloseOnValueChange: this.boundShouldCloseOnNewDateRange
		};

		return (
			<MaterialPickerFieldContainer {...containerProps}>
				<MaterialDateRangePicker value={value} onChange={this.boundOnPickerChange}
				                         dateRangeMode={dateRangeMode} />
			</MaterialPickerFieldContainer>
		);
	}

	static getDerivedStateFromProps(props, state) {
		const {value, isDefault, locale} = props;
		const {prevValue, isFocused, selectValueFromInputText, dateRangeFieldValue} = state;
		const derivedState = {prevValue: value};
		if (prevValue !== value &&
			!isFocused &&
			(value !== selectValueFromInputText(dateRangeFieldValue) || isDefault)) {
			derivedState.dateRangeFieldValue = getFieldValueFromDateRange(value, locale);
		}
		return derivedState;
	}

	validate(dateRange, text) {
		let isValid;
		if (dateRange === null) {
			isValid = (!text || text.trim() === '') || this.getValidationMessage();
		} else {
			const moments = MaterialDateRangeField.dateRangeToMoments(dateRange);
			isValid = (
				moments.length > 0 &&
				moments.some(aMoment => moment.isMoment(aMoment)) &&
				moments.every(aMoment => (aMoment === null || aMoment.isValid()))
			);
		}
		return isValid || this.getValidationMessage();
	}

	getValidationMessage() {
		const {locale, label} = this.props;
		return ValidatorsMessagesTranslator.getFormattedMessage('InvalidDateFormat', locale, {
			inputFieldLabel: label,
			dateFormat: getDateFormatDisplayString(locale)
		});
	}

	static dateRangeToMoments(dateRange) {
		let moments = [];
		if (moment.isMoment(dateRange)) {
			moments = [dateRange];
		} else if (Immutable.Map.isMap(dateRange)) {
			moments = [dateRange.get('from'), dateRange.get('to')];
		}
		return moments;
	}

	onChange(newValue) {
		const {onChange, onValidationError} = this.props;
		const {selectValueFromInputText} = this.state;
		this.setState({dateRangeFieldValue: newValue});
		if (onChange) {
			const dateRange = selectValueFromInputText(newValue);
			const validation = this.validate(dateRange, newValue);
			if (validation === true) {
				onChange(dateRange);
			} else {
				onValidationError(validation, newValue);
			}
		}
	}

	setFocused() {
		this.setState({isFocused: true});
	}

	setBlurred() {
		this.#processAssistiveInput();
		this.setState({
			isFocused: false
		});
	}

	onKeyPress(event) {
		if (event.key === KEY_VALUE_RETURN) {
			this.#processAssistiveInput();
		}
	}

	#processAssistiveInput() {
		const {dateRangeFieldValue} = this.state;
		const {locale} = this.props;
		const detectedAssistiveInput = assistiveDateHandler(dateRangeFieldValue);
		if (detectedAssistiveInput) {
			this.onChange(getFieldValueFromDateRange(detectedAssistiveInput, locale));
		}
	}

	onPickerChange(newValue) {
		const {onChange, locale} = this.props;
		this.setState({
			dateRangeFieldValue: getFieldValueFromDateRange(newValue, locale)
		});
		onChange(newValue);
	}

	shouldCloseOnNewDateRange(oldDateRangeString, newDateRangeString) {
		const {selectValueFromInputText} = this.state;
		const newValue = selectValueFromInputText(newDateRangeString);
		const isDate = moment.isMoment(newValue);
		const isCompleteDate = Immutable.Map.isMap(newValue) && newValue.get('from') !== null && newValue.get('to') !== null;

		return (
			selectValueFromInputText(oldDateRangeString) !== selectValueFromInputText(newDateRangeString)
		) && (isDate || isCompleteDate);
	}
}

function convertStringToDateRange(dateRangeString, locale) {
	let value = null;
	if (dateRangeString) {
		let dateFormat = getDateFormatString(locale);
		dateFormat = dateFormat.replace(/DD/, 'D').replace(/MM/, 'M');
		const dateFormatRegex = dateFormat.replace(/[a-zA-Z]+/g, '[0-9]+');
		const exactDateRegex = new RegExp(`^\\s*(${dateFormatRegex})\\s*$`);
		const dateRangeRegex = new RegExp(`^(${dateFormatRegex})?\\s*-\\s*(${dateFormatRegex})?$`);

		const exactDateMatch = dateRangeString.match(exactDateRegex);
		const dateRangeMatch = dateRangeString.match(dateRangeRegex);

		if (exactDateMatch) {
			value = moment(exactDateMatch[1], dateFormat, true);
		} else if (dateRangeMatch) {
			value = Immutable.Map({
				from: (dateRangeMatch[1] && moment(dateRangeMatch[1], dateFormat, true)) || null,
				to: (dateRangeMatch[2] && moment(dateRangeMatch[2], dateFormat, true)) || null
			});
		}
	}
	return value;
}

function getFieldValueFromDateRange(value, locale) {
	let fieldValue = '';
	const dateFormat = getDateFormatString(locale);

	if (moment.isMoment(value)) {
		fieldValue = value.format(dateFormat);
	} else if (Immutable.Map.isMap(value)) {
		const from = value.get('from');
		const to = value.get('to');
		const fromString = (from && from.format(dateFormat)) || '';
		const toString = (to && to.format(dateFormat)) || '';
		fieldValue = `${fromString} - ${toString}`;
	}

	return fieldValue;
}

function createLocaleAwareSelector(locale) {
	return createSelector(
		identity, fieldValue => convertStringToDateRange(fieldValue, locale)
	);
}

MaterialDateRangeField.propTypes = {
	locale: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.instanceOf(moment), immutableMapPropType, PropTypes.string]),
	dateRangeMode: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	hint: PropTypes.string,
	invalid: PropTypes.bool,
	isDefault: PropTypes.bool,
	onChange: PropTypes.func,
	onValidationError: PropTypes.func
};

MaterialDateRangeField.defaultProps = {
	onValidationError: noop
};

export default wrapInLocalizationContext(MaterialDateRangeField);
