import {call, cancel, cancelled, fork, select, take} from 'redux-saga/effects';

import {debugLog} from '../../commons/utils/DebugLog.js';
import {RESTORE_FROM_HISTORY, UPDATE_LOCATION_STATE} from '../../router/constants/LocationActionTypes.js';
import {pathSelector} from '../../router/flux/selectors/LocationSelectors.js';

export default function createLocationTask(tasks) {
	return function* locationTask() {
		const paths = Object.keys(tasks);
		let currentActiveLocation = null;
		let currentTask = null;
		const newLocation = yield select(pathSelector);
		if (paths.includes(newLocation)) {
			currentTask = yield fork(tasks[newLocation]);
			debugLog(`Started location task: ${tasks[newLocation].name}`);
			currentActiveLocation = newLocation;
		}
		yield call(handleLocationChanges, tasks, currentTask, currentActiveLocation);
	};
}

function* handleLocationChanges(tasks, initialCurrentTask, initialCurrentActiveLocation) {
	const paths = Object.keys(tasks);
	let newLocation;
	let currentTask = initialCurrentTask;
	let currentActiveLocation = initialCurrentActiveLocation;
	while (!(yield cancelled())) {
		yield take([RESTORE_FROM_HISTORY, UPDATE_LOCATION_STATE]);
		newLocation = yield select(pathSelector);
		if (paths.includes(newLocation) && newLocation !== currentActiveLocation) {
			if (currentTask && currentActiveLocation) {
				yield cancel(currentTask);
				debugLog(`Stopped location task: ${tasks[currentActiveLocation].name}`);
			}
			currentTask = yield fork(tasks[newLocation]);
			debugLog(`Started location task: ${tasks[newLocation].name}`);
			currentActiveLocation = newLocation;
		} else if (!paths.includes(newLocation) && currentActiveLocation && currentTask) {
			yield cancel(currentTask);
			debugLog(`Stopped location task: ${tasks[currentActiveLocation].name}`);
			currentTask = null;
			currentActiveLocation = null;
		}
	}
}
