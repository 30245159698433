import search from '../../commons/api/operations/search.js';
import convertToDocumentClass from '../data/DocumentClass.js';

export const DOCUMENT_CLASS_URL = '/documentclass';

function processRawResults(rawResults) {
	const {results} = rawResults;
	rawResults.results = results.map(convertToDocumentClass);
	return rawResults;
}

export function searchDocumentClasses() {
	return search(DOCUMENT_CLASS_URL, undefined, processRawResults);
}
