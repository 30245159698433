import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import '../../../styles/document-search/components/DocumentSearchDataTableRow.scss';

export default class DocumentSearchDataTableRow extends React.PureComponent {
	render() {
		const {active, children} = this.props;
		const className = classnames({
			active,
			'document-search-table-row ': true
		});
		return (
			<tr className={className}>
				{children}
			</tr>
		);
	}
}

DocumentSearchDataTableRow.propTypes = {
	active: PropTypes.bool
};
