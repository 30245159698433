import createFormAccessors from '../commons/data/FormAccessorsFactory.js';
import {reset} from '../commons/data/onChangeHandlers.js';
import {required} from '../commons/data/validators';
import CreateOrderTranslator from './i18n/CreateOrderTranslator.js';

const FIELD_SERVICE_DESCRIPTION = 'serviceDescription';

const CREATE_ORDER_FORM_SCHEMA = {
	fields: {
		aetId: {
			label: 'AETModality',
			defaultValue: '',
			validators: [required],
			onChange: [reset(FIELD_SERVICE_DESCRIPTION, (value, newValue) => value !== newValue)]
		},
		[FIELD_SERVICE_DESCRIPTION]: {
			label: 'AETService',
			defaultValue: '',
			validators: [required]
		},
		orderer: {
			label: 'Orderer',
			defaultValue: ''
		}
	}
};

export default createFormAccessors(CREATE_ORDER_FORM_SCHEMA, CreateOrderTranslator);
