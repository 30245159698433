import search from '../../commons/api/operations/search.js';

const AETS_BASE_URL = '/order/aet';
const DEFAULT_SORT = [
	['name', 'ASC'],
	['description', 'ASC'],
	['id', 'ASC']
];

/**
 * Queries all aets available for order creation.
 * @returns {Promise<ResultList>} The results as a ResultList object.
 */
export function getAets() {
	return search(AETS_BASE_URL, {nocount: true, sortBy: DEFAULT_SORT});
}
