import React from 'react';

import PatientContext from '../../../webupload/bricks/PatientContext.js';
import UploadController from '../../../webupload/bricks/UploadController.js';
import BrickGuard from '../../bricks/components/BrickGuard.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import PatientSearchAppBar from '../../patient-search/components/PatientSearchAppBar';
import WebViewUploadAppBar from './WebViewUploadAppBar.js';

export default function WebViewUploadAppBarController(props) {
	const hasPatient = useBrick(PatientContext, context => Boolean(context.getPatient()));
	return hasPatient ? (
		<BrickGuard brick={UploadController}>
			<WebViewUploadAppBar {...props} />
		</BrickGuard>
	) : <PatientSearchAppBar {...props} />;
}
