import React from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import {DAYS_PER_WEEK, WEEK_DAY_OFFSET} from './constants.js';

export default React.memo(WeekHeader);

function WeekHeader(props) {
	const {locale} = props;
	return (
		<thead>
			<tr>
				{
					Immutable.Range(0, DAYS_PER_WEEK)
						.map(weekDayIndex => (
							<th key={weekDayIndex}>
								{moment()
									.day(weekDayIndex + WEEK_DAY_OFFSET)
									.locale(locale)
									.format('dd')}
							</th>
						))
						.toArray()
				}
			</tr>
		</thead>
	);
}
WeekHeader.propTypes = {
	locale: PropTypes.string
};
