import React from 'react';

import PatientInfo from '../../commons/components/webview/PatientInfo.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';

function searchResultPatientInfo(withBirthDate = true) {
	const birthDateSelector = withBirthDate ? result => (result.get('birth_date') || '-') : noop;
	function PatientInfoRenderer(props) {
		const {result, ...remainingProps} = props;
		const birthDate = birthDateSelector(result);
		const finalProps = cloneWithoutProperties(
			remainingProps, 'resultList', 'rowIndex', 'loadInProgress', 'startIndex', 'numberRows'
		);
		return (
			<PatientInfo className='patient-info' lastName={result.get('last_name')} firstName={result.get('first_name')}
			             sex={result.get('sex_fk') || result.get('sex')} birthDate={birthDate} {...finalProps} />
		);
	}

	PatientInfoRenderer.propTypes = {
		...PatientInfo.propTypes,
		result: immutableMapPropType.isRequired
	};

	return PatientInfoRenderer;
}

export default searchResultPatientInfo;
