import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import LockOpenIcon from '../../ui/components/icons/LockOpenIcon.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import LoadingButton from '../../ui/components/LoadingButton.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';
import ClaimErrorDisplay from './ClaimErrorDisplay.js';

export default function DocumentPermitClaimFormWithoutTan(props) {
	const {isBusy, onSubmit} = props;
	const disableSubmitButton = useDisabledState();
	return (
		<React.Fragment>
			<SynFormattedMessage element={VerticalLayout} message='ClaimWithoutTanMessage' />
			<LoadingButton startIcon={<LockOpenIcon />} disabled={disableSubmitButton} loading={isBusy}
			               loadingPosition='start' onClick={onSubmit} flat opaque>
				<SynFormattedMessage message='PerformClaim' />
			</LoadingButton>
			<ClaimErrorDisplay />
		</React.Fragment>
	);
}

DocumentPermitClaimFormWithoutTan.propTypes = {
	isBusy: PropTypes.bool,
	onSubmit: PropTypes.func
};

function useDisabledState() {
	const canClaim = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.canClaim());
	return !canClaim;
}
