import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '../../commons/components/AppBar.js';
import AppBarOverflowButton from '../../commons/components/AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../../commons/components/webview/AppBarSidebarHeader.js';
import {memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import AppBarSearchFilter from '../../material-design/components/AppBarSearchFilter.js';
import {buildInputName} from '../../material-design/components/form/InputFieldUtils.js';
import IconButton from '../../ui/components/IconButton.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import InboxMessageTranslator from '../i18n/InboxMessagesTranslator.js';

const INBOX_TITLE = <SynFormattedMessage message='InboxTitle' translator={InboxMessageTranslator} />;

class InboxAppBar extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundNavigateToInbox = this.navigateToInbox.bind(this);
		this.boundNavigateToInboxSearch = this.navigateToInboxSearch.bind(this);
		this.getFilterLabel = memoizeByFirstArg(
			locale => InboxMessageTranslator.getFormattedMessage('InboxFilterLabel', locale)
		);
	}

	render() {
		const {sidebarElevated, locale, filterString, filterInbox} = this.props;
		const filterLabel = this.getFilterLabel(locale);
		const appBarProps = {};
		const searchActive = filterString !== undefined;

		if (searchActive) {
			appBarProps.menuButton = <IconButton color='inherit' icon={<BackIcon />} onClick={this.boundNavigateToInbox} />;
		} else if (!sidebarElevated) {
			appBarProps.menuButton = false;
		}

		appBarProps.title = searchActive ? false : INBOX_TITLE;
		return (
			<AppBar>
				<AppBarPrimaryContent {...appBarProps}>
					<AppBarSearchFilter placeholder={filterLabel} onDeactivate={this.boundNavigateToInbox}
					                    onActivate={this.boundNavigateToInboxSearch} onChange={filterInbox}
					                    value={filterString} active={searchActive}
					                    name={buildInputName('inbox-search-filter')} />
				</AppBarPrimaryContent>
				<AppBarOverflowButton visible={sidebarElevated} />
				<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
			</AppBar>
		);
	}

	navigateToInbox() {
		const {clearInboxFilter} = this.props;
		clearInboxFilter();
	}

	navigateToInboxSearch() {
		const {filterInbox} = this.props;
		filterInbox('');
	}
}

InboxAppBar.propTypes = {
	sidebarElevated: PropTypes.bool,
	clearInboxFilter: PropTypes.func.isRequired,
	filterInbox: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	filterString: PropTypes.string
};

InboxAppBar.defaultProps = {
	filterString: undefined
};

export default wrapInLocalizationContext(InboxAppBar);
