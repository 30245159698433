import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import ArchiveDocumentsDialog from '../../components/ArchiveDocumentsDialog.js';
import {deleteStepperState, updateData} from '../ArchiveDocumentsDialogActions.js';
import {
	selectData,
	selectHasFinished,
	selectUploadState,
	selectWasCancelled
} from '../selectors/ArchiveDocumentsDialogSelectors.js';

const ArchiveDocumentsDialogContainer = connect(
	createStructuredSelector({
		hasFinished: selectHasFinished,
		wasCancelled: selectWasCancelled,
		uploadState: selectUploadState,
		data: selectData
	}),
	bindActions({
		deleteStepperState,
		updateData
	})
)(ArchiveDocumentsDialog);

export default ArchiveDocumentsDialogContainer;
