import React, {useCallback, useRef} from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {inputBaseClasses} from '@mui/material/InputBase';

import BarcodeScannerButtonContainer from '../../../commons/containers/BarcodeScannerButtonContainer.js';
import {useMemoFactory} from '../../../commons/utils/customHooks';
import {getHocDisplayName} from '../../../commons/utils/ReactUtils.js';
import DefaultAutoCompleteTextField from './DefaultAutoCompleteTextField.js';

const MUI_ICON_PADDING = 4;
const MUI_SMALL_ICON_WIDTH = 26;
const MUI_ICON_POSITION_RIGHT = 8;

export default function withBarcodeScannerButton(
		Component, handleScanResult, metricsKey, usageContext, initialShowBarcodeScanner, onCancel
) {
	function AutoCompleteTextFieldWithBarcodeScannerButton(props) {
		const {params: {InputProps}, setInputValue, onChange, label} = props;
		const inputRef = useRef();
		const handleBarcodeDetected = useCallback(newValue => {
			window.requestAnimationFrame(() => {
				const validScanResult = handleScanResult(newValue);
				if (validScanResult) {
					onChange({}, validScanResult);
					if (inputRef.current) {
						inputRef.current.blur();
					}
				} else {
					setInputValue(newValue);
				}
			});
		}, [onChange, setInputValue]);
		const extendedInputProps = useMemoFactory(
			createExtendedInputProps, InputProps, handleBarcodeDetected, metricsKey, usageContext,
			initialShowBarcodeScanner, onCancel, label
		);
		return (
			<Component {...props} ref={inputRef} InputProps={extendedInputProps} />
		);
	}
	AutoCompleteTextFieldWithBarcodeScannerButton.propTypes = DefaultAutoCompleteTextField.propTypes;
	AutoCompleteTextFieldWithBarcodeScannerButton.displayName = getHocDisplayName(Component, 'withBarcodeScannerButton');
	return AutoCompleteTextFieldWithBarcodeScannerButton;
}

function createExtendedInputProps(
		InputProps, handleBarcodeDetected, metricsKey, usageContext, initialShowBarcodeScanner, onCancel, label
) {
	const endAdornment = InputProps.endAdornment || (
		<InputAdornment position='end' style={{position: 'absolute', right: '9px'}} />
	);
	const baseButtons = React.Children.toArray(endAdornment.props.children);
	const finalButtonsCount = baseButtons.length + 1;
	const className = `${inputBaseClasses.root}-hasBarcodeIcon`;
	const extendedInputProps = {
		...InputProps,
		className
	};
	extendedInputProps.endAdornment = React.cloneElement(endAdornment, {}, [
		...baseButtons,
		<BarcodeScannerButtonContainer sx={{p: '2px'}} key='barcodeScannerButton' onChange={handleBarcodeDetected}
		                               metricsKey={metricsKey} usageContext={usageContext} small targetName={label}
		                               showInitial={initialShowBarcodeScanner} onCancel={onCancel} />
	]);
	extendedInputProps.sx = {
		[`&${`.${className}`.repeat(finalButtonsCount)}`]: {
			pr: `${finalButtonsCount * MUI_SMALL_ICON_WIDTH + MUI_ICON_PADDING + MUI_ICON_POSITION_RIGHT}px`
		}
	};
	return extendedInputProps;
}
