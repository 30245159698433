import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import Button from '../../../ui/components/Button.js';
import CardActions from '../../../ui/components/Card/CardActions.js';
import Spacer from '../../../ui/components/layout/Spacer.js';
import List from '../../../ui/components/List';
import ResponsiveCard from '../../../ui/components/ResponsiveCard.js';
import {STATUS_FAILED} from '../../constants/StatusMessageConstants.js';
import StatusMessage from '../webview/StatusMessage.js';
import ErrorCardListItem from './ErrorCardListItem.js';

export default React.memo(ErrorCard);

const CARD_STYLE = {
	display: 'flex',
	flexDirection: 'column',
	overflowX: 'hidden',
	overflowY: 'auto'
};

function ErrorCard(props) {
	const {statusMessage, errors, onErrorConfirmed} = props;
	return (
		<ResponsiveCard style={CARD_STYLE} centered maxCardWidth='300px'>
			<Spacer />
			<StatusMessage status={STATUS_FAILED}>
				<SynFormattedMessage message={statusMessage} />
			</StatusMessage>
			<List>
				{errors.map(error => <ErrorCardListItem key={error} error={error} />)}
			</List>
			<Spacer />
			<CardActions>
				<Button fullWidth opaque onClick={onErrorConfirmed}>
					<SynFormattedMessage message='ConfirmError' />
				</Button>
			</CardActions>
		</ResponsiveCard>
	);
}

ErrorCard.propTypes = {
	statusMessage: PropTypes.string.isRequired,
	errors: PropTypes.arrayOf(ErrorCardListItem.propTypes.error).isRequired,
	onErrorConfirmed: PropTypes.func.isRequired
};
