import {createAction} from 'redux-actions';
import {cancelled, fork, put, select, take} from 'redux-saga/effects';

import {createErroneousAction} from '../../commons/utils/ActionUtils.js';
import {identity} from '../../commons/utils/FunctionUtils.js';
import {
	PATIENT_SEARCH_FETCH_RESULTS,
	PATIENT_SEARCH_LOAD_NEXT_RESULTS,
	PATIENT_SEARCH_LOAD_PREVIOUS_RESULTS,
	PATIENT_SEARCH_PROCESS_RESULTS,
	PATIENT_SEARCH_RELOAD_RESULTS,
	PATIENT_SEARCH_SEARCH_PATIENTS
} from './PatientSearchActionTypes.js';
import {
	selectLoadSequenceNumberForPatientSearch,
	selectPatientSearchCriteria,
	selectPatientSearchResults
} from './PatientSearchSelectors.js';

const fetchResults = createAction(PATIENT_SEARCH_FETCH_RESULTS);
const processResults = createAction(PATIENT_SEARCH_PROCESS_RESULTS,
	(loadSequenceNumber, results = null) => ({results, loadSequenceNumber})
);

export default function* patientSearchSaga(selectPatientSearchState, searchPatientsFetch) {
	function* selectPatientSearchDetail(detailSelector) {
		return yield* selectFromPatientState(selectPatientSearchState, detailSelector);
	}
	while (!(yield cancelled())) {
		const currentAction = yield take([
			PATIENT_SEARCH_SEARCH_PATIENTS,
			PATIENT_SEARCH_RELOAD_RESULTS,
			PATIENT_SEARCH_LOAD_NEXT_RESULTS,
			PATIENT_SEARCH_LOAD_PREVIOUS_RESULTS
		]);

		const {type, payload} = currentAction;
		switch (type) {
			case PATIENT_SEARCH_SEARCH_PATIENTS: {
				yield fork(handleSearchPatients, selectPatientSearchDetail, searchPatientsFetch, payload);
				break;
			}
			case PATIENT_SEARCH_RELOAD_RESULTS: {
				yield fork(handleReloadResults, selectPatientSearchDetail, searchPatientsFetch, payload);
				break;
			}
			case PATIENT_SEARCH_LOAD_PREVIOUS_RESULTS: {
				yield fork(handleLoadPreviousResults, selectPatientSearchDetail, searchPatientsFetch, payload);
				break;
			}
			case PATIENT_SEARCH_LOAD_NEXT_RESULTS: {
				yield fork(handleLoadNextResults, selectPatientSearchDetail, searchPatientsFetch, payload);
				break;
			}
			default:
				// NOTE: (Empty) default case enforced by eslint-rule!
				break;
		}
	}
}

function* handleSearchPatients(selectPatientSearchDetail, searchPatientsFetch, searchPatientPayload) {
	const {criteria, rowsPerPage} = searchPatientPayload;
	const thisSequenceNumber = yield* incrementLoadSequenceNumber(selectPatientSearchDetail);
	yield* doLoadResults(
		selectPatientSearchDetail, searchPatientsFetch, thisSequenceNumber, 0, rowsPerPage, criteria
	);
}

function* handleReloadResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage) {
	yield* loadAscendingResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage,
		result => {
			const localSize = result.getLocalSize();
			const totalSize = result.getTotalSize();
			const wantsLess = rowsPerPage < localSize;
			const wantsMore = rowsPerPage > localSize;
			const hasMore = totalSize > localSize;
			return wantsLess || wantsMore && hasMore;
		},
		identity
	);
}

function* handleLoadNextResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage) {
	yield* loadAscendingResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage,
		result => result.canLoadNextResults(),
		(proposedStartIndex, currentResults) => {
			const loadStartIndex = proposedStartIndex + rowsPerPage;
			return loadStartIndex < currentResults.getTotalSize() ? loadStartIndex : proposedStartIndex;
		}
	);
}

function* handleLoadPreviousResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage) {
	yield* loadAscendingResults(selectPatientSearchDetail, searchPatientsFetch, rowsPerPage,
		result => result.canLoadPreviousResults(),
		proposedStartIndex => Math.max(proposedStartIndex - rowsPerPage, 0)
	);
}

function* loadAscendingResults(
		selectPatientSearchDetail, searchPatientFetch,
		rowsPerPage, resultCondition, nextStartIndexSupplier) {
	if (rowsPerPage > 0) {
		const currentResults = yield* selectPatientSearchDetail(selectPatientSearchResults);
		if (currentResults) {
			const thisSequenceNumber = yield* incrementLoadSequenceNumber(selectPatientSearchDetail);
			if (resultCondition(currentResults)) {
				let loadStartIndex = currentResults.getRawDataStart();
				if (currentResults.getLocalSize() !== rowsPerPage) {
					loadStartIndex = getIndexedStartIndex(currentResults, rowsPerPage);
				}
				loadStartIndex = nextStartIndexSupplier(loadStartIndex, currentResults);
				yield* doLoadResults(
					selectPatientSearchDetail, searchPatientFetch, thisSequenceNumber, loadStartIndex, rowsPerPage
				);
			}
		} else {
			const thisSequenceNumber = yield* incrementLoadSequenceNumber(selectPatientSearchDetail);
			yield* doLoadResults(selectPatientSearchDetail, searchPatientFetch, thisSequenceNumber, 0, rowsPerPage);
		}
	}
}

function* incrementLoadSequenceNumber(selectPatientSearchDetail) {
	const loadSequenceNumber = yield* selectPatientSearchDetail(selectLoadSequenceNumberForPatientSearch);
	const thisSequenceNumber = loadSequenceNumber + 1;
	yield put(fetchResults(thisSequenceNumber));
	return thisSequenceNumber;
}

function* doLoadResults(selectPatientSearchDetail, searchPatientsFetch, sequenceNumber, from, batchSize, criteria) {
	let loadCriteria = criteria;
	if (loadCriteria === undefined) {
		loadCriteria = yield* selectPatientSearchDetail(selectPatientSearchCriteria);
	}
	try {
		const sortOrder = undefined;
		const results = yield searchPatientsFetch(loadCriteria.toObject(), sortOrder, from, batchSize);
		yield put(processResults(sequenceNumber, results));
	} catch (loadError) {
		yield put(createErroneousAction(PATIENT_SEARCH_PROCESS_RESULTS, loadError));
	}
}

function* selectFromPatientState(selectPatientSearchState, detailsSelector) {
	const patientSearchState = yield select(selectPatientSearchState);
	return detailsSelector(patientSearchState);
}

function getIndexedStartIndex(results, batchSize) {
	return getBatchIndex(results, batchSize) * batchSize;
}

function getBatchIndex(results, batchSize) {
	let index = 0;
	if (results) {
		index = Math.round(results.getRawDataStart() / batchSize);
	}
	return index;
}
