import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {required} from '../../../commons/data/validators';
import DocumentPermitClaimTranslator from '../../i18n/DocumentPermitClaimTranslator.js';

export const DOCUMENT_PERMIT_CLAIM_FORM_SCHEMA = {
	fields: {
		tan: {
			label: 'PermitAccessCodeTitle',
			defaultValue: null,
			validators: [required]
		}
	}
};

export default createFormAccessors(DOCUMENT_PERMIT_CLAIM_FORM_SCHEMA, DocumentPermitClaimTranslator);
