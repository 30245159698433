import PatientContextMonitor from '../../../webupload/bricks/PatientContextMonitor.js';
import UploadController from '../../../webupload/bricks/UploadController.js';
import UploadFilesCollection from '../../../webupload/bricks/UploadFilesCollection.js';
import {UploadContextBuilder} from '../../archive-documents/api/UploadContextBuilder.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import WebViewProducerList from './WebViewProducerList.js';
import WebViewUploadContext from './WebViewUploadContext.js';
import WebViewUploadPatientContext from './WebViewUploadPatientContext.js';

export default class WebViewUploadController extends UploadController {
	constructor(
		webViewUploadPatientContext,
		webViewUploadContext, uploadFilesCollection, patientContextMonitor, producerList,
		scheduleUploadTask, uploadFileApiCall
	) {
		super(webViewUploadContext, uploadFilesCollection, patientContextMonitor, scheduleUploadTask);
		this.uploadFileCall = uploadFileApiCall;
		this.producerList = producerList;
		const memoizedProducerChanged = memoizeLast(this.onProducerChanged);
		this.subscribeTo(
			webViewUploadContext,
			uploadContext => memoizedProducerChanged(uploadContext.getProducerAbk())
		);
		const memoizedPatientIdChanged = memoizeLast(this.onPatientIdChanged);
		this.subscribeTo(
			webViewUploadPatientContext,
			patientContext => memoizedPatientIdChanged(patientContext.getPatientAIMId())
		);
	}

	onProducerChanged(producerAbk) {
		this.updateBrickState(oldState => ({
			...oldState,
			producerAbk
		}));
	}

	onPatientIdChanged(newPid) {
		this.updateBrickState(oldState => ({
			...oldState,
			patientId: newPid
		}));
	}

	uploadFile(fileName, file, uploadStartedWhen) {
		const contextBuilder = new UploadContextBuilder()
			.setPatientAimId(this.#getPatientAIMId())
			.setProducerAbk(this.#getProducer())
			.setGenericDocumentCreatedWhen(uploadStartedWhen);
		return this.uploadFileCall(file, contextBuilder.build());
	}

	getState() {
		const superState = super.getState();
		if (superState === UploadController.State.SELECT_PRODUCER) {
			return this.#getProducer() && this.#getPatientAIMId()
				? UploadController.State.READY
				: UploadController.State.PREPARING;
		}
		return superState;
	}

	#getProducer() {
		return this.getBrickState().producerAbk;
	}

	#getPatientAIMId() {
		return this.getBrickState().patientId;
	}
	
	setStateSelectProducer() {
		const {getSingleProducer} = this.producerList;
		if (getSingleProducer()) {
			return this.startUpload();
		}
		return this.updateBrickState(oldState => ({
			...oldState,
			state: oldState.state === WebViewUploadController.State.READY 
				? WebViewUploadController.State.SELECT_PRODUCER
				: oldState.state
		}));
	}

	setStateReady() {
		return this.updateBrickState(oldState => ({
			...oldState,
			state: oldState.state === WebViewUploadController.State.SELECT_PRODUCER 
				? WebViewUploadController.State.READY 
				: oldState.state
		}));
	}
}

WebViewUploadController.State = {
	...UploadController.State,
	SELECT_PRODUCER: Symbol('SelectProducer')
};

declareBrick(
	WebViewUploadController,
	[
		WebViewUploadPatientContext, WebViewUploadContext, UploadFilesCollection, PatientContextMonitor,
		WebViewProducerList
	]
);
