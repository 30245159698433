import React from 'react';
import PropTypes from 'prop-types';

import FullScreenDialogAnchorContainer from '../../commons/containers/FullScreenDialogAnchorContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {ClickTrackedIconButton, ClickTrackedMenuEntry} from '../../metrics-collector/components/TrackedComponents.js';
import {CameraPlusIcon, CameraPlusOutlineIcon} from '../../ui/components/icons/CameraPlusIcon.js';
import {
	ARCHIVE_DOCUMENTS_DIALOG_ID,
	ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT
} from '../constants/ArchiveDocumentsConstants.js';
import ArchiveDocumentsDialogContainer from '../flux/containers/ArchiveDocumentsDialogContainer.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

const MENU_ICON = <CameraPlusIcon />;
const MENU_TEXT = <SynFormattedMessage message='CapturePhotosMenuEntry' translator={ArchiveDocumentsTranslator} />;
const METRICS_KEY = 'OpenArchiveDialog';

export default class ArchiveDialogButton extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.dialogController = null;
		this.boundSetDialogController = this.setDialogController.bind(this);
		this.boundOpenDialog = this.openDialog.bind(this);
		this.dialogElement = (
			<FullScreenDialogAnchorContainer id={ARCHIVE_DOCUMENTS_DIALOG_ID}
			                                 dialogControllerRef={this.boundSetDialogController}>
				<ArchiveDocumentsDialogContainer />
			</FullScreenDialogAnchorContainer>
		);
	}

	render() {
		const {asMenu, ...remainingProps} = this.props;
		return asMenu ? (
			<React.Fragment>
				<ClickTrackedMenuEntry metricsKey={METRICS_KEY} usageContext={ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT}
				                       icon={MENU_ICON} primaryText={MENU_TEXT} onClick={this.boundOpenDialog}
				                       id='doArchiveImagesEntry' {...remainingProps} />
				{this.dialogElement}
			</React.Fragment>
		) : (
			<ClickTrackedIconButton metricsKey={METRICS_KEY} usageContext={ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT}
			                        id='appBarArchiveDialogButton' color='inherit' onClick={this.boundOpenDialog}>
				<CameraPlusOutlineIcon />
				{this.dialogElement}
			</ClickTrackedIconButton>
		);
	}

	setDialogController(dialogController) {
		this.dialogController = dialogController;
	}

	openDialog() {
		if (this.dialogController) {
			this.dialogController.openDialog();
		}
	}
}

ArchiveDialogButton.propTypes = {
	asMenu: PropTypes.bool
};

ArchiveDialogButton.defaultProps = {
	asMenu: false
};
