import {createStructuredSelector} from 'reselect';

import {getWindowParams} from '../../utils/ImageViewerUtils.js';
import {selectSingleViewerProperty} from './ViewerSelectors.js';

function createSelectCurrentWindow(viewerId) {
	return createStructuredSelector({
		windowCenter: selectSingleViewerProperty(viewerId, 'windowCenter'),
		windowWidth: selectSingleViewerProperty(viewerId, 'windowWidth')
	});
}

export function createSelectWindowProperties(viewerId) {
	const selectCurrentWindow = createSelectCurrentWindow(viewerId);
	return function selectWindowProperties(state, decodedImage = null) {
		return getWindowParams(selectCurrentWindow(state), decodedImage);
	};
}

export function createSelectShowAnnotations(viewerId) {
	return selectSingleViewerProperty(viewerId, 'showAnnotations', true);
}

