/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * IMPORTANT:
 *     Keep the set of known metrics in this file in sync with the set of allowed metrics
 *     in WebViewCoreSC.java
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * @author p.spitzlinger@synedra.com
 */

import {
	ANNOTATION_METRIC_KEYS, EXPORT_METRIC_KEYS,
	IMAGE_VIEWER_TOOLS_METRIC_KEYS, INFO_METRIC_KEYS, LAYOUT_METRIC_KEYS,
	PATIENT_DETAILS_METRIC_KEYS,
	PHOTO_UPLOAD_METRIC_KEYS, 	SHOW_HELP_METRIC_KEY,
	VIEWER_TOOLS_METRIC_KEYS, WINDOW_PRESET_METRIC_KEYS
} from './commons/constants/MetricKeys.js';
import AllowedMetricsSet from './metrics-collector/utils/AllowedMetricsSet.js';

const CREATE_ORDER_PATIENT_SEARCH_METRIC_KEYS = [
	'CreateOrderPatientSearch.PatientIDVisitIDBarcodeScannerDetectionSuccessful',
	'CreateOrderPatientSearch.PatientIDVisitIDBarcodeScannerOpen',
	'CreateOrderPatientSearch.ExpandExtendedPatientSearchFields',
	'CreateOrderPatientSearch.CollapseExtendedPatientSearchFields',
	'CreateOrderPatientSearch.EmergencyPatient'
];

const SHARE_METRIC_KEYS = [
	'share.MultipleSharesDone',
	'share.MultipleSharesUndone',
	'share.ShareDone',
	'share.ShareUndone',
	'share.CreateShareExistingReceiver',
	'share.CreateShareNewReceiver'
];

const DOCUMENT_SEARCH_METRIC_KEYS = [
	'DocumentSearch.PatientIDBarcodeScannerDetectionSuccessful',
	'DocumentSearch.PatientIDBarcodeScannerOpen'
];

const CREATE_ORDER_METRIC_KEYS = [
	'CreateOrder.AETSelectField-BarcodeScannerDetectionSuccessful',
	'CreateOrder.AETSelectField-BarcodeScannerOpen'
];

const EXPORT_PRINT_METRIC_KEY = 'export.Print';

const ALLOWED_METRICS = [
	SHOW_HELP_METRIC_KEY,
	EXPORT_PRINT_METRIC_KEY,
	...SHARE_METRIC_KEYS,
	...EXPORT_METRIC_KEYS,
	...PATIENT_DETAILS_METRIC_KEYS,
	...VIEWER_TOOLS_METRIC_KEYS,
	...IMAGE_VIEWER_TOOLS_METRIC_KEYS,
	...LAYOUT_METRIC_KEYS,
	...ANNOTATION_METRIC_KEYS,
	...WINDOW_PRESET_METRIC_KEYS,
	...DOCUMENT_SEARCH_METRIC_KEYS,
	...CREATE_ORDER_PATIENT_SEARCH_METRIC_KEYS,
	...CREATE_ORDER_METRIC_KEYS,
	...PHOTO_UPLOAD_METRIC_KEYS,
	...INFO_METRIC_KEYS
];

export default new AllowedMetricsSet(ALLOWED_METRICS);
