import React from 'react';
import {useStore} from 'react-redux';
import {createSelector} from 'reselect';

import {attachSession} from '../../authentication/flux/actions/SessionActions.js';
import AutoBrickFactory from '../../bricks/components/AutoBrickFactory.js';
import BrickGuard from '../../bricks/components/BrickGuard.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {withSelectors} from '../../commons/utils/FunctionUtils.js';
import {querySelector} from '../../router/flux/selectors/LocationSelectors.js';
import {tryToClaimPermit} from '../api/documentPermitApi.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow.js';
import DocumentPermitClaimFormAccessors from './accessors/DocumentPermitClaimFormAccessors.js';
import DocumentPermitRegisterUserAccessors from './accessors/DocumentPermitRegisterUserAccessor.js';

export default function DocumentPermitClaimWorkflowProvider(props) {
	const {children} = props;
	const reduxStore = useStore();
	const createWorkflowBrick = useMemoFactory(createWorkflowFactory, reduxStore);
	return (
		<AutoBrickFactory brickType={DocumentPermitClaimWorkflow} brickFactory={createWorkflowBrick}>
			<BrickGuard brick={DocumentPermitClaimWorkflow}>
				{children}
			</BrickGuard>
		</AutoBrickFactory>
	);
}

function createWorkflowFactory(reduxStore) {
	return (documentPermitContext, ...autoDependencies) => {
		const claimWorkflow =
			new DocumentPermitClaimWorkflow(tryToClaimPermit, documentPermitContext, ...autoDependencies);
		connectWorkflowToStore(claimWorkflow, reduxStore);
		reactToClaimResult(claimWorkflow, documentPermitContext, reduxStore);
		return claimWorkflow;
	};
}

function connectWorkflowToStore(claimWorkflow, reduxStore) {
	connectWithQuery(claimWorkflow, reduxStore);
	connectWithTanForm(claimWorkflow, reduxStore);
	connectWithRegistrationForm(claimWorkflow, reduxStore);
}

function connectWithQuery(claimWorkflow, reduxStore) {
	const connection = createSelector(
		querySelector,
		query => {
			const isTanRequiredQueryValue = query.get('isTanRequired');
			const noTanRequired = isTanRequiredQueryValue === 'false' || isTanRequiredQueryValue === 0;
			const token = query.get('claimToken');

			claimWorkflow.updateBrickState(oldState => {
				claimWorkflow.setIsTanRequired(!noTanRequired);
				claimWorkflow.setToken(token);
				return oldState;
			});
		}
	);
	claimWorkflow.subscribeTo(reduxStore, () => connection(reduxStore.getState()));
}

function connectWithTanForm(claimWorkflow, reduxStore) {
	const selectValidTan = createSelector(
		DocumentPermitClaimFormAccessors.isValid,
		state => DocumentPermitClaimFormAccessors.getFormFieldValue(state, 'tan'),
		(formIsValid, tan) => (formIsValid ? tan : null)
	);
	const connection = createSelector(
		selectValidTan,
		tan => {
			if (tan === null) {
				claimWorkflow.clearTan();
			} else {
				claimWorkflow.setTan(tan);
			}
		}
	);
	claimWorkflow.subscribeTo(reduxStore, () => connection(reduxStore.getState()));
}

function connectWithRegistrationForm(claimWorkflow, reduxStore) {
	const selectValidRegistrationInfo = createSelector(
		DocumentPermitRegisterUserAccessors.isValid,
		DocumentPermitRegisterUserAccessors.getAllMappedFormFieldValues,
		(formIsValid, formData) => (formIsValid ? formData : null)
	);
	const connection = createSelector(
		selectValidRegistrationInfo,
		registrationInfo => {
			if (registrationInfo) {
				const {
					firstName: firstname, lastName: lastname, eMail: email, newPassword: passwd, mobileNumber: sms
				} = registrationInfo;
				const newUserInfo = {firstname, lastname, email, sms, passwd};
				claimWorkflow.setNewUserInfo(newUserInfo);
			} else {
				claimWorkflow.clearNewUserInfo();
			}
		}
	);
	claimWorkflow.subscribeTo(reduxStore, () => connection(reduxStore.getState()));
}

function reactToClaimResult(claimWorkflow, documentPermitContext, reduxStore) {
	const boundAttachSession = () => reduxStore.dispatch(attachSession());
	claimWorkflow.subscribeTo(claimWorkflow, withSelectors(
		workflow => workflow.getLastClaimResult(),
		workflow => workflow.isNewUserRequired() && workflow.hasNewUserInfo(),
		(claimResult, withRegistration) => {
			if (claimResult === DocumentPermitClaimWorkflow.State.CLAIM_SUCCEEDED) {
				if (withRegistration) {
					// NOTE: A session transition to logged in state triggers a reload of the context.
					boundAttachSession();
				} else {
					documentPermitContext.reloadPermit();
				}
			}
		}
	));
}
