import ALLOWED_METRICS from '../AllowedMetrics.js';
import passwordStateReducer from '../authentication/flux/reducers/PasswordStateReducer.js';
import webViewSessionReducer from '../authentication/flux/reducers/webViewSessionReducer.js';
import basicApplicationFlux from '../commons/flux/BasicApplicationFlux.js';
import aimLinkReducer from '../commons/reducers/AIMLinkReducer.js';
import crudResourcesReducer from '../commons/reducers/crudresources/CrudResourcesReducer.js';
import selectedItemsReducer from '../commons/reducers/SelectedItemsReducer.js';
import structuredDataReducer from '../commons/reducers/StructuredDataReducer.js';
import inboxReducer from '../inbox/flux/InboxReducer.js';
import patientDetailsReducer from '../patient-details/flux/PatientDetailsReducer.js';
import patientSearchReducer from '../patient-search/flux/patientSearchReducer.js';
import searchReducer from '../search/flux/SearchReducer.js';
import UIReducer from '../ui/flux/UIReducer.js';
import annotationReducer from '../viewer/flux/reducers/AnnotationReducer.js';
import viewerItemsReducer from '../viewer/flux/reducers/ViewerItemsReducer.js';
import viewersReducer from '../viewer/flux/reducers/ViewerReducer.js';
import viewerSyncToolsReducer from '../viewer/synchronization/flux/viewerSyncReducer.js';
import WebViewRoutes from '../WebViewRoutes.js';
import webViewSaga from './webViewSaga.js';

export default function createWebViewFlux() {
	return basicApplicationFlux(WebViewRoutes, {
		ui: UIReducer,
		structuredData: structuredDataReducer,
		session: webViewSessionReducer,
		inbox: inboxReducer,
		patientDetails: patientDetailsReducer,
		viewerItems: viewerItemsReducer,
		documentSearch: searchReducer,
		passwordState: passwordStateReducer,
		crudResources: crudResourcesReducer,
		annotations: annotationReducer,
		aimLinks: aimLinkReducer,
		viewers: viewersReducer,
		viewerSyncTools: viewerSyncToolsReducer,
		selectedItems: selectedItemsReducer,
		patientSearch: patientSearchReducer
	}, webViewSaga, ALLOWED_METRICS);
}

