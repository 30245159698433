import React, {useState} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import {DEVICE_SIZE_LARGE, DEVICE_SIZE_MEDIUM, DEVICE_SIZE_SMALL} from '../../commons/constants/DeviceInformation.js';
import {useEffectEasily, useMemoFactory} from '../../commons/utils/customHooks';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';
import {MAX_PATIENT_SEARCH_RESULTS_LIMIT} from '../PatientSearchConstants.js';
import {
	LargePatientSearchDataTable,
	MediumPatientSearchDataTable,
	SmallPatientSearchDataTable
} from './PatientSearchResultsTables.js';

export default React.memo(PatientSearchResults);

const PATIENT_SEARCH_DATA_TABLES = {
	[DEVICE_SIZE_SMALL]: SmallPatientSearchDataTable,
	[DEVICE_SIZE_MEDIUM]: MediumPatientSearchDataTable,
	[DEVICE_SIZE_LARGE]: LargePatientSearchDataTable
};

function PatientSearchResults(props) {
	const {onPatientClick, deviceSize} = props;
	const {
		results, editCriteria, searchCriteria, reloadResults, loadNextResults, loadPreviousResults,
		getPatientSearchServiceState
	} = useBrick(AbstractPatientSearchService, selectPatientServiceProps);
	const [rowCount, setRowCount] = useState(0);
	const trimmedResults = useMemoFactory(trimToRowCount, results, rowCount);
	const nrResults = trimmedResults ? trimmedResults.getTotalSize() : undefined;
	const startIndex = trimmedResults ? trimmedResults.getRawDataStart() : 0;
	const lastDisplayedResult = startIndex + (trimmedResults ? trimmedResults.getLocalSize() : 0);
	const onLoadNextBatch = useMemoFactory(
		createLoadBatch,
		trimmedResults && trimmedResults.canLoadNextResults(), loadNextResults, rowCount
	);
	const onLoadPreviousBatch = useMemoFactory(
		createLoadBatch,
		trimmedResults && trimmedResults.canLoadPreviousResults(), loadPreviousResults, rowCount
	);
	useEffectEasily(
		reloadOnRowsPerPageChange, reloadResults, trimmedResults !== null, rowCount, getPatientSearchServiceState
	);
	const DataTableComponent = PATIENT_SEARCH_DATA_TABLES[deviceSize];
	return (
		<DataTableComponent resultList={trimmedResults} startIndex={startIndex} to={lastDisplayedResult}
		                    nrResults={nrResults} maxLimit={MAX_PATIENT_SEARCH_RESULTS_LIMIT}
		                    onClickPrev={onLoadPreviousBatch} onClickNext={onLoadNextBatch}
		                    onRowCountChange={setRowCount} rowsPerPage={rowCount}
		                    additionalProps={{deviceSize, editCriteria, searchCriteria, onPatientClick}} />
	);
}

PatientSearchResults.propTypes = {
	onPatientClick: PropTypes.func,
	deviceSize: PropTypes.string
};

function selectPatientServiceProps(service) {
	return {
		results: service.getResults(),
		searchCriteria: service.getCriteria(),
		reloadResults: service.reloadResults,
		loadNextResults: service.loadNextResults,
		loadPreviousResults: service.loadPreviousResults,
		editCriteria: service.editCriteria,
		getPatientSearchServiceState: service.getState
	};
}

function createLoadBatch(canLoad, loadBatch, rowCount) {
	return canLoad ? () => loadBatch(rowCount) : undefined;
}

function trimToRowCount(results, rowCount) {
	let trimmedResults = null;
	if (results) {
		trimmedResults = results.slice(0, rowCount);
	}
	return trimmedResults;
}

function reloadOnRowsPerPageChange(reloadResults, hasResults, rowsPerPage, getPatientSearchServiceState) {
	const isShowingResults = getPatientSearchServiceState() === AbstractPatientSearchService.State.SHOW_RESULTS;
	if (hasResults && isShowingResults) {
		reloadResults(rowsPerPage);
	}
}
