import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import PasswordFormField from '../../authentication/components/PasswordFormField';
import useBrick from '../../bricks/hooks/useBrick';
import PhoneField from '../../commons/components/PhoneField';
import SubtextLink from '../../commons/components/SubtextLink';
import FormContainer from '../../commons/containers/FormContainer';
import {useAction} from '../../commons/flux/ReactHooks';
import {useUniqueId} from '../../commons/utils/customHooks/index.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage';
import Column from '../../material-design/components/Column';
import EMailFormField from '../../material-design/components/form/EMailFormField';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn';
import Row from '../../material-design/components/Row';
import Divider from '../../ui/components/Divider';
import LockOpenIcon from '../../ui/components/icons/LockOpenIcon.js';
import Spacer from '../../ui/components/layout/Spacer';
import VerticalLayout from '../../ui/components/layout/VerticalLayout';
import LoadingButton from '../../ui/components/LoadingButton.js';
import DocumentPermitClaimWorkflow from '../bricks/DocumentPermitClaimWorkflow';
import {useIsTanRequired} from '../hooks/useIsTanRequired';
import DocumentPermitClaimFormAccessors from './accessors/DocumentPermitClaimFormAccessors';
import DocumentPermitRegisterUserAccessor from './accessors/DocumentPermitRegisterUserAccessor';
import ClaimErrorDisplay from './ClaimErrorDisplay.js';
import {TanFormContent} from './DocumentPermitClaimFormWithTan';

export function DocumentPermitRegisterUserForm() {
	const formId = useUniqueId('form');
	const isTanRequired = useIsTanRequired();
	const onSubmit = useOnSubmit();
	const isBusy = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.isBusy());
	const requireNewUser = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.requireNewUser);
	const goToLogin = useCallback(() => requireNewUser(false), [requireNewUser]);

	return (
		<React.Fragment>
			<VerticalLayout element={FormContainer} key='user-form' id={formId} className='document-permit-register-user'
								 description={DocumentPermitRegisterUserAccessor} onSubmit={onSubmit} submitIfInvalid>
				<Row>
					<RegistrationColumn>
						<SingleLineFormField fieldName='firstName' name='FirstName' autoFocus />
					</RegistrationColumn>
					<RegistrationColumn>
						<SingleLineFormField fieldName='lastName' name='LastName' />
					</RegistrationColumn>
					<RegistrationColumn>
						<EMailFormField fieldName='eMail' name='EMail' />
					</RegistrationColumn>
					<RegistrationColumn>
						<SingleLineFormField component={PhoneField} forceFloating fieldName='mobileNumber' name='mobileNumber' />
					</RegistrationColumn>
				</Row>
				<Row>
					<RegistrationColumn>
						<PasswordFormField fieldName='newPassword' name='Password' />
					</RegistrationColumn>
					<RegistrationColumn>
						<PasswordFormField fieldName='newPasswordConfirmation' name='PasswordConfirmation' style={{alignItems: 'start'}} />
					</RegistrationColumn>
				</Row>
			</VerticalLayout>

			{isTanRequired && <RegistrationTanContent key='tan-form' onSubmit={onSubmit} />}

			<RegistrationButtons formId={formId} onSubmit={onSubmit} isBusy={isBusy} goToLogin={goToLogin} />
			<Spacer minHeight='8px' />
			<ClaimErrorDisplay align='center' />
		</React.Fragment>
	);
}

function RegistrationTanContent(props) {
	const {onSubmit} = props;
	return (
		<React.Fragment>
			<RegistrationDivider />
			<Row>
				<Column width={{xs: 4, sm: 8}}>
					<TanFormContent onSubmit={onSubmit} submitIfInvalid autoFocusTan={false} />
				</Column>
			</Row>
		</React.Fragment>
	);
}

RegistrationTanContent.propTypes = {
	onSubmit: PropTypes.func
};

function RegistrationDivider() {
	return (
		<React.Fragment>
			<Spacer minHeight='20px' />
			<Divider />
			<Spacer minHeight='20px' />
		</React.Fragment>
	);
}

function RegistrationColumn(props) {
	const {children} = props;
	const styleAlignOverride = {alignItems: 'start'};
	return (
		<Column width={{xs: 4, sm: 8, lg: 6}} style={styleAlignOverride}>
			{children}
		</Column>);
}

function useOnSubmit() {
	const canClaim = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.canClaim());
	const claimDocument = useBrick(DocumentPermitClaimWorkflow, workflow => workflow.claim);
	const forceUserRegistrationFormValidation = useAction(DocumentPermitRegisterUserAccessor.setFormValidationForced);
	const forceTanFormValidation = useAction(DocumentPermitClaimFormAccessors.setFormValidationForced);
	return useCallback(() => {
		if (canClaim) {
			claimDocument();
		} else {
			forceUserRegistrationFormValidation();
			forceTanFormValidation();
		}
	}, [canClaim, claimDocument, forceUserRegistrationFormValidation, forceTanFormValidation]);
}

function RegistrationButtons(props) {
	const {goToLogin, isBusy, formId} = props;
	return (
		<React.Fragment>
			<LoadingButton form={formId} type='submit' startIcon={<LockOpenIcon />} loading={isBusy} fullWidth flat opaque>
				<SynFormattedMessage message='ClaimAndRegisterButtonLabel' />
			</LoadingButton>
			<FullWidthColumn justifyContent='center'>
				<SubtextLink onClick={goToLogin}>
					<SynFormattedMessage message='BackToLogin' />
				</SubtextLink>
			</FullWidthColumn>
		</React.Fragment>
	);
}

RegistrationButtons.propTypes = {
	formId: PropTypes.string,
	goToLogin: PropTypes.func,
	isBusy: PropTypes.bool
};
