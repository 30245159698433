import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {ASC, DESC} from '../../commons/constants/SortOrders.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import useTranslator from '../../i18n/hooks/useTranslator.js';
import SuggestComboBox from '../../ui/components/SuggestComboBox.js';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';
import documentSearchSortDefinitions from './DocumentSearchSortDefinitions.js';

const DOCUMENT_SEARCH_SORT_COMBO_ENTRIES = createSortEntries(documentSearchSortDefinitions);

function createSortEntries(sortDefinitions) {
	return sortDefinitions.reduce((currentDefinitions, sortProperties, labelMessage) => {
		currentDefinitions.push(createSortEntry(labelMessage, sortProperties, ASC));
		currentDefinitions.push(createSortEntry(labelMessage, sortProperties, DESC));
		return currentDefinitions;
	}, []);
}

function createSortEntry(labelMessage, sortProperties, sortOrder) {
	return {
		label: labelMessage + ((sortOrder === ASC) ? 'Asc' : 'Desc'),
		value: sortProperties.map(propertyName => Immutable.List([propertyName, sortOrder]))
	};
}

export default function DocumentSearchSortComboBox(props) {
	const {value, className, onChange, ...remainingProps} = props;
	const getFormattedMessage = useTranslator(DocumentSearchPageMessagesTranslator);
	const options = useMemoFactory(getOptions, getFormattedMessage);
	const selectedOption = options.find(entry => Immutable.is(entry.value, value));
	const title = useTranslation('SortOrder');
	const onChangeHandler = useMemoFactory(createOnChangeHandler, onChange);

	delete remainingProps.onChange;

	return (
		<SuggestComboBox options={options} title={title} value={selectedOption} onChange={onChangeHandler} small
		                 className={combineClassNames('document-search-sort-combo', className)}
		                 getOptionLabel={getOptionLabel} {...remainingProps} noMargin name='documentSearchSortCombo' />
	);
}

function getOptionLabel(option) {
	return option.label;
}

function createOnChangeHandler(onChange) {
	return selectedEntry => {
		onChange(selectedEntry?.value);
	};
}

function getOptions(getFormattedMessage) {
	return DOCUMENT_SEARCH_SORT_COMBO_ENTRIES.map(entry => ({
		label: getFormattedMessage(entry.label),
		value: entry.value
	}));
}

DocumentSearchSortComboBox.propTypes = {
	value: immutableListPropType,
	className: PropTypes.string,
	onChange: PropTypes.func
};
