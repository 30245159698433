import React, {useCallback, useState} from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {MaterialFormSuggestComboBox} from '../../material-design/components/form/MaterialFormComboBox.js';
import {ClickTrackedIconButton} from '../../metrics-collector/components/TrackedComponents.js';
import DefaultAutoCompleteTextField from '../../ui/components/AutoCompleteTextField/DefaultAutoCompleteTextField.js';
import withBarcodeScannerButton from '../../ui/components/AutoCompleteTextField/withBarCodeScannerButton.js';
import BarcodeScanIcon from '../../ui/components/icons/BarcodeScanIcon.js';
import {CREATE_ORDER_METRICS_USAGE_CONTEXT} from '../CreateOrderConstants.js';

const METRICS_KEY = 'AETSelectField-BarcodeScanner';

export default function AETComboFormField(props) {
	const {aetList, ...remainingProps} = props;
	const onlyOneAETListEntry = aetList.size <= 1;
	const [initialShowBarcodeScanner, setInitialShowBarcodeScanner] = useState(false);
	const options = useMemoFactory(toOptionsMap, aetList);
	const activateInitialBarcodeScanner = useCallback(
		() => setInitialShowBarcodeScanner(true), [setInitialShowBarcodeScanner]
	);
	const deactivateInitialBarcodeScanner = useCallback(
		() => setInitialShowBarcodeScanner(false), [setInitialShowBarcodeScanner]
	);
	const onBarcodeScanned = useMemoFactory(createOnBarcodeScanned, aetList, deactivateInitialBarcodeScanner);
	const inputTextField = useMemoFactory(
		withBarcodeScannerButton, DefaultAutoCompleteTextField, onBarcodeScanned, METRICS_KEY,
		CREATE_ORDER_METRICS_USAGE_CONTEXT, initialShowBarcodeScanner, deactivateInitialBarcodeScanner
	);
	const fullScreenTextFieldButtons = useMemoFactory(createFullScreenTextFieldButtons, activateInitialBarcodeScanner);
	return (
		<MaterialFormSuggestComboBox options={options} {...remainingProps} getValue={getValue}
		                             getOptionLabel={getOptionLabel} getSelectedValue={getSelectedValue}
		                             inputTextField={onlyOneAETListEntry ? undefined : inputTextField}
		                             fullScreenTextFieldButtons={!onlyOneAETListEntry && fullScreenTextFieldButtons} />
	);
}

AETComboFormField.propTypes = {
	aetList: immutableListPropType,
	fieldName: PropTypes.string
};

function createFullScreenTextFieldButtons(activateInitialBarcodeScanner) {
	return (
		<ClickTrackedIconButton onClick={activateInitialBarcodeScanner} icon={<BarcodeScanIcon />}
		                        usageContext={CREATE_ORDER_METRICS_USAGE_CONTEXT} metricsKey={`${METRICS_KEY}Open`} />
	);
}


function createOnBarcodeScanned(aetList, deactivateInitialBarcodeScanner) {
	return scannedValue => {
		deactivateInitialBarcodeScanner();
		const result = aetList.find(aetEntry => aetEntry.get('name') === scannedValue);
		if (Immutable.Map.isMap(result)) {
			return {aetId: result.get('id')};
		}
		return result;
	};
}

function getSelectedValue(newValue) {
	if (newValue === null) {
		return newValue;
	}
	return newValue.aetId;
}

function getOptionLabel(option) {
	return option.aetText;
}

function getValue(value, options) {
	const selectedOption = options.find(aet => aet.aetId === value);
	return typeof selectedOption === 'undefined' ? null : selectedOption;
}

function toOptionsMap(aetList) {
	let options = [];
	if (aetList) {
		options = aetList.reduce((aetOptionsMap, aetEntry) => {
			const aetId = aetEntry.get('id');
			const aetName = aetEntry.get('name');
			const aetDescription = aetEntry.get('description');
			aetOptionsMap.push({
				aetId,
				aetText: `${aetName} - ${aetDescription}`
			});
			return aetOptionsMap;
		}, []);
	}
	return options;
}
