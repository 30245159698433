import React from 'react';
import {useStore} from 'react-redux';
import {createSelector} from 'reselect';

import {selectIsLoggedIn} from '../../authentication/flux/selectors/SessionSelectors.js';
import BrickProvider from '../../bricks/components/BrickProvider.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {getCurrentRouteVariables} from '../../router/flux/selectors/RoutingSelectors.js';
import {getPermitStatus} from '../api/documentPermitApi.js';
import DocumentPermitContext from '../bricks/DocumentPermitContext.js';

export default function DocumentPermitContextProvider() {
	const documentPermitContext = useDocumentPermitContext();
	return <BrickProvider brick={documentPermitContext} />;
}

function useDocumentPermitContext() {
	const reduxStore = useStore();
	return useMemoFactory(createDocumentPermitContext, reduxStore);
}

function createDocumentPermitContext(reduxStore) {
	const documentPermitContext = new DocumentPermitContext(getPermitStatus);
	connectToStore(documentPermitContext, reduxStore);
	return documentPermitContext;
}

function connectToStore(documentPermitContext, reduxStore) {
	const connection = createSelector(
		getCurrentRouteVariables,
		selectIsLoggedIn,
		(routeVariables, isLoggedIn) => {
			const permitId = routeVariables.get('permitId') || null;
			documentPermitContext.loadPermit(permitId, isLoggedIn);
		}
	);
	documentPermitContext.subscribeTo(
		reduxStore,
		() => connection(reduxStore.getState())
	);
}
