import React from 'react';
import PropTypes from 'prop-types';

import {HTMLFileObjectShape} from '../../commons/utils/CustomPropTypes.js';
import ArchiveImagePreview from './ArchiveImagePreview.js';
import DocumentsBrowserTile from './DocumentsBrowserGridTile.js';

export default function UploadPhotoTile(props) {
	const {file, onToggleSelection, isSelected} = props;
	return (
		<DocumentsBrowserTile>
			<ArchiveImagePreview isSelected={isSelected} file={file} toggleSelection={onToggleSelection} />
		</DocumentsBrowserTile>
	);
}

UploadPhotoTile.propTypes = {
	file: HTMLFileObjectShape.isRequired,
	isSelected: PropTypes.bool,
	onToggleSelection: PropTypes.func
};
