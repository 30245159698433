import React, {useState} from 'react';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import SuggestComboBox from '../../../ui/components/SuggestComboBox.js';
import {THE_YEAR_1900} from './constants.js';

export function getValidYearOptions(today = moment()) {
	return Immutable.Range(THE_YEAR_1900, today.year() + 2).toJS();
}

export default function YearComboBox(props) {
	const {date, disabled, onBlur, onChange, name} = props;
	const onYearChange = useMemoFactory(createOnYearChange, onChange, date);
	const [validYearOptions] = useState(getValidYearOptions());
	const value = validYearOptions.find(yearEntry => yearEntry === date.year());
	return (
		<SuggestComboBox options={validYearOptions} small disableClearable noFullWidth noMargin value={value}
		                 onChange={onYearChange} className='material-date-picker--year-box' disabled={disabled}
		                 onBlur={onBlur} getOptionLabel={getOptionLabel} name={name} />
	);
}
YearComboBox.propTypes = {
	date: PropTypes.instanceOf(moment),
	disabled: PropTypes.bool,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	name: PropTypes.string.isRequired
};

function getOptionLabel(option) {
	return String(option.toString());
}

function createOnYearChange(onChange, date) {
	return function onYearChange(newYear) {
		onChange(moment(date).year(newYear));
	};
}
