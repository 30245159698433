import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {setSortParams} from '../../../search/flux/SearchActions.js';
import DocumentSearchSortCombo from '../../components/DocumentSearchSortCombo.js';
import {documentSearchSortParams} from '../DocumentSearchSelectors.js';

export default connect(createStructuredSelector({
	value: documentSearchSortParams
}), bindActions({
	onChange: setSortParams
}))(DocumentSearchSortCombo);

