import React from 'react';
import {useStore} from 'react-redux';

import AutoBrickFactory from '../../bricks/components/AutoBrickFactory.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import ClearedPatientSearchPageGuard from '../../components/ClearedPatientSearchPageGuard.js';
import {selectPatientSearch} from '../../create-order/flux/CreateOrderSelectors.js';
import ReduxPatientSearchService from '../flux/ReduxPatientSearchService.js';

/**
 * Needed for error free rendering of pages, which depend on ReduxPatientSearchService.
 * The order of
 * <AutoBrickFactory .. />
 * <ClearedPatientSearchPageGuard>
 * is crucial as ClearedPatientSearchPageGuard depends on ReduxPatientSearchService.
 *
 * @author p.spietzlinger@synedra.com
 * @author m.perkhofer@synedra.com
 *
 */
export default function CleanPatientSearchServiceProvider(props) {
	const {children} = props;
	const reduxStore = useStore();
	const createPatientSearchService = useMemoFactory(createPatientSearchServiceFactory, reduxStore);
	return (
		<React.Fragment>
			<AutoBrickFactory brickType={ReduxPatientSearchService} brickFactory={createPatientSearchService} />
			<ClearedPatientSearchPageGuard>
				{children}
			</ClearedPatientSearchPageGuard>
		</React.Fragment>
	);
}

function createPatientSearchServiceFactory(reduxStore) {
	return () => new ReduxPatientSearchService(reduxStore, selectPatientSearch);
}
