import React from 'react';

import useBrick from '../../bricks/hooks/useBrick.js';
import ErrorCard from '../../commons/components/errors/ErrorCard.js';
import CreateOrderService from '../CreateOrderService.js';

const errorMessages = {
	[CreateOrderService.Error.FETCH_AET_LIST_FAILED]: 'Error_AetListFetchFailed',
	[CreateOrderService.Error.AET_LIST_EMPTY]: 'Error_AetListEmpty',
	[CreateOrderService.Error.FETCH_ORDERER_LIST_FAILED]: 'Error_OrdererListFetchFailed',
	[CreateOrderService.Error.ORDERER_LIST_EMPTY]: 'Error_OrdererListEmpty',
	[CreateOrderService.Error.CREATE_ORDER_FAILED]: 'Error_CreatingOrderFailed'
};

const errorHints = {
	[CreateOrderService.Error.AET_LIST_EMPTY]: 'ErrorHint_AetListEmpty'
};

export default React.memo(CreateOrderErrorDisplay);

function CreateOrderErrorDisplay(/*props*/) {
	const {errors, reset} = useBrick(CreateOrderService, selectCreateOrderServiceProps);
	const errorsList = errors.map(error => ({
		message: errorMessages[error],
		hintMessage: errorHints[error]
	})).toArray();

	return <ErrorCard statusMessage='CreateOrderErrorShort' onErrorConfirmed={reset} errors={errorsList} />;
}


function selectCreateOrderServiceProps(service) {
	return {
		errors: service.getErrors(),
		reset: service.reset
	};
}
