import React from 'react';

import PatientContext from '../../../webupload/bricks/PatientContext.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import {deviceSizePropType} from '../../commons/constants/DeviceInformation.js';
import {useEffectOnUnmount} from '../../commons/utils/customHooks';
import PatientSearchController from '../../patient-search/components/PatientSearchController.js';
import ReduxPatientSearchService from '../../patient-search/flux/ReduxPatientSearchService';
import ProducerList from '../bricks/ProducerList';
import WebViewUploadPatientContext from '../bricks/WebViewUploadPatientContext';
import WebViewUploadWizardContext from './WebViewUploadWizardContext.js';

import '../../../styles/upload/UploadPageBody.scss';

export default function WebViewUploadPageBody(props) {
	const {deviceSize} = props;
	const {hasPatient, loadPatient} = useBrick(PatientContext, selectPatientContextProps);
	const clearWebViewUploadPatientContext = useBrick(WebViewUploadPatientContext, service => service.clearPatient);
	const resetReduxPatientSearchService = useBrick(ReduxPatientSearchService, service => service.reset);
	const resetProducerList = useBrick(ProducerList, service => service.reset);
	
	useEffectOnUnmount(resetProducerList);
	useEffectOnUnmount(resetReduxPatientSearchService);
	useEffectOnUnmount(clearWebViewUploadPatientContext);
	
	if (hasPatient) {
		return <WebViewUploadWizardContext />;
	}
	return (<PatientSearchController initiallyExpandedSearchFields
				deviceSize={deviceSize} onPatientClick={loadPatient} />);
}
WebViewUploadPageBody.propTypes = {
	deviceSize: deviceSizePropType
};

function selectPatientContextProps(patContext) {
	return {
		loadPatient: patContext.loadPatient,
		hasPatient: Boolean(patContext.getPatient())
	};
}
