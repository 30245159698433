import React from 'react';

import BrickBase from '../bricks/BrickBase.js';
import {declareBrick} from '../bricks/brickTools.js';
import NotImplementedError from '../commons/errors/NotImplementedError.js';
import {debugLog} from '../commons/utils/DebugLog.js';

export default class AbstractPatientSearchService extends BrickBase {
	constructor() {
		super();
		if (Object.getPrototypeOf(this).constructor === AbstractPatientSearchService) {
			throw new Error('Abstract class cannot be instanced!');
		}
	}

	reset() {
		debugLog(this);
		throw new NotImplementedError();
	}

	clearCriteria() {
		debugLog(this);
		throw new NotImplementedError();
	}

	searchPatients(/*criteria, rowsPerPage*/) {
		debugLog(this);
		throw new NotImplementedError();
	}

	reloadResults(/*rowsPerPage*/) {
		debugLog(this);
		throw new NotImplementedError();
	}

	loadPreviousResults(/*rowsPerPage*/) {
		debugLog(this);
		throw new NotImplementedError();
	}

	loadNextResults(/*rowsPerPage*/) {
		debugLog(this);
		throw new NotImplementedError();
	}

	editCriteria() {
		debugLog(this);
		throw new NotImplementedError();
	}

	getCriteria() {
		debugLog(this);
		throw new NotImplementedError();
	}

	getResults() {
		debugLog(this);
		throw new NotImplementedError();
	}

	hasResults() {
		debugLog(this);
		throw new NotImplementedError();
	}

	getState() {
		debugLog(this);
		throw new NotImplementedError();
	}

	getErrorType() {
		debugLog(this);
		throw new NotImplementedError();
	}

	assertState(expectedState) {
		if (this.getState() !== expectedState) {
			throw new Error(`State ${this.getState().description} !== ${expectedState.description}`);
		}
	}

	isCleared() {
		return this.getState() === AbstractPatientSearchService.State.EDIT_CRITERIA &&
			this.getCriteria() === null &&
			!this.hasResults();
	}
}
AbstractPatientSearchService.State = {
	EDIT_CRITERIA: Symbol('EDIT_CRITERIA'),
	SHOW_RESULTS: Symbol('SHOW_RESULTS'),
	ERROR: Symbol('ERROR')
};
AbstractPatientSearchService.Error = {
	FETCH_FAILED: Symbol('FETCH_FAILED')
};
declareBrick(AbstractPatientSearchService);
