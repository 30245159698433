import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import useBrick from '../../bricks/hooks/useBrick.js';
import {PatientInfoWithGenderAvatar} from '../../commons/components/webview/PatientInfo.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Column from '../../material-design/components/Column.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn.js';
import Row from '../../material-design/components/Row.js';
import Spacer from '../../ui/components/layout/Spacer.js';
import ResponsiveCard from '../../ui/components/ResponsiveCard.js';
import {uiIsExtraSmallDevice} from '../../ui/flux/UISelectors.js';
import CreateOrderService from '../CreateOrderService.js';
import OrderDetailsFromAccessors from '../OrderDetailsFormAccessors.js';
import AETComboFormField from './AETComboFormField.js';
import AETServiceField from './AETServiceField.js';
import OrdererField from './OrdererField.js';

function OrderDetailsForm(props) {
	const {deviceExtraSmall} = props;
	const {
		aetList, aetId, selectAetById, aetServicesList, createOrder, ordererList, patient
	} = useBrick(CreateOrderService, selectCreateOrderServiceProps);
	const combinedSearchCriteria = useMemoFactory(buildCombinedInitialCriteria, aetId);
	const onSubmit = useMemoFactory(createOnSubmit, createOrder);
	return (
		<ResponsiveCard style={{overflowX: 'hidden', overflowY: 'auto'}} className='create-order-details'>
			<Row>
				<FullWidthColumn>
					<PatientInfoWithGenderAvatar patient={patient} disableGenderAvatar />
				</FullWidthColumn>
			</Row>
			<FormContainer onSubmit={onSubmit} initialValues={combinedSearchCriteria} className='create-order-form'
					               description={OrderDetailsFromAccessors}>
				<Row>
					<Column width={{xs: 4, sm: 4}}>
						<AETComboFormField fieldName='aetId' aetList={aetList} onChange={selectAetById} />
					</Column>
					<Column width={{xs: 4, sm: 4}}>
						<AETServiceField fieldName='serviceDescription' aetId={aetId} aetServices={aetServicesList} />
					</Column>
				</Row>
				<Row>
					<FullWidthColumn>
						<OrdererField ordererList={ordererList} />
					</FullWidthColumn>
				</Row>
				<Spacer minHeight='8px' />
				<Row>
					<FullWidthColumn justifyContent='right'>
						<MaterialFormButton type='submit' flat opaque fullWidth={deviceExtraSmall}>
							<SynFormattedMessage message='CreateOrderButton' />
						</MaterialFormButton>
					</FullWidthColumn>
				</Row>
				<Spacer minHeight='8px' />
			</FormContainer>
		</ResponsiveCard>
	);
}

OrderDetailsForm.propTypes = {
	deviceExtraSmall: PropTypes.bool.isRequired
};

function createOnSubmit(createOrder) {
	return function onSubmit(criteria) {
		const {orderer, serviceDescription} = criteria;
		const ordererAbk = orderer && orderer.abk || undefined;
		createOrder(serviceDescription, ordererAbk);
	};
}

function buildCombinedInitialCriteria(aetId) {
	const combinedCriteria = Immutable.Map();
	return combinedCriteria.set('aetId', aetId ? aetId : '');
}

function selectCreateOrderServiceProps(service) {
	return {
		aetList: service.getAetList(),
		aetId: service.getSelectedAetId(),
		selectAetById: service.selectAetById,
		aetServicesList: service.getServicesForSelectedAet(),
		createOrder: service.createOrder,
		ordererList: service.getOrdererList(),
		patient: service.getSelectedPatient()
	};
}

export default connect(createStructuredSelector({
	deviceExtraSmall: uiIsExtraSmallDevice
}))(OrderDetailsForm);
