import React from 'react';
import Immutable from 'immutable';

import Status from '../../a11y/components/landmarks/Status.js';
import createSimplePureRenderComponent from '../../commons/components/createSimplePureRenderComponent.js';
import NoResults from '../../commons/components/data/NoResults.js';
import {
	birthDateProperty,
	column as simpleColumn,
	dateTimeProperty,
	multiPropertyColumn,
	resultProperty
} from '../../commons/components/data/Table.js';
import DocumentDownloadButton from '../../commons/components/webview/DocumentDownloadButton.js';
import SelectDocumentCheckbox from '../../commons/components/webview/SelectDocumentCheckbox.js';
import {ASC, DESC} from '../../commons/constants/SortOrders.js';
import {FEATURE_DOCUMENT_SHARING_ENABLED} from '../../commons/constants/SynSettingsConstants.js';
import createSortLinkContainer from '../../commons/containers/data/createSortLinkContainer.js';
import DocumentSearchResultRowContainer from '../../commons/containers/webview/DocumentSearchResultRowContainer.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {tr} from '../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import createDataTable from '../../material-design/components/createDataTable.js';
import createMaterialTable from '../../material-design/components/createMaterialTable.js';
import {SortAscIcon, SortDescIcon} from '../../material-design/components/icons/SortIcons.js';
import CardHeader from '../../material-design/components/layout/CardHeader.js';
import Title from '../../material-design/components/Title.js';
import searchResultPatientInfo from '../../search/components/SearchResultPatientInfo.js';
import SearchStatus from '../../search/components/SearchStatus.js';
import {SEARCH_SORT_PARAMS} from '../../search/constants/SearchPropertyNames.js';
import SelectedDocumentsShareButtonContainer from '../../share-document/flux/containers/SelectedDocumentsShareButtonContainer.js';
import {MAX_LIMIT} from '../constants/DocumentSearchConstants.js';
import DocumentLinkContainer from '../flux/containers/DocumentLinkContainer.js';
import DocumentSearchParametersListContainer from '../flux/containers/DocumentSearchParametersListContainer.js';
import DocumentSearchSortComboContainer from '../flux/containers/DocumentSearchSortComboContainer.js';
import {documentSearchSortParams} from '../flux/DocumentSearchSelectors.js';
import DocumentSearchPageMessagesTranslator from '../i18n/DocumentSearchPageMessagesTranslator.js';
import documentSearchSortDefinitions from './DocumentSearchSortDefinitions.js';

import '../../../styles/document-search/components/DocumentSearchDataTables.scss';

const TOOL_BUTTON_WIDTH = 48;

const BirthDatePropertyFormatter = birthDateProperty('birth_date');
const Pipe = () => ' | ';

function PropsIgnoringDocumentShareButton(/*props*/) {
	return <SelectedDocumentsShareButtonContainer />;
}

function NoResultsComponent(props) {
	return (
		<NoResults {...props}>
			<DocumentSearchParametersListContainer />
		</NoResults>
	);
}

function createDocumentSearchTable(columnDefinitions, tableOptions) {
	return createMaterialTable(columnDefinitions.filter(columnDefinition => Boolean(columnDefinition)), {
		...tableOptions,
		RowComponent: DocumentSearchResultRowContainer
	});
}

function column(HeaderComponent, CellComponent, columnWidth, wrapInRowLink = true) {
	return simpleColumn(
		HeaderComponent,
		createSimplePureRenderComponent(function documentLink(props) {
			return renderCellContent(CellComponent, props, wrapInRowLink);
		}),
		columnWidth
	);
}

function conditionalColumn(condition, ...columnArgs) {
	return condition ? column(...columnArgs) : false;
}

function renderCellContent(CellComponent, props, wrapInRowLink) {
	const content = <CellComponent {...props} />;
	return wrapInRowLink
		? (
			<DocumentLinkContainer document={props.result} className='row-link'>
				{content}
			</DocumentLinkContainer>
		) : content;
}

const DocumentToolButtons = createSimplePureRenderComponent(({result}) => {
	const documentId = result.get('id');
	return (
		<div className='tool-buttons-container'>
			{result.get('exportable') &&
				<DocumentDownloadButton documentId={documentId} />
			}
		</div>
	);
});

const SelectDocumentCheckboxCell = createSimplePureRenderComponent(
	({result}) => <SelectDocumentCheckbox document={result} />
);

const downloadColumn = simpleColumn(
	emptyHeader,
	DocumentToolButtons,
	`${TOOL_BUTTON_WIDTH}px`, 'tools-column'
);

function emptyHeader() {
	return false;
}

const NrFilesFormatter = tr(GeneralMessagesTranslator, 'NumberFiles');
const NrSeriesFormatter = tr(GeneralMessagesTranslator, 'NumberSeries');

function allTypes({result}) {
	const allTypesValue = isDicom(result) ? result.get('all_modalities') : result.get('all_extensions');
	return allTypesValue.split(',');
}

function isDicom(result) {
	return result.get('document_type_fk') === 'DICOM_STUDY';
}

function translateColumnHeader(columnName) {
	const Translator = tr(DocumentSearchPageMessagesTranslator, columnName);
	return function TranslatedColumnHeader() {
		return <Translator />;
	};
}

function textualFileCount({result}) {
	let countRepresentation;
	if (isDicom(result)) {
		const numSeries = result.get('num_series');
		countRepresentation = <NrSeriesFormatter key='number' messageParams={{count: numSeries}} />;
	} else {
		const numFilesOrImages = result.get('num_files_or_images');
		countRepresentation = <NrFilesFormatter key='number' messageParams={{count: numFilesOrImages}} />;
	}
	return countRepresentation;
}

const numericFileCount = createSimplePureRenderComponent(({result}) => {
	const numFilesOrImages = result.get('num_files_or_images');
	return isDicom(result)
		? `${result.get('num_series')}/${numFilesOrImages}`
		: numFilesOrImages;
});

function sortLink(sortDefinitionName, defaultSortOrder) {
	const defaultSortProperties = documentSearchSortDefinitions.get(sortDefinitionName)
		.map(sortPropertyName => Immutable.List([sortPropertyName, defaultSortOrder]));
	const TranslatedHeader = translateColumnHeader(sortDefinitionName);

	return createSortLinkContainer(
		function SortLink(props) {
			const {sortOrder} = props;
			let SortIcon = '';
			let sortClass = '';
			if (sortOrder === ASC) {
				SortIcon = <SortAscIcon />;
				sortClass = 'sort-asc';
			} else if (sortOrder === DESC) {
				SortIcon = <SortDescIcon />;
				sortClass = 'sort-desc';
			}
			return (
				<span className={sortClass}>
					<TranslatedHeader />
					{' '}
					{SortIcon}
				</span>
			);
		},
		documentSearchSortParams, SEARCH_SORT_PARAMS, defaultSortProperties, defaultSortOrder
	);
}

export const LargeDocumentSearchDataTable = createDataTable(
	function DataTableHeader(props) {
		return (
			<CardHeader className={props.className}>
				<Title wrapperComponent={Status}>
					<SearchStatus {...props} maxLimit={MAX_LIMIT} />
				</Title>
				<DocumentSearchParametersListContainer />
			</CardHeader>
		);
	},
	createDocumentSearchTable([
		conditionalColumn(FEATURE_DOCUMENT_SHARING_ENABLED,
			PropsIgnoringDocumentShareButton,
			SelectDocumentCheckboxCell,
			'48px',
			false
		),
		column(
			sortLink('Patient', ASC),
			searchResultPatientInfo(true),
			'40%'
		),
		column(
			sortLink('ExaminationDate', DESC),
			dateTimeProperty('document_created_when'),
			'150px'
		),
		column(
			sortLink('Document', ASC),
			multiPropertyColumn(Pipe,
				allTypes,
				resultProperty('description', '-')
			),
			'60%'
		),
		column(
			sortLink('Files', ASC),
			numericFileCount,
			'60px'
		),
		downloadColumn
	]),
	NoResultsComponent
);

export const MediumDocumentSearchDataTable = createDataTable(
	function DataTableHeader(props) {
		return (
			<CardHeader className={props.className}>
				<div className='document-search-datatables-medium-header--first-row'>
					<Title className='document-search-datatables-medium-header--title'>
						<SearchStatus {...props} maxLimit={MAX_LIMIT} />
					</Title>
					<DocumentSearchSortComboContainer className='document-search-datatables-medium-header--sort-combo' />
				</div>
				<DocumentSearchParametersListContainer />
			</CardHeader>
		);
	},
	createDocumentSearchTable([
		conditionalColumn(FEATURE_DOCUMENT_SHARING_ENABLED,
			PropsIgnoringDocumentShareButton,
			SelectDocumentCheckboxCell,
			'48px',
			false
		),
		column(
			translateColumnHeader('Patient'),
			multiPropertyColumn('br',
				searchResultPatientInfo(false),
				BirthDatePropertyFormatter
			)
		),
		column(
			translateColumnHeader('Document'),
			multiPropertyColumn('br',
				resultProperty('description', '-'),
				multiPropertyColumn(Pipe,
					dateTimeProperty('document_created_when'),
					allTypes,
					textualFileCount
				)
			)
		),
		downloadColumn
	], {
		linesPerRow: 2
	}),
	NoResultsComponent
);

export const SmallDocumentSearchDataTable = createDataTable(
	function DataTableHeader(props) {
		return (
			<div className={combineClassNames(props.className, 'small')}>
				{FEATURE_DOCUMENT_SHARING_ENABLED && <SelectedDocumentsShareButtonContainer />}
				<DocumentSearchSortComboContainer fullWidth />
			</div>
		);
	},
	createDocumentSearchTable([
		conditionalColumn(FEATURE_DOCUMENT_SHARING_ENABLED,
			emptyHeader,
			SelectDocumentCheckboxCell,
			'48px',
			false
		),
		column(
			emptyHeader,
			multiPropertyColumn('br',
				searchResultPatientInfo(true),
				multiPropertyColumn(Pipe,
					dateTimeProperty('document_created_when'),
					allTypes,
					textualFileCount
				),
				resultProperty('description', '-')
			),
			'100%'
		),
		downloadColumn
	], {
		linesPerRow: 3,
		showHeader: false
	})
);
