import React from 'react';

import ContentPageContainer from '../../commons/containers/ContentPageContainer.js';
import NotchInset from '../../ui/components/layout/NotchInset.js';
import {BLACK_COLOR_NAME} from '../../ui/constants/ColorConstants.js';

import '../../../styles/commons/components/Page.scss';

export default function createEmbeddedWebViewPage(TitleBarComponent, BodyComponent, pageName) {
	function EmbeddedWebViewPage(props) {
		return (
			<ContentPageContainer className={pageName}>
				<TitleBarComponent {...props} />
				<div className='webview-page--body-container'>
					<NotchInset variant='left' bgColor={BLACK_COLOR_NAME} />
					<BodyComponent {...props} />
					<NotchInset variant='right' bgColor={BLACK_COLOR_NAME} />
				</div>
			</ContentPageContainer>
		);
	}
	return EmbeddedWebViewPage;
}
