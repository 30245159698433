import React from 'react';
import PropTypes from 'prop-types';

import UploadWizard from '../../../webupload/components/UploadWizard';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import WebViewUploadStepperFooter from './WebViewUploadStepperFooter';
import {WebViewUploadStepperInfo} from './WebViewUploadStepperInfo';

export default function WebViewUploadWizard(props) {
	const {stepsComponent} = props;
	return (
		<VerticalLayout justify='start' sx={{overflow: 'auto', height: '100%'}}>
			<UploadWizard stepsComponent={stepsComponent} infoComponent={WebViewUploadStepperInfo}
			              footerComponent={WebViewUploadStepperFooter} />
		</VerticalLayout>
	);
}

WebViewUploadWizard.propTypes = {
	stepsComponent: PropTypes.elementType
};
