import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {archiveDocumentsButtonVisible} from '../../../archive-documents/flux/selectors/ArchiveDocumentsSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils';
import {showPrintLayout} from '../../../ui/flux/UIActions';
import {uiIsMobileDevice, uiIsSmallDevice, uiShowPrintLayout} from '../../../ui/flux/UISelectors';
import {anyPrintableViewerVisible, isViewerShown} from '../../../viewer/flux/selectors/ViewerSelectors';
import EmbeddedPatientDetailsAppBar from '../../patient-details/components/EmbeddedPatientDetailsAppBar.js';

export default connect(
	createStructuredSelector({
		viewerShown: isViewerShown,
		showPrintLayout: uiShowPrintLayout,
		isMobileDevice: uiIsMobileDevice,
		isSmallDevice: uiIsSmallDevice,
		anyPrintableViewerVisible,
		archiveDocumentsButtonVisible
	}),
	bindActions({
		doPrint: showPrintLayout
	})
)(EmbeddedPatientDetailsAppBar);
