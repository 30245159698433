import React from 'react';

import GuardedUserInfo from '../../../authentication/flux/containers/GuardedUserInfo';
import AppBar from '../../../commons/components/AppBar';
import AppBarPrimaryContent from '../../../commons/components/AppBarPrimaryContent';
import AppBarSidebarExtensionArea from '../../../commons/components/webview/AppBarSidebarExtensionArea';
import PopupHelpButton from '../../../commons/components/webview/PopupHelpButton';
import GuardedPatientInfoContainer from '../../../patient-details/flux/containers/GuardedPatientInfoContainer';
import BackIcon from '../../../ui/components/icons/BackIcon';
import {WebViewPopupHelp} from '../../../viewer/components/ViewerPopupHelp';
import EmbeddedPatientDetailsBackLinkContainer from '../../flux/containers/EmbeddedPatientDetailsBackLinkContainer';

export default function EmbeddedPatientDetailsAppBar(props) {
	const {showPrintLayout} = props;
	return (showPrintLayout)
		? renderAppBarInPrintMode()
		: renderAppBar(props);
}

function renderAppBarInPrintMode() {
	return (
		<AppBar>
			<GuardedPatientInfoContainer />
		</AppBar>
	);
}

function renderAppBar(appBarProps) {
	const {
		viewerShown, isMobileDevice
	} = appBarProps;

	const shouldShowHelpButton = !isMobileDevice || viewerShown;

	return (
		<AppBar>
			{appBarPrimaryContent()}
			{shouldShowHelpButton && <PopupHelpButton helpComponent={WebViewPopupHelp} />}
			{appBarSidebarExtensionArea()}
		</AppBar>
	);
}


function appBarPrimaryContent() {
	return (
		<AppBarPrimaryContent menuButton={BACK_BUTTON}>
			<GuardedPatientInfoContainer />
		</AppBarPrimaryContent>
	);
}

function appBarSidebarExtensionArea() {
	return (
		<AppBarSidebarExtensionArea className='webview-details-app-bar--user-info'>
			<GuardedUserInfo />
		</AppBarSidebarExtensionArea>
	);
}

const BACK_BUTTON = (
	<EmbeddedPatientDetailsBackLinkContainer>
		<BackIcon />
	</EmbeddedPatientDetailsBackLinkContainer>
);

