import {createSelector} from 'reselect';

import {identity} from '../../commons/utils/FunctionUtils.js';
import {
	PATIENT_SEARCH_FIELD_CRITERIA, PATIENT_SEARCH_FIELD_ERROR_TYPE,
	PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER,
	PATIENT_SEARCH_FIELD_RESULTS, PATIENT_SEARCH_FIELD_STATE
} from './PatientSearchStateFields.js';

export const selectPatientSearchCriteria =
	createSelector(identity, patientSearchState => patientSearchState.get(PATIENT_SEARCH_FIELD_CRITERIA));
export const selectLoadSequenceNumberForPatientSearch =
	createSelector(identity, patientSearchState => patientSearchState.get(PATIENT_SEARCH_FIELD_LOAD_SEQ_NUMBER));
export const selectPatientSearchResults =
	createSelector(identity, patientSearchState => patientSearchState.get(PATIENT_SEARCH_FIELD_RESULTS));
export const selectPatientSearchState =
	createSelector(identity, patientSearchState => patientSearchState.get(PATIENT_SEARCH_FIELD_STATE));
export const selectPatientSearchErrorType =
	createSelector(identity, patientSearchState => patientSearchState.get(PATIENT_SEARCH_FIELD_ERROR_TYPE));
