import React from 'react';

import AutoBrickFactory from '../bricks/components/AutoBrickFactory.js';
import BrickRegistryProvider from '../bricks/components/BrickRegistryProvider.js';
import {CREATE_ORDER_PAGE} from '../commons/constants/PageNames.js';
import {getAets} from '../create-order/api/AetApi.js';
import {createOrderForExistingPatient, createOrderForNewPatient} from '../create-order/api/OrderApi.js';
import {getOrderers} from '../create-order/api/OrdererApi.js';
import CreateOrderAppBarController from '../create-order/components/CreateOrderAppBarController.js';
import CreateOrderController from '../create-order/components/CreateOrderPageController.js';
import CreateOrderService from '../create-order/CreateOrderService.js';
import CreateOrderTranslator from '../create-order/i18n/CreateOrderTranslator.js';
import {TranslationProvider} from '../i18n/components/TranslationContext.js';
import CleanPatientSearchServiceProvider from '../patient-search/components/CleanPatientSearchServiceProvider.js';
import createWebViewPage from './createWebViewPage.js';

const WebViewCreateOrderPageImpl =
	createWebViewPage(CreateOrderAppBarController, CreateOrderController, CREATE_ORDER_PAGE);

/**
 * This Component wires all the dependencies for the CreateOrderPage:
 * - an instance of AbstractPatientSearchService
 * - an instance of CreateOrderService
 * - translations.
 *
 * @param props - simply passed on to the nex component
 * @returns {JSX.Element} - The page bound to web view specific dependencies.
 */
export default function WebViewCreateOrderPage(props) {
	return (
		<BrickRegistryProvider>
			<CleanPatientSearchServiceProvider>
				<AutoBrickFactory brickType={CreateOrderService} brickFactory={createCreateOrderService} />
				<TranslationProvider translator={CreateOrderTranslator}>
					<WebViewCreateOrderPageImpl {...props} />
				</TranslationProvider>
			</CleanPatientSearchServiceProvider>
		</BrickRegistryProvider>
	);
}

function createCreateOrderService(patientSearchService) {
	return new CreateOrderService(
		getAets, getOrderers, createOrderForExistingPatient, createOrderForNewPatient,
		patientSearchService
	);
}
