import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {END_SESSION, LOGIN} from '../../authentication/constants/SessionActionTypes.js';
import {booleanValue} from '../../commons/utils/BooleanUtils.js';
import {extractLongDate, extractShortDate} from '../../commons/utils/DateUtils.js';
import {groupSorted} from '../../commons/utils/SeqUtils.js';
import {BEGIN_INBOX_FETCH, FILTER_INBOX, LOAD_INBOX, MARK_SHARES_DONE} from '../constants/InboxActionTypes.js';
import {
	INBOX_FETCH_ERROR,
	INBOX_FETCH_ID,
	INBOX_FILTERED_ID_MAP,
	INBOX_FILTERED_SHARES,
	INBOX_ID_MAP,
	INBOX_LAST_FETCH_TIME_STAMP,
	INBOX_SHARES,
	INBOX_SHARES_ID_MAP
} from '../constants/InboxPropertyNames.js';

const INITIAL_STATE = Immutable.Map({
	[INBOX_FILTERED_SHARES]: null,
	[INBOX_FILTERED_ID_MAP]: null,
	[INBOX_SHARES]: null,
	[INBOX_SHARES_ID_MAP]: null,
	[INBOX_LAST_FETCH_TIME_STAMP]: -1,
	[INBOX_FETCH_ID]: null,
	[INBOX_FETCH_ERROR]: null
});

export default handleActions({
	[BEGIN_INBOX_FETCH]: handleBeginInboxFetch,
	[FILTER_INBOX]: createFetchHandler(handleFilterInbox),
	[LOAD_INBOX]: createFetchHandler(handleLoadInbox),
	[MARK_SHARES_DONE]: handleMarkSharesDone,
	[END_SESSION]: getInitialInboxState,
	[LOGIN]: getInitialInboxState
}, getInitialInboxState());

export function getInitialInboxState() {
	return INITIAL_STATE;
}

function handleBeginInboxFetch(inboxState, action) {
	const {payload: fetchId} = action;
	return inboxState
		.set(INBOX_FETCH_ID, fetchId)
		.set(INBOX_FETCH_ERROR, null);
}

function extractPatientInfo(documentShare) {
	return Immutable.Map({
		aimid: documentShare.get('patient_aimid'),
		first_name: documentShare.get('first_name'),
		last_name: documentShare.get('last_name'),
		sex: documentShare.get('sex'),
		birth_date: extractLongDate(documentShare.get('birth_date'))
	});
}

function groupResults(results) {
	return Immutable.List(
		groupSorted(results, documentShare => documentShare.get('patient_aimid'))
			.map(documentShares => Immutable.Map({
				patientInfo: extractPatientInfo(documentShares[0]),
				documentShares: Immutable.List(
					documentShares.map(share => share
						.update('document_created_when', extractLongDate)
						.update('inserted_when', extractShortDate)
						.update('valid_until', extractShortDate)
						.update('done', booleanValue)
					)
				),
				[INBOX_ID_MAP]: documentShares.reduce(
					(id2indexMap, share, index) => {
						const shareId = share.get('share_id');
						return (typeof (shareId) === 'number') ? id2indexMap.set(shareId, index) : id2indexMap;
					}
					, Immutable.Map()
				)
			}))
	);
}

function getPatientIdFromPatientGroup(patientGroup) {
	return patientGroup.get('patientInfo').get('aimid');
}

function handleFilterInbox(inboxState, resultList) {
	let newState = inboxState;
	if (resultList) {
		const groupedResults = groupResults(resultList);
		newState = newState
			.set(INBOX_FILTERED_SHARES, groupedResults)
			.set(INBOX_FILTERED_ID_MAP, groupedResults.reduce(
				(id2indexMap, patientGroup, index) => id2indexMap.set(getPatientIdFromPatientGroup(patientGroup), index)
				, Immutable.Map()
			));
	} else {
		newState = newState
			.set(INBOX_FILTERED_SHARES, null)
			.set(INBOX_FILTERED_ID_MAP, null);
	}
	return newState;
}

function handleLoadInbox(inboxState, resultList) {
	const groupedResults = groupResults(resultList);
	return inboxState
		.set(INBOX_SHARES, groupedResults)
		.set(INBOX_SHARES_ID_MAP, groupedResults.reduce(
			(id2indexMap, patientGroup, index) => id2indexMap.set(getPatientIdFromPatientGroup(patientGroup), index)
			, Immutable.Map()
		));
}

function markShareDone(shares, idMap, patId, shareId, done) {
	let modifiedShares = shares;
	if (modifiedShares !== null) {
		const groupIndex = idMap.get(patId);
		let patientGroup = modifiedShares.get(groupIndex);
		if (groupIndex !== undefined && Boolean(patientGroup) && getPatientIdFromPatientGroup(patientGroup) === patId) {
			const shareIndex = patientGroup.get(INBOX_ID_MAP).get(shareId);
			const share = patientGroup.get('documentShares').get(shareIndex);
			if (shareIndex !== undefined && Boolean(share) && share.get('share_id') === shareId) {
				patientGroup = patientGroup
					.update('documentShares', documentShare => documentShare.set(shareIndex, share.set('done', done)));
				modifiedShares = modifiedShares.set(groupIndex, patientGroup);
			}
		}
	}
	return modifiedShares;
}

function handleMarkSharesDone(inboxState, {payload: updates}) {
	return inboxState
		.update(INBOX_SHARES, previousShares => {
			const inboxSharesIdMap = inboxState.get(INBOX_SHARES_ID_MAP);
			return updates.reduce(
				(shares, {patId, shareId, done}) => markShareDone(shares, inboxSharesIdMap, patId, shareId, done),
				previousShares
			);
		})
		.update(INBOX_FILTERED_SHARES, filteredShares => {
			const filteredSharesIdMap = inboxState.get(INBOX_FILTERED_ID_MAP);
			return updates.reduce(
				(shares, {patId, shareId, done}) => markShareDone(shares, filteredSharesIdMap, patId, shareId, done),
				filteredShares
			);
		});
}

function createFetchHandler(handler) {
	return function fetchHandler(inboxState, action) {
		const {payload, error} = action;
		const {fetchId, results, error: fetchError} = payload;
		const currentFetchId = inboxState.get(INBOX_FETCH_ID, null);
		const wasLastFetch = currentFetchId !== null && fetchId === currentFetchId;
		let nextInboxState = inboxState;
		if (wasLastFetch) {
			if (error) {
				nextInboxState = nextInboxState.set(INBOX_FETCH_ERROR, fetchError);
			} else {
				nextInboxState = handler(nextInboxState, results);
			}
			nextInboxState = nextInboxState
				.set(INBOX_FETCH_ID, null);
			if (results) {
				nextInboxState = nextInboxState
					.set(INBOX_LAST_FETCH_TIME_STAMP, results.fetchTimeStamp);
			}
		}
		return nextInboxState;
	};
}
