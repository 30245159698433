export const BARCODE_TYPE_CODE_128 = 'code_128';
export const BARCODE_TYPE_CODE_39 = 'code_39';
export const BARCODE_TYPE_CODE_93 = 'code_93';
export const BARCODE_TYPE_EAN_13 = 'ean_13';
export const BARCODE_TYPE_EAN_8 = 'ean_8';
export const BARCODE_TYPE_EAN_13_EAN_5_EAN_2 = 'ean_13_ean_5_ean_2';
export const BARCODE_TYPE_CODABAR = 'codabar';
export const BARCODE_TYPE_UPC_A = 'upc_a';
export const BARCODE_TYPE_UPC_E = 'upc_e';
export const BARCODE_TYPE_I2OF5 = 'i2of5';
