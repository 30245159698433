import React from 'react';
import CameraPlus from '@mdi/svg/svg/camera-plus.svg';
import CameraPlusOutline from '@mdi/svg/svg/camera-plus-outline.svg';

import {createSVGComponentIcon} from './SVGIconCreationUtils.js';

const CameraPlusIcon = createSVGComponentIcon(CameraPlus);
const CameraPlusOutlineIcon = createSVGComponentIcon(CameraPlusOutline);

export {CameraPlusIcon, CameraPlusOutlineIcon};
