import createFormAccessors from '../commons/data/FormAccessorsFactory.js';
import {formNotEmpty} from '../commons/data/validators';
import PatientSearchTranslator from './i18n/PatientSearchTranslator.js';

const PATIENT_SEARCH_FORM_SCHEMA = {
	fields: {
		search_id: {
			label: 'PatientOrVisitId',
			defaultValue: null
		},
		first_name: {
			label: 'FirstName',
			defaultValue: null
		},
		last_name: {
			label: 'LastName',
			defaultValue: null
		},
		sex: {
			label: 'Sex',
			defaultValue: ''
		},
		birth_date: {
			label: 'BirthDate',
			defaultValue: null
		}
	},
	validators: [formNotEmpty(PatientSearchTranslator, 'SearchFormEmptyMessage')]
};

export default createFormAccessors(PATIENT_SEARCH_FORM_SCHEMA, PatientSearchTranslator);
