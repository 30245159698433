import Immutable from 'immutable';

import RequestPasswordResetPageContainer from './authentication/flux/containers/RequestPasswordResetPageContainer.js';
import {
	selectCanArchiveDocuments,
	selectCanCreateOrders
} from './authentication/flux/selectors/webViewSessionSelectors.js';
import createPasswordResetRoute from './commons/components/webview/createPasswordResetRoute.js';
import createUserActivationRoute from './commons/components/webview/createUserActivationRoute.js';
import InboxPage from './commons/components/webview/InboxPage.js';
import {
	HELP_PATH,
	ITEM_DOWNLOAD_PATH,
	ITEM_DOWNLOAD_PATH_PARAMETER_MATCHERS,
	PASSWORDRESET_PATH,
	ROOT_PATH
} from './commons/constants/CommonRoutingConstants.js';
import {FEATURE_INBOX_ENABLED, FEATURE_SEARCH_ENABLED} from './commons/constants/SynSettingsConstants.js';
import {
	isDocumentSearchEnabled, isDocumentSharingEnabled,
	isDocumentUploadEnabled,
	isEmbeddedModeEnabled,
	isInboxEnabled,
	isOrderCreationEnabled,
	isPasswordResetEnabled
} from './commons/selectors/GeneralConfigSelectors.js';
import {chain} from './commons/utils/FunctionUtils.js';
import WebViewCreateOrderPage from './components/WebViewCreateOrderPage.js';
import WebViewDocumentPermitClaimPage from './components/WebViewDocumentPermitClaimPage.js';
import DownloadPage from './components/WebViewDownloadPage.js';
import WebViewHelpPage from './components/WebViewHelpPage.js';
import WebViewUploadPage from './components/WebViewUploadPage.js';
import DocumentSearchPageContainer from './document-search/flux/containers/DocumentSearchPageContainer.js';
import EmbeddedNotePage from './embedded-mode/pages/EmbeddedNotePage.js';
import EmbeddedPatientDetailsPage from './embedded-mode/pages/EmbeddedPatientDetailsPage.js';
import PatientDetailsPage from './patient-details/components/PatientDetailsPage.js';
import PatientDetailsLoaderPageContainer from './patient-details/flux/containers/PatientDetailsLoaderPageContainer.js';
import createLocation from './router/createLocation.js';
import {
	guarded,
	legacyDocumentToPatientDetailsLoader,
	legacyInboxItemToPatientDetailsLoader,
	page,
	patientDetailsRedirector,
	redirector,
	redirectTo,
	removeExtention,
	toWebViewLocation,
	unguarded
} from './router/RoutingUtils.js';
import {
	LEGACY_DOCUMENT_QUERY_PATH,
	LEGACY_SINGLE_DOCUMENT_PATH,
	LEGACY_SINGLE_INBOX_ITEM_PATH,
	WEBVIEW_CREATE_ORDER_PATH,
	WEBVIEW_DOCUMENT_PATH, WEBVIEW_DOCUMENT_PERMIT_CLAIM_PATH,
	WEBVIEW_DOCUMENT_SHARE_PATH,
	WEBVIEW_PATIENT_DETAILS_LOADER_PATH,
	WEBVIEW_PATIENT_DETAILS_PATH,
	WEBVIEW_UPLOAD_PATH
} from './WebViewRoutingConstants.js';

const DOCUMENT_SHARE_LOCATION = createLocation({
	path: WEBVIEW_DOCUMENT_SHARE_PATH
});

export const RedirectToInbox = redirectTo(DOCUMENT_SHARE_LOCATION);

const DOCUMENT_LOCATION = createLocation({
	path: WEBVIEW_DOCUMENT_PATH
});

function selectDefaultPage() {
	if (!FEATURE_INBOX_ENABLED && !FEATURE_SEARCH_ENABLED) {
		throw new Error('At least one of the default pages Inbox or Document Search must be activated');
	}
	return FEATURE_INBOX_ENABLED ? RedirectToInbox : redirectTo(DOCUMENT_LOCATION);
}

const DEFAULT_ROUTES = Immutable.OrderedMap({
	[ROOT_PATH]: guarded(selectDefaultPage),
	[WEBVIEW_DOCUMENT_SHARE_PATH]: guarded(page(InboxPage), isInboxEnabled),
	[WEBVIEW_DOCUMENT_PATH]: guarded(
		patientDetailsRedirector(DocumentSearchPageContainer), isDocumentSearchEnabled
	),
	[WEBVIEW_PATIENT_DETAILS_PATH]: guarded(page(PatientDetailsPage)),
	[WEBVIEW_PATIENT_DETAILS_LOADER_PATH]: guarded(page(PatientDetailsLoaderPageContainer)),
	[WEBVIEW_UPLOAD_PATH]: guarded(page(WebViewUploadPage),
		state => isDocumentUploadEnabled() && selectCanArchiveDocuments(state)
	),
	[HELP_PATH]: guarded(page(WebViewHelpPage)),
	[PASSWORDRESET_PATH]: unguarded(page(RequestPasswordResetPageContainer), isPasswordResetEnabled),
	[LEGACY_SINGLE_DOCUMENT_PATH]: redirector(
		legacyDocumentToPatientDetailsLoader, Immutable.Map({document_id: '[0-9]+'})
	),
	[LEGACY_SINGLE_INBOX_ITEM_PATH]: redirector(
		legacyInboxItemToPatientDetailsLoader, Immutable.Map({share_id: '[0-9]+'})
	),
	[LEGACY_DOCUMENT_QUERY_PATH]: redirector(chain(toWebViewLocation, removeExtention)),
	[ITEM_DOWNLOAD_PATH]: guarded(page(DownloadPage),
		undefined, undefined, ITEM_DOWNLOAD_PATH_PARAMETER_MATCHERS),
	[WEBVIEW_CREATE_ORDER_PATH]: guarded(page(WebViewCreateOrderPage),
		state => isOrderCreationEnabled() && selectCanCreateOrders(state)
	),
	[WEBVIEW_DOCUMENT_PERMIT_CLAIM_PATH]: unguarded(page(WebViewDocumentPermitClaimPage), isDocumentSharingEnabled)
}).map(createUserActivationRoute)
	.map(createPasswordResetRoute);

const EMBEDDED_ROUTES = Immutable.OrderedMap({
	[ROOT_PATH]: guarded(page(EmbeddedNotePage)),
	[WEBVIEW_PATIENT_DETAILS_PATH]: guarded(page(EmbeddedPatientDetailsPage)),
	[WEBVIEW_PATIENT_DETAILS_LOADER_PATH]: guarded(page(PatientDetailsLoaderPageContainer))
});

export default isEmbeddedModeEnabled() ? EMBEDDED_ROUTES : DEFAULT_ROUTES;
