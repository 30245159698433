import React from 'react';
import DoneIcon from '@mui/icons-material/Done';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import Checkbox from '../../ui/components/Checkbox.js';

export default function AllDoneButton(props) {
	return <Checkbox checkedIcon={<DoneIcon />} uncheckedIcon={<DoneIcon />} {...props} />;
}
AllDoneButton.propTypes = cloneWithoutProperties(Checkbox.propTypes, 'uncheckedIcon', 'checkedIcon');
AllDoneButton.defaultProps = cloneWithoutProperties(Checkbox.defaultProps, 'uncheckedIcon', 'checkedIcon');
