import React from 'react';
import PropTypes from 'prop-types';

import DataGuard from '../../commons/components/data/DataGuard.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_BUSY, STATUS_FAILED, STATUS_SUCCESS} from '../../commons/constants/StatusMessageConstants.js';
import SelectionPurgerContainer from '../../commons/containers/SelectionPurgerContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {
	DATA_FIELD_IS_CREATING_SHARE,
	DATA_FIELD_SHARE_CREATED_SUCCESSFULLY,
	DATA_FIELD_SHARE_CREATION_ERROR
} from '../constants/ShareDocumentConstants.js';
import ShareDocumentMessagesTranslator from '../i18n/ShareDocumentTranslator.js';

import '../../../styles/share-document/components/StepCreateDocumentShare.scss';

function CenteredStatus(props) {
	const {message, messageParams, status} = props;
	return (
		<StatusMessage status={status}>
			<SynFormattedMessage messageParams={messageParams} 
				message={message} translator={ShareDocumentMessagesTranslator} />
		</StatusMessage>
	);
}

class StepCreateDocumentShare extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundBeginCreateShare = this.beginCreateShare.bind(this);
	}

	render() {
		const {data, documentIds} = this.props;
		const shareCreationError = data.get(DATA_FIELD_SHARE_CREATION_ERROR, null);
		const nDocuments = documentIds.length;
		return shareCreationError 
			? StepCreateDocumentShare.renderCreationError(nDocuments) 
			: this.renderCreationState(data, nDocuments);
	}

	static renderCreationError(nDocuments) {
		const messageParams = {nDocuments};
		return (
			<CenteredStatus status={STATUS_FAILED} messageParams={messageParams} className='error' message='StatusShareCreationFailed' />
		);
	}

	renderCreationState(data, nDocuments) {
		const isCreatingShare = data.get(DATA_FIELD_IS_CREATING_SHARE, false);
		const shareCreatedSuccessfully = data.get(DATA_FIELD_SHARE_CREATED_SUCCESSFULLY, false);
		const messageParams = {nDocuments};
		const loadingIndicator = (
			<CenteredStatus status={STATUS_BUSY} messageParams={messageParams} message='StatusCreatingShare' />
		);
		return (
			<DataGuard isLoadNeeded={!isCreatingShare && !shareCreatedSuccessfully} data={shareCreatedSuccessfully}
						  load={this.boundBeginCreateShare} loadingIndicator={loadingIndicator}>
				<SelectionPurgerContainer>
					<CenteredStatus status={STATUS_SUCCESS} messageParams={messageParams} message='StatusShareCreatedSuccessfully' />
				</SelectionPurgerContainer>
			</DataGuard>
		);
	}

	beginCreateShare() {
		const {data, documentIds, currentReceiver, createPrivateShare} = this.props;
		const message = data.get('message', '');
		createPrivateShare(documentIds, message, currentReceiver);
	}
}

CenteredStatus.propTypes = {
	message: PropTypes.string, 
	messageParams: PropTypes.object, 
	status: PropTypes.string
};

StepCreateDocumentShare.propTypes = {
	data: PropTypes.object,
	documentIds: PropTypes.arrayOf(PropTypes.string),
	currentReceiver: PropTypes.object, 
	createPrivateShare: PropTypes.func
};

export default StepCreateDocumentShare;
