import React from 'react';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import DataGuard from '../../commons/components/data/DataGuard.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';

const DEBOUNCE_LOAD_FILTERED_TIMEOUT = 50;

export default class ShareReceiverLoader extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundCallLoadFiltered = this.callLoadFiltered.bind(this);
		this.debouncedLoadFiltered = _debounce(this.debounceLoadFiltered.bind(this), DEBOUNCE_LOAD_FILTERED_TIMEOUT);
	}

	render() {
		const {shareReceivers, isLoading, receiverFilter, requestedReceiverFilter, children} = this.props;
		const isLoadNeeded = !isLoading && (!shareReceivers || receiverFilter !== requestedReceiverFilter);
		const loadFunction = shareReceivers ? this.debouncedLoadFiltered : this.boundCallLoadFiltered;
		return (
			<DataGuard data={shareReceivers} doReload isLoadNeeded={isLoadNeeded} load={loadFunction}>
				{children}
			</DataGuard>
		);
	}

	debounceLoadFiltered() {
		this.callLoadFiltered();
	}

	callLoadFiltered() {
		const {loadFiltered} = this.props;
		callSafe(loadFiltered);
	}
}

ShareReceiverLoader.propTypes = {
	shareReceivers: resultListPropType,
	isLoading: PropTypes.bool,
	receiverFilter: PropTypes.string,
	requestedReceiverFilter: PropTypes.string,
	loadFiltered: PropTypes.func
};
