import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {registerMetricsAction} from '../../metrics-collector/flux/MetricsCollectorActions.js';
import {uiIsMobileDevice} from '../../ui/flux/UISelectors.js';
import BarcodeScannerButton from '../components/BarcodeScannerButton.js';
import {bindActions} from '../utils/FluxUtils.js';

export default connect(
	createStructuredSelector({
		isMobileDevice: uiIsMobileDevice
	}),
	bindActions({
		registerMetricsAction
	}))(BarcodeScannerButton);
