import React from 'react';
import PropTypes from 'prop-types';

import SearchFilter from '../../material-design/components/SearchFilter.js';
import {
	DATA_FIELD_RECEIVER_FILTER,
	DATA_FIELD_SELECTED_RECEIVER,
	DATA_FIELD_SHARE_MODE,
	MODE_NEW_RECEIVER
} from '../constants/ShareDocumentConstants.js';
import ShareReceiverListContainer from '../flux/containers/ShareReceiverListContainer.js';
import ShareDocumentMessagesTranslator from '../i18n/ShareDocumentTranslator.js';

import '../../../styles/share-document/components/TabSearchReceiver.scss';

class TabSearchReceiver extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundOnSearchActivated = this.onSearchActivated.bind(this);
		this.boundOnSearchDeactivated = this.onSearchDeactivated.bind(this);
		this.boundOnChange = this.onFilterChanged.bind(this);
		this.boundOnReceiverSelected = this.onReceiverSelected.bind(this);
		this.boundSwitchToCreateReceiver = this.switchToCreateReceiver.bind(this);

		this.state = {
			searchActive: false
		};
	}

	render() {
		const {data, receiverFilter = ''} = this.props;
		const {searchActive} = this.state;
		const selectedReceiver = data.get('receiver', null);
		return (
			<React.Fragment>
				<div key='searchFilterContainer' className='tab-search-receiver--search-field-container'>
					<SearchFilter placeholder={this.getSearchFieldPlaceholder()} active={searchActive}
					              onActivate={this.boundOnSearchActivated} onDeactivate={this.boundOnSearchDeactivated}
					              onChange={this.boundOnChange} value={receiverFilter} onChangeMaxInterval={0} />
				</div>
				<ShareReceiverListContainer key='shareReceiverList' className='tab-search-receiver--receiver-list'
				                            onReceiverSelected={this.boundOnReceiverSelected}
				                            selectedReceiver={selectedReceiver}
				                            switchToCreateReceiver={this.boundSwitchToCreateReceiver} />
			</React.Fragment>
		);
	}

	updateData(field, newValue) {
		const {data, updateData} = this.props;
		updateData(data.set(field, newValue));
	}

	onSearchActivated() {
		this.setState({
			searchActive: true
		});
	}

	onSearchDeactivated() {
		this.setState({
			searchActive: false
		});
	}

	onFilterChanged(newValue) {
		this.updateData(DATA_FIELD_RECEIVER_FILTER, newValue);
	}

	onReceiverSelected(receiver) {
		this.updateData(DATA_FIELD_SELECTED_RECEIVER, receiver);
	}

	switchToCreateReceiver() {
		this.updateData(DATA_FIELD_SHARE_MODE, MODE_NEW_RECEIVER);
	}

	getSearchFieldPlaceholder() {
		const {locale} = this.props;
		return ShareDocumentMessagesTranslator.getTranslation('StepSelectReceiver', locale);
	}
}

TabSearchReceiver.propTypes = {
	receiverFilter: PropTypes.string,
	locale: PropTypes.string,
	data: PropTypes.object,
	updateData: PropTypes.func
};

export default TabSearchReceiver;
