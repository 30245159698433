import React from 'react';
import Immutable from 'immutable';
import PropTypes, {oneOfType} from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {MaterialFormSuggestComboBox} from '../../material-design/components/form/MaterialFormComboBox.js';

export default function AETServiceField(props) {
	const {aetId, aetServices, fieldName} = props;
	const options = useMemoFactory(createValueListOptions, aetServices);
	return (
		<MaterialFormSuggestComboBox options={options} fieldName={fieldName} disabled={!aetId} getValue={getValue} />
	);
}

AETServiceField.propTypes = {
	fieldName: PropTypes.string.isRequired,
	aetId: oneOfType([PropTypes.string, PropTypes.number]),
	aetServices: immutableListPropType
};

AETServiceField.defaultProps = {
	aetServices: Immutable.List()
};

function createValueListOptions(stringList) {
	return stringList.toArray();
}

function getValue(value) {
	return value === '' ? null : value;
}
