import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import InboxAppBar from '../../components/InboxAppBar.js';
import {clearInboxFilter, filterInbox} from '../InboxActions.js';
import {getInboxFilterString} from '../InboxSelectors.js';

export default connect(createStructuredSelector({
	filterString: getInboxFilterString
}), bindActions({
	filterInbox,
	clearInboxFilter
})
)(InboxAppBar);
