import React from 'react';

import {tr} from '../../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import LoadingBar from '../../../material-design/components/LoadingBar.js';
import {cloneWithoutProperties} from '../../utils/ObjectUtils';
import createSimplePureRenderComponent from '../createSimplePureRenderComponent.js';

const ShortDateTimeTranslator = tr(GeneralMessagesTranslator, 'ShortDateShortTime');
const BirthDateTranslator = tr(GeneralMessagesTranslator, 'BirthDate');

/**
 * props result tableProps
 *
 * @param propertyName a propertyName or a renderFunction
 * @param emptyReplacement inserted instead of result property if the property is empty or undefined
 * @returns {Component} a simple com
 */
export function resultProperty(propertyName, emptyReplacement = '') {
	return createSimplePureRenderComponent(props => `${props.result.get(propertyName) || emptyReplacement}`);
}

export function birthDateProperty(propertyName) {
	return createSimplePureRenderComponent(props => {
		const date = props.result.get(propertyName);
		return date && <BirthDateTranslator messageParams={{birthDate: date}} /> || '-';
	});
}

export function dateTimeProperty(propertyName) {
	return createSimplePureRenderComponent(props => {
		const dateTime = props.result.get(propertyName);
		return dateTime && <ShortDateTimeTranslator messageParams={{time: dateTime, date: dateTime}} /> || '-';
	});
}

export function column(headerComponent, cellComponent, columnWidth = 'auto', columnClass = undefined) {
	return {
		Header: headerComponent,
		Cell: cellComponent,
		columnWidth, columnClass
	};
}

export function multiPropertyColumn(Separator, ...components) {
	return createSimplePureRenderComponent(props => components.reduce((joinedLines, Component) => {
		const newLine = <Component key={joinedLines && (joinedLines.length + 1) || 0} {...props} />;
		let newJoinedLines;
		if (joinedLines === null) {
			newJoinedLines = [newLine];
		} else {
			newJoinedLines = joinedLines.concat([<Separator key={joinedLines && joinedLines.length} />, newLine]);
		}
		return newJoinedLines;
	}, null)
	);
}

function tableBody(tableDefinitions, RowComponent) {
	function contentBody(props) {
		const {resultList} = props;
		const localStartIndex = props.startIndex - resultList.getRawDataStart();

		const subResults = resultList.getList().toIndexedSeq()
			.slice(localStartIndex, localStartIndex + props.numberRows);
		return subResults.map((result, index) => (
			<RowComponent key={resultList.getList().keyOf(result)} index={index} result={result} {...props}>
				{
					tableDefinitions.map(tableDefinition => {
						const {Cell, columnWidth, columnClass} = tableDefinition;
						return (
							<td key={tableDefinitions.indexOf(tableDefinition)} width={columnWidth}
							    className={columnClass}>
								<Cell rowIndex={index} result={result} {...props} />
							</td>
						);
					})
				}
			</RowComponent>
		)).toArray();
	}

	return createSimplePureRenderComponent(props => {
		const {startIndex, numberRows, resultList} = props;
		const endIndex = Math.min(startIndex + numberRows, resultList.getTotalSize());
		const currentDataInRange = (endIndex - resultList.getRawDataStart());
		const hasSufficientData = currentDataInRange >= 0 && currentDataInRange <= resultList.getLocalSize();
		const rows = hasSufficientData ? contentBody(props) : false;

		return (
			<tbody>
				{rows}
			</tbody>
		);
	});
}

export function tableHeader(tableDefinitions) {
	return createSimplePureRenderComponent(props => (
		<thead>
			<tr>
				{
					tableDefinitions.map(tableDefinition => {
						const {Header, columnWidth, columnClass} = tableDefinition;
						return (
							<th key={tableDefinitions.indexOf(tableDefinition)} width={columnWidth}
							    className={columnClass}>
								<Header {...props} />
							</th>
						);
					})
				}
			</tr>
			<tr className='loading-bar-row'>
				<td colSpan={tableDefinitions.length}>
					<LoadingBar isLoading={props.loadInProgress} />
				</td>
			</tr>
		</thead>
	));
}

const DEFAULT_TABLE_HEIGHTS = {
	header: 58,
	row: 46
};

export function createTable(tableDefinition, tableOptions = {}) {
	const {
		TableComponent = 'table',
		RowComponent = 'tr',
		tableHeights = DEFAULT_TABLE_HEIGHTS,
		tableHeaderCreator = tableHeader,
		tableBodyCreator = tableBody
	} = tableOptions;

	const TableHeaderComponent = tableHeaderCreator(tableDefinition);
	const TableBodyComponent = tableBodyCreator(tableDefinition, RowComponent);
	const Table = createSimplePureRenderComponent(props => {
		const {bodyProps, ...remainingProps} = props;
		const finalTableProps = TableComponent === 'table' ? cloneWithoutProperties(remainingProps, 'numberRows', 'startIndex', 'resultList', 'loadInProgress') : props;
		return (
			<TableComponent {...finalTableProps}>
				{TableHeaderComponent && <TableHeaderComponent {...remainingProps} />}
				{renderTableBody({...remainingProps, ...bodyProps})}
			</TableComponent>
		);
	});

	function renderTableBody(props) {
		return props.resultList === null ? false : <TableBodyComponent {...props} />;
	}

	Table.measureNumberRowsForHeight = function measureRowsFunction(height) {
		return Math.max(1, Math.floor((height - tableHeights.header) / tableHeights.row));
	};

	return Table;
}
