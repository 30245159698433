import React from 'react';
import PropTypes from 'prop-types';

import Status from '../../a11y/components/landmarks/Status.js';
import AppBar from '../../commons/components/AppBar.js';
import AppBarOverflowButton from '../../commons/components/AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import LoadingBar from '../../material-design/components/LoadingBar.js';
import Title from '../../material-design/components/Title.js';
import {loadInProgressPropType} from '../searchLayoutUtil.js';
import SearchStatus from './SearchStatus.js';

import '../../../styles/search/components/SmallSearchAppBar.scss';

export default function SmallSearchAppBar(props) {
	const {title: defaultTitle, showResults, loadInProgress, nrResults, maxLimit, backButton, subTitle} = props;
	let title = defaultTitle;
	let loadingBar = false;
	let menuButton;

	if (showResults) {
		menuButton = backButton;
		const isLoading = showResults && loadInProgress !== null;
		loadingBar = <LoadingBar isLoading={isLoading} className='search-appbar--loading-bar' />;
		title = <SearchStatus nrResults={nrResults} maxLimit={maxLimit} />;
	}

	return (
		<AppBar>
			<AppBarPrimaryContent menuButton={menuButton}>
				<Title subTitle={subTitle} wrapperComponent={Status}>
					{title}
				</Title>
			</AppBarPrimaryContent>
			<AppBarOverflowButton />
			{loadingBar}
		</AppBar>
	);
}

SmallSearchAppBar.propTypes = {
	title: PropTypes.node.isRequired,
	subTitle: PropTypes.node,
	maxLimit: PropTypes.number.isRequired,
	showResults: PropTypes.bool,
	loadInProgress: loadInProgressPropType,
	nrResults: PropTypes.number,
	backButton: PropTypes.node
};

SmallSearchAppBar.defaultProps = {
	loadInProgress: null
};
