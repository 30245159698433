import React from 'react';
import PropTypes from 'prop-types';

import DataGuard from '../../commons/components/data/DataGuard.js';
import createDataTable from '../../material-design/components/createDataTable.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';
import LargePatientSearchResults from './LargePatientSearchResults.js';
import PatientSearchResultsHeader from './PatientSearchResultsHeader.js';
import PatientSearchResultsNoResults from './PatientSearchResultsNoResults.js';
import SmallPatientSearchResult from './SmallPatientSearchResults.js';

export const SmallPatientSearchDataTable = createDataTable(
	EmptyHeader, createTableBodyComponent(SmallPatientSearchResult), PatientSearchResultsNoResults
);
export const MediumPatientSearchDataTable = SmallPatientSearchDataTable;
export const LargePatientSearchDataTable = createDataTable(
	PatientSearchResultsHeader, createTableBodyComponent(LargePatientSearchResults), PatientSearchResultsNoResults
);

function createTableBodyComponent(ResultsComponent) {
	function TableBodyComponent(props) {
		const {resultList, numberRows, startIndex, onPatientClick} = props;
		return (
			<DataGuard data={resultList}>
				<ResultsComponent onPatientClick={onPatientClick} resultList={resultList}
				                  rowCount={numberRows} startIndex={startIndex} />
			</DataGuard>
		);
	}
	TableBodyComponent.measureNumberRowsForHeight = ResultsComponent.measureNumberRowsForHeight;
	TableBodyComponent.propTypes = {
		resultList: resultListPropType,
		numberRows: PropTypes.number,
		startIndex: PropTypes.number,
		onPatientClick: PropTypes.func
	};
	return TableBodyComponent;
}

function EmptyHeader() {
	return false;
}
