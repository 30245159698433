import patientSearchSaga from '../../patient-search/flux/patientSearchSaga.js';
import {searchPatients} from '../../patient-search/PatientApi.js';
import {selectPatientSearch} from './CreateOrderSelectors.js';

/**
 * A specialized patient search saga.
 * It is responsible for managing the patient-search of the create-order workflow.
 * @return A saga bound to the patient search state of the create-order workflow.
 */
export default function* createOrderPatientSearchSaga() {
	yield* patientSearchSaga(selectPatientSearch, searchPatients);
}
