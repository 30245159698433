import MaterialDataTableConstants from '../../../constants/MaterialDataTableConstants.json';
import {createTable, tableHeader} from '../../commons/components/data/Table.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import DocumentSearchDataTableRow from '../../document-search/components/DocumentSearchDataTableRow.js';

const TOTAL_TABLE_HEADER_HEIGHT = MaterialDataTableConstants['material-data-table']['column-header-height'] +
	MaterialDataTableConstants['material-data-table']['indicator-height'];

export default function createMaterialTable(tableDefinition, materialTableOptions = {}) {
	const {
		linesPerRow = 1,
		showHeader = true,
		tableHeaderCreator = tableHeader,
		...additionalTableArguments
	} = materialTableOptions;


	const rowHeight = Math.max(
		MaterialDataTableConstants['material-data-table']['row-min-height'],
		MaterialDataTableConstants['material-data-table']['row-line-height'] * linesPerRow +
			MaterialDataTableConstants['material-data-table']['row-padding'] * 2
	);
	const tableHeights = {
		header: showHeader && TOTAL_TABLE_HEADER_HEIGHT || 0,
		row: rowHeight
	};
	const finalTableHeaderCreator = showHeader && tableHeaderCreator || noop;

	return createTable(tableDefinition, {
		tableHeights,
		tableHeaderCreator: finalTableHeaderCreator,
		RowComponent: DocumentSearchDataTableRow,
		...additionalTableArguments
	});
}
