import React from 'react';
import AllDoneIcon from '@mui/icons-material/DoneAll';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import Checkbox from './Checkbox.js';

export default function AllDoneButton(props) {
	return <Checkbox checkedIcon={<AllDoneIcon />} uncheckedIcon={<AllDoneIcon />} {...props} />;
}
AllDoneButton.propTypes = cloneWithoutProperties(Checkbox.propTypes, 'uncheckedIcon', 'checkedIcon');
AllDoneButton.defaultProps = cloneWithoutProperties(Checkbox.defaultProps, 'uncheckedIcon', 'checkedIcon');
