import Immutable from 'immutable';
import {createSelector} from 'reselect';

import createStepperSelectors from '../../../commons/selectors/createStepperSelectors.js';
import {
	createStep,
	createStepperLabelStaticText,
	StepperFooterStateBuilder
} from '../../../commons/utils/StepperUtils.js';
import selectFromProps from '../../../viewer/flux/selectors/selectFromProps.js';
import {
	DATA_FIELD_DESCRIPTION,
	DATA_FIELD_DOCUMENT_CLASS,
	DATA_FIELD_IMAGES,
	DATA_FIELD_UPLOAD_STATE,
	MAXIMUM_DESCRIPTION_FIELD_LENGTH,
	STEPPER_ID,
	UPLOAD_STATE_ERROR,
	UPLOAD_STATE_SUCESS,
	UPLOAD_STATE_UPLOAD_IDLE,
	UPLOAD_STATE_UPLOAD_IN_PROGRESS
} from '../../constants/ArchiveDocumentsConstants.js';
import ArchiveDocumentsTranslator from '../../i18n/ArchiveDocumentsTranslator.js';

function createSelectImageChoiceIsComplete(baseSelectors) {
	const {createSelectDataField} = baseSelectors;
	return createSelector(
		createSelectDataField(DATA_FIELD_IMAGES, Immutable.List()),
		images => !images.isEmpty()
	);
}

const PERFORM_UPLOAD_PHRASE = createStepperLabelStaticText('PerformUploadPhrase', ArchiveDocumentsTranslator);

function createSelectAdditionalDataIsComplete(baseSelectors) {
	const {createSelectDataField} = baseSelectors;
	return createSelector(
		createSelectDataField(DATA_FIELD_DESCRIPTION, ''),
		createSelectDataField(DATA_FIELD_DOCUMENT_CLASS),
		selectFromProps('documentClasses', Immutable.List()),
		(description, documentClassChoice, documentClasses) => (
			(documentClasses.isEmpty() || !documentClasses.isEmpty() && documentClassChoice !== null) &&
			description.length > 0 &&
			description.length <= MAXIMUM_DESCRIPTION_FIELD_LENGTH
		)
	);
}

function createSelectCreateAdditionalDataFooterState() {
	const footerStateBuilder = new StepperFooterStateBuilder();
	return () => footerStateBuilder.setNextLabel(PERFORM_UPLOAD_PHRASE).build();
}

function buildPerformUploadFooterState(data) {
	const uploadState = data.get(DATA_FIELD_UPLOAD_STATE, UPLOAD_STATE_UPLOAD_IDLE);
	let footerStateBuilder = new StepperFooterStateBuilder();
	if (uploadState === UPLOAD_STATE_UPLOAD_IN_PROGRESS || uploadState === UPLOAD_STATE_SUCESS) {
		footerStateBuilder = footerStateBuilder.hidePreviousButton();
	}
	return (uploadState === UPLOAD_STATE_ERROR
		? footerStateBuilder.nextIsClose()
		: footerStateBuilder.nextIsFinish()).build();
}

function createSelectPerformUploadFooterState(baseSelectors) {
	const {selectData: data} = baseSelectors;
	return createSelector(
		data, buildPerformUploadFooterState
	);
}

function createSelectUploadIsComplete(baseSelectors) {
	const {createSelectDataField} = baseSelectors;
	return createSelector(
		createSelectDataField(DATA_FIELD_UPLOAD_STATE, UPLOAD_STATE_UPLOAD_IDLE),
		uploadState => uploadState !== UPLOAD_STATE_UPLOAD_IN_PROGRESS
	);
}

const ARCHIVE_DOCUMENTS_STEPS = [
	createStep(createSelectImageChoiceIsComplete),
	createStep(createSelectAdditionalDataIsComplete, createSelectCreateAdditionalDataFooterState),
	createStep(createSelectUploadIsComplete, createSelectPerformUploadFooterState)
];

const STEPPER_SELECTORS = createStepperSelectors(STEPPER_ID, ARCHIVE_DOCUMENTS_STEPS);

export const {selectNrSteps} = STEPPER_SELECTORS;
export const {selectCurrentStep} = STEPPER_SELECTORS;

export const {selectData} = STEPPER_SELECTORS;
export const selectUploadState = STEPPER_SELECTORS.createSelectDataField(
	DATA_FIELD_UPLOAD_STATE, UPLOAD_STATE_UPLOAD_IDLE
);
export const {selectHasFinished} = STEPPER_SELECTORS;
export const {selectWasCancelled} = STEPPER_SELECTORS;
export const {selectFooterState} = STEPPER_SELECTORS;
export const {selectIsStepComplete} = STEPPER_SELECTORS;
