import {searchDocuments as searchDocumentsOperation} from '../../commons/api/DocumentApi.js';
import {createAction} from '../../commons/utils/ActionUtils.js';
import {BEGIN_SEARCH, PROCESS_SEARCH_RESULTS, SEARCH_ABORTED} from '../../search/constants/SearchActionTypes.js';
import {PERCENT_POSTFIX_FIELDS, PERCENT_PREFIX_FIELDS} from '../constants/DocumentSearchConstants.js';

const processSearchResultsAction = createAction(PROCESS_SEARCH_RESULTS);
const beginSearchAction = createAction(
	BEGIN_SEARCH,
	(searchNumber, queryParams, sortParams, start, limit) => ({searchNumber, queryParams, sortParams, start, limit}));
const abortSearchAction = createAction(SEARCH_ABORTED);

let staticDocumentSearchSearchNumber = 0;

function fetchResults(queryParams, sortParams, start, fetchLimit, limit) {
	staticDocumentSearchSearchNumber += 1;
	const thisSearchNumber = staticDocumentSearchSearchNumber;
	return dispatch => {
		dispatch(beginSearchAction(thisSearchNumber, queryParams, sortParams, start, limit));
		dispatch(processSearchResultsAction(
			searchDocumentsOperation(
				prepareParamsForFetch(queryParams),
				sortParams.toJS(),
				start, fetchLimit, limit
			).then(results => ({
				results,
				searchNumber: thisSearchNumber,
				queryParams
			}))
				.catch(() => dispatch(abortSearchAction(thisSearchNumber)))
		));
	};
}

function prepareParamsForFetch(params) {
	let preparedParams = trimParams(params);
	preparedParams = PERCENT_POSTFIX_FIELDS.reduce((fixedParams, fieldName) => {
		let nextParams = fixedParams;
		const value = nextParams.get(fieldName, null);
		if (Boolean(value) && value[value.length - 1] !== '%') {
			nextParams = nextParams.set(fieldName, `${value}%`);
		}
		return nextParams;
	}, preparedParams);

	preparedParams = PERCENT_PREFIX_FIELDS.reduce((fixedParams, fieldName) => {
		const value = fixedParams.get(fieldName, null);
		if (Boolean(value) && value[0] !== '%') {
			return fixedParams.set(fieldName, `%${value}`);
		}
		return fixedParams;
	}, preparedParams);

	return preparedParams.toObject();
}

function trimParams(params) {
	return params.map(value => ((Boolean(value) && typeof (value.trim) === 'function') ? value.trim() : value));
}

export {fetchResults};
