import React from 'react';
import Immutable from 'immutable';

import BrickBase from '../bricks/BrickBase.js';
import {declareBrick} from '../bricks/brickTools.js';
import {createLocalDateAsUTC} from '../commons/utils/DateUtils.js';
import generateUniqueKey from '../commons/utils/generateUniqueKey.js';
import {cancellable} from '../commons/utils/PromiseUtils.js';
import AbstractPatientSearchService from '../patient-search/AbstractPatientSearchService.js';

const PATIENT_ID_MAX_LENGTH = 32;
const PATIENT_ID_PREFIX = 'syn';
const PAT_ID_RANDOM_LENGTH = PATIENT_ID_MAX_LENGTH - PATIENT_ID_PREFIX.length;

export default class CreateOrderService extends BrickBase {
	constructor(fetchAetList, fetchOrderer, createExistingPatientOrder, createNewPatientOrder, patientSearchService) {
		super();
		this.fetchAetList = fetchAetList;
		this.fetchOrdererList = fetchOrderer;
		this.createExistingPatientOrderCall = createExistingPatientOrder;
		this.createNewPatientOrderCall = createNewPatientOrder;
		this.patientSearchService = patientSearchService;

		this.updateBrickState(() => ({
			pendingAetListFetch: null,
			aetList: null,
			ordererList: null,
			pendingOrdererListFetch: null,
			pendingCreateOrder: null,
			createdOrder: null,
			patient: null,
			aet: null,
			errors: null,
			state: CreateOrderService.State.BUSY
		}));

		this.subscribeTo(this.patientSearchService, this.onPatientSearchServiceChanged);
	}

	onPatientSearchServiceChanged() {
		if (this.onlyOnePatientFound()) {
			this.selectPatient(this.patientSearchService.getResults().get(0));
		}
	}

	onlyOnePatientFound() {
		return this.patientSearchService.getState() === AbstractPatientSearchService.State.SHOW_RESULTS &&
			this.patientSearchService.hasResults() &&
			this.patientSearchService.getResults().getTotalSize() === 1;
	}

	reset() {
		this.updateBrickState(oldState => {
			let nextState = CreateOrderService.cancelAetListLoad(oldState);
			nextState = CreateOrderService.cancelOrdererListLoad(nextState);
			nextState = this.loadAetList(nextState);
			nextState = this.loadOrdererList(nextState);
			const {aetList} = nextState;
			return {
				...nextState,
				patient: null,
				errors: null,
				aet: CreateOrderService.getDefaultAet(aetList),
				state: CreateOrderService.State.SELECT_PATIENT
			};
		});
	}

	resetAll() {
		this.reset();
		this.patientSearchService.reset();
	}

	start() {
		this.updateBrickState(oldState => {
			let nextState = this.loadAetList(oldState);
			nextState = this.loadOrdererList(nextState);
			return {
				...nextState,
				errors: null,
				state: CreateOrderService.State.SELECT_PATIENT
			};
		});
	}

	selectPatient(patientObject) {
		this.updateBrickState(oldState => {
			const nextWorkflowState = patientObject === null
				? CreateOrderService.State.SELECT_PATIENT
				: CreateOrderService.State.FILL_DETAILS;
			return {
				...oldState,
				patient: patientObject,
				state: nextWorkflowState
			};
		});
	}

	selectEmergencyPatient() {
		const emergencyPatient = Immutable.Map({
			patient_id: `${PATIENT_ID_PREFIX}${generateUniqueKey(PAT_ID_RANDOM_LENGTH)}`.toUpperCase(),
			first_name: 'N',
			last_name: 'N',
			birth_date: new Date('1990-01-01'),
			sex: 'O',
			emergency: true
		});
		this.patientSearchService.reset();
		this.selectPatient(emergencyPatient);
	}

	clearSelectedPatient() {
		this.selectPatient(null);
		if (this.onlyOnePatientFound()) {
			this.patientSearchService.editCriteria();
		}
	}

	selectAetById(aetId) {
		this.updateBrickState(oldState => {
			const {aetList} = oldState;
			const selectedAet = aetList ? aetList.find(aet => aet.get('id') === aetId) : null;
			return {
				...oldState,
				aet: selectedAet
			};
		});
	}

	selectCreateOrderCall() {
		const selectedPatient = this.getSelectedPatient();
		if (selectedPatient !== null) {
			const isNewPatient = selectedPatient.get('emergency');
			return (...orderParams) => {
				if (isNewPatient) {
					return this.createNewPatientOrderCall(selectedPatient.delete('emergency').toJS(), ...orderParams);
				}
				return this.createExistingPatientOrderCall(selectedPatient.get('id'), ...orderParams);
			};
		}
		throw new ReferenceError('Insufficient patient supplied to CreateOrderCall method determination.');
	}

	createOrder(serviceDescription, ordererAbk) {
		if (!this.canCreateOrder() || !serviceDescription) {
			this.updateBrickState(CreateOrderService.recordError, CreateOrderService.Error.ILLEGAL_STATE);
		} else {
			this.updateBrickState(oldState => {
				const createOrderCall = this.selectCreateOrderCall();
				const orderStartDate = createLocalDateAsUTC();
				const pendingCreateOrder = cancellable(createOrderCall(
					this.getSelectedAetId(),
					serviceDescription,
					ordererAbk,
					orderStartDate
				)).maybe(() => this.onCreateOrderSuccess(ordererAbk, serviceDescription, orderStartDate))
					.catch(this.onCreateOrderError)
					.cancelled(this.onCreateOrderCancelled);
				return {
					...oldState,
					pendingCreateOrder,
					state: CreateOrderService.State.BUSY
				};
			});
		}
	}

	onCreateOrderSuccess(ordererAbk, serviceDescription, orderStartDate) {
		this.updateBrickState(oldState => {
			const createdOrder = Immutable.Map({
				patient: this.getSelectedPatient(),
				aet: this.getSelectedAet(),
				serviceDescription,
				orderer: this.getOrdererList()
					.find(orderer => orderer.get('abk') === ordererAbk),
				orderStartDate
			});
			return {
				...oldState,
				state: CreateOrderService.State.FINISHED,
				pendingCreateOrder: null,
				createdOrder
			};
		});
	}

	onCreateOrderError() {
		this.updateBrickState(oldState => {
			const nextState = CreateOrderService.recordError(oldState, CreateOrderService.Error.CREATE_ORDER_FAILED);
			nextState.pendingCreateOrder = null;
			return nextState;
		});
	}

	onCreateOrderCancelled() {
		this.updateBrickState(oldState => {
			const {pendingCreateOrder} = oldState;
			let nextState = oldState;
			if (pendingCreateOrder) {
				pendingCreateOrder.cancel();
				nextState = {...nextState, pendingCreateOrder: null};
			}
			return nextState;
		});
	}

	loadAetList(oldState) {
		const {aetList, pendingAetListFetch} = oldState;
		let nextState = oldState;
		if (aetList === null && pendingAetListFetch === null) {
			nextState = {
				...oldState,
				pendingAetListFetch: cancellable(this.fetchAetList())
					.maybe(this.onAetListLoadSuccess)
					.catch(this.onAetListLoadError)
					.cancelled(this.onAetListLoadCancelled)
			};
		}
		return nextState;
	}

	onAetListLoadCancelled() {
		this.updateBrickState(CreateOrderService.cancelAetListLoad);
	}

	static cancelAetListLoad(oldState) {
		const {pendingAetListFetch} = oldState;
		let nextState = oldState;
		if (pendingAetListFetch) {
			pendingAetListFetch.cancel();
			nextState = {
				...oldState,
				aetList: null,
				pendingAetListFetch: null
			};
		}
		return nextState;
	}

	onAetListLoadSuccess(aetSearchResults) {
		this.updateBrickState(oldState => {
			let nextState = oldState;
			const aetList = aetSearchResults.getList()
				.map(aet => aet
					.update('services', Immutable.List(), services => services
						.map(service => service.get('display'))
					)
				);
			if (aetList.size === 0) {
				nextState = CreateOrderService.recordError(nextState, CreateOrderService.Error.AET_LIST_EMPTY);
			}
			return {
				...nextState,
				pendingAetListFetch: null,
				aetList,
				aet: CreateOrderService.getDefaultAet(aetList)
			};
		});
	}

	onAetListLoadError() {
		this.updateBrickState(oldState => ({
			...CreateOrderService.recordError(oldState, CreateOrderService.Error.FETCH_AET_LIST_FAILED),
			pendingAetListFetch: null
		}));
	}

	loadOrdererList(oldState) {
		const {ordererList, pendingOrdererListFetch} = oldState;
		let nextState = oldState;
		if (ordererList === null && pendingOrdererListFetch === null) {
			nextState = {
				...oldState,
				pendingOrdererListFetch: cancellable(this.fetchOrdererList())
					.maybe(this.onOrdererListLoadSuccess)
					.catch(this.onOrdererListLoadError)
					.cancelled(this.onOrdererListCancelled)
			};
		}
		return nextState;
	}

	onOrdererListCancelled() {
		this.updateBrickState(CreateOrderService.cancelOrdererListLoad);
	}

	static cancelOrdererListLoad(oldState) {
		const {pendingOrdererListFetch} = oldState;
		let nextState = oldState;
		if (pendingOrdererListFetch) {
			pendingOrdererListFetch.cancel();
			nextState = {
				...oldState,
				ordererList: null,
				pendingOrdererListFetch: null
			};
		}
		return nextState;
	}

	onOrdererListLoadSuccess(ordererSearchResults) {
		this.updateBrickState(oldState => {
			const ordererList = ordererSearchResults.getList();
			let nextState = oldState;
			if (ordererList.size === 0) {
				nextState = CreateOrderService.recordError(nextState, CreateOrderService.Error.ORDERER_LIST_EMPTY);
			}
			return {
				...nextState,
				ordererList,
				pendingOrdererListFetch: null
			};
		});
	}

	onOrdererListLoadError() {
		this.updateBrickState(oldState => ({
			...CreateOrderService.recordError(oldState, CreateOrderService.Error.FETCH_ORDERER_LIST_FAILED),
			pendingOrdererListFetch: null
		}));
	}

	isLoading() {
		const {pendingCreateOrder, pendingAetListFetch, pendingOrdererListFetch, state} = this.getBrickState();
		return pendingCreateOrder !== null || pendingAetListFetch !== null ||
			state === CreateOrderService.State.FILL_DETAILS && pendingOrdererListFetch !== null;
	}

	canCreateOrder() {
		const {pendingCreateOrder, aet, patient} = this.getBrickState();
		return pendingCreateOrder === null &&
			aet !== null &&
			patient !== null;
	}

	getAetList() {
		return this.getBrickState().aetList;
	}

	getSelectedAetId() {
		const selectedAet = this.getSelectedAet();
		return selectedAet ? selectedAet.get('id') : null;
	}

	getSelectedAet() {
		return this.getBrickState().aet;
	}

	static getDefaultAet(aetList) {
		return Immutable.List.isList(aetList) && aetList.size === 1 ? aetList.first() : null;
	}

	getServicesForSelectedAet() {
		const selectedAet = this.getSelectedAet();
		return selectedAet ? selectedAet.get('services') : Immutable.List();
	}

	getOrdererList() {
		return this.getBrickState().ordererList;
	}

	getSelectedPatient() {
		return this.getBrickState().patient;
	}

	getState() {
		return this.isLoading() ? CreateOrderService.State.BUSY : this.getBrickState().state;
	}

	static recordError(oldState, error) {
		let {errors} = oldState;
		if (errors === null) {
			errors = new Immutable.Set([error]);
		} else if (!errors.has(error)) {
			errors = errors.add(error);
		}
		return {...oldState, errors};
	}

	getErrors() {
		return this.getBrickState().errors;
	}

	getCreatedOrder() {
		return this.getBrickState().createdOrder;
	}
}

CreateOrderService.State = {
	BUSY: 'BUSY',
	SELECT_PATIENT: 'SELECT_PATIENT',
	FILL_DETAILS: 'FILL_DETAILS',
	FINISHED: 'FINISHED',
	ERROR: 'ERROR'
};
CreateOrderService.Error = {
	FETCH_AET_LIST_FAILED: 'FETCH_AET_LIST_FAILED',
	AET_LIST_EMPTY: 'AET_LIST_EMPTY',
	FETCH_ORDERER_LIST_FAILED: 'FETCH_ORDERER_LIST_FAILED',
	ORDERER_LIST_EMPTY: 'ORDERER_LIST_EMPTY',
	ILLEGAL_STATE: 'ILLEGAL_STATE',
	CREATE_ORDER_FAILED: 'CREATE_ORDER_FAILED'
};
declareBrick(CreateOrderService, [AbstractPatientSearchService]);
