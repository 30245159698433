import React from 'react';

import LandingPage from '../../commons/components/webview/LandingPage.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import GeneralTranslator from '../../i18n/translators/GeneralTranslator.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import {Body1, Body2} from '../../ui/components/typography/Typography.js';

const EMBEDDED_MODE_NOTE_TITLE = <SynFormattedMessage message='EmbeddedModeNoteTitle' translator={GeneralTranslator} />;
const EMBEDDED_MODE_NOTE_TEXT = (
	<VerticalLayout>
		<SynFormattedMessage element={Body1} message='EmbeddedModeNoteSubTitle' translator={GeneralTranslator} align='center' />
		<StatusMessage status={STATUS_FAILED}>
			<SynFormattedMessage element={Body2} message='EmbeddedModeNoteText' translator={GeneralTranslator} />
		</StatusMessage>
	</VerticalLayout>
);

export default function EmbeddedNotePage() {
	return (
		<LandingPage headerTemplate='loginPage_header' footerTemplate='loginPage_footer' title={EMBEDDED_MODE_NOTE_TITLE}>
			{EMBEDDED_MODE_NOTE_TEXT}
		</LandingPage>
	);
}
