import React from 'react';
import PropTypes from 'prop-types';

import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../commons/constants/StatusMessageConstants.js';
import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Column from '../../material-design/components/Column.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';
import UploadSummaryInfo from './UploadSummaryInfo.js';

import '../../../styles/commons/components/UploadErrorStatus.scss';

export default class UploadErrorStatus extends React.PureComponent {
	render() {
		const {imagesData, numUploadedFiles, initiateUpload} = this.props;
		const totalFiles = imagesData.count();

		return (
			<Column element={VerticalLayout} width={{xs: 4, sm: 4}} justify='center' align='center'
			        className='upload-error-status-column'>
				<StatusMessage status={STATUS_FAILED}>
					<SynFormattedMessage message='ErrorMessageShort' translator={ArchiveDocumentsTranslator} />
				</StatusMessage>
				<UploadSummaryInfo numUploadedFiles={numUploadedFiles} totalFiles={totalFiles} />
				<ErrorHint translator={ArchiveDocumentsTranslator} hintMessage='TechnicalIssueTryAgainLater'
								   solutionMessage='ReTryUpload' solution={initiateUpload} textCenter />
			</Column>
		);
	}
}

UploadErrorStatus.propTypes = {
	imagesData: immutablePropType,
	numUploadedFiles: PropTypes.number,
	initiateUpload: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};
