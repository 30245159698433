import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {shareReceiver as shareReceiverFluxApi} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import ShareReceiverLoader from '../../components/ShareReceiverLoader.js';
import {selectReceiverFilterOrResourceFilterString} from '../ShareDocumentDialogSelectors.js';

const {
	selectors: shareReceiverSelectors,
	actions: shareReceiverActions
} = shareReceiverFluxApi;

const ShareReceiverListContainer = connect(
	createStructuredSelector({
		shareReceivers: shareReceiverSelectors.getRawResponse,
		isLoading: shareReceiverSelectors.isLoadInProgress,
		requestedReceiverFilter: shareReceiverSelectors.selectFilterString,
		receiverFilter: selectReceiverFilterOrResourceFilterString
	}),
	bindActions({
		loadFiltered: shareReceiverActions.loadFiltered
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {receiverFilter} = stateProps || {};
		const {loadFiltered} = dispatchProps;
		return {
			...stateProps,
			...ownProps,
			loadFiltered: () => loadFiltered(receiverFilter)
		};
	}
)(ShareReceiverLoader);

export default ShareReceiverListContainer;
