import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName} from '../../../commons/utils/ReactUtils.js';

export default memoWithName(Search);

function Search(props) {
	const {className, children} = props;
	return (
		<div role='search' className={className}>
			{children}
		</div>
	);
}

Search.propTypes = {
	className: PropTypes.string
};
