import BrickBase from '../../bricks/BrickBase.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {withSelectors} from '../../commons/utils/FunctionUtils.js';
import {onlyMostResentCancellable, reusePending} from '../../commons/utils/PromiseUtils.js';
import {PERMIT_STATUS_REQUIRES_AUTHENTICATION, PERMIT_STATUS_UNKNOWN} from '../api/documentPermitApi.js';

const PERMIT_STATUS_UNDEFINED = Symbol('PERMIT_STATUS_UNDEFINED');

export default class DocumentPermitContext extends BrickBase {
	#getPermitStatus = null;
	constructor(getPermitStatusOperation) {
		super({
			isLoggedIn: null,
			permitId: null,
			permitStatus: PERMIT_STATUS_UNDEFINED,
			pendingStatusRequest: null
		});
		this.#getPermitStatus = DocumentPermitContext.#createGetStatusFetch(getPermitStatusOperation);
		this.subscribeTo(this, withSelectors(
			permitContext => permitContext.getBrickState().permitId,
			permitContext => permitContext.getBrickState().isLoggedIn,
			this.#loadPermit.bind(this)
		));
	}

	shutdown() {
		super.shutdown();
		const {pendingStatusRequest} = this.getBrickState();
		if (pendingStatusRequest) {
			pendingStatusRequest.cancel();
		}
	}

	loadPermit(permitId, isLoggedIn) {
		this.updateBrickState({permitId, isLoggedIn});
	}

	getPermitDocuments() {
		const {permitStatus} = this.getBrickState();
		return permitStatus?.documents;
	}

	getPermitValidUntil() {
		const {permitStatus} = this.getBrickState();
		return permitStatus?.valid_until;
	}

	reloadPermit() {
		const {permitId, isLoggedIn} = this.getBrickState();
		this.#loadPermit(permitId, isLoggedIn);
	}

	isBusy() {
		const {pendingStatusRequest} = this.getBrickState();
		return pendingStatusRequest !== null;
	}

	getPermitId() {
		return this.getBrickState().permitId;
	}

	canBeClaimed() {
		const {permitStatus} = this.getBrickState();
		return !this.#wasFetchedOnce() ||
			permitStatus === PERMIT_STATUS_REQUIRES_AUTHENTICATION || permitStatus === PERMIT_STATUS_UNKNOWN;
	}

	wasClaimedByCurrentUser() {
		const {permitStatus} = this.getBrickState();
		return this.#wasFetchedOnce() &&
			permitStatus !== PERMIT_STATUS_UNKNOWN &&
			permitStatus !== PERMIT_STATUS_REQUIRES_AUTHENTICATION &&
			permitStatus !== null && permitStatus !== undefined;
	}

	#wasFetchedOnce() {
		return this.getBrickState().permitStatus !== PERMIT_STATUS_UNDEFINED;
	}

	#loadPermit(permitId, isLoggedIn) {
		if (permitId) {
			const thisStatusFetch = this.#getPermitStatus(permitId, isLoggedIn)
				.maybe(permitStatus => {
					this.updateBrickState(previousState => ({
						...previousState,
						pendingStatusRequest: null,
						permitStatus
					}));
				});
			this.updateBrickState({
				pendingStatusRequest: thisStatusFetch
			});
		}
	}

	static #createGetStatusFetch(originalFetch) {
		const wrappedFetch = onlyMostResentCancellable(originalFetch);
		return reusePending(
			(permitId /*, isLoggedIn*/) => wrappedFetch(permitId)
		);
	}
}
declareBrick(DocumentPermitContext);
