import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import SelectionPurgerContainer from '../../commons/containers/SelectionPurgerContainer.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import CardHeader from '../../material-design/components/layout/CardHeader.js';
import Title from '../../material-design/components/Title.js';
import ResponsiveCard from '../../ui/components/ResponsiveCard.js';
import SearchLayout from './SearchLayout.js';

import '../../../styles/search/components/SearchBody.scss';

class SearchBody extends React.PureComponent {
	render() {
		const {
			showResults, title, resultsCardClassName, className, children
		} = this.props;
		const dataTableClassName = combineClassNames('data-table', 'material-data-table', resultsCardClassName);
		const childrenAsArray = React.Children.toArray(children);

		return (
			<SelectionPurgerContainer>
				<SearchLayout showResults={showResults} className={className}>
					<div key='search-form-container' className='search-form-container'>
						<SearchForm key='search-form' title={title} className={className}>
							{childrenAsArray[0]}
						</SearchForm>
					</div>
					<main aria-labelledby='searchResultsTitle' key='data-table-container'
							className='data-table-container'>
						<ResponsiveCard className={dataTableClassName} classNameRoot='data-table-wrapper'>
							{childrenAsArray[1]}
						</ResponsiveCard>
					</main>
				</SearchLayout>
			</SelectionPurgerContainer>
		);
	}
}

SearchBody.propTypes = {
	showResults: PropTypes.bool,
	title: PropTypes.node.isRequired,
	resultsCardClassName: PropTypes.string,
	className: PropTypes.string
};

function SearchForm(props) {
	const {title, className, children} = props;
	const searchFormClasses = classnames(
		'search-body--search-form',
		`${className}--search-form`
	);
	return (
		<ResponsiveCard className={searchFormClasses} classNameRoot='search-body--search-form-wrapper'>
			<CardHeader>
				<Title>
					{title}
				</Title>
			</CardHeader>
			{children}
		</ResponsiveCard>
	);
}

SearchForm.propTypes = {
	title: PropTypes.node.isRequired,
	className: PropTypes.string
};

export default SearchBody;
