import {createSelector} from 'reselect';

import {booleanValue} from '../../commons/utils/BooleanUtils.js';
import {querySelector} from '../../router/flux/selectors/LocationSelectors.js';
import {SEARCH_SHOW_RESULTS} from '../constants/SearchPropertyNames.js';

export const searchShowResults = createSelector(
	querySelector,
	query => booleanValue(query.get(SEARCH_SHOW_RESULTS, false))
);
