import React from 'react';
import PropTypes from 'prop-types';

import StepPerformUpload from '../../../webupload/components/StepPerformUpload.js';
import UploadReport from '../../../webupload/components/UploadReport.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import WebViewUploadController from '../bricks/WebViewUploadController.js';
import WebViewStepPrepareUploadList from './WebViewStepPrepareUploadList.js';
import WebViewStepPrepareUploadProducer from './WebViewStepPrepareUploadProducer.js';

export default function WebViewUploadSteps(props) {
	const {controllerState} = props;
	const StepperContentComponent = useMemoFactory(getStepComponent, controllerState);
	return StepperContentComponent && <StepperContentComponent controllerState={controllerState} {...props} />;
}

WebViewUploadSteps.propTypes = {
	controllerState: PropTypes.oneOf(
		Object.keys(WebViewUploadController.State).map(key => WebViewUploadController.State[key])
	)
};

function getStepComponent(uploadState) {
	switch (uploadState) {
		case WebViewUploadController.State.SELECT_PRODUCER:
			return WebViewStepPrepareUploadProducer;
		case WebViewUploadController.State.PREPARING:
		case WebViewUploadController.State.READY:
			return WebViewStepPrepareUploadList;
		case WebViewUploadController.State.UPLOADING:
			return StepPerformUpload;
		case WebViewUploadController.State.FINISHED:
			return UploadReport;
		default:
			return null;
	}
}
