import {StatusCodes} from 'http-status-codes';
import moment from 'moment';

import createFileUploadRequest from '../../commons/api/createFileUploadRequest.js';
import {getContentLocation, handleUnexpectedResponse} from '../../commons/utils/AjaxUtils.js';
import {formatLongDate} from '../../commons/utils/DateUtils.js';

export const ARCHIVE_DOCUMENT_URL = '/archivedocument';

export function uploadFile(file, context, instanceNumber = 0, fileName = null) {
	const {
		patientAimId, visitAimId,
		documentClassId, documentDescription,
		producer, genericDocumentCreatedWhen
	} = context;
	const validCreationDate = checkedCreationDate(genericDocumentCreatedWhen);
	const requestParams = {
		patient_aim_id: patientAimId,
		generic_instancenumber: instanceNumber,
		generic_document_description: documentDescription,
		generic_document_created_when: formatLongDate(validCreationDate, false),
		producer,
		...(visitAimId && {generic_visit_aim_id: visitAimId}),
		...(documentClassId && {generic_document_class_fk: documentClassId})
	};

	return fetch(ARCHIVE_DOCUMENT_URL, createFileUploadRequest(file, fileName, requestParams))
		.then(handleUnexpectedResponse(StatusCodes.OK, 'Document upload failed!'))
		.then(getContentLocation);
}

function checkedCreationDate(dateValue) {
	const dateMoment = moment(dateValue);
	if (!dateValue || !dateMoment.isValid()) {
		throw new Error('Provided genericDocumentCreatedWhen value is not a valid date!');
	}
	return dateMoment;
}

