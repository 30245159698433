import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import Collapsible from '../../commons/components/Collapsible.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SexComboFormField from '../../document-search/components/SexComboFormField.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Column from '../../material-design/components/Column.js';
import MaterialFormDateRangeField from '../../material-design/components/form/MaterialFormDateRangeField.js';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import FullWidthColumn from '../../material-design/components/layout/FullWidthColumn.js';
import Row from '../../material-design/components/Row.js';
import {ClickTrackedButton} from '../../metrics-collector/components/TrackedComponents.js';
import {ExpandLessIcon, ExpandMoreIcon} from '../../ui/components/icons/ExpandIcons.js';
import {PATIENT_SEARCH_METRICS_USAGE_CONTEXT} from '../PatientSearchConstants.js';

const EXTENDED_FIELD_NAMES = ['birth_date', 'first_name', 'last_name', 'sex'];

export default function ExtendedPatientSearchFields(props) {
	const {initialCriteria, fieldWidths, initiallyExpanded} = props;
	const [searchFieldsExpanded, setSearchFieldsExpanded] = useState(() => initiallyExpanded || initialCriteria?.some(
		(value, fieldName) => value && EXTENDED_FIELD_NAMES.includes(fieldName))
	);
	const handleFoldFieldsButton = useCallback(() => {
		setSearchFieldsExpanded(!searchFieldsExpanded);
	}, [searchFieldsExpanded]);
	const metricsKey = searchFieldsExpanded ? 'ExpandExtendedPatientSearchFields' : 'CollapseExtendedPatientSearchFields';

	return (
		<React.Fragment>
			<Row>
				<FullWidthColumn>
					<ClickTrackedButton id='expand-extended-search-fields' flat fullWidth onClick={handleFoldFieldsButton} labelLeftAlign
					                    endIcon={searchFieldsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					                    usageContext={PATIENT_SEARCH_METRICS_USAGE_CONTEXT} metricsKey={metricsKey}>
						<SynFormattedMessage message='ExtendedSearchCriteria' />
					</ClickTrackedButton>
				</FullWidthColumn>
			</Row>
			<Collapsible isExpanded={searchFieldsExpanded} element={Row}>
				<Column width={fieldWidths}>
					<SingleLineFormField fieldName='last_name' />
				</Column>
				<Column width={fieldWidths}>
					<SingleLineFormField fieldName='first_name' />
				</Column>
				<Column width={fieldWidths}>
					<SexComboFormField fieldName='sex' />
				</Column>
				<Column width={fieldWidths}>
					<MaterialFormDateRangeField fieldName='birth_date' />
				</Column>
			</Collapsible>
		</React.Fragment>
	);
}

ExtendedPatientSearchFields.propTypes = {
	initialCriteria: immutableMapPropType,
	fieldWidths: PropTypes.object,
	initiallyExpanded: PropTypes.bool
};
