import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SearchParametersList from '../../search/components/SearchParametersList.js';
import PatientSearchFormAccessors from '../PatientSearchFormAccessors.js';

function SearchResultsParameters(props) {
	const {parameters, showForm, translateParameter} = props;
	return <SearchParametersList translateParameter={translateParameter} parameters={parameters} showForm={showForm} />;
}

SearchResultsParameters.propTypes = {
	parameters: immutableMapPropType,
	showForm: PropTypes.func,
	translateParameter: PropTypes.func
};

const TranslatedSearchResultsParameters = connect(
	createStructuredSelector({
		translateParameter: state => label => PatientSearchFormAccessors.getFieldLabel(state, label)
	})
)(SearchResultsParameters);

export default TranslatedSearchResultsParameters;
