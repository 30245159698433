import React from 'react';
import PropTypes from 'prop-types';

import {immutableListPropType, immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import generateUniqueKey from '../../commons/utils/generateUniqueKey.js';
import {autoBind} from '../../commons/utils/ObjectUtils';
import {
	DATA_FIELD_DESCRIPTION,
	DATA_FIELD_DOCUMENT_CLASS,
	DATA_FIELD_PRODUCER,
	DATA_FIELD_VISIT
} from '../constants/ArchiveDocumentsConstants.js';
import DescriptionField from './DescriptionField.js';
import DocumentClassField from './DocumentClassField.js';
import ProducerField from './ProducerField.js';
import VisitField from './VisitField.js';

class AdditionalDataForm extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			producerUniqueKey: AdditionalDataForm.generateProducerKey()
		};

		autoBind(this,
			this.handleDescriptionChange,
			this.handleDocumentClassChange,
			this.handleVisitChange,
			this.handleProducerChange
		);
	}

	render() {
		const {data, archivePermissions, documentClasses, visits, enableVisitField} = this.props;
		const {producerUniqueKey} = this.state;

		return (
			<React.Fragment>
				<ProducerField onChange={this.handleProducerChange} archivePermissions={archivePermissions}
					producerChoice={data.get(DATA_FIELD_PRODUCER)} />
				<DocumentClassField onChange={this.handleDocumentClassChange} documentClasses={documentClasses}
										  documentClassChoice={data.get(DATA_FIELD_DOCUMENT_CLASS)} />
				<DescriptionField key={producerUniqueKey} onChange={this.handleDescriptionChange}
				                  archivePermission={archivePermissions.get(data.get(DATA_FIELD_PRODUCER))}
				                  descriptionChoice={data.get(DATA_FIELD_DESCRIPTION)} />
				{
					enableVisitField && <VisitField onChange={this.handleVisitChange} visits={visits}
				                                 visitChoice={data.get(DATA_FIELD_VISIT)} />
				}
			</React.Fragment>
		);
	}

	componentDidMount() {
		const {archivePermissions, documentClasses, visits} = this.props;
		this.handleDefaultField(archivePermissions, documentClasses, visits);
		this.handleDefaultDescription();
	}

	componentDidUpdate() {
		this.handleDefaultDescription();
	}

	static getDefaultSelection(data, field) {
		let defaultChoice;
		if (data.count() === 1) {
			defaultChoice = 0;
		} else {
			defaultChoice = data.findKey(dataEntry => dataEntry.isDefault);
		}
		return defaultChoice !== undefined && {[field]: defaultChoice};
	}

	handleDefaultField(archivePermissions, documentClasses, visits) {
		const {updateData, data} = this.props;
		const defaultData = {
			...(!data.has(DATA_FIELD_PRODUCER) &&
				AdditionalDataForm.getDefaultSelection(archivePermissions, DATA_FIELD_PRODUCER)),
			...(!data.has(DATA_FIELD_DOCUMENT_CLASS) &&
				AdditionalDataForm.getDefaultSelection(documentClasses, DATA_FIELD_DOCUMENT_CLASS)),
			...(!data.has(DATA_FIELD_VISIT) &&
				AdditionalDataForm.getDefaultSelection(visits, DATA_FIELD_VISIT))
		};
		updateData(data.merge(defaultData));
	}

	handleDefaultDescription() {
		const {updateData, data, archivePermissions} = this.props;
		if (data.has(DATA_FIELD_PRODUCER) && !data.has(DATA_FIELD_DESCRIPTION)) {
			const archivePermission = archivePermissions.get(data.get(DATA_FIELD_PRODUCER));
			const defaultDescription = archivePermission.descriptions.find(description => description.isDefault);
			const onlyOneKeyWord = archivePermission.descriptions.size === 1;
			if (onlyOneKeyWord) {
				updateData(data.set(DATA_FIELD_DESCRIPTION, archivePermission.descriptions.first().description));
				return;
			}
			if (defaultDescription !== undefined) {
				updateData(data.set(DATA_FIELD_DESCRIPTION, defaultDescription.description));
			}
		}
	}

	static generateProducerKey() {
		return `producer${generateUniqueKey()}`;
	}

	handleProducerChange(newValue) {
		const {updateData, data} = this.props;
		let newData = data.delete(DATA_FIELD_DESCRIPTION);
		if (typeof newValue === 'number') {
			newData = newData.set(DATA_FIELD_PRODUCER, newValue);
		} else {
			newData = newData.delete(DATA_FIELD_PRODUCER);
		}
		updateData(newData);
		this.setState({
			producerUniqueKey: AdditionalDataForm.generateProducerKey()
		});
	}

	handleDocumentClassChange(newValue) {
		const {updateData, data} = this.props;
		updateData(data.set(DATA_FIELD_DOCUMENT_CLASS, newValue));
	}

	handleVisitChange(newValue) {
		const {updateData, data} = this.props;
		if (typeof newValue === 'number') {
			updateData(data.set(DATA_FIELD_VISIT, newValue));
		} else {
			updateData(data.delete(DATA_FIELD_VISIT));
		}
	}

	handleDescriptionChange(newValue) {
		const {updateData, data} = this.props;
		if (newValue !== data.get(DATA_FIELD_DESCRIPTION)) {
			updateData(data.set(DATA_FIELD_DESCRIPTION, newValue === null ? '' : newValue));
		}
	}
}

AdditionalDataForm.propTypes = {
	data: immutablePropType.isRequired,
	updateData: PropTypes.func.isRequired,
	archivePermissions: immutablePropType.isRequired,
	documentClasses: immutableListPropType.isRequired,
	visits: immutablePropType.isRequired,
	enableVisitField: PropTypes.bool
};

AdditionalDataForm.defaultProps = {
	enableVisitField: true
};

export default AdditionalDataForm;
