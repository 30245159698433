import React from 'react';
import PropTypes from 'prop-types';

import Status from '../../a11y/components/landmarks/Status.js';
import DataGuard from '../../commons/components/data/DataGuard.js';
import Delayed from '../../commons/components/Delayed.js';
import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Spinner from '../../ui/components/Spinner.js';
import InboxMessagesTranslator from '../i18n/InboxMessagesTranslator.js';
import SimpleResultList from './SimpleResultList.js';

import '../../../styles/inbox/components/InboxResultsList.scss';

const DELAYED_SPINNER = (
	<CenterLayout>
		<Delayed delayMS={150}>
			<Spinner />
		</Delayed>
	</CenterLayout>
);

export default class InboxResultsList extends React.Component {
	render() {
		const {data, load, markSharesDone} = this.props;
		const isEmpty = !data || data.size === 0;
		return (
			<DataGuard doReload={this.#shouldReload()} isLoadNeeded={this.#isLoadNeeded()} data={data} load={load}
			           loadingIndicator={DELAYED_SPINNER} indicatorDelayMS={0}>
				{(isEmpty) ? InboxResultsList.renderEmpty() : InboxResultsList.renderResultList(data, markSharesDone)}
			</DataGuard>
		);
	}

	static renderEmpty() {
		return (
			<CenterLayout element={Status}>
				<SynFormattedMessage translator={InboxMessagesTranslator} message='NoShares' />
			</CenterLayout>
		);
	}

	static renderResultList(data, markSharesDone) {
		return <SimpleResultList key={data.size} data={data} markSharesDone={markSharesDone} />;
	}

	componentDidMount() {
		this.#refreshOnMount();
	}

	#shouldReload() {
		const {isLoading, isInboxDataUpToDate} = this.props;
		return !isLoading && !isInboxDataUpToDate;
	}

	#isLoadNeeded() {
		const {hasInboxData, isInboxDataUpToDate, isLoading} = this.props;
		return !isLoading && (!hasInboxData || !isInboxDataUpToDate);
	}

	#refreshOnMount() {
		const {load} = this.props;
		if (!this.#isLoadNeeded()) {
			load();
		}
	}
}

InboxResultsList.propTypes = {
	hasInboxData: PropTypes.bool,
	isInboxDataUpToDate: PropTypes.bool,
	isLoading: PropTypes.bool,
	load: PropTypes.func.isRequired,
	data: PropTypes.object,
	markSharesDone: PropTypes.func
};

InboxResultsList.defaultProps = {
	data: null
};
