import React from 'react';
import PropTypes from 'prop-types';

import ShareReceiverListConstants from '../../../constants/ShareReceiverListConstants.json';
import VirtualizedList from '../../commons/components/data/VirtualizedList.js';
import {ColumnCenterLayout} from '../../commons/components/layout/CenterLayout.js';
import Tappable from '../../commons/components/Tappable.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Button from '../../ui/components/Button.js';
import {DATA_FIELD_INDEX_KEY} from '../constants/ShareReceiverConstants.js';
import ShareDocumentDialogMessagesTranslator from '../i18n/ShareDocumentTranslator.js';
import ShareReceiverListItem from './ShareReceiverListItem.js';

const LIST_ITEM_HEIGHT = ShareReceiverListConstants['list-item-height'];

function receiversEqual(a, b) {
	return a === b || (a.get(DATA_FIELD_INDEX_KEY) === b.get(DATA_FIELD_INDEX_KEY));
}

class ShareReceiverList extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundRenderListItem = this.renderListItem.bind(this);
		this.boundRenderAutoSizedResultList = this.renderAutoSizedResultList.bind(this);
		this.boundRenderNoReceiversMessage = this.renderNoReceiversMessages.bind(this);
		this.boundOnCreateReceiver = this.onCreateReceiver.bind(this);
	}

	render() {
		const {className, shareReceivers} = this.props;
		const noShareReceivers = !shareReceivers || shareReceivers.getTotalSize() === 0;
		const renderFunction = noShareReceivers
			? this.boundRenderNoReceiversMessage
			: this.boundRenderAutoSizedResultList;
		return (
			<div className={className}>
				{renderFunction()}
			</div>
		);
	}

	renderAutoSizedResultList() {
		const {shareReceivers, selectedReceiver} = this.props;
		return (
			<VirtualizedList forceRenderOnSelectedReceiverChange={selectedReceiver}
								overscanCount={5} itemCount={shareReceivers.getTotalSize()} itemSize={LIST_ITEM_HEIGHT}>
				{this.boundRenderListItem}
			</VirtualizedList>
		);
	}

	renderListItem(rowProps) {
		const {index, style} = rowProps;
		const {shareReceivers} = this.props;
		const result = shareReceivers.get(index);
		return (
			<Tappable onClick={this.createOnClick(result)} style={style}>
				<ShareReceiverListItem shareReceiver={result} selected={this.isSelected(result)} />
			</Tappable>
		);
	}

	renderNoReceiversMessages() {
		return (
			<ColumnCenterLayout fitParent>
				<SynFormattedMessage translator={ShareDocumentDialogMessagesTranslator} message='NoReceiversMessage' />
				<Button flat onClick={this.boundOnCreateReceiver}>
					<SynFormattedMessage translator={ShareDocumentDialogMessagesTranslator} message='TabTitleCreateReceiver' />
				</Button>
			</ColumnCenterLayout>
		);
	}

	isSelected(receiver) {
		const {selectedReceiver} = this.props;
		return Boolean(selectedReceiver) && Boolean(receiver) && receiversEqual(receiver, selectedReceiver);
	}

	createOnClick(receiver) {
		return () => {
			const {onReceiverSelected} = this.props;
			callSafe(onReceiverSelected, receiver);
		};
	}

	getRenderIndicationProps() {
		const {shareReceivers, selectedReceiver} = this.props;
		return {renderedData: shareReceivers, renderedSelectedReceiver: selectedReceiver};
	}

	onCreateReceiver() {
		const {switchToCreateReceiver} = this.props;
		callSafe(switchToCreateReceiver);
	}
}

ShareReceiverList.propTypes = {
	shareReceivers: PropTypes.object,
	selectedReceiver: PropTypes.object,
	switchToCreateReceiver: PropTypes.func,
	className: PropTypes.string,
	onReceiverSelected: PropTypes.func
};

export default ShareReceiverList;
