import React from 'react';
import PropTypes from 'prop-types';

import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import useTranslator from '../../i18n/hooks/useTranslator.js';
import SuggestComboBox from '../../ui/components/SuggestComboBox.js';
import SuggestTextField from '../../ui/components/SuggestTextField.js';
import {MAXIMUM_DESCRIPTION_FIELD_LENGTH} from '../constants/ArchiveDocumentsConstants.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

const ELEMENT_ID = 'archive-document-description-auto-select';

function DescriptionField(props) {
	const {archivePermission} = props;
	const getArchiveDocumentsTranslation = useTranslator(ArchiveDocumentsTranslator);
	const descriptionLabel = getArchiveDocumentsTranslation('Description');
	const enabled = archivePermission &&
		(archivePermission.isDescriptionEditable || !archivePermission.descriptions.isEmpty());
	if (enabled) {
		return renderEnabled(descriptionLabel, getArchiveDocumentsTranslation, props);
	}
	return renderDisabled(descriptionLabel);
}

DescriptionField.propTypes = {
	descriptionChoice: PropTypes.string,
	onChange: PropTypes.func,
	archivePermission: immutablePropType
};

function renderEnabled(label, translate, componentProps) {
	const {archivePermission, onChange, descriptionChoice} = componentProps;
	let errorMessage = '';
	if (isDescriptionToShort(descriptionChoice)) {
		errorMessage = translate('NoDescriptionErrorMessage');
	} else if (isDescriptionToLong(descriptionChoice)) {
		errorMessage = translate(
			'maxDescriptionLengthErrorMessage',
			{maxLength: MAXIMUM_DESCRIPTION_FIELD_LENGTH}
		);
	}
	const commonProps = {
		id: ELEMENT_ID,
		label,
		onChange,
		value: descriptionChoice,
		hint: errorMessage,
		error: Boolean(errorMessage)
	};
	const options = archivePermission.descriptions.map(entry => entry.description).toArray();
	if (archivePermission.isDescriptionEditable) {
		return <SuggestTextField suggestions={options} {...commonProps} />;
	}
	return <SuggestComboBox options={options} {...commonProps} />;
}

function isDescriptionToShort(description) {
	return !description || description.length < 1;
}

function isDescriptionToLong(description) {
	return description && description.length > MAXIMUM_DESCRIPTION_FIELD_LENGTH;
}

function renderDisabled(label) {
	return <SuggestTextField disabled label={label} id={ELEMENT_ID} suggestions={[]} />;
}

export default React.memo(DescriptionField);
