import React from 'react';

import PatientContextMonitor from '../../../webupload/bricks/PatientContextMonitor.js';
import UploadFilesBrowserController from '../../../webupload/bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../../../webupload/bricks/UploadFilesCollection.js';
import UploadFilesCollector from '../../../webupload/bricks/UploadFilesCollector/index.js';
import UploadWizardTranslator from '../../../webupload/i18n/UploadWizardTranslator.js';
import {uploadFile} from '../../archive-documents/api/ArchiveDocumentApi.js';
import AutoBrickFactory from '../../bricks/components/AutoBrickFactory.js';
import {
	ALLOWED_UPLOAD_FILE_EXTENSIONS,
	ALLOWED_UPLOAD_FILE_FILE_SIZE
} from '../../commons/constants/SynSettingsConstants.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext.js';
import {scheduleTask} from '../../scheduling/api/index.js';
import WebViewUploadContext from '../bricks/WebViewUploadContext.js';
import WebViewUploadController from '../bricks/WebViewUploadController.js';
import WebViewUploadSteps from './WebViewUploadSteps.js';
import WebViewUploadWizard from './WebViewUploadWizard.js';

export default function WebViewUploadWizardContext(/*props*/) {
	return (
		<React.Fragment>
			<AutoBrickFactory brickType={UploadFilesCollection} />
			<AutoBrickFactory brickType={UploadFilesCollector} brickFactory={createUploadFilesCollector}
			                  additionalDependencies={[UploadFilesCollection]} />
			<AutoBrickFactory brickType={PatientContextMonitor} />
			<AutoBrickFactory brickType={WebViewUploadContext} />
			<AutoBrickFactory brickType={WebViewUploadController} brickFactory={createWebViewUploadController} />
			<AutoBrickFactory brickType={UploadFilesBrowserController} />
			<TranslationProvider translator={UploadWizardTranslator}>
				<WebViewUploadWizard stepsComponent={WebViewUploadSteps} />
			</TranslationProvider>
		</React.Fragment>
	);
}

function createUploadFilesCollector(uploadFilesCollection) {
	return new UploadFilesCollector(
		ALLOWED_UPLOAD_FILE_EXTENSIONS,
		ALLOWED_UPLOAD_FILE_FILE_SIZE,
		uploadFilesCollection.addFileList, uploadFilesCollection.setPreparedContainerValue
	);
}

function createWebViewUploadController(...bricks) {
	return new WebViewUploadController(...bricks, scheduleTask, uploadFile);
}
