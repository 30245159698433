function resetOnChangeHandler(fieldToReset, condition = () => true) {
	return function resetHandler(currentValue, newValue, fieldMetaData, updaters) {
		if (condition(currentValue, newValue)) {
			return updaters[fieldToReset](fieldMetaData[fieldToReset].defaultValue.get('value'));
		}
		return null;
	};
}

export {resetOnChangeHandler as reset};
