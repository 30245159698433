import create from '../../commons/api/operations/create.js';
import {formatLongDate} from '../../commons/utils/DateUtils.js';

const ORDER_URL = '/order';

export function createOrderForExistingPatient(patientId, aetId, serviceDescription, ordererAbk, orderStartDate) {
	const orderDescription = {
		...basicOrderDescription(aetId, serviceDescription, ordererAbk, orderStartDate),
		patient_id: patientId
	};
	return createOrder(orderDescription);
}

export function createOrderForNewPatient(patientQuintuple, aetId, serviceDescription, ordererAbk, orderStartDate) {
	const {
		patient_id: patientId, first_name: firstName, last_name: lastName, sex, birth_date: birthDate
	} = patientQuintuple;
	const orderDescription = {
		...basicOrderDescription(aetId, serviceDescription, ordererAbk, orderStartDate),
		patient_patient_id: patientId,
		first_name: firstName,
		last_name: lastName,
		sex_fk: sex,
		birth_date: birthDate
	};
	return createOrder(orderDescription);
}

function basicOrderDescription(aetId, serviceDescription, ordererAbk, orderStartDate) {
	const orderDescription = {
		aet_id: Number(aetId),
		service_description: serviceDescription
	};
	if (ordererAbk) {
		orderDescription.orderer_abk = ordererAbk;
	}
	if (orderStartDate) {
		orderDescription.order_start_date = formatLongDate(orderStartDate);
	}
	return orderDescription;
}

function createOrder(orderDescription) {
	return create(ORDER_URL, orderDescription);
}
