import PatientContext from '../../../webupload/bricks/PatientContext.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import AbstractPatientSearchService from '../../patient-search/AbstractPatientSearchService.js';

export default class WebViewUploadPatientContext extends PatientContext {
	constructor(patientSearchService) {
		super();
		this.updateBrickState(oldState => ({
			...oldState,
			patientAimId: null
		}));

		this.patientSearchService = patientSearchService;
		this.memoizedOnPatientSearchResultsChanged = memoizeLast(this.onPatientSearchResultsChanged);
		this.subscribeTo(
			patientSearchService, service => this.memoizedOnPatientSearchResultsChanged(service.getResults())
		);
	}

	onPatientSearchResultsChanged(results) {
		if (this.onlyOnePatientFound()) {
			this.loadPatient(results.get(0));
		}
	}

	onlyOnePatientFound() {
		return this.patientSearchService.getState() === AbstractPatientSearchService.State.SHOW_RESULTS &&
			this.patientSearchService.hasResults() &&
			this.patientSearchService.getResults().getTotalSize() === 1;
	}

	loadPatient(patient) {
		this.updateBrickState(oldState => {
			super.loadPatient(patient);
			return {
				...oldState,
				patientAimId: getOrNull(patient, 'id')
			};
		});
	}

	getPatientAIMId() {
		return this.getBrickState().patientAimId;
	}

	clearPatient() {
		this.updateBrickState(oldState => ({
			...oldState,
			patient: null,
			patientAimId: null
		}));

		if (this.onlyOnePatientFound()) {
			this.patientSearchService.editCriteria();
		}
	}
}
declareBrick(WebViewUploadPatientContext);

function getOrNull(map, field) {
	return map ? map.get(field) : null;
}
