import React from 'react';

import ArchiveDialogButton from '../../archive-documents/components/ArchiveDialogButton.js';
import GuardedUserInfo from '../../authentication/flux/containers/GuardedUserInfo.js';
import AppBar from '../../commons/components/AppBar.js';
import AppBarOverflowButton from '../../commons/components/AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../commons/components/AppBarPrimaryContent.js';
import AppBarSidebarExtensionArea from '../../commons/components/webview/AppBarSidebarExtensionArea.js';
import PopupHelpButton from '../../commons/components/webview/PopupHelpButton.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import PrintIcon from '../../ui/components/icons/PrintIcon.js';
import MenuEntry from '../../ui/components/MenuEntry.js';
import {WebViewPopupHelp} from '../../viewer/components/ViewerPopupHelp.js';
import GuardedPatientInfoContainer from '../flux/containers/GuardedPatientInfoContainer.js';
import PatientDetailsBackLinkContainer from '../flux/containers/PatientDetailsBackLinkContainer.js';

import '../../../styles/patient-details/components/PatientDetailsAppBar.scss';

export default function PatientDetailsAppBar(props) {
	const {showPrintLayout} = props;
	return (showPrintLayout)
		? renderAppBarInPrintMode()
		: renderAppBar(props);
}

function renderAppBarInPrintMode() {
	return (
		<AppBar>
			<GuardedPatientInfoContainer />
		</AppBar>
	);
}

function renderAppBar(appBarProps) {
	const {
		viewerShown, isMobileDevice, anyPrintableViewerVisible, isSmallDevice, archiveDocumentsButtonVisible,
		doPrint
	} = appBarProps;

	const shouldShowHelpButton = !isMobileDevice || viewerShown;

	const shouldShowArchiveMenuEntry = archiveDocumentsButtonVisible && isSmallDevice && !viewerShown;
	const shouldShowPrintMenuEntry = anyPrintableViewerVisible && shouldShowHelpButton;
	const archiveMenuEntry = shouldShowArchiveMenuEntry && <ArchiveDialogButton asMenu />;
	const printMenuEntry = shouldShowPrintMenuEntry && renderPrintMenuEntry(doPrint);

	return (
		<AppBar>
			{appBarPrimaryContent()}
			{photoUploadButton(archiveDocumentsButtonVisible, isSmallDevice, viewerShown)}
			{shouldShowHelpButton && <PopupHelpButton helpComponent={WebViewPopupHelp} />}
			{appBarOverflowButton(isSmallDevice, archiveMenuEntry, printMenuEntry)}
			{appBarSidebarExtensionArea(printMenuEntry)}
		</AppBar>
	);
}

function photoUploadButton(archiveDocumentsButtonVisible, isSmallDevice, viewerShown) {
	const shouldShowPhotoUploadButton = archiveDocumentsButtonVisible && !isSmallDevice && !viewerShown;
	return shouldShowPhotoUploadButton && <ArchiveDialogButton />;
}

function renderPrintMenuEntry(printCallback) {
	const menuText = <SynFormattedMessage message='DoPrint' translator={ViewerTranslator} />;
	const menuIcon = <PrintIcon />;
	return <MenuEntry id='doPrintMenuEntry' onClick={printCallback} icon={menuIcon} primaryText={menuText} />;
}

function appBarPrimaryContent() {
	return (
		<AppBarPrimaryContent menuButton={BACK_BUTTON}>
			<GuardedPatientInfoContainer />
		</AppBarPrimaryContent>
	);
}

function appBarOverflowButton(isSmallDevice, doArchiveImagesEntry, doPrintMenuEntry) {
	return (
		<AppBarOverflowButton visible={isSmallDevice}>
			{doArchiveImagesEntry}
			{doPrintMenuEntry}
		</AppBarOverflowButton>
	);
}

function appBarSidebarExtensionArea(doPrintMenuEntry) {
	return (
		<AppBarSidebarExtensionArea className='webview-details-app-bar--user-info'>
			<GuardedUserInfo />
			<AppBarOverflowButton>
				{doPrintMenuEntry}
			</AppBarOverflowButton>
		</AppBarSidebarExtensionArea>
	);
}

const BACK_BUTTON = (
	<PatientDetailsBackLinkContainer>
		<BackIcon />
	</PatientDetailsBackLinkContainer>
);
