import React from 'react';
import Immutable from 'immutable';
import {createSelector, createStructuredSelector} from 'reselect';

import createDataTableContainer from '../../../commons/containers/data/createDataTableContainer.js';
import connectWithInstanceSelector from '../../../commons/flux/connectWithInstanceSelector.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {locationSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {SEARCH_SEEK_POSITION} from '../../../search/constants/SearchPropertyNames.js';
import {searchShowResults} from '../../../search/flux/SearchSelectors.js';
import {MAX_LIMIT} from '../../constants/DocumentSearchConstants.js';
import {fetchResults} from '../DocumentSearchActions.js';
import {
	documentSearchFetchInProgress,
	documentSearchQueryParams,
	documentSearchQueryParamsUsedToRetrieveResultList,
	documentSearchResults,
	documentSearchSortParams
} from '../DocumentSearchSelectors.js';

const THREE = 3;
const ROWS_PER_PAGE_FETCH_WINDOW_FACTOR = 3;
const ROWS_PER_PAGE_FETCH_FACTOR = ROWS_PER_PAGE_FETCH_WINDOW_FACTOR * THREE;

function createStartIndexSelector(seekPositionSelector, rowsPerPage) {
	return createSelector(seekPositionSelector,
		seekPosition => {
			const offsetFromPageStart = (seekPosition % rowsPerPage);
			return seekPosition - offsetFromPageStart;
		}
	);
}

function createToSelector(resultListSelector, startIndexSelector, rowsPerPage) {
	return createSelector(
		resultListSelector, startIndexSelector,
		(resultList, startIndex) => {
			const to = startIndex + rowsPerPage;
			return (resultList === null) ? to : Math.min(to, resultList.getTotalSize());
		}
	);
}

function createIsResultListValidSelector(
		resultListSelector, queryParamsUsedToRetrieveResultListSelector, queryParamsSelector, sortParamsSelector,
		startIndexSelector, toSelector
) {
	return createSelector(
		resultListSelector,
		queryParamsUsedToRetrieveResultListSelector,
		queryParamsSelector,
		sortParamsSelector,
		startIndexSelector,
		toSelector,
		(resultList, queryParamsUsedToRetrieveResultList, queryParams, sortParams, startIndex, to) => {
			const noQueryParamsAvailable = (queryParams === null || queryParams.size === 0);
			let isValid = (resultList === null && noQueryParamsAvailable);

			if (!isValid && resultList !== null) {
				const queryParamMatches = Immutable.is(queryParams, queryParamsUsedToRetrieveResultList);
				const sortInfoMatches = Immutable.is(sortParams, resultList.getSortInfo());
				const dataIsInRange =
					(startIndex >= resultList.getRawDataStart()) &&
					to <= (resultList.getRawDataStart() + resultList.getLocalSize());

				isValid = queryParamMatches && sortInfoMatches && dataIsInRange;
			}

			return isValid;
		}
	);
}

function createIsCompatibleFetchRequestInProgressSelector(
		resultListSelector, queryParamsSelector, sortParamsSelector, startIndexSelector, fetchInProgressSelector,
		fetchWindowSize
) {
	return createSelector(
		resultListSelector, queryParamsSelector, sortParamsSelector, startIndexSelector, fetchInProgressSelector,
		(resultList, queryParams, sortParams, startIndex, fetchInProgress) => fetchInProgress !== null &&
			(Immutable.is(fetchInProgress.get('queryParams'), queryParams) &&
				Immutable.is(fetchInProgress.get('sortParams'), sortParams) &&
				(startIndex + fetchWindowSize) <= (fetchInProgress.get('start') + fetchInProgress.get('limit')))
	);
}

function createIsEnoughDataAvailableSelector(resultListSelector, startIndexSelector, fetchWindowSize) {
	return createSelector(
		resultListSelector, startIndexSelector,
		(resultList, startIndex) => {
			const windowStart = startIndex - (startIndex % fetchWindowSize);
			return resultList !== null && (resultList.getTotalSize() === 0 ||
				((resultList.getRawDataStart() <= Math.max(windowStart - fetchWindowSize, 0)) &&
					(Math.min((fetchWindowSize * 2) + windowStart, resultList.getTotalSize()) <=
						(resultList.getRawDataStart() + resultList.getLocalSize())
					))
			);
		}
	);
}

function createIsFetchNeededSelector(
		queryParamsSelector, isResultListValidSelector, isCompatibleFetchRequestInProgressSelector,
		isEnoughDataAvailableSelector
) {
	return createSelector(
		queryParamsSelector,
		isResultListValidSelector,
		isCompatibleFetchRequestInProgressSelector,
		isEnoughDataAvailableSelector,
		searchShowResults,
		(queryParams, isResultListValid, isCompatibleFetchRequestInProgress, isEnoughtDataAvailable, showResults) => {
			const canFetch = queryParams.size > 0;
			const dataRequired = !isResultListValid || !isEnoughtDataAvailable;
			return showResults && canFetch && dataRequired && !isCompatibleFetchRequestInProgress;
		}
	);
}

function createIsFetchInProgressSelector(fetchInProgressSelector) {
	return createSelector(
		fetchInProgressSelector,
		fetchInProgress => fetchInProgress !== null
	);
}

function createFinalResultListSelector(resultListSelector, isResultListValidSelector) {
	return createSelector(
		resultListSelector,
		isResultListValidSelector,
		(resultList, isValid) => (isValid && resultList) || null
	);
}

function createDataTableSelector(
		resultListSelector, queryParamsUsedToRetrieveResultListSelector, fetchInProgressSelector, queryParamsSelector,
		sortParamsSelector, seekParamName, rowsPerPage
) {
	const seekPositionSelector = createSelector(querySelector, query => query.get(seekParamName, 0));
	const startIndexSelector = createStartIndexSelector(seekPositionSelector, rowsPerPage);
	const toSelector = createToSelector(resultListSelector, startIndexSelector, rowsPerPage);
	const fetchWindowSize = rowsPerPage * ROWS_PER_PAGE_FETCH_WINDOW_FACTOR;
	const fetchStartIndexSelector = createSelector(
		startIndexSelector,
		startIndex => {
			const windowStart = startIndex - (startIndex % fetchWindowSize);
			return Math.max(0, windowStart - fetchWindowSize);
		}
	);
	const isResultListValidSelector = createIsResultListValidSelector(
		resultListSelector, queryParamsUsedToRetrieveResultListSelector, queryParamsSelector, sortParamsSelector,
		startIndexSelector, toSelector
	);
	const isCompatibleFetchRequestInProgressSelector = createIsCompatibleFetchRequestInProgressSelector(
		resultListSelector, queryParamsSelector, sortParamsSelector, startIndexSelector, fetchInProgressSelector,
		fetchWindowSize
	);
	const isEnoughDataAvailableSelector = createIsEnoughDataAvailableSelector(
		resultListSelector, startIndexSelector, fetchWindowSize
	);
	const isFetchInProgressSelector = createIsFetchInProgressSelector(fetchInProgressSelector);
	const isFetchNeededSelector = createIsFetchNeededSelector(
		queryParamsSelector, isResultListValidSelector, isCompatibleFetchRequestInProgressSelector,
		isEnoughDataAvailableSelector
	);
	const finalResultListSelector = createFinalResultListSelector(resultListSelector, isResultListValidSelector);
	const hasPreviousPageSelector = createSelector(startIndexSelector, startIndex => startIndex > 0);
	const hasNextPageSelector = createSelector(
		startIndexSelector, finalResultListSelector,
		(startIndex, resultList) => (resultList !== null && startIndex + rowsPerPage < resultList.getTotalSize())
	);
	const previousPageLocationSelector = createSelector(
		hasPreviousPageSelector, locationSelector, startIndexSelector,
		(hasPreviousPage, location, startIndex) => (hasPreviousPage && location.setIn(['query', seekParamName], Math.max(0, startIndex - rowsPerPage))) || null
	);
	const nextPageLocationSelector = createSelector(
		hasNextPageSelector, locationSelector, startIndexSelector,
		(hasNextPage, location, startIndex) => (hasNextPage && location.setIn(['query', seekParamName], startIndex + rowsPerPage)) || null
	);
	return createStructuredSelector({
		queryParams: queryParamsSelector,
		sortParams: sortParamsSelector,
		startIndex: startIndexSelector,
		to: toSelector,
		isFetchInProgress: isFetchInProgressSelector,
		isFetchNeeded: isFetchNeededSelector,
		resultList: finalResultListSelector,
		fetchStartIndex: fetchStartIndexSelector,
		previousPageLocation: previousPageLocationSelector,
		nextPageLocation: nextPageLocationSelector
	});
}

export default createDataTableContainer(
	connectWithInstanceSelector(
		props => createDataTableSelector(
			documentSearchResults,
			documentSearchQueryParamsUsedToRetrieveResultList,
			documentSearchFetchInProgress,
			documentSearchQueryParams,
			documentSearchSortParams,
			SEARCH_SEEK_POSITION,
			props.rowsPerPage
		),
		bindActions({
			fetchResults
		}),
		function mergeStateToProps(stateProps, dispatchProps, ownProps) {
			return {
				...ownProps,
				...stateProps,
				nrResults: stateProps.resultList?.getTotalSize(),
				fetchResults() {
					const {queryParams, sortParams, fetchStartIndex} = stateProps;
					const {rowsPerPage} = ownProps;
					const fetchSize = rowsPerPage * ROWS_PER_PAGE_FETCH_FACTOR;
					if (stateProps.isFetchNeeded) {
						dispatchProps.fetchResults(queryParams, sortParams, fetchStartIndex, fetchSize, MAX_LIMIT + 1);
					}
				}
			};
		}
	)
);
