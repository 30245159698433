import React from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material/styles';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {NameColumn} from '../../../webupload/components/UploadFilesList/Columns.js';
import Main from '../../a11y/components/landmarks/Main';
import useBrick from '../../bricks/hooks/useBrick.js';
import Link from '../../commons/components/Link.js';
import ScrollView from '../../commons/components/ScrollView.js';
import LinkContainer from '../../commons/containers/LinkContainer.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {getRemainingDays} from '../../commons/utils/DateUtils.js';
import {webAppURL} from '../../commons/WebViewHelpers.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {locationSelector} from '../../router/flux/selectors/LocationSelectors.js';
import {toUrlString} from '../../router/LocationUtils.js';
import CardContent from '../../ui/components/Card/CardContent.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import ListContainer from '../../ui/components/List/ListContainer.js';
import ListRow from '../../ui/components/List/ListRow.js';
import ResponsiveCard from '../../ui/components/ResponsiveCard.js';
import Ellipsis from '../../ui/components/typography/Ellipsis.js';
import {Body1} from '../../ui/components/typography/Typography.js';
import DocumentPermitContext from '../bricks/DocumentPermitContext.js';

import '../../../styles/commons/components/data/DataTable.scss';
import '../../../styles/material-design/components/DataTable.scss';

const styledLinkComponent = styled(Link)({maxWidth: '100%'});

export default function DocumentPermitClaimSuccess() {
	const {documents, validUntil} = useBrick(DocumentPermitContext, getDocumentPermitContextProps);
	const remainingDays = useMemoFactory(getRemainingDays, validUntil);
	return (
		<ResponsiveCard wrapperElement={Main} className='data-table material-data-table'>
			<CardContent component={VerticalLayout} justify='start' sx={{overflow: 'hidden'}}>
				<SynFormattedMessage element={Body1} pb={2} message='ClaimWasSuccessful' messageParams={{remainingDays, validUntil}} />
				<ScrollView>
					<ListContainer className='table-body'>
						<PermitDocumentsList documents={documents} />
					</ListContainer>
				</ScrollView>
			</CardContent>
		</ResponsiveCard>
	);
}

function PermitDocumentsList(props) {
	const {documents} = props;
	const currentLocation = useSelector(locationSelector);
	if (documents.length === 0) {
		return <SynFormattedMessage message='EmptyPermitsList' />;
	}
	return documents.map(document => (
		<ListRow key={document.id} style={{height: '48px'}}>
			<NameColumn>
				<LinkContainer linkComponent={styledLinkComponent} path={webAppURL('patient')}
				               query={Immutable.Map.of('document_id', document.id, 'refererLocation', toUrlString(currentLocation))}>
					<Ellipsis>
						{document.document_information}
					</Ellipsis>
				</LinkContainer>
			</NameColumn>
		</ListRow>
	));
}

PermitDocumentsList.propTypes = {
	documents: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		document_information: PropTypes.string
	}))
};

function getDocumentPermitContextProps(service) {
	return {
		documents: service.getPermitDocuments(),
		validUntil: service.getPermitValidUntil()
	};
}
