import React from 'react';
import PropTypes from 'prop-types';

import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import IconButton from '../../ui/components/IconButton.js';
import FlashlightIcon from '../../ui/components/icons/FlashlightIcon.js';
import FlashlightOffIcon from '../../ui/components/icons/FlashlightOffIcon.js';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout.js';
import NotchInset from '../../ui/components/layout/NotchInset.js';
import SuggestComboBox from '../../ui/components/SuggestComboBox.js';
import {getMedian} from '../../viewer/utils/MathUtils.js';
import {
	BARCODE_TYPE_CODABAR,
	BARCODE_TYPE_CODE_39,
	BARCODE_TYPE_CODE_93,
	BARCODE_TYPE_CODE_128,
	BARCODE_TYPE_EAN_8,
	BARCODE_TYPE_EAN_13,
	BARCODE_TYPE_EAN_13_EAN_5_EAN_2,
	BARCODE_TYPE_I2OF5,
	BARCODE_TYPE_UPC_A,
	BARCODE_TYPE_UPC_E
} from '../constants/BarcodeScannerTypes.js';
import {parseBarcodeTypes, postProcessBarcode} from '../utils/BarcodeScannerUtils.js';
import {callSafe, memoizeLast} from '../utils/FunctionUtils.js';
import {allEqual} from '../utils/SeqUtils.js';

import '../../../styles/commons/components/JSBasedBarcodeScanner.scss';

const EAN13_SUPPLEMENTS = {
	format: 'ean_reader',
	config: {supplements: ['ean_5_reader', 'ean_2_reader']}
};

const READERS_MAPPING = new Map([
	[BARCODE_TYPE_CODE_128, 'code_128_reader'],
	[BARCODE_TYPE_CODE_39, 'code_39_reader'],
	[BARCODE_TYPE_CODE_93, 'code_93_reader'],
	[BARCODE_TYPE_EAN_13, 'ean_reader'],
	[BARCODE_TYPE_EAN_13_EAN_5_EAN_2, EAN13_SUPPLEMENTS],
	[BARCODE_TYPE_EAN_8, 'ean_8_reader'],
	[BARCODE_TYPE_CODABAR, 'codabar_reader'],
	[BARCODE_TYPE_UPC_A, 'upc_reader'],
	[BARCODE_TYPE_UPC_E, 'upc_e_reader'],
	[BARCODE_TYPE_I2OF5, 'i2of5_reader']
]);

const DETECTION_MEAN_ERROR_THRESHOLD = 0.05;
const DETECTION_MEDIAN_ERROR_THRESHOLD = 0.04;
const DETECTION_IDENTICAL_RETRIES_COUNT = 5;

const MEDIA_TRACK_CONSTRAINTS = {
	facingMode: {ideal: 'environment'},
	aspectRatio: {exact: 1},
	resizeMode: 'crop-and-scale',
	width: {ideal: 1280},
	height: {ideal: 1280}
};

class JSBasedBarcodeScanner extends React.PureComponent {
	constructor(props) {
		super(props);
		const {locale, symbologies} = this.props;
		this.quagga = undefined;
		this.scanResults = [];
		this.detectionSuccessful = false;
		this.state = {
			streamLabel: '',
			devices: new Map(),
			torch: null
		};
		this.mediaTrackConstraints = MEDIA_TRACK_CONSTRAINTS;

		this.boundOnDetected = this.onDetected.bind(this);
		this.boundOnCameraChange = this.onCameraChange.bind(this);
		this.boundOnTriggerTorch = this.onTriggerTorch.bind(this);
		this.cameraSelectLabel = GeneralMessagesTranslator.getFormattedMessage('ChooseCamera', locale);
		this.readers = parseBarcodeTypes(symbologies)
			.map(({symbology}) => symbology)
			.filter((item, index, arr) => arr.indexOf(item) === index)
			.map(symbology => READERS_MAPPING.get(symbology));
		this.boundMeanErrorGuard = this.meanErrorGuard.bind(this);
		this.boundMedianErrorGuard = this.medianErrorGuard.bind(this);
		this.boundDetectionRetriesGuard = this.detectionRetriesGuard.bind(this);
		this.detectionQualityGuards = [
			this.boundMeanErrorGuard,
			this.boundMedianErrorGuard,
			this.boundDetectionRetriesGuard
		];
		this.memoizedGetErrorsFromDecodedCodes = memoizeLast(JSBasedBarcodeScanner.getErrorsFromDecodedCodes);
	}

	render() {
		const {torch} = this.state;
		const torchIcon = torch ? <FlashlightIcon /> : <FlashlightOffIcon />;
		return (
			<React.Fragment>
				<HorizontalLayout className='js-based-barcode-scanner' justify='start' align='start' wrap>
					<div id='interactive' className='viewport' />
					<HorizontalLayout className='js-based-barcode-scanner--controls' align='center'>
						{this.renderDeviceSelectBox()}
						<IconButton disabled={torch === null} className='media-stream-torch-switch'
						            onClick={this.boundOnTriggerTorch} icon={torchIcon} />
					</HorizontalLayout>
				</HorizontalLayout>
				<NotchInset variant='right' />
			</React.Fragment>
		);
	}

	renderDeviceSelectBox() {
		const {devices, streamLabel} = this.state;
		const options = Array.from(devices.keys());
		return (
			<SuggestComboBox id='select-camera' disabled={devices.size < 2} options={options} disableClearable
			                 onChange={this.boundOnCameraChange} value={streamLabel} label={this.cameraSelectLabel} />
		);
	}

	componentDidMount() {
		import(/* webpackChunkName: "quagga", webpackPrefetch: true */ '@ericblade/quagga2').then(module => {
			this.quagga = module.default;
			this.quaggaStart();
		});
	}

	quaggaStart() {
		const config = {
			locate: false,
			inputStream: {
				name: 'Live',
				type: 'LiveStream',
				constraints: this.mediaTrackConstraints
			},
			decoder: {
				readers: this.readers
			}
		};

		this.quagga.init(config, error => {
			if (!error) {
				this.quagga.start();
				this.checkTorchCapability();
				this.quagga.onDetected(this.boundOnDetected);
				this.quagga.CameraAccess.enumerateVideoDevices().then(videoDevices => {
					this.setState({
						devices: buildDevicesMap(videoDevices),
						streamLabel: this.quagga.CameraAccess.getActiveStreamLabel()
					});
				});
			}
		});
	}

	async quaggaStop() {
		if (this.quagga) {
			this.quagga.offDetected(this.boundOnDetected);
			await this.quagga.stop();
		}
	}

	checkTorchCapability() {
		const track = this.quagga.CameraAccess.getActiveTrack();
		let capabilities = {};
		if (typeof track.getCapabilities === 'function') {
			capabilities = track.getCapabilities();
		}
		const torchAvailable = capabilities.torch !== undefined;
		if (torchAvailable) {
			const settings = track.getSettings();
			this.setState({
				torch: settings.torch
			});
		} else {
			this.setState({
				torch: null
			});
		}
	}

	onCameraChange(value) {
		const {devices, streamLabel} = this.state;
		if (value && value !== devices.get(streamLabel)) {
			this.quaggaStop().then(() => {
				this.mediaTrackConstraints.deviceId = {exact: devices.get(value)};
				this.quaggaStart();
			});
		}
	}

	onDetected(data) {
		const {onChange} = this.props;
		const {codeResult} = data;
		if (codeResult && codeResult.decodedCodes.length > 0) {
			if (!this.detectionSuccessful && this.detectionQualityGuardsHandler(codeResult)) {
				const {code, format} = codeResult;
				const postProcessedCode = postProcessBarcode(code, format);
				if (postProcessedCode !== null) {
					this.detectionSuccessful = true;
					this.quaggaStop();
					callSafe(onChange, postProcessedCode);
				}
			}
		}
	}

	detectionQualityGuardsHandler(decodedCodes) {
		// https://github.com/serratus/quaggaJS/issues/237#issue-270285902
		return this.detectionQualityGuards.every(guard => guard(decodedCodes));
	}

	meanErrorGuard(codeResult) {
		const {decodedCodes} = codeResult;
		const errors = this.memoizedGetErrorsFromDecodedCodes(decodedCodes);
		const errorsSum = errors.reduce((a, b) => a + b, 0);
		const detectionErrorRate = errorsSum / decodedCodes.length;
		return detectionErrorRate < DETECTION_MEAN_ERROR_THRESHOLD;
	}

	medianErrorGuard(codeResult) {
		const {decodedCodes} = codeResult;
		const errors = this.memoizedGetErrorsFromDecodedCodes(decodedCodes);
		const median = getMedian(...errors) || 0;
		return median < DETECTION_MEDIAN_ERROR_THRESHOLD;
	}

	detectionRetriesGuard(codeResult) {
		// This guard is only needed if there is no error information.
		// Currently, this is the case with: CODABAR, CODE-93 and CODE-39.
		const {decodedCodes, code} = codeResult;
		const errors = this.memoizedGetErrorsFromDecodedCodes(decodedCodes);
		if (errors.length === 0) {
			this.scanResults.unshift(code);
			this.scanResults = this.scanResults.slice(0, DETECTION_IDENTICAL_RETRIES_COUNT);
			return this.scanResults.length === DETECTION_IDENTICAL_RETRIES_COUNT && allEqual(this.scanResults);
		}
		return true;
	}

	static getErrorsFromDecodedCodes(decodedCodes) {
		return decodedCodes
			.filter(result => result.error !== undefined)
			.map(result => result.error);
	}

	onTriggerTorch() {
		const {torch} = this.state;
		const torchAvailable = torch !== null;
		if (!torchAvailable) {
			return;
		}
		if (torch === false) {
			this.mediaTrackConstraints.advanced = [{torch: true}];
			this.applyConstraints(() => {
				this.setState({torch: true});
			});
		} else if (torch === true) {
			delete this.mediaTrackConstraints.advanced;
			// To turn torch off, mediaStreamTrack has to be restarted.
			// https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
			this.quaggaStop().then(() => {
				this.setState({torch: false}, () => this.quaggaStart());
			});
		}
	}

	applyConstraints(cb) {
		const track = this.quagga.CameraAccess.getActiveTrack();
		if (track) {
			track.applyConstraints(this.mediaTrackConstraints).then(cb);
		}
	}

	componentWillUnmount() {
		this.quaggaStop();
	}
}

function buildDevicesMap(videoDevices) {
	return videoDevices.reduce((devices, deviceInfo) => {
		const {label, deviceId, id} = deviceInfo;
		devices.set(label, deviceId || id);
		return devices;
	}, new Map());
}

JSBasedBarcodeScanner.propTypes = {
	onChange: PropTypes.func.isRequired,
	locale: PropTypes.string,
	symbologies: PropTypes.arrayOf(PropTypes.shape({
		symbology: PropTypes.string,
		regex: PropTypes.string
	}))
};

export default wrapInLocalizationContext(JSBasedBarcodeScanner);
