import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import NewShareReceiverForm from '../../components/NewShareReceiverForm.js';
import ShareReceiverFormAccessors from '../../components/ShareReceiverFormAccessors.js';
import {
	DATA_FIELD_RECEIVER_FILTER,
	DATA_FIELD_SHARE_MODE,
	MODE_EXISTING_RECEIVER
} from '../../constants/ShareDocumentConstants.js';
import {updateData} from '../ShareDocumentDialogActions.js';
import {selectData, selectNewShareReceiverExists} from '../ShareDocumentDialogSelectors.js';


export default connect(
	createStructuredSelector({
		stepperData: selectData,
		emailExists: selectNewShareReceiverExists,
		emailFieldValue: state => ShareReceiverFormAccessors.getFormFieldValue(state, 'email')
	}),
	bindActions({
		updateData
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {emailExists, emailFieldValue} = stateProps || {};
		const {updateData: boundUpdateData} = dispatchProps;
		return {
			...ownProps,
			emailExists,
			switchToReceiverSearch: () => boundUpdateData(
				stateProps.stepperData
					.set(DATA_FIELD_SHARE_MODE, MODE_EXISTING_RECEIVER)
					.set(DATA_FIELD_RECEIVER_FILTER, emailFieldValue)
			)
		};
	}
)(NewShareReceiverForm);
