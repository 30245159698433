import React from 'react';

import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

export default React.memo(UploadSuccessStatus);

function UploadSuccessStatus() {
	return (
		<React.Fragment>
			<StatusMessage>
				<SynFormattedMessage translator={ArchiveDocumentsTranslator} className='archive-documents--message'
				                     message='SuccessMessageShort' />
			</StatusMessage>
			<SynFormattedMessage translator={ArchiveDocumentsTranslator} className='archive-documents--message'
			                     message='SuccessMessageDetails' />
		</React.Fragment>
	);
}
