import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createSelector, createStructuredSelector} from 'reselect';

import {fragmentSelector, pathSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {ASC, DESC} from '../../constants/SortOrders.js';
import LinkContainer from '../LinkContainer.js';

export default function createSortLinkContainer(
		ChildComponent, sortParamsSelector, sortParameterName, defaultSortProperties, correspondingDefaultSortOrder
) {
	const currentSortOrder = getCurrentSortOrderSelector(
		sortParamsSelector, defaultSortProperties, correspondingDefaultSortOrder
	);
	const nextSortProperties = getNextSortPropertiesSelector(
		currentSortOrder, defaultSortProperties, correspondingDefaultSortOrder
	);
	const nextQueryPath = getNextQueryPathSelector(nextSortProperties, sortParameterName);

	return connect(createStructuredSelector({
		linkProps: createStructuredSelector({
			query: nextQueryPath,
			path: pathSelector,
			fragment: fragmentSelector
		}),
		sortOrder: currentSortOrder
	}),
	undefined,
	function merge(stateProps, dispatchProps, ownProps) {
		const {linkProps, sortOrder} = stateProps || {};
		return {
			linkProps,
			childProps: {...ownProps, sortOrder}
		};
	})(function SortLink({linkProps, childProps}) {
		return (
			<LinkContainer {...linkProps}>
				<ChildComponent {...childProps} />
			</LinkContainer>
		);
	});
}

function getCurrentSortOrderSelector(sortParamsSelector, defaultSortProperties, correspondingDefaultSortOrder) {
	return createSelector(sortParamsSelector, sortParams => {
		let sortOrderIsSameAsCorrespondingDefaultSortOrder;
		const haveSameNumberOfSortProperties = sortParams.size === defaultSortProperties.size;
		const doesSortPropertiesMatch = haveSameNumberOfSortProperties && defaultSortProperties.every(
			(defaultSortProperty, index) => {
				const defaultSortPropertyName = defaultSortProperty.get(0);
				const defaultSortPropertyOrder = defaultSortProperty.get(1);
				const sortParamName = sortParams.getIn([index, 0]);
				const sortParamSortOrder = sortParams.getIn([index, 1]);

				const paramNameMatches = sortParamName === defaultSortPropertyName;
				sortOrderIsSameAsCorrespondingDefaultSortOrder = sortOrderIsSameAsCorrespondingDefaultSortOrder ||
					sortParamSortOrder === defaultSortPropertyOrder;
				const sortOrderMatches = sortOrderIsSameAsCorrespondingDefaultSortOrder
					? (sortParamSortOrder === defaultSortPropertyOrder)
					: sortParamSortOrder !== defaultSortPropertyOrder;
				return paramNameMatches && sortOrderMatches;
			});
		const correspondingSortOrder = (sortOrderIsSameAsCorrespondingDefaultSortOrder)
			? correspondingDefaultSortOrder
			: invertSortOrder(correspondingDefaultSortOrder);
		return doesSortPropertiesMatch ? correspondingSortOrder : undefined;
	});
}

function getNextSortPropertiesSelector(currentSortOrder, defaultSortProperties, correspondingDefaultSortOrder) {
	return createSelector(currentSortOrder, currentSortOrderState => {
		let nextSortPropertiesResult = defaultSortProperties;
		if (currentSortOrderState === correspondingDefaultSortOrder) {
			nextSortPropertiesResult = defaultSortProperties.map(
				defaultSortProperty => Immutable.List.of(
					defaultSortProperty.get(0), invertSortOrder(defaultSortProperty.get(1))
				));
		}
		return nextSortPropertiesResult;
	});
}

function getNextQueryPathSelector(nextSortProperties, sortParameterName) {
	return createSelector(
		querySelector,
		nextSortProperties,
		(query, nextSortPropertiesState) => query.set(sortParameterName, Immutable.List(nextSortPropertiesState))
	);
}

function invertSortOrder(sortOrder) {
	return (sortOrder === ASC && DESC) || ASC;
}
