import React from 'react';

export default function createDataTableContainerCreator(connector) {
	return function DataTableContainerCreator(DataTable) {
		const ConnectedDataTable = connector(DataTable);

		return class DataTableContainer extends React.Component {
			constructor(props, context) {
				super(props, context);
				this.boundOnRowCountChange = this.onRowCountChange.bind(this);
				this.state = {
					rowCount: DataTable.measureNumberRowsForHeight(window.innerHeight)
				};
			}

			render() {
				const {rowCount} = this.state;
				return (
					<ConnectedDataTable rowsPerPage={rowCount} onRowCountChange={this.boundOnRowCountChange}
					                    {...this.props} />
				);
			}

			onRowCountChange(newRowCount) {
				this.setState({
					rowCount: newRowCount
				});
			}
		};
	};
}
