import React from 'react';
import PropTypes from 'prop-types';

import AutoBrickFactory from '../../bricks/components/AutoBrickFactory.js';
import {IMAGE_JPEG, IMAGE_PNG} from '../../commons/constants/MimeTypes.js';
import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe, memoizeByFirstArg, noop} from '../../commons/utils/FunctionUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext.js';
import DialogBody from '../../material-design/components/DialogBody.js';
import DialogHeader from '../../material-design/components/DialogHeader.js';
import StepperDialog from '../../material-design/components/StepperDialog.js';
import ImageCollection from '../bricks/ImageCollection.js';
import {DATA_FIELD_IMAGES} from '../constants/ArchiveDocumentsConstants.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';
import GuardedArchiveDocumentsContent from './GuardedArchiveDocumentsContent.js';

import '../../../styles/archive-documents/components/ArchiveDocumentsDialog.scss';

const ALLOWED_MIME_TYPES = [IMAGE_JPEG, IMAGE_PNG];

class ArchiveDocumentsDialog extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundInvokeDialogClose = this.invokeDialogClose.bind(this);
		this.boundGetImageCollection = this.getImageCollection.bind(this);
		this.getDialogTitle = memoizeByFirstArg(
			locale => ArchiveDocumentsTranslator.getFormattedMessage('CaptureImagesDialogTitle', locale)
		);
		this.imageCollection = new ImageCollection(ALLOWED_MIME_TYPES);
		this.unsubscribeUpdateData = noop;
	}

	render() {
		const {locale} = this.props;
		return (
			<StepperDialog className='archive-documents-dialog'>
				<TranslationProvider translator={ArchiveDocumentsTranslator}>
					<AutoBrickFactory brickType={ImageCollection} brickFactory={this.boundGetImageCollection} />
					<DialogHeader onClose={this.boundInvokeDialogClose} title={this.getDialogTitle(locale)}
									  alwaysShowCloseButton />
					<DialogBody>
						<GuardedArchiveDocumentsContent handleDialogClose={this.boundInvokeDialogClose} />
					</DialogBody>
				</TranslationProvider>
			</StepperDialog>
		);
	}

	getImageCollection() {
		return this.imageCollection;
	}

	invokeDialogClose() {
		const {onClose, deleteStepperState} = this.props;
		if (Boolean(onClose) && Boolean(deleteStepperState)) {
			onClose();
			deleteStepperState();
		}
	}

	subscribeUpdateData(updateData, data) {
		this.unsubscribeUpdateData();
		// TODO: Resolve dualism between Redux and ImageCollection Brick here.
		//  Images collection should not be sourced from more then one sources.
		this.unsubscribeUpdateData = this.imageCollection.subscribe(imageCollection => {
			callSafe(updateData, data.set(DATA_FIELD_IMAGES, imageCollection.getImages()));
		});
	}

	componentDidMount() {
		const {updateData, data} = this.props;
		this.subscribeUpdateData(updateData, data);
	}

	componentDidUpdate(prevProps) {
		const {updateData, data, wasCancelled, hasFinished} = this.props;
		if ((!prevProps.wasCancelled && wasCancelled) || (!prevProps.hasFinished && hasFinished)) {
			this.boundInvokeDialogClose();
		}

		if (prevProps.updateData !== updateData || prevProps.data !== data) {
			this.subscribeUpdateData(updateData, data);
		}
	}

	componentWillUnmount() {
		this.unsubscribeUpdateData();
		this.unsubscribeUpdateData = noop;
		this.imageCollection.shutdown();
	}
}

ArchiveDocumentsDialog.propTypes = {
	updateData: PropTypes.func,
	data: immutablePropType,
	wasCancelled: PropTypes.bool,
	hasFinished: PropTypes.bool,
	locale: PropTypes.string,
	onClose: PropTypes.func,
	deleteStepperState: PropTypes.func
};

export default wrapInLocalizationContext(ArchiveDocumentsDialog);
