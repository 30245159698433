import {NEW_PASSWORD_FORM_SCHEMA} from '../../../authentication/components/accessors/NewPasswordFormAccessors';
import createFormAccessors from '../../../commons/data/FormAccessorsFactory';
import {email, minLength, phonenumber, required} from '../../../commons/data/validators';
import DocumentPermitClaimTranslator from '../../i18n/DocumentPermitClaimTranslator';

const NAME_MIN_LENGTH = 3;

const DOCUMENT_PERMIT_REGISTER_USER_FORM_SCHEMA = {
	fields: {
		firstName: {
			label: 'FieldLabelFirstName',
			defaultValue: null,
			validators: [required, minLength(NAME_MIN_LENGTH)]
		},
		lastName: {
			label: 'FieldLabelLastName',
			defaultValue: null,
			validators: [required, minLength(NAME_MIN_LENGTH)]
		},
		eMail: {
			label: 'FieldLabelEMailAddress',
			defaultValue: null,
			validators: [required, email]
		},
		mobileNumber: {
			label: 'FieldLabelMobileNumber',
			defaultValue: null,
			validators: [phonenumber]
		},
		...NEW_PASSWORD_FORM_SCHEMA.fields
	}
};

export default createFormAccessors(DOCUMENT_PERMIT_REGISTER_USER_FORM_SCHEMA, DocumentPermitClaimTranslator);
