import React from 'react';
import {styled} from '@mui/material/styles';

import GridListConstants from '../../../constants/GridListConstants.json';
import GridTile from '../../material-design/components/layout/GridTile.js';

const TILE_WIDTH = GridListConstants['tile-width'];
const TILE_BORDER_RADIUS = GridListConstants['tile-border-radius'];
const TILE_MARGIN = GridListConstants['tile-margin'];
const TILE_SIZE = TILE_WIDTH - (2 * TILE_MARGIN);
export const TILE_STYLE = {
	width: `${TILE_SIZE}px`,
	height: `${TILE_SIZE}px`,
	borderRadius: `${TILE_BORDER_RADIUS}px`,
	'& img[alt="thumbnail"]': {
		width: `${TILE_SIZE}px`,
		height: `${TILE_SIZE}px`,
		objectFit: 'contain'
	}
};

export default styled(GridTile, {name: 'DocumentBrowserGridTile'})(TILE_STYLE);
