import React from 'react';
import {useSelector} from 'react-redux';

import LoginWorkflowControllerContainer from '../../authentication/flux/containers/LoginWorkflowControllerContainer.js';
import createSessionGuardedComponentSelector from '../../authentication/utils/createSessionGuardedComponentSelector.js';
import {ROOT_PATH} from '../../commons/constants/CommonRoutingConstants.js';
import {debugLog} from '../../commons/utils/DebugLog.js';
import createLocation from '../../router/createLocation.js';
import {redirectTo} from '../../router/RoutingUtils.js';

export default function SessionGuardedCanNotBeClaimedHandler() {
	const GuardedComponent = useSelector(pageSelector);
	return <GuardedComponent />;
}

const RedirectToRoot = redirectTo(createLocation({
	path: ROOT_PATH
}));

const pageSelector = createSessionGuardedComponentSelector(
	() => function Redirector() {
		debugLog('PermitId is either invalid or was already claimed by other user. Redirecting to root path.');
		return <RedirectToRoot />;
	},
	LoginWorkflowControllerContainer
);
