import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ArchiveDocumentsTranslator from '../i18n/ArchiveDocumentsTranslator.js';

function UploadSummaryInfo(props) {
	const {totalFiles, numUploadedFiles} = props;
	const messageParams = {
		numUploadedFiles,
		totalFiles
	};

	return (<SynFormattedMessage messageParams={messageParams} message='UploadFilesProgress'
										 translator={ArchiveDocumentsTranslator} />);
}

UploadSummaryInfo.propTypes = {
	numUploadedFiles: PropTypes.number.isRequired,
	totalFiles: PropTypes.number.isRequired
};

export default React.memo(UploadSummaryInfo);
