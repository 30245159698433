import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import PhoneField from '../../commons/components/PhoneField.js';
import Tappable from '../../commons/components/Tappable.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {callSafe, noop} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import EMailFormField from '../../material-design/components/form/EMailFormField.js';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import ShareDocumentMessagesTranslator from '../i18n/ShareDocumentTranslator.js';
import ShareReceiverFormAccessors from './ShareReceiverFormAccessors.js';

import '../../../styles/share-document/components/NewShareReceiverForm.scss';


export default class NewShareReceiverForm extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundSwitchToReceiverSearch = this.switchToReceiverSearch.bind(this);

		this.blurHandlers = {};
		this.state = {
			validateFields: Immutable.Set()
		};
	}

	render() {
		return (
			<FormContainer id='create-share-receiver-form' description={ShareReceiverFormAccessors}
			               initialValuesSelector={ShareReceiverFormAccessors.getAllMappedFormFieldValues}>
				{this.createEMailFormField()}
				{this.createSingleLineFormField('firstName')}
				{this.createSingleLineFormField('lastName')}
				{this.createMobilePhoneNumberFormField()}
			</FormContainer>
		);
	}

	renderExistingEmailMessage() {
		return (
			<React.Fragment>
				<SynFormattedMessage translator={ShareDocumentMessagesTranslator} message='EmailAlreadyRegistered' />
				(
				<Tappable onClick={this.boundSwitchToReceiverSearch} Component={SynFormattedMessage} element='span'
				          translator={ShareDocumentMessagesTranslator} message='ShowSearchResults'
				          className='new-share-receiver-form--show-search-results-link' />
				).
			</React.Fragment>
		);
	}

	onFieldBlurred(fieldName) {
		this.setState(currentState => ({
			validateFields: currentState.validateFields.add(fieldName)
		}));
		this.blurHandlers[fieldName] = noop;
	}

	createBlurHandler(fieldName) {
		let focusHandler = this.blurHandlers[fieldName];
		if (focusHandler === undefined) {
			focusHandler = () => this.onFieldBlurred(fieldName);
			this.blurHandlers[fieldName] = focusHandler;
		}
		return focusHandler;
	}

	createSingleLineFormField(fieldName, additionalProps = {}, FormFieldComponent = SingleLineFormField) {
		const {validateFields} = this.state;
		const displayValidationResult = validateFields.has(fieldName);
		const onBlur = this.createBlurHandler(fieldName);
		return (<FormFieldComponent displayValidationResult={displayValidationResult} onBlur={onBlur}
											 fieldName={fieldName} key={fieldName} {...additionalProps} />);
	}

	createExistingEmailProps() {
		return {
			hint: this.renderExistingEmailMessage(),
			invalid: true
		};
	}

	switchToReceiverSearch() {
		const {switchToReceiverSearch} = this.props;
		callSafe(switchToReceiverSearch);
	}

	createEMailFormField() {
		const {emailExists} = this.props;
		const additionalProps = emailExists ? this.createExistingEmailProps() : undefined;
		return this.createSingleLineFormField('email', additionalProps, EMailFormField);
	}

	createMobilePhoneNumberFormField() {
		return this.createSingleLineFormField('mobileNumber', {
			component: PhoneField,
			forceFloating: true
		});
	}
}

NewShareReceiverForm.propTypes = {
	switchToReceiverSearch: PropTypes.func,
	emailExists: PropTypes.bool
};
