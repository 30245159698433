import React from 'react';
import PropTypes from 'prop-types';

import PatientContext from '../../../webupload/bricks/PatientContext.js';
import BrickGuard from '../../bricks/components/BrickGuard.js';
import useBrick from '../../bricks/hooks/useBrick.js';
import AppBarSidebarHeader from '../../commons/components/webview/AppBarSidebarHeader.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import IconButton from '../../ui/components/IconButton.js';
import BackIcon from '../../ui/components/icons/BackIcon.js';
import WebViewUploadController from '../bricks/WebViewUploadController';
import WebViewUploadAppBarBody from './WebViewUploadAppBarBody.js';

export default function WebViewUploadAppBar(props) {
	const {sidebarElevated} = props;
	const {controllerState, restartController, setStateReady} = useBrick(
		WebViewUploadController, selectWebViewUploadControllerProps
	);
	const clearPatient = useBrick(PatientContext, context => context.clearPatient);
	const backButtonNode = useMemoFactory(getBackButton, controllerState, clearPatient, restartController,
		setStateReady
	);

	return (
		<BrickGuard brick={WebViewUploadController}>
			<WebViewUploadAppBarBody button={backButtonNode}>
				<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
			</WebViewUploadAppBarBody>
		</BrickGuard>
	);
}

function selectWebViewUploadControllerProps(brick) {
	return {
		controllerState: brick.getState(),
		restartController: brick.restart,
		setStateReady: brick.setStateReady
	};
}

function getBackButton(controllerState, clearPatient, restartController, setStateReady) {
	let backButtonHandler;
	switch (controllerState) {
		case WebViewUploadController.State.PREPARING:
			backButtonHandler = clearPatient;
			break;
		case WebViewUploadController.State.SELECT_PRODUCER:
			backButtonHandler = setStateReady;
			break;
		case WebViewUploadController.State.UPLOADING:
			backButtonHandler = null;
			break;
		default:
			backButtonHandler = restartController;
	}
	return (
		<IconButton onClick={backButtonHandler} color='inherit'>
			<BackIcon />
		</IconButton>
	);
}

WebViewUploadAppBar.propTypes = {
	sidebarElevated: PropTypes.bool.isRequired
};
