import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import ErrorCard from '../../commons/components/errors/ErrorCard.js';
import AbstractPatientSearchService from '../AbstractPatientSearchService.js';

const errorMessages = {
	[AbstractPatientSearchService.Error.FETCH_FAILED]: 'Error_PatientSearchFetchFailed'
};

export default function PatientSearchErrorDisplay(props) {
	const {reset} = useBrick(AbstractPatientSearchService, selectPatientSearchServiceProps);
	const {errorType} = props;
	const errorsList = [
		{
			message: errorMessages[errorType]
		}
	];
	return <ErrorCard statusMessage='PatientSearchErrorShort' onErrorConfirmed={reset} errors={errorsList} />;
}

PatientSearchErrorDisplay.propTypes = {
	errorType: PropTypes.symbol.isRequired
};

function selectPatientSearchServiceProps(service) {
	return {
		reset: service.reset
	};
}
