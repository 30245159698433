import React from 'react';

import UploadFilesCollection from '../../../webupload/bricks/UploadFilesCollection.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {createFilterAllowedMimeTypes} from '../../commons/utils/filesCollectionFilters.js';

export default class ImageCollection extends UploadFilesCollection {
	constructor(allowedMimeTypes) {
		super();
		//TODO MPe :: This filter might be better realised by using UploadFilesCollector
		this.filesCollectionFilter = createFilterAllowedMimeTypes(allowedMimeTypes);
	}

	addImages(images) {
		this.addFileList(this.filesCollectionFilter(images));
	}

	removeImages(images) {
		this.updateBrickState(oldState => {
			images.forEach(image => this.removePreparedFile(image.name));
			return oldState;
		});
	}

	getImages() {
		return this.getPreparedFiles('').map(name => this.filesCollection.getValue(name, 'prepared'));
	}
}
declareBrick(ImageCollection);
