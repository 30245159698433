import React from 'react';
import PropTypes from 'prop-types';

import FileSelectionForm from '../../commons/components/form/FileSelectionForm.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import ArchiveDocumentsFileButton from '../../ui/components/ArchiveDocumentsFileButton.js';
import CameraIcon from '../../ui/components/icons/CameraIcon.js';
import {COLOR_PRIMARY} from '../../ui/constants/SynMUIOptions.js';
import DocumentsBrowserTile from './DocumentsBrowserGridTile.js';

export default function OpenCameraTile(props) {
	const {onFileChange, label} = props;
	const photoButton = useMemoFactory(createPhotoUploadButton, label);
	return (
		<DocumentsBrowserTile className='photo-upload-button-tile'>
			<FileSelectionForm className='photo-upload-button' handleFileList={onFileChange} cameraButton={photoButton} multi />
		</DocumentsBrowserTile>
	);
}

OpenCameraTile.propTypes = {
	onFileChange: PropTypes.func,
	label: PropTypes.string.isRequired
};

function createPhotoUploadButton(buttonLabel) {
	return (
		<ArchiveDocumentsFileButton startIcon={<CameraIcon />} color={COLOR_PRIMARY}>
			{buttonLabel}
		</ArchiveDocumentsFileButton>
	);
}
