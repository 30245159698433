import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import searchResultPatientInfo from '../../search/components/SearchResultPatientInfo.js';
import {resultListPropType} from '../../search/searchLayoutUtil.js';
import List from '../../ui/components/List';
import MenuEntry from '../../ui/components/MenuEntry.js';
import Ellipsis from '../../ui/components/typography/Ellipsis.js';
import {uiIsSmallDevice} from '../../ui/flux/UISelectors.js';
import PatientIdInfo from './PatientIdInfo.js';

const MUI_LIST_HEIGHT = 73;
const ITEM_STYLE = {
	minHeight: `${MUI_LIST_HEIGHT}px`,
	maxHeight: `${MUI_LIST_HEIGHT}px`,
	height: `${MUI_LIST_HEIGHT}px`
};

export default function SmallPatientSearchResult(props) {
	const {resultList, onPatientClick} = props;
	return (
		<List noVerticalPadding>
			{resultList
				.getList()
				.map(result => renderSmallPatientSearchResult(onPatientClick, result))
			}
		</List>
	);
}

SmallPatientSearchResult.propTypes = {
	resultList: resultListPropType.isRequired,
	onPatientClick: PropTypes.func.isRequired
};

SmallPatientSearchResult.measureNumberRowsForHeight = function measureRowsFunction(height) {
	return Math.max(1, Math.floor(height / MUI_LIST_HEIGHT));
};

const PatientInfoComponent = searchResultPatientInfo();

function PatientInfo(props) {
	const {onPatientClick, patientInfo} = props;
	const patientId = patientInfo.get('patient_id');
	const isSmallDevice = useSelector(uiIsSmallDevice);
	const patientInfoText = useMemoFactory(createPatientInfoText, patientInfo, isSmallDevice);
	const patientIdText = useMemoFactory(createPatientIdInfo, patientInfo);
	const onPatientClickCallback = useCallback(() => onPatientClick(patientInfo), [onPatientClick, patientInfo]);
	return (
		<MenuEntry id={patientId} key={patientId} onClick={onPatientClickCallback}
					  primaryText={patientInfoText} secondaryText={patientIdText}
		           style={ITEM_STYLE} asListItem button divider />
	);
}

PatientInfo.propTypes = {
	onPatientClick: PropTypes.func.isRequired,
	patientInfo: immutableMapPropType.isRequired
};

function createPatientInfoText(result, useShortBirthDate) {
	return <PatientInfoComponent result={result} element={Ellipsis} shortBirthDate={useShortBirthDate} />;
}

function createPatientIdInfo(result) {
	return <PatientIdInfo patientInfo={result} />;
}

function renderSmallPatientSearchResult(onPatientClick, result) {
	const resultKey = `${result.get('id')}_${result.get('visit_id')}`;
	return (
		<PatientInfo key={resultKey} onPatientClick={onPatientClick} patientInfo={result} />
	);
}
