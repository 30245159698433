import Immutable, {Record} from 'immutable';

import {booleanValue} from '../utils/BooleanUtils.js';

export const DescriptionEntry = new Record({
	description: '',
	isDefault: false
});

export const ArchivePermission = new Record({
	orgunitAbk: null,
	orgunitName: '',
	isDefault: false,
	isDescriptionEditable: false,
	descriptions: Immutable.List()
}, 'ArchivePermissionEntry');


function descriptionEntryFromRawJSON(rawJSON) {
	return new DescriptionEntry({
		description: rawJSON.description,
		isDefault: booleanValue(rawJSON.is_default)
	});
}

export default function convertToArchivePermission(rawJSON) {
	return new ArchivePermission({
		orgunitAbk: rawJSON.abk,
		orgunitName: rawJSON.name,
		isDefault: booleanValue(rawJSON.is_default),
		isDescriptionEditable: booleanValue(rawJSON.description_editable),
		descriptions: Immutable.fromJS(rawJSON.descriptions.map(descriptionEntryFromRawJSON))
	});
}
