import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SuggestComboBox from '../../ui/components/SuggestComboBox.js';

function AdditionalDataComboBox(props) {
	const {options, selectedOptionsIndex, onChange, label, errorMessage, hintMessage, id} = props;
	const onChangeImpl = useMemoFactory(createOnChangeImpl, options, onChange);
	let value = null;
	let error = true;
	let noSelectionErrorMessage = errorMessage || hintMessage;
	if (typeof selectedOptionsIndex === 'number') {
		error = false;
		noSelectionErrorMessage = hintMessage;
		value = options[selectedOptionsIndex];
	}
	return (
		<SuggestComboBox id={id} onChange={onChangeImpl} label={label} value={value} hint={noSelectionErrorMessage}
		                 error={error} options={options} />
	);
}

AdditionalDataComboBox.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	id: PropTypes.string,
	label: PropTypes.string,
	errorMessage: PropTypes.string,
	hintMessage: PropTypes.string,
	selectedOptionsIndex: PropTypes.number,
	onChange: PropTypes.func
};

function createOnChangeImpl(options, onChange) {
	return function onChangeImpl(newValue) {
		let optionsEntryKey = options.findIndex(option => option === newValue);
		if (optionsEntryKey === -1) {
			optionsEntryKey = null;
		}
		callSafe(onChange, optionsEntryKey);
	};
}

export default AdditionalDataComboBox;
