import createRestSearchApiCall from '../commons/api/createRestSearchApiCall.js';

const PATIENT_BASE_URL = '/patient';
const DATE_RANGE_PARAMETERS = ['birth_date'];
const DEFAULT_SORT_BY = [['last_name', 'ASC'], ['first_name', 'ASC'], ['birth_date', 'ASC'], ['patient_id', 'ASC']];

const searchPatientsApiCall = createRestSearchApiCall(PATIENT_BASE_URL, DATE_RANGE_PARAMETERS, DEFAULT_SORT_BY);

function createSearchPatients(additionalParams = {}) {
	/**
	 * Searches for patients, that match the given search parameters.
	 * Currently the following search parameters are supported:
	 *  sex: string - [F|M|O]
	 *  birth_date: Immutable.Map({from: moment[, to: moment]})
	 *  first_name: string
	 *  last_name: string
	 *  search_id: string - a PatientID, PatientAIMID or VisitID
	 *
	 * @param searchParams - parameter to use in search
	 * @param sortBy - sort order for returned results according to internal WeServices documentation
	 * @param start - first result to return
	 * @param fetchLimit - number of results to fetch
	 * @param limit - number of results to query from db
	 * @returns {Promise<ResultList>} - instance of ResultList representing the search results
	 */
	function searchPatients(searchParams, sortBy, start, fetchLimit, limit) {
		// This function allows us to use a specialized interface for searchParams when switching to TypeScript
		const preparedSearchParams = prepareSearchParams(searchParams);
		return searchPatientsApiCall({...preparedSearchParams, ...additionalParams}, sortBy, start, fetchLimit, limit);
	}
	return searchPatients;
}

function prepareSearchParams(searchParams) {
	return ['first_name', 'last_name'].reduce((finalSearchParams, paramName) => {
		const paramValue = finalSearchParams[paramName];
		if (paramValue) {
			finalSearchParams[paramName] = makeFuzzy(paramValue);
		}
		return finalSearchParams;
	}, {...searchParams});
}

function makeFuzzy(searchTerm) {
	const trimmedSearchTerm = searchTerm.trim();
	return trimmedSearchTerm.endsWith('%') ? trimmedSearchTerm : `${trimmedSearchTerm}%`;
}

const searchCreateOrderPatients = createSearchPatients();
const searchUploadPatients = createSearchPatients({accepts_upload: true});

export {searchCreateOrderPatients as searchPatients, searchUploadPatients};
