import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../authentication/constants/SessionActionTypes.js';
import {booleanValue} from '../../commons/utils/BooleanUtils.js';
import {
	BEGIN_SEARCH,
	CANCEL_SEARCH,
	CLEAR_SEARCH_RESULTS,
	PROCESS_SEARCH_RESULTS, SEARCH_ABORTED
} from '../constants/SearchActionTypes.js';
import {
	SEARCH_QUERY_PARAMS, SEARCH_RESULTS,
	SEARCH_SEARCH_IN_PROGRESS
} from '../constants/SearchPropertyNames.js';

const INITIAL_REDUCER_STATE = Immutable.Map({
	[SEARCH_RESULTS]: null,
	[SEARCH_QUERY_PARAMS]: null,
	[SEARCH_SEARCH_IN_PROGRESS]: null
});

export default handleActions({
	[PROCESS_SEARCH_RESULTS]: processSearchResults,
	[BEGIN_SEARCH]: beginSearchHandler,
	[SEARCH_ABORTED]: searchAbortedHandler,
	[CLEAR_SEARCH_RESULTS]: clearSearchResultsHandler,
	[CANCEL_SEARCH]: getInitialState,
	[BEGIN_SESSION]: getInitialState,
	[END_SESSION]: getInitialState
}, getInitialState());

export function getInitialState() {
	return INITIAL_REDUCER_STATE;
}

/**
 * @param state the current state
 * @param payload consists of { searchNumber, queryParams, sortParams, start, limit }
 * @returns the state containing the new search
 */
function beginSearchHandler(state, {payload}) {
	return state.set(SEARCH_SEARCH_IN_PROGRESS, Immutable.Map(payload));
}

function processSearchResults(state, {payload: {results, searchNumber, queryParams}}) {
	let newState = state;
	if (state.getIn([SEARCH_SEARCH_IN_PROGRESS, 'searchNumber'], null) === searchNumber) {
		newState = state
			.set(SEARCH_RESULTS, prepareResults(results))
			.set(SEARCH_SEARCH_IN_PROGRESS, null)
			.set(SEARCH_QUERY_PARAMS, queryParams);
	}
	return newState;
}

function searchAbortedHandler(state, {payload: searchNumber}) {
	let newState = state;
	if (state.getIn([SEARCH_SEARCH_IN_PROGRESS, 'searchNumber'], null) === searchNumber) {
		newState = state.set(SEARCH_SEARCH_IN_PROGRESS, null);
	}
	return newState;
}

function prepareResults(resultList) {
	return resultList.map(
		result => result
			.update('shareable', false, booleanValue)
			.update('exportable', false, booleanValue)
	);
}

function clearSearchResultsHandler(state /*, action*/) {
	return state
		.set(SEARCH_RESULTS, getInitialState().get(SEARCH_RESULTS))
		.set(SEARCH_QUERY_PARAMS, getInitialState().get(SEARCH_QUERY_PARAMS));
}
