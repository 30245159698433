import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createSelector, createStructuredSelector} from 'reselect';

import {modality as modalityFluxApi} from '../../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {MaterialFormSuggestComboBox} from '../../../material-design/components/form/MaterialFormComboBox.js';

const {
	selectors: {getModalities}
} = modalityFluxApi;

const SUGGESTED_MODALITIES = Immutable.Set(['CR', 'CT', 'DX', 'MR', 'US', 'XA']);
export default connect(
	mapStateToProps,
	undefined,
	mergeProps
)(ModalityComboBox);

function ModalityComboBox(props) {
	return (
		<MaterialFormSuggestComboBox {...props} groupBy={groupModalitiesBy} getOptionLabel={getModalityLabel}
		                             getSelectedValue={getSelectedValue} getValue={getValue} />
	);
}

function getValue(value, options) {
	const selectedOption = options.find(modality => modality.id === value);
	return typeof selectedOption === 'undefined' ? '' : selectedOption;
}

function getSelectedValue(newValue) {
	if (newValue === null) {
		return '';
	}

	const {id} = newValue;
	if (typeof id === 'string') {
		return id;
	}
	return newValue;
}


function groupModalitiesBy(option) {
	return option.groupLabel;
}

function getModalityLabel(option) {
	return option.modality;
}

function mapStateToProps(state, props) {
	return createStructuredSelector({
		options: createSelector(getModalities, currentLocale,
			(modalities, locale) => buildModalityOptions(modalities, locale, props)
		)
	});
}

function mergeProps(stateProps, dispatchProps, ownProps) {
	const {value, ...remainingOwnProps} = ownProps;
	const remainingProps = cloneWithoutProperties(remainingOwnProps, 'getGroupLabel');
	return {
		...stateProps,
		...dispatchProps,
		...remainingProps,
		value: value || '',
		emptyLabel: '',
		fullWidth: true
	};
}

function buildModalityOptions(modalities, locale, props) {
	const {getGroupLabel} = props;

	const modalityGroups =
		buildModalityGroups(modalities)
			.map(convertToModalityDisplayMap);

	return convertToComboBoxGroups(modalityGroups, getGroupLabel, locale);
}

function buildModalityGroups(allModalities) {
	return Immutable.List([
		extractSuggestedModalities(allModalities),
		allModalities.getList()
	]);
}

function extractSuggestedModalities(allModalities) {
	return allModalities
		.getList()
		.filter(isSuggestedModality);
}

function isSuggestedModality(modalityEntry) {
	return SUGGESTED_MODALITIES.has(modalityEntry.get('id'));
}

function convertToModalityDisplayMap(modalities) {
	return modalities.reduce(
		(collectedModality, modality) => collectedModality.set(modality.get('id'), modality.get('display')),
		Immutable.Map()
	);
}

function convertToComboBoxGroups(modalityGroups, getGroupLabel, locale) {
	const optionList = modalityGroups
		.filter(modalities => !modalities.isEmpty())
		.reduce(
			(options, modalities, group) => {
				const groupLabel = getGroupLabel(group, locale);
				modalities.forEach((modality, id) => {
					options.push({
						groupLabel,
						modality,
						id
					});
				});
				return options;
			}, []
		);
	optionList.unshift({modality: '', id: ''});
	return optionList;
}

