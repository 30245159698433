import convertToPatientVisit from '../data/PatientVisit.js';
import search from './operations/search.js';

export const PATIENT_VISIT_URL = '/patientvisit';

function processRawResults(rawResults) {
	const {results} = rawResults;
	rawResults.results = results.map(convertToPatientVisit);
	return rawResults;
}

export function searchVisits(patientAimId) {
	const params = {patient_aimid: patientAimId};
	return search(PATIENT_VISIT_URL, {params}, processRawResults);
}
