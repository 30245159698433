import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createStructuredSelector} from 'reselect';

import LinkContainer from '../../../commons/containers/LinkContainer.js';
import {webAppURL} from '../../../commons/WebViewHelpers.js';
import {querySelector} from '../../../router/flux/selectors/LocationSelectors.js';

export default connect(createStructuredSelector({
	query: querySelector
}), undefined, function merge(stateProps, dispatchProps, ownProps) {
	const {document} = ownProps;
	const {query} = stateProps || {};
	const patientId = document.get('patient_aimid');
	const documentId = document.get('id');

	const finalProps = {
		...ownProps, path: webAppURL(`patient/${patientId}/document`),
		fragment: `document=${documentId}`,
		query: Immutable.Map.of('originQueryParams', query)
	};
	delete finalProps.document;
	return finalProps;
})(LinkContainer);
